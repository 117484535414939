import { h } from 'preact';

import { routes } from '../../routes';
import { Banner, BannerActions, BannerTitle, BannerContent } from '../components/Banner';
import { ButtonLink } from '../components/Button';

const BannerVerifyEmail = () => (
    <Banner light>
        <BannerTitle>Welcome to Hugo!</BannerTitle>
        <BannerContent>
            <p>First, we need to verify your email to send you important documents.</p>
        </BannerContent>
        <BannerActions>
            <ButtonLink classNames="track:email-banner__verify" href={routes.ACCOUNT_EMAIL.path}>
                Verify
            </ButtonLink>
        </BannerActions>
    </Banner>
);

export default BannerVerifyEmail;
