// masks.js
// Helpers for masking values to fit a specific format.

import { lpad, moneyString } from './formatters';

/**
 * Mask provided value into phone number format `(###) ###-####`
 * @param {Number|String} value to format
 * @returns {String}
 */
export const phoneNumber = (value = '') => {
    if (!value && value !== 0) {
        return '';
    }

    // Remove all characters but numbers and return array with split parts.
    const onlyNumber = value.toString().replace(/\D/g, '');
    const parts = onlyNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (parts[3]) {
        return `(${parts[1]}) ${parts[2]}-${parts[3]}`;
    }

    if (parts[2]) {
        return `(${parts[1]}) ${parts[2]}`;
    }

    return parts[1];
};

const distributeDateParts = (splitNumbers) => {
    const [month = '', day = '', year = ''] = splitNumbers.filter((val) => Boolean(val));
    const result = [];
    let remainder = '';
    [month, day].forEach((val) => {
        const next = `${remainder}${val}`;
        result.push(next.substring(0, 2));
        remainder = next.substring(2);
    });

    result.push(`${remainder}${year}`.substring(0, 4));
    return result;
};

// Mask provided value into date format `MM/DD/YY`
// @param {String|Number} value to format
// @returns {String} date as MM/DD/YYYY
export const date = (value) => {
    // Allow for copy/paste value, strip out everything that is not a number or a slash
    const splitNumbers = value.replace(/[^\d/]/g, '').split('/');

    // If we have enough split parts, then use that, otherwise remove everything
    // except for numbers and then group into date parts
    const parts =
        splitNumbers.length > 2
            ? [value, ...distributeDateParts(splitNumbers)]
            : value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

    const isSlashLast = value[value.length - 1] === '/';
    // In case user types slash, pad values and add slash appropriately
    const appendSlash = (part) => (isSlashLast && !parts[3] ? `${lpad(part)}/` : part);

    const month = parts[1];

    let d = parts[2] ? `${month}/${appendSlash(parts[2])}` : appendSlash(month);
    if (parts[3]) {
        d = `${d}/${parts[3]}`;
    }

    return d || '';
};

export const socialSecurityNumber = (value) => {
    if (!value && value !== 0) {
        return '';
    }

    // Remove all characters but numbers and return array with split parts.
    const onlyNumber = value.toString().replace(/\D/g, '');
    const parts = onlyNumber.match(/(\d{0,3})(\d{0,2})(\d{0,4})/);

    if (parts[3]) {
        return `${parts[1]}-${parts[2]}-${parts[3]}`;
    }

    if (parts[2]) {
        return `${parts[1]}-${parts[2]}`;
    }

    return parts[1];
};

export const alphaNumeric = (value) => {
    return value.toString().replace(/[^a-z0-9]*/gi, '');
};

// #region Masking currency input
// https://github.com/ianmcnally/react-currency-masked-input
const getDigitsFromValue = (value = '') =>
    value.toString().replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits) => {
    const desiredLength = 3;
    const actualLength = digits.length;

    if (actualLength >= desiredLength) {
        return digits;
    }

    const amountToAdd = desiredLength - actualLength;
    const padding = '0'.repeat(amountToAdd);

    return padding + digits;
};

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number, separator) => {
    const centsStartingPosition = number.length - 2;
    const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
    const cents = number.substring(centsStartingPosition);
    return dollars + separator + cents;
};

export const toCurrency = (value, options = {}) => {
    const separator = options.separator || '.';
    const digits = getDigitsFromValue(value);
    const digitsWithPadding = padDigits(digits);
    return options.formatLocale
        ? moneyString(digitsWithPadding)
        : addDecimalToNumber(digitsWithPadding, separator);
};
// #endregion
