// formatters.js
// Functions to format input into another shape.

import '@gouch/to-title-case';
import { CoverageDefinition, CoverageDictionary } from '@popularlab/enums';
import { ReactNode } from 'preact/compat';

import { IVehicleName } from '../ui/steps/flows/flows.types';
import { isNotANumber } from './value-checkers';

/**
 * Formats a string from MM/DD/YYYY format into ISO-8601 date format if it is a valid date.
 * Returns empty string otherwise
 * @param {String} dateString String to format
 */
export const isoDate = (dateString: string) => {
    try {
        const splitBySlashes = dateString.split('/');
        if (splitBySlashes.length !== 3) {
            return '';
        }
        const [month, day, year] = splitBySlashes;

        // Theoretically browsers should parse ISO 8601 date strings correctly
        // everywhere, so hopefully this doesn't cause any issues
        const date = new Date(`${year}-${month}-${day}`);

        if (Number.isNaN(date.getDate())) {
            return '';
        }
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
    } catch (e) {
        return '';
    }
};

/**
 * Formats a string in MM/DD/YYYY format if it is a valid date. Returns empty string otherwise
 * @param {String|Date} dateString String to format
 * @param {Boolean=} local If true, use local timezone, otherwise use UTC time
 */
export const displayDate = (dateString: string | Date, local?: boolean) => {
    try {
        const date = new Date(dateString);

        if (Number.isNaN(date.getDate())) {
            return '';
        }

        if (local) {
            return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
                .getDate()
                .toString()
                .padStart(2, '0')}/${date.getFullYear()}`;
        }

        return `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}/${date
            .getUTCDate()
            .toString()
            .padStart(2, '0')}/${date.getUTCFullYear()}`;
    } catch (e) {
        return '';
    }
};

/**
 * Formats a string in MM/DD format, if it's a valid date. Returns empty string otherwise
 * @param {String|Date} dateString String to format
 */
export const displayMonthDay = (dateString: string | Date) => {
    try {
        const date = new Date(dateString);

        if (Number.isNaN(date.getDate())) {
            return '';
        }

        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}`;
    } catch (e) {
        return '';
    }
};

/**
 * Takes YYYY-MM-DD string and returns MM/DD/YYYY.
 * Note: input doesn't need to be valid date string.
 * @param {String} date in YYYY-MM-DD format
 * @returns {String} date in MM/DD/YYYY format
 */
export const fromIsoDateString = (date: string): string => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
};

/**
 * Takes MM/DD/YYYY string and returns YYYY-MM-DD.
 * Note: input doesn't need to be valid date string.
 * @param {String} date in MM/DD/YYYY format
 * @returns {String} date in YYYY-MM-DD format
 */
export const toIsoDateString = (date: string): string => {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
};

/**
 * Converts the provided amount in a cents
 * @param {Number|String} number Money number, in dollars
 */
export const dollarsToCents = (number: string): null | number => {
    if (isNotANumber(number)) {
        console.warn('Passed value is not a number or numerical string.');
        return null;
    }

    return Math.round(Number.parseFloat(number) * 100);
};

/**
 * Converts the provided amount in a dollar amount, fixed to two decimal places
 * @param {Number|String=} number Money number, in cents
 * @returns {String|undefined}
 */
export const centsToDollars = (number?: number | string): string | undefined => {
    if (isNotANumber(number)) {
        console.warn('Passed value is not a number or numerical string.');
        return undefined;
    }

    return (Number(number) / 100).toFixed(2);
};

/**
 * For provided money string (ie. "$1,012.36") returns value in cents
 * @param {String} moneyString
 * @returns {Number}
 */
export const moneyStringToCents = (moneyString: string): null | number => {
    const dollars = moneyString.replace(/[^0-9.]/g, '');
    return dollarsToCents(dollars);
};

/**
 * Formats the provided amount as currency
 * @param {Number|String|undefined} amount Money amount, in cents
 * @param {Intl.NumberFormatOptions} additionalOptions Number formatting options
 * @param {boolean} roundToNearestDollar Round to nearest dollar
 */
export const moneyString = (
    amount?: number | string,
    additionalOptions: Intl.NumberFormatOptions = {},
    roundToNextDollar: boolean = false
) => {
    if (isNotANumber(amount)) {
        console.warn('Passed value is not a number or numerical string.');
        return '';
    }

    let converted = Number(centsToDollars(amount));
    if (roundToNextDollar) {
        converted = Math.ceil(converted);
    }

    return converted.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        ...additionalOptions
    });
};

/**
 * Returns percentage of passed value.
 * @param {String|Number} value to format as percentage.
 * @return {String} Percentage as string with '%' postfix.
 */

export const percentage = (value: string | number): string => {
    if (isNotANumber(value)) {
        console.warn('Passed value is not a number or numerical string.');
        return '';
    }

    return Number(value).toLocaleString('en-US', { style: 'percent' });
};

/**
 * Slugifies passed string
 * @param string {String}
 * @returns {String}
 */
export const toSlug = (string: string = ''): string => {
    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // whitespace
        .replace(/[^\w-]+/g, '') // non-alpha chars
        .replace(/--+/g, '-') // multiple dashes
        .replace(/^-+/, '') // dash on start
        .replace(/-+$/, ''); // dash on end
};

/**
 * Takes title string and converts it to title case.
 * @param {String|Array} title string or string in array (if passed as component children)
 * @returns {String}
 */
export const normalizeTitle = (title?: ReactNode): string | string[] => {
    if (Array.isArray(title)) {
        return title.map((node) => {
            if (typeof node === 'string') {
                // @ts-ignore toTileCase comes from to-title-case package
                return node.toTitleCase();
            }
            return node;
        });
    }
    if (typeof title === 'string') {
        // @ts-ignore toTileCase comes from to-title-case package
        return title.toTitleCase();
    }
    console.warn('normalizeTitle: wrong type passed.');
    return '';
};

/**
 * For passed X, returns `X day(s)`
 * @param {Number|String} days
 * @returns {String}
 */
export const daysString = (days?: string | number): string => {
    // https://english.stackexchange.com/questions/13073/correct-plural-form-of-a-noun-preceded-by-zero
    return `${days} day${Number(days) === 1 ? '' : 's'}`;
};

/**
 * Return passed string with first letter in upper case
 * @param {String} string to convert
 * @param {Boolean} lowercase make rest of the string lower case
 * @return {String} converted string
 */
export const ucfirst = (string: string = '', lowercase: boolean = false): string => {
    const trimString = string.toString().trim();
    const finalString = lowercase ? trimString.toLowerCase() : trimString;
    return finalString.charAt(0).toUpperCase() + finalString.slice(1);
};

/**
 * Left-pad the value with provided character, to a total length
 * @param {string|number} value provided value to left pad on
 * @param {number} width max padded length
 * @param {string} char number character to pad with
 */
// Reference: https://stackoverflow.com/a/10073788/2382115
export const lpad = (value: string | number, width: number = 2, char: string = '0') => {
    const { length } = value.toString();
    return length >= width ? value : new Array(width - length + 1).join(char) + value;
};

export const socialSecurity = (value = '') => {
    if (value.length !== 9) {
        return value;
    }

    return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
};

/**
 * Maps the year/make/model/trim to a consistent string for displaying
 * vehicle information
 * @param {Object} vehicle Vehicle object
 */
export const vehicleName = (vehicle: IVehicleName) => {
    const make = typeof vehicle.make === 'string' ? vehicle.make : vehicle?.make?.label;
    const model = typeof vehicle.model === 'string' ? vehicle.model : vehicle?.model?.label;
    let trim = '';
    if (vehicle.trim) {
        trim = typeof vehicle.trim === 'string' ? vehicle.trim : vehicle.trim.label;
    }

    return `${vehicle.year} ${make} ${model} ${trim}`.trim();
};

/**
 * Takes coverage acronym and an array of objects that has acronyms, term and definition
 * Rerturns the term and definition for the cooresponding coverage acronym
 * @param {string} acronym e.g. BI
 * @returns {Object} { acronyms: ['nc'], term: 'Name of Coverage', definition: 'Explanation of what coverage is' }
 */
export const getCoverageOptionByAcronym = (acronym: string): CoverageDefinition => {
    const coverageTerm = CoverageDictionary.find((coverage) =>
        coverage.acronyms.includes(acronym.toLowerCase())
    );

    if (coverageTerm === undefined) {
        if (window.analytics) {
            window.analytics.track('WARNING', {
                message: 'Unmapped coverage title',
                acronym
            });
        }
        return {
            acronyms: ['undefined'],
            term: 'Other Coverage',
            definition: ''
        };
    }

    return coverageTerm;
};

// These coverage types have more specific names/title than the dictionary provides
const CoverageTypeLabels: Record<string, string> = {
    bi: 'Liability, Bodily Injury',
    pd: 'Liability, Property Damage',
    acqexp: 'Acquisition Expense',
    uimbi: 'Underinsured Motorist Bodily Injury',
    uimpd: 'Underinsured Motorist Property Damage',
    umbi: 'Uninsured Motorist Bodily Injury',
    umpd: 'Uninsured Motorist Property Damage',
    umuimbi: 'Uninsured/Underinsured Motorist Bodily Injury',
    umuimpd: 'Uninsured/Underinsured Motorist Property Damage'
};

/**
 * Takes coverage acronym
 * Rerturns the only term for the coverage
 * @param {string} type e.g. BI
 * @returns {Object} 'Name of Coverage'
 */
export const getCoverageTitle = (type: string): string => {
    const coverageTerm =
        CoverageTypeLabels[type.toLowerCase()] || getCoverageOptionByAcronym(type).term;

    if (coverageTerm === undefined) {
        if (window.analytics) {
            window.analytics.track('WARNING', {
                message: 'Unmapped coverage title',
                type
            });
        }
        return 'Other Coverage';
    }

    return coverageTerm;
};

const getCoverageItem = (type: string, value: number | string) => {
    switch (type) {
        case 'perPersonLimit':
            return `${moneyString(value, { minimumFractionDigits: 0 })} per person`;
        case 'propertyDamageLimit':
        case 'totalLimit':
            return `${moneyString(value, { minimumFractionDigits: 0 })} per accident`;
        case 'deductible':
            return `${moneyString(value, { minimumFractionDigits: 0 })} deductible`;
        case 'perDay':
            return `${moneyString(value, { minimumFractionDigits: 0 })} per day`;
        case 'max':
            return `${moneyString(value, { minimumFractionDigits: 0 })} maximum`;
        default:
            return `${moneyString(value, { minimumFractionDigits: 0 })}`;
    }
};

export const mapCoverageOption = (type: string, optionObject: Record<string, any> = {}) => {
    const title = getCoverageTitle(type);
    const coverageItemTypes = Object.keys(optionObject);
    const items = coverageItemTypes.map((itemType) =>
        getCoverageItem(itemType, optionObject[itemType])
    );

    return {
        id: type,
        title,
        items
    };
};

interface CoverageOption {
    id: string;
    items: string[];
    title: string;
}

/**
 * Converts an object of coverage options to a displayable format
 * @param {Object} optionsObject Coverage Options object from backend
 * @returns Array of object with properties: ['id', 'items', 'title']
 */
export const mapCoverageOptions = (optionsObject: Record<string, any> = {}): CoverageOption[] => {
    const types = Object.keys(optionsObject);

    return types.map((type) => mapCoverageOption(type, optionsObject[type]));
};

export const driversLicense = (license?: { state: string; number: string } | null): string => {
    if (license) {
        return `${license.state} ${license.number}`;
    }

    return '';
};

/**
 * Convert any string to camel case
 * @param  {String} string
 * @return {String}
 */
export const toCamelCase = (string: string = ''): string => {
    return string
        .trim()
        .toLowerCase()
        .replace(/['"]/g, '')
        .replace(/\W+/g, ' ')
        .replace(/ (.)/g, ($1) => $1.toUpperCase())
        .replace(/ /g, '');
};

/**
 * Format the given number with commas
 * @param {String|Number} number to format
 * @return {String}
 */
export const numberWithCommas = (number: string | number): string => {
    if (isNotANumber(number)) {
        console.warn('Passed value is not a number or numerical string.');

        return '';
    }

    return Number(number).toLocaleString('en-US');
};

/**
 * Maps the number of months to a string:
 * Example: 36 will return '3 years'
 *          37 will return '1 year and 1 month'
 * @param {number} monthCount months The number of months to parse
 * @param {string} separator If years and months are both used, the separator to concat both parts
 */
export const monthsToYearAndMonthsString = (monthCount: number, separator: string = ' and ') => {
    const years = Math.floor(monthCount / 12);
    const months = monthCount % 12;

    let monthsString = '';
    let yearString = '';

    if (years > 0) {
        if (years === 1) {
            yearString = `${years} year`;
        } else {
            yearString = `${years} years`;
        }
    }

    if (months >= 0) {
        if (months === 1) {
            monthsString = `${months} month`;
        } else if ((years === 0 && months === 0) || months > 1) {
            monthsString = `${months} months`;
        }
    }

    return [yearString, monthsString].filter((str) => str !== '').join(separator);
};

export const dateTimeString = (
    value: number | string | Date | null | undefined,
    longform: boolean = false
) => {
    if (!value) {
        console.warn('Passed invalid date.');
        return '';
    }

    const dateObject = new Date(value);

    if (!dateObject.getMonth || Number.isNaN(dateObject.getMonth())) {
        console.warn('Passed invalid date.');
        return '';
    }

    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    const date = dateObject.toLocaleDateString('en-US', {
        month: longform ? 'long' : 'short',
        day: 'numeric',
        year: 'numeric'
    });
    const time = dateObject.toLocaleString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: longform ? 'short' : undefined
    });
    return `${date} at ${time}`;
};

export const getDayOrdinal = (d: string) => {
    const dString = String(d);
    const last = Number(dString.slice(-2));
    if (last > 3 && last < 21) {
        return 'th';
    }
    switch (last % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};
