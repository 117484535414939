// API paths extracted in variables.

import { StateCode } from '@popularlab/enums';

const API_URL = process.env.HUGO_API_URL || ''; // No trailing slash.

export default {
    /**
     * Send confirmation code to user phone via SMS.
     * @api {POST}
     * @apiParam {String} phoneNumber
     */
    SEND_SMS_TOKEN: `${API_URL}/accounts/send-sms-verification-code`,

    /**
     * Send email magic login link to user email address
     * @api {POST}
     * @apiParam {string} email
     */
    SEND_EMAIL_LOGIN_LINK: `${API_URL}/accounts/send-email-login-link`,

    /**
     * Subscribes the device to push notifications
     */
    SUBSCRIBE_PUSH_NOTIFICATIONS: `${API_URL}/accounts/self/subscribe-push-notification`,
    /**
     * Subscribes the device to push notifications
     */
    UNSUBSCRIBE_PUSH_NOTIFICATIONS: `${API_URL}/accounts/self/unsubscribe-push-notification`,

    /**
     * Tracks clicks of a push notification
     */
    PUSH_NOTIFICATION_CLICKED: `${API_URL}/accounts/self/click-push-notification`,

    /**
     * Tracks views of a push notification
     */
    PUSH_NOTIFICATION_VIEWED: `${API_URL}/accounts/self/view-push-notification`,

    /**
     * @api {POST}
     * @apiParam {String} phoneNumber
     * @apiParam {String} code
     */
    ACCOUNT_CREATE: `${API_URL}/accounts`,

    /**
     * Submits Google token to log the current user in
     * @api {POST}
     * @apiParam {String} googleToken
     */
    ACCOUNT_LOGIN_GOOGLE: `${API_URL}/accounts/login/google`,

    /**
     * If phone number and token are valid return login session.
     * @api {POST}
     * @apiParam {String} phoneNumber
     * @apiParam {String} code
     */
    ACCOUNT_LOGIN_PHONE: `${API_URL}/accounts/login/phone-number`,

    /**
     * Adds google authentication to the user's account
     * @api {POST}
     * @apiParam {String} googleToken
     * @apiParam {Boolean} setEmail
     */
    ACCOUNT_ADD_GOOGLE_AUTH: `${API_URL}/accounts/self/google-auth`,

    /**
     * Clears access/refresh tokens, logging the user out
     * @api {GET}
     */
    ACCOUNT_LOGOUT: `${API_URL}/accounts/logout`,

    /**
     * Gets the current account information
     * @api {GET}
     */
    ACCOUNT: `${API_URL}/accounts/self`,

    /**
     * Gets the current account balance
     * @api {GET}
     */
    BALANCE: `${API_URL}/balance/self`,

    /**
     * Gets the current account dashboard
     * @api {GET}
     */
    ACCOUNT_DASHBOARD: `${API_URL}/accounts/self/dashboard`,

    /**
     * @api {POST}
     * @apiParam {String} accountId
     */
    REFRESH_LOGIN: `${API_URL}/accounts/login/refresh`,

    /**
     * Finalizes the quote application and prepares it for ESig
     * @api {PUT}
     */
    PRE_BIND_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/self/pre-bind`,

    /**
     * Prepares bind, by sending up the number of days to start the account with,
     * and autoreload preferences; returns bind proposal ID
     */
    BIND_PROPOSAL: `${API_URL}/auto-insurance/quote-applications/self/bind-proposal`,

    /**
     * Completes the quote application process with specified bind proposal,
     * and the idempotency key to identify the request
     * @api {POST}
     * @apiParam {Number} days
     * @apiParam {String} stripeIdempotencyKey
     */
    BIND_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/self/bind-policy`,

    /**
     * Creates a quote application
     * @api {POST}
     * @apiParam {String} zipCode
     */
    CREATE_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications`,

    /**
     * Creates an account or logs in an account and links it to a quote application
     * @api {POST}
     * @apiParam {Object} accountData
     */
    LINK_ACCOUNT_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/self/account`,

    /**
     * Gets the current in progress quote application. It is dependent on a cookie
     * set by the API.
     * @api {GET}
     */
    GET_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/self`,

    /**
     * Pings Insurify to get quotes from other carriers so that we can potentially
     * recoup some money when acquiring leads
     */
    GET_QUOTE_APPLICATION_LEAD_INSURIFY: `${API_URL}/auto-insurance/quote-applications/self/insurify`,

    /**
     * Updates the quote application by patching a specific portion of data
     * @api {PATCH} | {DELETE}
     * @apiParam {Object} submission
     */
    UPDATE_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/self`,

    /**
     * Deletes the current quote application so the user can start over from scratch
     * @api {DELETE}
     */
    DELETE_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications`,

    /**
     * Gets new quote and coverages by sending user-selected coverage values
     * @api {POST}
     */
    QUOTE_CALCULATOR: `${API_URL}/auto-insurance/quote-applications/self/quote`,

    /**
     * Cancels passive RTQ task on BE and immediatly sends RTQ message to available
     * communications channel (email or phone).
     * @api {POST}
     */
    SEND_RTQ_MESSAGE: `${API_URL}/auto-insurance/quote-applications/self/send-rtq-message`,

    /**
     * Validates an RTQ token, returns whether there's a quote application for it,
     * and whether the application is associated with an account already
     * @api {POST}
     */
    VALIDATE_SHORTCODE: `${API_URL}/auto-insurance/quote-applications/validate-shortcode`,

    /**
     * Authenticates applicant by zipcode, dob, and last name to resume a quote application
     * on success, returns true and sets the cookie for the quote application
     * @api {POST}
     */
    RESUME_QUOTE_APPLICATION: `${API_URL}/auto-insurance/quote-applications/resume-quote-application`,

    /**
     * Validates address using Google Places API
     * @api {GET}
     * @apiParam {String} street
     * @apiParam {String} city
     * @apiParam {String} zip
     * @apiParam {String} state
     * @returns {Boolean} isValid
     * @returns {Object} suggestedAddress
     */
    VALIDATE_ADDRESS: `${API_URL}/validators/validated-address`,

    /**
     * Take VIN and returns vehicle MMY
     * @api {GET}
     * @apiParam {String} vin
     * @returns {String} make
     * @returns {String} model
     * @returns {String} year
     */
    VALIDATE_VIN: `${API_URL}/auto-insurance/quote-applications/self/vehicles`,

    /**
     * @API GET
     * @returns {Array} list of all valid vehicle years from Polk
     */
    GET_CAR_YEARS: `${API_URL}/auto-insurance/quote-applications/self/vehicles/years`,

    /**
     * @API GET
     * @returns {Array} list of all car makes
     */
    GET_CAR_MAKES: `${API_URL}/auto-insurance/quote-applications/self/vehicles/makes`,

    /**
     * @API GET
     * @returns {Array} list of all models for provided car make
     */
    GET_CAR_MODELS: `${API_URL}/auto-insurance/quote-applications/self/vehicles/models`,

    /**
     * @API GET
     * @returns {Array} list of all trims for provided car make and model
     */
    GET_CAR_TRIMS: `${API_URL}/auto-insurance/quote-applications/self/vehicles/trims`,

    /**
     * Gets the packages the current user is able to purcahse
     * @api {GET} /policies/self/charge-options
     */
    GET_AVAILABLE_PACKAGES: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/charge-options`,

    /**
     * Sets or updates user phone number.
     * @api {PUT}
     * @apiParam {String} phoneNumber
     * @apiParam {String} code
     */
    VERIFY_PHONE: `${API_URL}/accounts/self/phone-number`,

    /**
     * Send verification email to address provided.
     * @api {POST}
     * @apiParam {String} email
     */
    SEND_VERIFICATION_EMAIL: `${API_URL}/accounts/self/send-email-verification-link`,

    /**
     * Verifies an email address by submitting a special token that the user would receive
     * @api {POST}
     * @apiParam {String} token
     */
    VERIFY_EMAIL: `${API_URL}/accounts/self/verify-email`,

    /**
     * Returns user insurance binder in pdf
     * @api {GET}
     */
    DOWNLOAD_BINDER: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/binder`,

    /**
     * Returns user declarations page in pdf
     * @api {GET}
     */
    DOWNLOAD_DECLARATIONS: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/declarations`,

    /**
     * Returns user insurance policy in pdf
     * @api {GET}
     */
    DOWNLOAD_DOCUMENTS: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/contract`,

    EMAIL_POLICY_PACKET: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/packet/send`,

    EMAIL_POLICY_BINDER: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/binder/send`,

    PROOF_OF_INSURANCE_AVAILABLE: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/proof-of-insurance-available`,

    DOWNLOAD_PROOF_OF_INSURANCE: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/proof-of-insurance`,

    DOWNLOAD_COVERAGE_HISTORY: `${API_URL}/accounts/self/coverage-history`,

    DOWNLOAD_LETTER_OF_EXPERIENCE: (letterFileId: number) =>
        `${API_URL}/accounts/self/letter-of-experience/download?id=${letterFileId}`,

    /**
     * Retuns list of active ticket types
     * @api {GET}
     * @returns {Array} ticketTypes
     */
    GET_TICKETS: `${API_URL}/support/tickets`,

    /**
     * Creates CS ticket
     * @api {POST}
     * @apiParam {String} firstName
     * @apiParam {String} lastName
     * @apiParam {String} email
     * @apiParam {String} phone
     * @apiParam {String} message
     * @apiParam {String} url
     * @apiParam {Array} tags
     */
    CREATE_TICKET: `${API_URL}/support/ticket`,

    /**
     * Triggers alert about policy renewal or update request to CS
     * @api {POST}
     * @apiParam {String} comment
     * @apiParam {Number} policyId
     * @apiParam {String} ticketType
     */
    UPDATE_POLICY: `${API_URL}/support/tickets/policy`,

    /**
     * Triggers alert about refund or funds widthdraw request to CS
     * @api {POST}
     * @apiParam {Number} amount
     * @apiParam {String} ticketType
     * @apiParam {Boolean} useGiftCard
     */
    RETURN_FUNDS: `${API_URL}/support/tickets/funds`,

    /** Get list of coverages available per geo
     * @api {GET}
     * @apiParam {String} geo - StateCode
     * @returns {Array} PolicyCoverageOptions
     */
    AVAILABLE_COVERAGES_FOR_GEO: (geo: StateCode): string =>
        `${API_URL}/support/coverages?geo=${geo}`,

    /** Get list of coverages available for zip code
     * @api {GET}
     * @apiParam {String} zipCode
     * @returns {Array} PolicyCoverageOptions
     */
    AVAILABLE_COVERAGES_FOR_ZIP: (zipCode: string): string =>
        `${API_URL}/support/coverages?zipCode=${zipCode}`,

    /**
     * GET: returns details about how much a member can request as a refund
     * POST: confirms specific amount, returns distribution among cards
     * @api {GET|POST}
     * @returns {RefundInfo} data
     */
    REFUND_INFO: `${API_URL}/balance/self/refund-info`,

    /**
     * Refunds money from Hugo account to payment card(s)
     * @api {POST}
     * @returns
     */
    REFUND_BALANCE: `${API_URL}/balance/self/refund`,

    /**
     * Base path for setting an account flag
     * @api {PUT}
     * @apiParam {bool} isEnabled Whether the flag is enabled or not
     */
    SET_ACCOUNT_FLAG: `${API_URL}/accounts/self/flags`,

    /**
     * Returns paginated list of payment history items
     * @api {get}
     * @returns {PaymentHistory}
     */
    GET_PAYMENT_HISTORY: `${API_URL}/balance/self/payment-history`,

    /**
     * URL for Registration Protection Preference
     * @api {get, put}
     * @returns {RegistrationProtectionPref}
     */
    REGISTRATION_PROTECTION_PREF: `${API_URL}/accounts/self/preferences/registration-protection`,

    /**
     * URL for transfering reward balance to Hugo balance
     * @api {post}
     */
    TRANSFER_REWARD_BALANCE_TO_HUGO_BALANCE: `${API_URL}/rewards/self/transfer-to-balance`,

    /**
     * URL for transfering reward balance to Hugo balance
     * @api {post}
     */
    TRANSFER_REWARD_BALANCE_TO_AMAZON_GIFT_CARD: `${API_URL}/rewards/self/transfer-to-amazon-gift-card`,

    /**
     * URL for sending touchpoint data
     * @api {post}
     */
    ANALYTICS_MARKETING_TOUCHPOINT: `${API_URL}/auto-insurance/analytics/marketing-touchpoint`,

    /**
     * Returns the current full policy to pre-fill for endorsement data
     * @api {get}
     */
    GET_ENDORSEMENT_POLICY_DATA: `${API_URL}/endorsements/self/policy-data`,

    /**
     * Sends a batch of endorsement post for validation
     * @api {patch}
     */
    UPDATE_ENDORSEMENT: `${API_URL}/endorsements/self`,

    /**
     * Completes, and binds the endorsements
     * @api {POST}
     */
    BIND_ENDORSEMENT: `${API_URL}/endorsements/self/bind`,

    /**
     * Validates VIN for endorsements
     * @api {GET}
     */
    ENDORSEMENT_VALIDATE_VIN: `${API_URL}/endorsements/vehicles`,

    /**
     * Gets price and coverages for endorsement
     * @api {POST}
     */
    ENDORSEMENT_QUOTE_CALCULATOR: `${API_URL}/endorsements/self/quote`,

    /**
     * Generates a quote application for an endorsement for ESig purposes
     * @api {POST}
     */
    ENDORSEMENT_START_ESIG: `${API_URL}/endorsements/self/esig`,

    /**
     * Gets the processing status for a policy
     * @api {GET}
     * @returns {PolicyProcessingInfo} data
     */
    GET_POLICY_PROCESSING: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/processing`,

    /**
     * Pings LeadCloud to get quotes from other carriers so that we can potentially
     * recoup some money when acquiring leads
     */
    GET_ACCOUNT_LEAD_INSURIFY: `${API_URL}/accounts/self/insurify`,

    /**
     * Subscribes the user to be notified that their policy has finished processing and they can perform things like endorsements
     * @api {POST}
     */
    SUBSCRIBE_POLICY_PROCESSED: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/processing/subscribe`,

    /**
     * Terminates the policy, allowing it for rebind
     */
    TERMINATE_POLICY: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/terminate`,

    /**
     * Rewrites API docs: https://stackoverflow.com/c/popular-lab/articles/299
     * @api {GET} | {POST} | {PUT}
     */
    REWRITE: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/coverage/rewrite`,

    /**
     * @typedef CoverageProposal
     * @type {Object}
     * @property {Boolean} [autoreload]
     * @property {String=} [proposalId]
     * @property {Boolean=} [requiresMoreInfo]
     * @property {Boolean} [rateMightChange]
     * @property {Number} [currentDayRate]
     * @property {Number} [newDayRate]
     * @property {Number} [balance]
     * @property {Number} [postPurchaseBalance]
     * @property {Number} [balanceRequired]
     * @property {Number} [daysRequired]
     * @property {Date} [autoReloadTriggerDate]
     * @property {Date=} [proposedCoverageEndTime]
     * @property {Date} [proposedCoverageStartTime]
     * @property {number=} [proposalExpiry]
     * @property {Date} [scheduledOffDate]
     */

    /**
     * @api {POST}
     * @param {number} policyId
     * @apiParam {CoverageStatus} targetCoverageStatus
     * @apiParam {number} chargeAmount
     * @apiParam {number} days
     * @apiParam {boolean} autoreload
     * @apiParam {number} rewardsAmount
     * @apiParam {boolean} forceError
     * @returns {CoverageProposal}
     */
    COVERAGE_PROPOSAL: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/coverage-proposal`,

    /**
     * @typedef BindCoverageProposalResponse
     * @type {Object}
     * @property {Object} [coverage]
     * @property {Object} [balance]
     * @property {Object} [autoreload]
     */

    /**
     * @api {POST}
     * @param {number} policyId
     * @apiParam {string} coverageProposalId
     * @apiParam {string} stripeIdempotencyKey
     * @returns {BindCoverageProposalResponse}
     */
    COVERAGE_BIND: (policyId: number): string =>
        `${API_URL}/accounts/self/policies/${policyId}/coverage-proposal/bind`,

    /**
     * @api {POST}
     * @apiParam {number} amount
     * @apiParam {number} expectedBalance
     * @apiParam {string} idempotencyKey
     */
    MAKE_PAYMENT: `${API_URL}/balance/self/payment`,

    /**
     * @api {POST}
     * @apiParam {string} phone
     * @apiParam {string} dateOfBirth
     * @apiParam {string} ccLastFour
     * @apiParam {number} lastPaymentAmount
     */
    SUPPORT_GET_TEMPORARY_ACCESS_TOKEN: `${API_URL}/support/login-help/validate`,

    /**
     * @api {POST}
     * @apiParam {string} phone
     * @apiParam {string} code
     * @apiParam {string} accessToken
     */
    SUPPORT_UPDATE_PHONE_NUMBER: `${API_URL}/support/login-help/update-phone`,
    /**
     * Creates a waitlist account
     * @api {POST}
     * @apiParam {String} email
     * @apiParam {Boolean} zipCode
     */
    WAITLIST_CREATE_USER: `${API_URL}/accounts/waitlist`,

    /**
     * Fetches a Stripe client secret to use for SetupIntent requests
     * @api {GET}
     */
    GET_STRIPE_SETUP_INTENT: `${API_URL}/balance/self/stripe-setup-intent`,

    /**
     * Confirms the SetupIntent when adding a payment card for an account and
     * retrieves the payment details
     * @api {POST}
     * @apiParam {String} clientSecret
     * @apiParam {String} id
     * @apiParam {String} paymentMethod
     */
    CONFIRM_STRIPE_PAYMENT_SETUP: `${API_URL}/balance/self/confirm-setup-intent`,

    /**
     * Fetches open chargebacks that need responses
     * @api {GET|POST}
     */
    ACCOUNT_CHARGEBACKS: `${API_URL}/balance/self/chargebacks`,

    /**
     * Submit referral code
     * @api {POST}
     * @apiParam {String} code
     * @apiParam {String} location
     */
    SUBMIT_CODE: `${API_URL}/accounts/self/code`,

    /**
     * Creates LOE for passed date
     * @api {POST}
     * @apiParam {String} letterDate
     */
    CREATE_LETTER_OF_EXPERIENCE: `${API_URL}/accounts/self/letter-of-experience`,

    /** Send LOE to mail or fax
     * @api {POST}
     * @apiParam {String} address
     * @apiParam {String} letterDate
     * @apiParam {Number} letterFileId
     * @apiParam {String} recipientName
     */
    SEND_LETTER_OF_EXPERIENCE: `${API_URL}/accounts/self/letter-of-experience/send`,

    /** Send declarations page to mail or fax
     * @api {POST}
     * @apiParam {String} address
     * @apiParam {String} recipientName
     * @apiParam {Number} policyId
     */
    SEND_DECLARATIONS_PAGE: (policyId: number) =>
        `${API_URL}/accounts/self/policies/${policyId}/declarations/send`,

    /** Submit documents for zendesk state filing ticket
     * @api {POST}
     * @apiParam {Number} accountId
     * @apiParam {Any} document
     * @returns {Promise} resolution of POST req.
     */
    SUBMIT_DOCUMENT: `${API_URL}/support/document`,

    /** Submit customer feedback about the product
     * @api {POST}
     * @apiParam {String} feedbackInput
     * @apiParam {String} preferredContactEmail
     * @apiParam {Boolean} researchOptIn
     */
    CUSTOMER_FEEDBACK: `${API_URL}/customer-feedback`
};
