import { useEffect, useState } from 'preact/hooks';

/**
 * Sensor hook that tracks state of CSS media query
 * https://github.com/streamich/react-use/blob/master/docs/useMedia.md
 * @param {String} query media query
 * @param {Boolean} defaultState state of media query in case of SSR
 * @return {Boolean} true if media query matches conditions
 */
const useMediaQuery = (query = 'all', defaultState = false) => {
    const isClient = typeof window !== 'undefined';
    const [state, setState] = useState(
        isClient ? () => window.matchMedia(query).matches : defaultState
    );

    useEffect(() => {
        let mounted = true;
        const mql = window.matchMedia(query);
        const onChange = () => {
            if (mounted) {
                setState(Boolean(mql.matches));
            }
        };

        mql.addListener(onChange);
        setState(mql.matches);

        return () => {
            mounted = false;
            mql.removeListener(onChange);
        };
    }, [query]);

    return state;
};

export default useMediaQuery;
