import { actions, status as Status } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import { IResetCoverageSessionAction } from '../types/action.types';

export interface ICoverageState {
    carrier: string;
    endTimeMs: number | null;
    id: string | null;
    naic: string;
    startTimeMs?: number;
    status: Status;
}

export const initialState: ICoverageState = {
    carrier: '',
    endTimeMs: null,
    id: null,
    naic: '',
    startTimeMs: undefined,
    status: Status.PAUSED
};

export const actionMap = {
    [actions.UPDATE_COVERAGE_DATA]: updateState,

    [actions.RESET_COVERAGE_VALUES]: (
        state: ICoverageState,
        action: IResetCoverageSessionAction
    ): ICoverageState => ({
        ...initialState,
        carrier: action.payload.carrier,
        naic: action.payload.naic
    })
};

export default createReducer(initialState, actionMap);
