import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface IGridItemProps {
    className?: string;
    span?: {
        xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    };
    start?: {
        xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    };
    end?: {
        xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
        lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    };
    [key: string]: any;
}

const GridItem: FunctionComponent<IGridItemProps> = ({
    children,
    className,
    span = {},
    start = {},
    end = {},
    ...rest
}) => (
    <div
        className={classnames(className, {
            [`l-grid__col-span-${span.xs}`]: span.xs,
            [`sm:l-grid__col-span-${span.sm}`]: span.sm,
            [`md:l-grid__col-span-${span.md}`]: span.md,
            [`lg:l-grid__col-span-${span.lg}`]: span.lg,
            [`l-grid__col-start-${start.xs}`]: start.xs,
            [`sm:l-grid__col-start-${start.sm}`]: start.sm,
            [`md:l-grid__col-start-${start.md}`]: start.md,
            [`lg:l-grid__col-start-${start.lg}`]: start.lg,
            [`l-grid__col-end-${end.xs}`]: end.xs,
            [`sm:l-grid__col-end-${end.sm}`]: end.sm,
            [`md:l-grid__col-end-${end.md}`]: end.md,
            [`lg:l-grid__col-end-${end.lg}`]: end.lg
        })}
        {...rest}
    >
        {children}
    </div>
);

export default GridItem;
