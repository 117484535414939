import { h, Fragment, FunctionComponent } from 'preact';
import classNames from 'classnames';

import { ButtonHelp } from '../components/Button';
import { LogoWide, LogoMin } from '../logo';
import { Header } from './Header';

import { useMediaQuery } from '@/hooks';
import { mediaQuery } from '@/constants';
import { routes } from '@/routes';

const MinimalLayout: FunctionComponent<{ hideHeader?: boolean }> = ({ children, hideHeader }) => {
    const isDesktop = useMediaQuery(mediaQuery.MIN_MEDIUM);
    return (
        <Fragment>
            {!hideHeader && (
                <Header sticky>
                    <a href={process.env.HUGO_CLIENT_URL}>
                        {isDesktop ? (
                            <LogoWide width="250" className="" />
                        ) : (
                            <LogoMin width="45" className="" />
                        )}
                    </a>
                </Header>
            )}
            <div className="l-minimal">
                <div className={classNames('l-minimal__inner', { 'u-pt-4': !hideHeader })}>
                    {children}
                </div>
            </div>
            <div className="l-btn-helpdesk">
                <ButtonHelp className="track:ticket-open" size={30} href={routes.HELPDESK.path} />
            </div>
        </Fragment>
    );
};

export default MinimalLayout;
