// Middleware to handle persistance of user session.

import { route } from 'preact-router';
import { Middleware } from 'redux';

import { localStorage } from '../helpers/storage';
import { actions, cookies } from '../constants';
import { routes } from '../routes';
import { StoreShape } from '../types/store.types';

// Save accountId in localStorage when saving it in store.
export const saveUserSession: Middleware<{}, StoreShape> =
    ({ getState }) =>
    (next) =>
    (action) => {
        next(action);

        if (action.type === actions.SAVE_ACCOUNT_ID) {
            const { accountId } = action.payload || getState().account;
            localStorage.setItem(cookies.loginCookie, accountId);
        }
    };

// At point of logout, clear localStorage along with resetting store state
// and throw user out of the app.
export const removeUserSession: Middleware<{}, StoreShape> = () => (next) => (action) => {
    next(action);

    if (action.type === actions.LOG_OUT) {
        localStorage.removeItem(cookies.loginCookie);
        route(routes.LOGIN.path, true);
    }
};
