import { h } from 'preact';

import { Banner, BannerContent } from '../components/Banner';

const BannerCanceled = () => (
    <Banner>
        <BannerContent>
            <p>The term of your Hugo policy has ended, and your policy has been cancelled.</p>

            <p>Don’t drive uninsured - renew your policy today!</p>
        </BannerContent>
    </Banner>
);

export default BannerCanceled;
