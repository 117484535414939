// Main file for all of application reducers.
// Combined reducers make application state.
// Note signup and endorsement reducers are loaded async

import { combineReducers, CombinedState, Reducer } from 'redux';

import { actions } from '../constants';
import { StoreShape, ReducerMap } from '../types/store.types';
import account from './account';
import api from './api';
import balance from './balance';
import coverage from './coverage';
import payment from './payment';
import policy from './policy';
import proposal from './proposal';
import query from './query';
import rewards from './rewards';
import ui from './ui';

const appReducer = (asyncReducers?: ReducerMap) =>
    combineReducers({
        account,
        api,
        balance,
        coverage,
        payment,
        policy,
        proposal,
        query,
        rewards,
        ui,
        ...asyncReducers
    }) as Reducer<CombinedState<Partial<StoreShape>>>;

const createReducer =
    (asyncReducers?: ReducerMap) =>
    (
        state: Partial<StoreShape> | undefined,
        action: { type: string; [key: string]: any }
    ): CombinedState<Partial<StoreShape>> => {
        // Resetting redux store.
        // https://stackoverflow.com/a/35641992/2382115
        if (action.type === actions.LOG_OUT) {
            // eslint-disable-next-line no-param-reassign
            state = undefined;
        }

        return appReducer(asyncReducers)(state, action);
    };

export default createReducer;
