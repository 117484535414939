import { h, FunctionComponent } from 'preact';

import { IconAlertWhite, IconLikeWhite, IconConfettiWhite, IconInfoWhite } from '../icons';
import { Modal, ModalContent, ModalIcon } from '../components/Modal';
import { RequestNotificationType } from '../types';

interface IModalNotificationProps {
    onHide: () => void;
    notifications: { message: string; type: RequestNotificationType }[];
}

const ModalApiNotifications: FunctionComponent<IModalNotificationProps> = ({
    onHide,
    notifications
}) => (
    <Modal onHide={onHide} dark drawer>
        <ModalIcon>
            {notifications.length === 1 &&
                notifications[0].type === RequestNotificationType.DANGER && (
                    <IconAlertWhite size="32" />
                )}
            {notifications.length === 1 &&
                notifications[0].type === RequestNotificationType.INFO && (
                    <IconLikeWhite size="32" />
                )}
            {notifications.length === 1 &&
                notifications[0].type === RequestNotificationType.SUCCESS && (
                    <IconConfettiWhite size="32" />
                )}
            {notifications.length > 1 && <IconInfoWhite size="32" />}
        </ModalIcon>
        <ModalContent center>
            {notifications.map(({ message }, i) => (
                <p key={i}>{message}</p>
            ))}
        </ModalContent>
    </Modal>
);

export default ModalApiNotifications;
