import { h, FunctionComponent, Fragment } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { connect } from 'react-redux';

import { ModalActions, ModalContent, ModalIcon, ModalTitle } from '../../components/Modal';
import { IconLocation } from '../../icons';
import { Button, ButtonCta } from '../../components/Button';

import { StoreShape } from '@/types/store.types';
import { showModal } from '@/actions/ui';
import { alerts } from '@/constants';
import { infoLogger } from '@/helpers/misc';

interface IModalLocationProps {
    scheduledOffDate?: Date;
    geolocationPermission: StoreShape['ui']['clientPermissions']['geolocation'];
    loading?: boolean;
    isBinding?: boolean;
    onHide: () => void;
    onSubmit: Function;
    dispatch: (action: any) => void;
}

export const ModalLocation: FunctionComponent<IModalLocationProps> = (props) => {
    const [locating, setLocating] = useState(false);
    const [originalScheduledOffDate] = useState(props.scheduledOffDate);

    const locateUser = useCallback(() => {
        infoLogger('Starting location acquisition');
        setLocating(true);
        const setPosition = (position: GeolocationPosition) => {
            infoLogger('High-accuracy geolocation success');
            // Chrome sets GeolocationPosition parameters on the prototype object,
            // making it non-serializable. We also validate this server-side, and want to maintain
            // forward-compatibility if it ever changes. So we copy the values to a new object
            props.onSubmit({
                coords: {
                    accuracy: position.coords.accuracy,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed
                },
                timestamp: position.timestamp
            });
            setLocating(false);
        };
        const setError = (error: GeolocationPositionError) => {
            infoLogger(`Geolocation error ${error ? error.code : ''}`);
            props.onHide();
            props.dispatch(showModal(alerts.LOCATION_PERMISSION_DENIED));
            setLocating(false);
        };
        navigator.geolocation.getCurrentPosition(
            setPosition,
            (error) => {
                infoLogger(`High-accuracy geolocation error ${error.code}`);
                // location timed out, retry with any location
                if (error && error.code === 3) {
                    navigator.geolocation.getCurrentPosition(setPosition, setError, {
                        maximumAge: 60000, // Allow a location from up to a minute ago
                        timeout: 20000
                    });
                    return;
                }
                setError(error);
            },
            {
                maximumAge: 60000, // Allow a location from up to a minute ago
                timeout: 5000, // If we can't get a lock quick enough, error out
                enableHighAccuracy: true // Try to get an accurate position
            }
        );
    }, [setLocating, props.onSubmit]);

    // if the user hangs out on the confirmation screen, and scheduled-off-date changes,
    // we want to close the modal
    useEffect(() => {
        infoLogger(`Current geolocation permission: ${props.geolocationPermission}`);
        if (props.scheduledOffDate !== originalScheduledOffDate && !props.isBinding) {
            props.onHide();
        }
    }, [props.scheduledOffDate]);

    return (
        <Fragment>
            <ModalIcon>
                <IconLocation
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="40"
                />
            </ModalIcon>
            <ModalTitle>Last step!</ModalTitle>
            <ModalContent>
                <p>
                    We need to check your location in order to start your coverage. This helps you
                    in case of a same-day claim.
                </p>
                <p>
                    <strong>We only check your location when we start your coverage</strong>, and
                    will always ask you before checking location.
                </p>
            </ModalContent>
            <ModalActions>
                <ButtonCta
                    className="track:location-confirm"
                    loading={props.loading || locating}
                    onClick={locateUser}
                >
                    Continue
                </ButtonCta>
                <Button link onClick={() => props.onHide()} disabled={props.loading}>
                    Cancel
                </Button>
            </ModalActions>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    geolocationPermission: state.ui.clientPermissions.geolocation,
    scheduledOffDate: state.proposal.scheduledOffDate
});

export default connect(mapStateToProps)(ModalLocation);
