import { h, FunctionComponent } from 'preact';

import { Modal, ModalContent, ModalIcon, ModalTitle, ModalActions } from '../components/Modal';
import { IconAlertColored } from '../icons';
import { ButtonCta } from '../components/Button';
import CopyClipboardTile from '../shared/ClipboardCopyTile';

interface IModalChangeBrowser {
    onHide: () => void;
    returnLink: string;
}

const ModalChangeBrowser: FunctionComponent<IModalChangeBrowser> = (props) => {
    return (
        <Modal onHide={props.onHide}>
            <ModalIcon>
                <IconAlertColored
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="42"
                />
            </ModalIcon>
            <ModalTitle>Browser not supported</ModalTitle>
            <ModalContent>
                <p>
                    Your internet browser is not supported. Please switch to Safari and return here
                    to start your Hugo policy successfully.
                </p>
                <p>Open the link below in Safari to return here and pickup where you left off.</p>
                <CopyClipboardTile text={props.returnLink} />
            </ModalContent>
            <ModalActions>
                <ButtonCta onClick={() => props.onHide()}>Got it</ButtonCta>
            </ModalActions>
        </Modal>
    );
};

export default ModalChangeBrowser;
