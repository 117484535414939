import { h } from 'preact';
import classnames from 'classnames';

import Svg from './Svg';

const SvgBasic = ({ className, ...rest }) => (
    <Svg className={classnames('icon-basic', className)} {...rest} />
);

// Colored SVG means that icon colors are defined inside SVG
// and are not meant to be overriden via CSS.
const SvgColored = ({ className, white, ...rest }) => (
    <Svg
        className={classnames('icon-colored', { 'icon-white': white }, className)}
        viewBox="0 0 32 32"
        {...rest}
    />
);

// stay as-is

export const IconAlert = (props) => <SvgBasic use="icon-alert" {...props} />;
export const IconAlertColored = (props) => <SvgBasic use="icon-alert-colored" {...props} />;
export const IconAlertRound = (props) => <SvgBasic use="icon-alert-round" {...props} />;
export const IconAlertShield = (props) => <SvgBasic use="icon-alert-shield" {...props} />;
export const IconArrowRight = (props) => <SvgBasic use="icon-arrow-right" {...props} />;
export const IconBell = (props) => <SvgBasic use="icon-bell" {...props} />;
export const IconCamera = (props) => <SvgBasic use="icon-camera" {...props} />;
export const IconCard = (props) => <SvgBasic use="icon-card" {...props} />;
export const IconCaretDown = (props) => <SvgBasic use="icon-caret-down" {...props} />;
export const IconCaretLeft = (props) => <SvgBasic use="icon-caret-left" {...props} />;
export const IconCaretRight = (props) => <SvgBasic use="icon-caret-right" {...props} />;
export const IconCaretRightRound = (props) => <SvgBasic use="icon-caret-right-round" {...props} />;
export const IconCheck = (props) => <SvgBasic use="icon-check" {...props} />;
export const IconClose = (props) => <SvgBasic use="icon-close" {...props} />;
export const IconEditRound = (props) => <SvgBasic use="icon-edit" {...props} />;
export const IconFacebook = (props) => <SvgBasic use="icon-facebook" {...props} />;
export const IconFatArrowDown = (props) => <SvgBasic use="icon-fat-arrow-down" {...props} />;
export const IconHelp = (props) => <SvgBasic use="icon-help" {...props} />;
export const IconHome = (props) => <SvgBasic use="icon-home" {...props} />;
export const IconLocation = (props) => <SvgBasic use="icon-location" {...props} />;
export const IconMenu = (props) => <SvgBasic use="icon-menu" {...props} />;
export const IconNegative = (props) => <SvgBasic use="icon-negative" {...props} />;
export const IconPaperClip = (props) => <SvgBasic use="icon-paper-clip" {...props} />;
export const IconPencilOutline = (props) => <SvgBasic use="icon-pencil-outline" {...props} />;
export const IconPlus = (props) => <SvgBasic use="icon-plus" {...props} />;
export const IconPositive = (props) => <SvgBasic use="icon-positive" {...props} />;
export const IconRate = (props) => <SvgBasic use="icon-rate" {...props} />;
export const IconRateSafe = (props) => <SvgBasic use="icon-rate-safe" {...props} />;
export const IconRotate = (props) => <SvgBasic use="icon-rotate" {...props} />;
export const IconShieldLock = (props) => <SvgBasic use="icon-shield-lock" {...props} />;
export const IconShieldCheck = (props) => <SvgBasic use="icon-shield-check" {...props} />;
export const IconStar = (props) => <SvgBasic use="icon-star" {...props} />;
export const IconTooltip = (props) => <SvgBasic use="icon-tooltip" {...props} />;
export const IconTwitter = (props) => <SvgBasic use="icon-twitter" {...props} />;
export const IconZap = (props) => <SvgBasic use="icon-zap" {...props} />;

// new b/w icons

export const IconAboutRate = (props) => <SvgColored use="icon-about-rate" {...props} />;
export const IconAddInfo = (props) => <SvgColored use="icon-add-doc" {...props} />;
export const IconAgent = (props) => <SvgColored use="icon-support" {...props} />;
export const IconApplicationNew = (props) => <SvgColored use="icon-policy-new" {...props} />;
export const IconApplicationTerminate = (props) => <SvgColored use="icon-terminate" {...props} />;
export const IconAutoReloadCircle = (props) => (
    <SvgColored use="icon-auto-reload-coins" {...props} />
);
export const IconBalanceHistory = (props) => <SvgColored use="icon-balance-history" {...props} />;
export const IconCalculator = (props) => <SvgColored use="icon-calculator" {...props} />;
export const IconCalendar = (props) => <SvgColored use="icon-cal" {...props} />;
export const IconCalendarCheck = (props) => <SvgColored use="icon-cal-tick" {...props} />;
export const IconCarHappy = (props) => <SvgColored use="icon-car-happy" {...props} />;
export const IconCarSad = (props) => <SvgColored use="icon-car-sad" {...props} />;
export const IconCarS = (props) => <SvgColored use="icon-car-s" {...props} />;
export const IconCarSuccess = (props) => <SvgColored use="icon-car-success" {...props} />;
export const IconCart = (props) => <SvgColored use="icon-cart" {...props} />;
export const IconCashMoney = (props) => <SvgColored use="icon-refer-friends" {...props} />;
export const IconChargeback = (props) => <SvgColored use="icon-chargeback" {...props} />;
export const IconCheckmark = (props) => <SvgColored use="icon-tick" {...props} />;
export const IconClaim = (props) => <SvgColored use="icon-claim" {...props} />;
export const IconCoins = (props) => <SvgColored use="icon-coins" {...props} />;
export const IconCoinsAlt = (props) => <SvgColored use="icon-withdraw" {...props} />;
export const IconConfetti = (props) => <SvgColored use="icon-confetti" {...props} />;
export const IconCoupon = (props) => <SvgColored use="icon-discounts" {...props} />;
export const IconCoverageAdb = (props) => <SvgColored use="icon-adb" {...props} />;
export const IconCoverageColl = (props) => <SvgColored use="icon-collision" {...props} />;
export const IconCoverageComp = (props) => <SvgColored use="icon-comp" {...props} />;
export const IconCoverageExtension = (props) => (
    <SvgColored use="icon-coverage-extension" {...props} />
);
export const IconCoverageLiability = (props) => <SvgColored use="icon-liability" {...props} />;
export const IconCoverageMed = (props) => <SvgColored use="icon-medical" {...props} />;
export const IconCoverageRent = (props) => <SvgColored use="icon-rental" {...props} />;
export const IconCoverageRoad = (props) => <SvgColored use="icon-roadside" {...props} />;
export const IconCoverageTow = (props) => <SvgColored use="icon-towing" {...props} />;
export const IconCoverageUm = (props) => <SvgColored use="icon-um" {...props} />;
export const IconDecPage = (props) => <SvgColored use="icon-dec-page" {...props} />;
export const IconDialog = (props) => <SvgColored use="icon-dialog" {...props} />;
export const IconDriverAdd = (props) => <SvgColored use="icon-driver-add" {...props} />;
export const IconFileUpload = (props) => <SvgColored use="icon-state-form" {...props} />;
export const IconGears = (props) => <SvgColored use="icon-settings" {...props} />;
export const IconGift = (props) => <SvgColored use="icon-rewards" {...props} />;
export const IconHandMoney = (props) => <SvgColored use="icon-receive-money" {...props} />;
export const IconHeart = (props) => <SvgColored use="icon-heart" {...props} />;
export const IconHelpBubble = (props) => <SvgColored use="icon-help-bubble" {...props} />;
export const IconHours100 = (props) => <SvgColored use="icon-hours-100" {...props} />;
export const IconHours60 = (props) => <SvgColored use="icon-hours-60" {...props} />;
export const IconHours30 = (props) => <SvgColored use="icon-hours-30" {...props} />;
export const IconLOE = (props) => <SvgColored use="icon-loe" {...props} />;
export const IconLock = (props) => <SvgColored use="icon-secure" {...props} />;
export const IconMagnifier = (props) => <SvgColored use="icon-search" {...props} />;
export const IconManageAddress = (props) => <SvgColored use="icon-location-manage" {...props} />;
export const IconManageCoverages = (props) => <SvgColored use="icon-coverage-change" {...props} />;
export const IconManageDrivers = (props) => <SvgColored use="icon-driver-manage" {...props} />;
export const IconManageLienholders = (props) => <SvgColored use="icon-lienholder-add" {...props} />;
export const IconManageVehicles = (props) => <SvgColored use="icon-vehicle-manage" {...props} />;
export const IconModifyPolicy = (props) => <SvgColored use="icon-policy-modify" {...props} />;
export const IconMoon = (props) => <SvgColored use="icon-snooze" {...props} />;
export const IconNoCoverage = (props) => <SvgColored use="icon-no-coverage" {...props} />;
export const IconPayment = (props) => <SvgColored use="icon-payment" {...props} />;
export const IconPaymentView = (props) => <SvgColored use="icon-payment-view" {...props} />;
export const IconPhoneAdd = (props) => <SvgColored use="icon-phone-add" {...props} />;
export const IconPiggyBank = (props) => <SvgColored use="icon-piggy-coin" {...props} />;
export const IconPiggyBankEmpty = (props) => <SvgColored use="icon-piggy" {...props} />;
export const IconPlaneTick = (props) => <SvgColored use="icon-airplane" {...props} />;
export const IconPoiCard = (props) => <SvgColored use="icon-poi" {...props} />;
export const IconPolicyBinder = (props) => <SvgColored use="icon-policy-binder" {...props} />;
export const IconPolicyDetails = (props) => <SvgColored use="icon-policy-details" {...props} />;
export const IconPolicyDocuments = (props) => <SvgColored use="icon-policy-documents" {...props} />;
export const IconPolicyExpiry = (props) => <SvgColored use="icon-policy-expiry" {...props} />;
export const IconPolicyPacket = (props) => <SvgColored use="icon-policy-packet" {...props} />;
export const IconPolicyRenewal = (props) => <SvgColored use="icon-policy-renewal" {...props} />;
export const IconPolicySubmit = (props) => <SvgColored use="icon-policy-submit" {...props} />;
export const IconRateChange = (props) => <SvgColored use="icon-rate-change" {...props} />;
export const IconRefreshGreen = (props) => <SvgColored use="icon-auto-reload" {...props} />;
export const IconRegistrationProtection = (props) => (
    <SvgColored {...props} use="icon-registration-protection" />
);
export const IconRepay = (props) => <SvgColored use="icon-add-money" {...props} />;
export const IconSearchResults = (props) => <SvgColored use="icon-search-results" {...props} />;
export const IconShield = (props) => <SvgColored use="icon-shield" {...props} />;
export const IconShieldAlt = (props) => <SvgColored use="icon-shield-alt" {...props} />;
export const IconThumbsUp = (props) => <SvgColored use="icon-like" {...props} />;
export const IconTopArticles = (props) => <SvgColored use="icon-top-articles" {...props} />;
export const IconTrash = (props) => <SvgColored use="icon-trash" {...props} />;
export const IconVehicleAdd = (props) => <SvgColored use="icon-vehicle-add" {...props} />;

// knockout (white) icons for dark drawers

export const IconAirplaneWhite = (props) => (
    <SvgColored white use="icon-airplane-white" {...props} />
);
export const IconAlertWhite = (props) => <SvgColored white use="icon-alert-white" {...props} />;
export const IconCarSadWhite = (props) => <SvgColored white use="icon-car-sad-white" {...props} />;
export const IconConfettiWhite = (props) => (
    <SvgColored white use="icon-confetti-white" {...props} />
);
export const IconInfoWhite = (props) => <SvgColored white use="icon-info-white" {...props} />;
export const IconInsuranceOnWhite = (props) => (
    <SvgColored white use="icon-insurance-on-white" {...props} />
);
export const IconLikeWhite = (props) => <SvgColored white use="icon-like-white" {...props} />;
export const IconSnoozeWhite = (props) => <SvgColored white use="icon-snooze-white" {...props} />;
export const IconWaveWhite = (props) => <SvgColored white use="icon-wave-white" {...props} />;

// large icons (40x40) w/ gray circle

export const IconConfettiCircle = (props) => (
    <SvgColored use="icon-confetti-circle" viewBox="0 0 40 40" {...props} />
);
