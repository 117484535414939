import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';

export interface IQueryState {
    code: string | null;
    emailToken: string | null;
    origin: string | null;
    pfZip: string | null;
    ref: string | null;
    rtqToken: string | null;
    [key: string]: string | null;
}

export const initialState: IQueryState = {
    code: null,
    emailToken: null,
    origin: null,
    pfZip: null,
    ref: null,
    rtqToken: null
};

export const actionMap = {
    [actions.SAVE_QUERY_STRING]: updateState
};

export default createReducer(initialState, actionMap);
