// App shell for quote flow. Used on `/quote`.

import { h, FunctionComponent } from 'preact';
import { createPortal } from 'preact/compat';
import { useEffect, useRef, useState, useMemo } from 'preact/hooks';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { StateCode } from '@popularlab/enums';

import { mediaQuery, alerts } from '../../constants';
import { get } from '../../helpers/misc';
import { getCurrentSignupStep } from '../../selectors';
import { useMediaQuery } from '../../hooks';
import { LogoMin, LogoWide } from '../logo';
import ModalResetQuoteApplication from '../alerts/ModalResetQuoteApplication';
import { Button, ButtonHelp } from '../components/Button';
import { IconRotate } from '../icons';
import BannerNewAppVersion from '../alerts/BannerNewAppVersion';
import asyncComponent from '../hoc/AsyncComponent';
import { Header, HeaderRight } from './Header';
import { QuoteFlowStep } from '../steps/flows/flows.types';
import { StoreShape } from '../../types/store.types';

const ModalTicket = asyncComponent(() => import('../shared/Helpdesk/ModalTicket'));
const BannerAnnouncement = asyncComponent(() => import('../alerts/BannerAnnouncement'));

interface IQuoteHeaderProps {
    isDesktop?: boolean;
    hasQuoteApplication?: boolean;
    setHelpdeskVisible: Function;
    toggleModal: Function;
    className?: string;
}

export const QuoteHeader: FunctionComponent<IQuoteHeaderProps> = ({
    isDesktop,
    hasQuoteApplication = true,
    setHelpdeskVisible,
    toggleModal,
    className
}) => (
    <Header className={className}>
        {isDesktop ? <LogoWide width="250" className="" /> : <LogoMin width="45" className="" />}

        <HeaderRight>
            {hasQuoteApplication && (
                <Button
                    className="u-font-4"
                    icon
                    minimal
                    onClick={() => toggleModal(true)}
                    secondary
                >
                    <IconRotate title="Start over" />
                </Button>
            )}

            <span className={classnames({ 'l-btn-helpdesk': isDesktop })}>
                <ButtonHelp
                    className="track:ticket-open"
                    size={30}
                    onClick={() => setHelpdeskVisible(true)}
                />
            </span>
        </HeaderRight>
    </Header>
);

interface IQuoteLayoutProps {
    currentStep: QuoteFlowStep;
    dispatch: (action: any) => Promise<any>;
    hasQuoteApplication?: boolean;
    announcementIsVisible?: boolean;
    newVersionAvailable?: boolean;
    geo?: StateCode;
}

export const QuoteLayoutPure: FunctionComponent<IQuoteLayoutProps> = ({
    children,
    currentStep,
    dispatch,
    hasQuoteApplication,
    announcementIsVisible,
    newVersionAvailable,
    geo
}) => {
    const [modalVisible, toggleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [helpdeskVisible, setHelpdeskVisible] = useState(false);
    const isDesktop = useMediaQuery(mediaQuery.MIN_MEDIUM);
    const el = useRef<HTMLDivElement>(null);

    const isEsig = useMemo(() => get(currentStep, 'isEsig'), [currentStep]);

    // This effect runs after currentStep changes, and forces the main viewport
    // to scroll to the top
    useEffect(() => {
        if (!el.current) {
            return;
        }

        el.current.scrollTop = 0;
    }, [currentStep]);

    return (
        <div className="l-quote">
            <QuoteHeader
                className={classnames('l-quote__header', { 'is-esig': isEsig })}
                isDesktop={isDesktop}
                hasQuoteApplication={hasQuoteApplication}
                toggleModal={toggleModal}
                setHelpdeskVisible={setHelpdeskVisible}
            />
            {newVersionAvailable && <BannerNewAppVersion inQuoteFlow />}
            {announcementIsVisible && <BannerAnnouncement />}
            <div
                className={classnames({
                    'l-quote__body': !isEsig,
                    'l-quote__iframe': isEsig
                })}
                ref={el}
            >
                {children}
            </div>
            {modalVisible &&
                createPortal(
                    <ModalResetQuoteApplication
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        onHide={() => toggleModal(false)}
                    />,
                    document.body
                )}
            {helpdeskVisible &&
                createPortal(
                    <ModalTicket onHide={() => setHelpdeskVisible(false)} geo={geo} />,
                    document.body
                )}
        </div>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    currentStep: getCurrentSignupStep(state),
    hasQuoteApplication: Boolean(get(state, 'signup.quoteApplication')),
    announcementIsVisible: Boolean(state.ui.visibleBanner === alerts.ANNOUNCEMENT),
    newVersionAvailable: Boolean(get(state, 'ui.newVersionAvailable')),
    geo: get(state, 'signup.quoteApplication.state')
});

export default connect(mapStateToProps)(QuoteLayoutPure);
