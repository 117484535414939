import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface ILoaderProps {
    alternative?: boolean;
    className?: string;
    hasOverlay?: boolean;
    hideChildren?: boolean;
    loading?: boolean;
    minHeight?: string;
}

const Loader: FunctionComponent<ILoaderProps> = ({
    alternative = false,
    children,
    className,
    hasOverlay = true,
    hideChildren,
    loading,
    minHeight
}) => (
    <div
        style={minHeight && loading ? { minHeight } : undefined}
        className={classnames(className, 'c-loader', {
            'c-spinner': loading,
            'c-spinner--alt': loading && alternative,
            'has-overlay': hasOverlay && loading
        })}
    >
        {hideChildren ? (
            <div className={loading ? 'u-visibility-hidden' : ''}>{children}</div>
        ) : (
            children
        )}
    </div>
);

export default Loader;
