// Returns links that have `is-active` class when
// root pathname matches url.

import { h, FunctionComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import Match from 'preact-router/match';
import classnames from 'classnames';

import { routes } from '../../../routes';

interface IRouterLinkProps extends h.JSX.DOMAttributes<HTMLAnchorElement> {
    href: string;
    className?: string;
}

const RouterLink: FunctionComponent<IRouterLinkProps> = ({
    href,
    children,
    className,
    ...rest
}) => {
    const isActive = useCallback((activeHref = '') => {
        if (!activeHref.includes('/') || typeof window === 'undefined') {
            return false;
        }

        // NOTE: this is a hack to allow insurance/modify link be highlighted
        // in sidebar nav without highlighting parent insurance/ route.
        // TODO: discuss weather navbar should just be place to drop various links
        // without reflecting app structure or to hold all pages (ie. have nested routes capability).
        const path = window.location.pathname;

        if (activeHref === routes.INSURANCE_MODIFY.path) {
            return path === activeHref;
        }

        if (activeHref === routes.INSURANCE.path && path === routes.INSURANCE_MODIFY.path) {
            return false;
        }

        // Covers nested paths like help/pay, help/support etc.
        return path.split('/')[1] === activeHref.split('/')[1];
    }, []);

    return (
        <Match>
            {() => (
                <a
                    className={classnames(className, { 'is-active': isActive(href) })}
                    href={href}
                    {...rest}
                >
                    {children}
                </a>
            )}
        </Match>
    );
};

export default RouterLink;
