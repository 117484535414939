// JSX wrapper for <svg> element.

import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

import { toSlug } from '../../helpers/formatters';

export interface ISvg extends Omit<h.JSX.SVGAttributes, 'size'> {
    className?: string;
    height?: string | number;
    size?: string | number;
    title?: string;
    use?: string;
    viewBox?: string;
    width?: string | number;
}

const iconsPath = process.env.STORYBOOK_BUILD ? './' : '/';

const Svg: FunctionComponent<ISvg> = ({
    children,
    className,
    height,
    size,
    title,
    use,
    width,
    ...rest
}) => (
    <svg
        aria-hidden={title ? undefined : true}
        aria-labelledby={title ? toSlug(title) : undefined}
        className={classnames(className, use)}
        role={title ? 'img' : undefined}
        xmlns="http://www.w3.org/2000/svg"
        width={width || size}
        height={height || size}
        {...rest}
    >
        {title && <title id={toSlug(title)}>{title}</title>}
        {use ? (
            <use href={`${iconsPath}icons.svg?v=${process.env.HUGO_APP_VERSION}#${use}`} />
        ) : (
            children
        )}
    </svg>
);

export default Svg;
