// Middleware to handle side-effects of API calls.

import { Middleware, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { route } from 'preact-router';

import { errorCodes, actions, status, apiPaths, alerts } from '../constants';
import { isTimeExpired } from '../helpers/value-checkers';
import { logOut, hydrateData } from '../actions/api';
import { routes } from '../routes';
import { showOffModal, setFlags, showModal } from '../actions/ui';
import { StoreShape } from '../types/store.types';
import { get } from '../helpers/misc';
import { isAcceptance } from '../helpers/geos';

import { browserNotSupported } from '@/ui/shared/Location/utils';

interface IThunkStore {
    dispatch: ThunkDispatch<StoreShape, unknown, AnyAction>;
    getState: () => StoreShape;
}

const IDLE_DISPATCH_THROTTLE = 30 * 1000;
const FOCUS_DISPATCH_THROTTLE = 5 * 1000;

export const REDIRECT_ERRORS = [
    errorCodes.NETWORK_ERROR,
    errorCodes.UNEXPECTED_RESPONSE_TYPE,
    errorCodes.INTERNAL_SERVER_ERROR,
    errorCodes.UNKNOWN_API_ERROR,
    errorCodes.MISSING_ENDPOINT
];

// NOT USED
// If API error is one of REDIRECT_ERRORS
// redirect from currect url to error view.
export const redirectOnError: Middleware<{}, StoreShape> = () => (next) => (action) => {
    next(action);

    if (action.type === actions.API_ERROR && REDIRECT_ERRORS.includes(action.error)) {
        route(routes.ERROR.path, true);
    }
};

export const redirectOnMaintenance: Middleware<{}, StoreShape> =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        if (
            action.type === actions.API_ERROR &&
            get(action, 'error.error') === errorCodes.MAINTENANCE
        ) {
            dispatch(setFlags({ isDownForMaintenance: true }));
            route(routes.MAINTENANCE.path, true);
        }
    };

// If API fails to provide new access token
// log user out of the app (so new access token can be requested via login/register).
export const logOutOnError: Middleware<{}, StoreShape> =
    ({ dispatch, getState }: IThunkStore) =>
    (next) =>
    (action) => {
        next(action);

        if (action.type === actions.API_ERROR && action.error) {
            const { status: errorStatus, error } = action.error;
            const activeRoutes = getState().api.loading;
            const isRefreshLoginRoute = activeRoutes.some((loadingRoute) =>
                loadingRoute.includes(apiPaths.REFRESH_LOGIN)
            );
            const isRefreshTokenMissingError =
                error === errorCodes.REFRESH_TOKEN_MISSING ||
                error === errorCodes.REFRESH_TOKEN_NOT_FOUND;

            if (errorStatus === 401 && (isRefreshLoginRoute || isRefreshTokenMissingError)) {
                dispatch(logOut());
            }
        }
    };

let lastIdleHydrate = 0;
export const hydrateWhileIdle: Middleware<{}, StoreShape> =
    ({ dispatch, getState }: IThunkStore) =>
    (next) =>
    (action) => {
        next(action);

        const { balance, coverage, policy } = getState();

        const { endTimeMs } = coverage;
        const { cancellationNonpaymentDate } = balance;

        const isCoverageOrPolicyExpired =
            isTimeExpired(cancellationNonpaymentDate) ||
            isTimeExpired(endTimeMs) ||
            isTimeExpired(policy.expiresAt);
        const isIdleAction = action.type === actions.APP_IDLE;
        const isFocusAction = action.type === actions.WINDOW_FOCUSED;

        const now = Date.now();
        const throttle = isIdleAction ? IDLE_DISPATCH_THROTTLE : FOCUS_DISPATCH_THROTTLE;
        if (
            isCoverageOrPolicyExpired &&
            (isIdleAction || isFocusAction) &&
            now - lastIdleHydrate > throttle
        ) {
            dispatch(hydrateData() as any);
            lastIdleHydrate = now;
        }
    };

export const updateInsuranceStatus: Middleware<{}, StoreShape> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const { coverage } = getState();
        const { status: oldStatus } = coverage;

        // Only action that sets coverage OFF is resetCoverageSession fired from normalize middleware
        if (oldStatus === status.PENDING_PAUSE && action.type === actions.RESET_COVERAGE_VALUES) {
            dispatch(
                showOffModal({
                    message: 'Insurance OFF!'
                })
            );
        }

        next(action);
    };

export const showWarningModal: Middleware<{}, StoreShape> =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const isBelowThreshold = (
            offDate: Date,
            expireDate: Date,
            unitTimeScalar: number = 1
        ): boolean => {
            const RENEWAL_THRESHOLD_MS = 1000 * 60 * 60 * 24 * 3 * unitTimeScalar; // 3 days
            const dateDiff = expireDate.valueOf() - offDate.valueOf();
            return dateDiff > 0 && dateDiff < RENEWAL_THRESHOLD_MS;
        };

        if (action.type === actions.MAP_API_DASHBOARD_DATA) {
            const {
                autoReloadPreferences,
                policies,
                account,
                unitTimeScalar,
                mustRespondToChargeback
            } = action.data;

            if (policies.length === 0) {
                return;
            }

            const { coverageSession, balance } = policies[0];
            const policy = policies[0];

            if (coverageSession.status === status.PAUSED && browserNotSupported()) {
                dispatch(
                    showModal(alerts.CHANGE_BROWSER, { returnLink: process.env.HUGO_APP_URL })
                );
                return;
            }

            const hasFallbackOffConsent =
                autoReloadPreferences.consentForFallbackOff &&
                autoReloadPreferences.scheduledOffDate;

            // Determine if we need to show a modal to address an urgent account
            // issue such as responding to a chargeback or reconfirming fallback
            // off consent
            if (mustRespondToChargeback) {
                dispatch(showModal(alerts.RESPOND_TO_CHARGEBACK));
            } else if (
                // Ensure we do _NOT_ have consent for fallback off
                !hasFallbackOffConsent &&
                // Make sure we are actually ON
                coverageSession.status === status.ACTIVE &&
                // Show the confirmation modal if autoreload is enabled and balance is not negative OR
                // if balance is positive. If autoreload is disabled and balance in days is 0, then we
                // need to make sure the empty account banner is visible, so we won't show the modal
                // https://github.com/popularlab/product/issues/4172
                ((autoReloadPreferences.isEnabled && balance.balanceInDays >= 0) ||
                    balance.balanceInDays > 0)
            ) {
                dispatch(showModal(alerts.CONFIRM_FALLBACK_OFF));
            } else if (
                // Reference: https://github.com/popularlab/product/issues/3979
                isAcceptance(policy.state, policy.data.controllerVersion) &&
                !autoReloadPreferences.isEnabled &&
                autoReloadPreferences.consentForFallbackOff &&
                isBelowThreshold(
                    new Date(autoReloadPreferences.scheduledOffDate),
                    new Date(policy.expireDate),
                    unitTimeScalar
                )
            ) {
                dispatch(showModal(alerts.RENEWAL_TIME_WARNING));
            } else if (account.phoneNumber && account.flags.hasPhoneDeliveryIssue) {
                dispatch(showModal(alerts.REVERIFY_PHONE));
            }
        }
    };
