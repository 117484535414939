import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface IModalContentProps {
    center?: boolean;
    className?: string;
    large?: boolean;
}

const ModalContent: FunctionComponent<IModalContentProps> = ({
    center = false,
    children,
    className,
    large = false,
    ...rest
}) => (
    <div
        className={classnames(
            'c-modal__content',
            {
                't-display-2': !large,
                't-display-3': large,
                'u-text-center': center
            },
            className
        )}
        {...rest}
    >
        {children}
    </div>
);

export default ModalContent;
