import { h } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import { useState } from 'preact/hooks';

import { routes } from '../../routes';
import { Modal, ModalActions, ModalContent, ModalIcon } from '../components/Modal';
import { Button, ButtonCta } from '../components/Button';
import { IconPhoneAdd } from '../icons';
import { StoreShape } from '../../types/store.types';
import { setAccountFlag } from '../../actions/api';
import { phoneNumber } from '../../helpers/masks';
import accountFlags from '../../constants/account-flags';

interface IModalReverifyPhoneProps {
    onHide: () => void;
    phone: string;
    dispatch: (action: any) => Promise<any>;
}

const ModalReverifyPhone = ({ onHide, phone, dispatch }: IModalReverifyPhoneProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirmPhoneIsCorrect = () => {
        setIsLoading(true);
        dispatch(setAccountFlag(accountFlags.HAS_PHONE_DELIVERY_ISSUE, false)).then(() => {
            onHide();
        });
    };

    const handleUpdatePhone = () => {
        route(routes.ACCOUNT_PHONE.path);
        onHide();
    };

    return (
        <Modal onHide={onHide}>
            <ModalIcon>
                <IconPhoneAdd
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="40"
                />
            </ModalIcon>
            <ModalContent>
                <p>
                    <b>
                        We’ve been unable to reach you at{' '}
                        <span className="u-no-wrap">{phoneNumber(phone)}.</span>
                    </b>
                </p>
                <p>
                    Please register a valid mobile number so that we can text message you important
                    alerts and reminders concerning your policy.
                </p>
            </ModalContent>
            <ModalActions>
                <ButtonCta onClick={handleUpdatePhone} loading={isLoading}>
                    Update my phone number
                </ButtonCta>

                <Button link onClick={handleConfirmPhoneIsCorrect} disabled={isLoading}>
                    Not now, thanks
                </Button>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    phone: state.account.phone
});

export default connect(mapStateToProps)(ModalReverifyPhone);
