import { h } from 'preact';

import { ButtonCta } from '../components/Button';
import { IconCarSadWhite } from '../icons';
import { Modal, ModalContent, ModalActions, ModalTitle, ModalIcon } from '../components/Modal';

const ModalQuoteExpired = (props) => (
    <Modal onHide={props.onHide} large dark>
        <ModalIcon>
            <IconCarSadWhite size="32" />
        </ModalIcon>
        <ModalTitle className="u-font-3">
            We’re sorry, it looks like your quote has expired.
        </ModalTitle>
        <ModalContent large center>
            <p>No problem — just start a new quote and we’ll get you moving in no time!</p>
        </ModalContent>
        <ModalActions>
            <ButtonCta white onClick={props.onHide}>
                Start new quote
            </ButtonCta>
        </ModalActions>
    </Modal>
);

export default ModalQuoteExpired;
