import { h, Fragment } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';
import { Router, route } from 'preact-router';
import { connect } from 'react-redux';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/preact';

import { routes, ROUTE_KEYS } from '../routes';
import { runAnalytics } from '../analytics';
import { setActiveUrl, showModal } from '../actions/ui';
import { get, set } from '../helpers/misc';
import { isObjectEmpty } from '../helpers/value-checkers';
import Auth from './hoc/Auth';
import Layout from './layouts';
import ModalRoot from './hoc/ModalRoot';
import { Loader } from './components/Loader';
import CheckUpdate from './hoc/CheckUpdate';
import { alerts, errorCodes } from '../constants';
import { ErrorView } from './views';
import { getAccountId } from '../selectors';
import { ServiceWorkerMessage } from '../constants/messages';
import { updateClientPermissions } from '../actions/app';

const appsignal = new Appsignal({
    key: process.env.HUGO_APPSIGNAL_MONITORING_KEY,
    ignoreErrors: [
        /Importing a module script failed/,
        /Failed to fetch dynamically imported module/
    ],
    namespace: 'frontend'
});

const App = ({
    dispatch,
    endorsement,
    quoteStatusChecked,
    sessionChecked,
    signup,
    store,
    accountId,
    apiVersion
}) => {
    useEffect(() => {
        if (apiVersion) {
            // Force AppSignal reported version to match the backend version
            // since AppSignal otherwise would treat them as separate deploys
            appsignal.addDecorator((span) => {
                // A bit of a hack to update the revision because AppSignal
                // doesn't provide a way to do this after initialization
                set(span, '_data.revision', apiVersion);
            });
        }
    }, [apiVersion]);

    useEffect(() => {
        if (sessionChecked) {
            // If on `/quote` wait until GET /quote-application/self
            // finish before loading analytics
            if (routes.QUOTE.path === window.location.pathname && quoteStatusChecked) {
                runAnalytics();
            } else {
                runAnalytics();
            }
        }
    }, [sessionChecked, quoteStatusChecked]);

    useEffect(() => {
        /**
         * Message handler from the service worker so that we can check if it supports
         * specific features.
         * @param {MessageEvent} e
         */
        const onMessage = (e) => {
            const { data } = e;
            if (data.type === ServiceWorkerMessage.CAPABILITIES) {
                const { payload } = data;
                if (payload && payload.notifications) {
                    dispatch(updateClientPermissions({ notifications: payload.notifications }));
                }
                navigator.serviceWorker.removeEventListener('message', onMessage);
            }
        };

        navigator.serviceWorker && navigator.serviceWorker.addEventListener('message', onMessage);
        navigator.serviceWorker &&
            navigator.serviceWorker.ready.then((registration) => {
                registration.active.postMessage(ServiceWorkerMessage.CAPABILITIES);
            });

        return () => {
            navigator.serviceWorker &&
                navigator.serviceWorker.removeEventListener('message', onMessage);
        };
    }, []);

    const handleRoute = useCallback(
        (e) => {
            // Throw confirmation modal if user is navigating away from
            // incomplete endorsement flow.
            if (
                e.previous === routes.INSURANCE_MODIFY.path &&
                endorsement &&
                endorsement.endorsementDiff
            ) {
                dispatch(showModal(alerts.UNSAVED_CHANGES, { url: e.url }));
                route(routes.INSURANCE_MODIFY.path, true);
                return;
            }
            // Use location.pathname instead e.url to get path without query string.
            let path = window.location.pathname;
            if (path.length > 1 && path[path.length - 1] === '/') {
                // Strip out trailing slashes just in case
                path = path.substring(0, path.length - 1);
            }
            if (routes.QUOTE.path === window.location.pathname && isObjectEmpty(signup)) {
                import('../reducers/signup')
                    .then((module) => store.injectReducer('signup', module.default))
                    .finally(() => dispatch(setActiveUrl(path)));
            } else if (
                routes.INSURANCE_MODIFY.path === window.location.pathname &&
                isObjectEmpty(endorsement)
            ) {
                import('../reducers/endorsement')
                    .then((module) => store.injectReducer('endorsement', module.default))
                    .finally(() => dispatch(setActiveUrl(path)));
            } else {
                dispatch(setActiveUrl(path));
            }
        },
        [signup, endorsement]
    );

    if (!sessionChecked) {
        return <Loader loading={true} minHeight={'100vh'} />;
    }
    return (
        <ErrorBoundary
            instance={appsignal}
            tags={{
                version: process.env.HUGO_APP_VERSION,
                url: window.location.href,
                accountId,
                fullstorySessionURL:
                    window.FS && typeof window.FS.getCurrentSessionURL === 'function'
                        ? window.FS.getCurrentSessionURL()
                        : null
            }}
            fallback={() => (
                <Layout>
                    <ErrorView errorType={errorCodes.TECHNICAL_ERROR} />
                </Layout>
            )}
        >
            <Router onChange={handleRoute}>
                <Fragment default>
                    <Layout>
                        <Fragment>
                            <Router>
                                {ROUTE_KEYS.map((key) => {
                                    const props = { ...routes[key] };
                                    return <Auth {...props} key={key} />;
                                })}
                            </Router>
                        </Fragment>
                    </Layout>
                </Fragment>
            </Router>
            <ModalRoot />
            <CheckUpdate />
        </ErrorBoundary>
    );
};

const mapStateToProps = (state) => ({
    endorsement: get(state, 'endorsement'),
    quoteStatusChecked: get(state, 'signup.meta.statusChecked'),
    sessionChecked: get(state, 'ui.sessionChecked'),
    signup: get(state, 'signup'),
    accountId: getAccountId(state),
    apiVersion: get(state, 'api.version')
});

export default connect(mapStateToProps)(App);
