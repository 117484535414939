import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { dateTimeString } from '../../helpers/formatters';
import { getLoadingArray } from '../../selectors';
import { StoreShape } from '../../types/store.types';
import { Banner, BannerTitle, BannerContent } from '../components/Banner';

export const BannerAccountLow: FunctionComponent<{
    scheduledOffDate: Date | null;
    loading?: boolean;
}> = ({ scheduledOffDate, loading }) => {
    // Don't render alert while coverage toggle is in progress.
    if (loading) {
        return null;
    }
    const offDate = dateTimeString(scheduledOffDate);

    return (
        <Banner>
            <BannerTitle>Your account is low!</BannerTitle>
            <BannerContent>
                <p>
                    You’ve scheduled your insurance to turn off at {offDate}. To keep coverage
                    active instead, just add money before {offDate}.
                </p>
            </BannerContent>
        </Banner>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    scheduledOffDate: state.payment.scheduledOffDate,
    loading: getLoadingArray(state).includes('coverage:bind')
});

export default connect(mapStateToProps)(BannerAccountLow);
