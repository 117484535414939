// Main entry file for application.
// DO NOT convert this file to TypeScript else build would fail.

import './styles/main.scss';
import './scripts/polyfills';

import { h, render } from 'preact';
import { Provider } from 'react-redux';

import store from './scripts/store';
import { hydrateData } from './scripts/actions/api';
import { setSessionChecked, setFlags } from './scripts/actions/ui';
import { updateClientPermissions } from './scripts/actions/app';
import { errorCodes } from './scripts/constants';
import App from './scripts/ui/App';

import './scripts/watchers';
import './scripts/url-parser';
import { infoLogger } from '@/helpers/misc';

// We wanna fire off initial API call ASAP,
// so we do it here before rendering happens.
// Until API responds only spinner is rendered.
store
    .dispatch(hydrateData())
    .catch((error) => {
        // Special case to handle down for maintenance. Since this is happening before we've
        // established the router for the app, we can't really do any redirects or routing, so
        // we will leverage a UI store flag that will force us to display the Maintenance
        // view when it is active, similar to forcing a logged out user to the login page
        if (error && error.error === errorCodes.MAINTENANCE) {
            store.dispatch(setFlags({ isDownForMaintenance: true }));
        }
    })
    .finally(() => store.dispatch(setSessionChecked()));

if (process.env.HUGO_ENV !== 'production') {
    window.store = store;
    window.hydrateData = () => store.dispatch(hydrateData());
}

if (navigator && navigator.permissions && navigator.permissions.query) {
    const permissionNames = ['geolocation', 'notifications'];
    // Add watcher for permission changes at the root level
    permissionNames.forEach((permissionName) => {
        navigator.permissions.query({ name: permissionName }).then((permission) => {
            infoLogger(`Permission ${permissionName} is ${permission.state}`);
            store.dispatch(updateClientPermissions({ [permissionName]: permission.state }));
            // eslint-disable-next-line no-param-reassign
            permission.onchange = () => {
                store.dispatch(
                    updateClientPermissions({
                        [permissionName]: permission.state
                    })
                );
            };
        });
    });
}

const HugoApp = () => (
    <Provider store={store}>
        <App store={store} />
    </Provider>
);

render(<HugoApp />, document.getElementById('root'));
