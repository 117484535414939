/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Actions for handling state of UI elements (data that represents how the UI is currently displayed).

import { actions, copy, alerts } from '../constants';
import { IUiState } from '../reducers/ui';
import { IShowBannerAction, IShowModalAction, ISetAnnouncementAction } from '../types/action.types';

export const setAnnouncement = (message: string): ISetAnnouncementAction => ({
    type: actions.SET_ANNOUNCEMENT,
    payload: { message }
});

// For passed modal id, set
// that modal state as visible.
export const showModal = (id: alerts, additionalProps?: any): IShowModalAction => ({
    type: actions.SHOW_MODAL,
    id,
    payload: { ...additionalProps }
});

export const showErrorModal = (props: any): IShowModalAction =>
    showModal(alerts.NOTIFICATION, { ...props, error: true });

export const showOnModal = (props: any): IShowModalAction =>
    showModal(alerts.NOTIFICATION, { ...props, on: true });

export const showOffModal = (props: any): IShowModalAction =>
    showModal(alerts.NOTIFICATION, { ...props, off: true });

export const showSentModal = (props: any): IShowModalAction =>
    showModal(alerts.NOTIFICATION, { ...props, sent: true });

export const showDoneModal = (props: any): IShowModalAction =>
    showModal(alerts.NOTIFICATION, { ...props, done: true });

// Reusable generic action for API failure.
export const showApiFailModal = (): IShowModalAction =>
    showErrorModal({
        message: copy.formErrors.api.GENERIC
    });

interface IHideModalAction {
    type: typeof actions.HIDE_MODAL;
}

// Hide modal if visible.
export const hideModal = (): IHideModalAction => ({ type: actions.HIDE_MODAL });

interface IOpenMenuAction {
    type: typeof actions.OPEN_MAIN_MENU;
}

export const openMenu = (): IOpenMenuAction => ({ type: actions.OPEN_MAIN_MENU });

interface ICloseMenuAction {
    type: typeof actions.CLOSE_MAIN_MENU;
}

export const closeMenu = (): ICloseMenuAction => ({ type: actions.CLOSE_MAIN_MENU });

interface IAppIdleAction {
    type: typeof actions.APP_IDLE;
}

// App is idle for at least 30sec.
export const setAppIdle = (): IAppIdleAction => ({ type: actions.APP_IDLE });

interface IWindowFocusedAction {
    type: typeof actions.WINDOW_FOCUSED;
}

export const windowInFocus = (): IWindowFocusedAction => ({ type: actions.WINDOW_FOCUSED });

export const showBanner = (bannerId: alerts): IShowBannerAction => ({
    type: actions.SHOW_BANNER,
    payload: bannerId
});

interface IDismissBannerAction {
    type: typeof actions.DISMISS_BANNER;
}

export const dismissBanner = (): IDismissBannerAction => ({ type: actions.DISMISS_BANNER });

export interface IActiveUrlAction {
    type: typeof actions.SET_ACTIVE_URL;
    payload: {
        activeUrl: string;
    };
}

export const setActiveUrl = (activeUrl: string): IActiveUrlAction => ({
    type: actions.SET_ACTIVE_URL,
    payload: { activeUrl }
});

interface ISetFlagsAction {
    type: typeof actions.SET_FLAGS;
    payload: Partial<IUiState>;
}

export const setFlags = (payload: Partial<IUiState>): ISetFlagsAction => ({
    type: actions.SET_FLAGS,
    payload
});

interface ISessionCheckedAction {
    type: typeof actions.SESSION_CHECKED;
}

export const setSessionChecked = (): ISessionCheckedAction => ({ type: actions.SESSION_CHECKED });

interface IReinstateIntentAction {
    type: typeof actions.SET_REINSTATE_INTENT;
    payload: {
        reinstateIntent: boolean;
    };
}

export const setReinstateIntent = (reinstateIntent: boolean): IReinstateIntentAction => ({
    type: actions.SET_REINSTATE_INTENT,
    payload: { reinstateIntent }
});

export const setUpdateAvailable = () => ({ type: actions.SET_UPDATE_AVAILABLE });

export interface IPwaAction {
    type: typeof actions.SAVE_PWA_EVENT;
    payload: { pwaInstallEvent: BeforeInstallPromptEvent | null };
}

export const saveInstallPromptEvent = (payload: {
    pwaInstallEvent: BeforeInstallPromptEvent | null;
}): IPwaAction => ({
    type: actions.SAVE_PWA_EVENT,
    payload
});
