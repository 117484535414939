import { h } from 'preact';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'preact/hooks';

import { hydrateData } from '../../actions/api';
import { ButtonCta } from '../components/Button';
import { IconAlertColored } from '../icons';
import { Modal, ModalContent, ModalActions, ModalTitle, ModalIcon } from '../components/Modal';

const ModalOverdrawnBalanceChanged = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        setIsLoading(true);
        dispatch(hydrateData()).then(() => {
            setIsLoading(false);
            props.onHide();
        });
    }, []);

    return (
        <Modal onHide={props.onHide} closeButton={false}>
            <ModalIcon>
                <IconAlertColored
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="42"
                />
            </ModalIcon>
            <ModalTitle>Your balance has changed...</ModalTitle>
            <ModalContent center>
                <p>
                    It looks like your balance has changed while viewing this page. Please review
                    the total again before checking out.
                </p>
            </ModalContent>

            <ModalActions>
                <ButtonCta loading={isLoading} onClick={onClose}>
                    Okay
                </ButtonCta>
            </ModalActions>
        </Modal>
    );
};

export default ModalOverdrawnBalanceChanged;
