import { h } from 'preact';

import { IconWaveWhite } from '../icons';
import { Modal, ModalContent, ModalTitle, ModalIcon } from '../components/Modal';

const ModalQuoteWelcomeBack = (props) => (
    <Modal onHide={props.onHide} dark drawer>
        <ModalIcon>
            <IconWaveWhite size="32" />
        </ModalIcon>
        <ModalTitle>Welcome back!</ModalTitle>
        <ModalContent center>
            <p>
                You can pickup where you left off. Don’t worry, you will be able to review or change
                your application before the final step.
            </p>
        </ModalContent>
    </Modal>
);

export default ModalQuoteWelcomeBack;
