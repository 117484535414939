import { h, FunctionComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import classnames from 'classnames';

import { IconClose } from '../../icons';
import { Button } from '../Button';

export interface IModalProps {
    className?: string;
    closeButton?: boolean;
    dark?: boolean;
    drawer?: boolean;
    large?: boolean;
    light?: boolean;
    onHide?: () => void;
    disableOverlayClick?: boolean;
}

const Modal: FunctionComponent<IModalProps> = ({
    children,
    className,
    closeButton = true,
    dark = false,
    drawer = false,
    large = false,
    light = false,
    onHide = () => {},
    disableOverlayClick = false,
    ...rest
}) => {
    const ref = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        if (ref.current && ref.current.showModal) {
            ref.current.showModal();
        }
    }, []);

    const onClose = () => {
        if (ref.current && ref.current.close) {
            ref.current.close();
        }
        onHide();
    };

    useEffect(() => {
        const handleOverlayClick = (e: MouseEvent) => {
            if (!ref.current || disableOverlayClick) {
                return;
            }

            const r = ref.current.getBoundingClientRect();
            const isOutsideClick =
                e.clientX < r.left ||
                e.clientX > r.right ||
                e.clientY < r.top ||
                e.clientY > r.bottom;

            if (isOutsideClick && (e.target as HTMLElement).tagName === 'DIALOG') {
                onClose();
            }
        };
        ref.current && ref.current.addEventListener('click', handleOverlayClick, true);
        return () => {
            ref.current && ref.current.removeEventListener('click', handleOverlayClick, true);
        };
    }, []);

    return (
        <dialog
            ref={ref}
            className={classnames(
                'c-modal',
                {
                    'c-modal--dark': dark,
                    'c-modal--drawer': drawer,
                    'c-modal--large': large,
                    'c-modal--light': light
                },
                className
            )}
            onKeyDown={(event) => event.code === 'Escape' && onClose()}
            {...rest}
        >
            {closeButton && (
                <Button icon className="c-modal__close" onClick={() => onClose()}>
                    <IconClose title="Close modal" />
                </Button>
            )}

            <div className="c-modal__inner">{children}</div>
        </dialog>
    );
};

export default Modal;
