import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { isInQuoteFlow } from '../../selectors';
import { StoreShape } from '../../types/store.types';
import { Banner, BannerTitle, BannerActions, BannerContent } from '../components/Banner';
import { ButtonLink } from '../components/Button';

export const BannerNewAppVersion: FunctionComponent<{ inQuoteFlow: Boolean }> = ({
    inQuoteFlow
}) => {
    return (
        <Banner light>
            <BannerTitle>A new version of Hugo is available.</BannerTitle>
            <BannerContent>
                <p>Please refresh your screen for the latest version.</p>
                {inQuoteFlow && <p>Your progress is saved.</p>}
            </BannerContent>
            <BannerActions>
                <ButtonLink
                    className="track:banner__new-app-version"
                    onClick={() => window.location.reload()}
                >
                    Refresh now
                </ButtonLink>
            </BannerActions>
        </Banner>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    inQuoteFlow: isInQuoteFlow(state)
});

export default connect(mapStateToProps)(BannerNewAppVersion);
