import { h, FunctionComponent } from 'preact';

import Button from './Button';
import { IButtonProps } from './Button.types';

interface IButtonCtaProps extends IButtonProps {
    white?: boolean;
}

const ButtonCta: FunctionComponent<IButtonCtaProps> = ({ children, white, ...rest }) => {
    return (
        <Button primary={!white} inverted={white} large {...rest}>
            {children}
        </Button>
    );
};

export default ButtonCta;
