// Browser and OS detection functions partially lifted from Twilio
// https://github.com/twilio/twilio-video.js/blob/master/lib/util/browserdetection.js
// https://github.com/twilio/twilio-video.js/blob/master/lib/webrtc/util/index.js

/**
 * Guess the browser.
 * @param {string} [userAgent=navigator.userAgent]
 * @returns {?string} browser - "chrome", "firefox", "safari", or undefined
 */
export function guessBrowser(userAgent?: string): string | undefined {
    const agent = userAgent || navigator.userAgent || '';
    if (/Chrome|CriOS/.test(agent)) {
        return 'chrome';
    }
    if (/Firefox|FxiOS/.test(agent)) {
        return 'firefox';
    }
    if (/Safari|iPhone|iPad|iPod/.test(agent)) {
        return 'safari';
    }
    return undefined;
}

/**
 * Check whether the current browser is iOS Chrome.
 * @param {string} [userAgent=navigator.userAgent]
 * @returns {boolean}
 */
export function isIOSChrome(userAgent?: string): boolean {
    const agent = userAgent || navigator.userAgent || '';
    return /Mobi/.test(agent) && guessBrowser(agent) === 'chrome' && /iPad|iPhone|iPod/.test(agent);
}

/**
 * Check iOS version
 * @param {string} [userAgent=navigator.userAgent]
 * @returns {?string} iOS major.minor version
 */
export function checkIOSVersion(userAgent?: string): string | undefined {
    const agent = userAgent || window.navigator.userAgent || '';

    if (/iPhone|iPad|iphone|iPod/.test(agent)) {
        const match =
            agent.match(/(iPad|iPhone|iphone|iPod).*?(OS |os |OS_)(\d+((_|\.)\d)?((_|\.)\d)?)/) ||
            [];
        const v = match[3].split('_');
        return `${v[0]}.${v[1]}`;
    }

    if (/Version\//.test(agent)) {
        const match = agent.match(/(Version)\/(\d+((_|\.)\d)?((_|\.)\d)?)/) || [];
        const v = match[2].split('.');
        return `${v[0]}.${v[1]}`;
    }

    return undefined;
}

/**
 * Check if current browser is Chrome on iOS 17.1
 * @returns {boolean}
 */
export function browserNotSupported(): boolean {
    const BROKEN_SAFARI_VERSION = '17.1';
    if (isIOSChrome() && checkIOSVersion() === BROKEN_SAFARI_VERSION) {
        return true;
    }
    return false;
}

/**
 * Check whether the current browser is a mobile browser
 * @returns {boolean}
 */
export function isMobile() {
    return /Mobi/.test(navigator.userAgent);
}

/**
 * Detects whether or not a device is an Apple touch screen device.
 * @returns {boolean}
 */
function hasTouchScreen() {
    return !!(navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
}

/**
 * Detects whether or not a device is an iPad.
 * @returns {boolean}
 */
function isIpad() {
    return (
        hasTouchScreen() &&
        window.screen.width >= 744 &&
        (/Macintosh/i.test(navigator.userAgent) ||
            /iPad/.test(navigator.userAgent) ||
            /iPad/.test(navigator.platform))
    );
}

/**
 * Detects whether or not a device is an iPhone.
 * @returns {boolean}
 */
function isIphone() {
    return (
        hasTouchScreen() &&
        window.screen.width <= 476 &&
        (/Macintosh/i.test(navigator.userAgent) ||
            /iPhone/.test(navigator.userAgent) ||
            /iPhone/.test(navigator.platform))
    );
}

/**
 * Check whether the current device is an iOS device.
 * @returns {boolean}
 */
export function isIOS() {
    return isIpad() || isIphone();
}
