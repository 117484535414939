import {
    CoverageBundleType,
    PolicyType,
    StateCode,
    BalanceAccountFeeType,
    BalanceAccountFeeAction
} from '@popularlab/enums';
import { h } from 'preact';

import { status as CoverageStatus } from '../constants';
import { IVehicle, PolicyCoverageOptions } from './steps/flows/flows.types';

export enum RequestNotificationType {
    SUCCESS = 'success',
    DANGER = 'danger',
    INFO = 'info'
}

// @TODO convert hoc/Form to TS
export interface IForm {
    handleInput: h.JSX.GenericEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    handleSubmit: (e: Event, callback?: Function) => {};
    setValues: Function;
    setRef: Function;
    errors: {
        firstName?: string;
        lastName?: string;
        [key: string]: any;
    };
    setErrors: FormSetErrors;
    fields: {
        firstName?: string;
        lastName?: string;
        [key: string]: any;
    };
    apiError?: string;
    isSubmitting?: boolean;
    isEmpty?: boolean;
}

export type FormSetErrors = (errors: { [key: string]: string }) => void;

export interface ICoverageSession {
    status: CoverageStatus;
    carrier: string;
    naic: string;
    id: string;
    startTimeMs: number;
    endTimeMs: number;
}

export interface IPolicyBalance {
    balance: number;
    balanceInDays: number;
    coverageStatus: CoverageStatus;
    dayRate: number;
    dueDate: string;
    isOverdrawn: boolean;
    maxWarningPeriodCoveredDays: number;
    minTrueUpBalance: number;
    minTrueUpBalanceInDays: number;
    onAndAutoReloadBalanceRequirement: number;
    onAndAutoReloadBalanceRequirementInDays: number;
    overdrawnBalance: number;
    overdrawnBalanceInDays: number;
    policyId: number;
    warningPeriodCoveredDays: number;
    warningPeriodCoveredDaysRemaining: number;
}

export interface IPolicyData {
    pni: any;
    policy: {
        coverageOptions: PolicyCoverageOptions;
        policyType: PolicyType;
    };
    vehicles: {
        discovered: {
            [key: string]: IVehicle;
        };
        reported: {
            [key: string]: IVehicle;
        };
    };
    additionalDrivers: any;
    basisTimestampMs: number;
    controllerVersion: string;
    flowVersion: string;
}

export interface IPolicy {
    id: number;
    policyNumber: string;
    zipCode: string;
    state: StateCode;
    timeZoneId: string;
    term: number;
    startDate: string;
    expireDate: string;
    data: IPolicyData;
    isCancelledForNonpayment: boolean;
    isObliterated: boolean;
    obliterationMeta: any;
    obliterationReason: any;
    nonRenewalReason: any;
    nonRenewalMeta: any;
    createdAt: string;
    updatedAt: string;
    naic: string;
    premium: {
        rate: number;
        dayRate: number;
    };
    coverageSession?: ICoverageSession;
    coverageBundleType: CoverageBundleType;
}

export interface IAccessProps {
    isLogged: boolean;
    isTraditional: boolean;
    isBasic: boolean;
    isCanceled: boolean;
    isFull: boolean;
    isLapsed: boolean;
    isRestrictedAndOverdrawn: boolean;
    isRestricted: boolean;
    notBasic: boolean;
    hasOverdrawnBalance: boolean;
    activeGeo?: StateCode;
    activeVersion?: string | null;
    isExtensionAvailable: boolean;
    isExtensionActive: boolean;
    isCoverageActive: boolean;
    hasFallbackOffConsent: boolean;
    hasReferralCode: boolean;
    isRenewalWindow: boolean;
}

export interface BalanceAccountFeeSchema {
    type: BalanceAccountFeeType;
    totalFee: number;
    feeDayRate: number;
}

export type PossibleAccountFees = {
    [key in BalanceAccountFeeAction]?: BalanceAccountFeeSchema[];
};

export interface ILeadInsurifyItem {
    carrierName: string;
    clickUrl: string;
    logoUrl: string;
    quotePrice?: number;
}

export interface ILeadResults {
    items: ILeadInsurifyItem[];
    completed: boolean;
}

export function assertNever(x: never): never {
    throw new Error(`Unknown type value: ${x}`);
}

export interface Screenshot {
    darkMode?: boolean;
    html: string;
    viewportWidth: number;
    viewportHeight: number;
}
