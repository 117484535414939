// Constants used in actions and reducers written as key mirror.

export default {
    // API
    API_CANCEL: 'API_CANCEL',
    API_END: 'API_END',
    API_ERROR: 'API_ERROR',
    API_START: 'API_START',
    HYDRATION_STATUS: 'HYDRATION_STATUS',
    SET_API_VERSION: 'SET_API_VERSION',

    // APP
    CHECK_FOR_ALERTS: 'CHECK_FOR_ALERTS',
    LOG_OUT: 'LOG_OUT',
    MAP_API_ACCOUNT_DATA: 'MAP_API_ACCOUNT_DATA',
    MAP_API_DASHBOARD_DATA: 'MAP_API_DASHBOARD_DATA',
    UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',

    // DATA
    ADD_TO_CART: 'ADD_TO_CART',
    APPLY_REWARDS_AT_CHECKOUT: 'APPLY_REWARDS_AT_CHECKOUT',
    CLEAR_CART: 'CLEAR_CART',
    RESET_COVERAGE_VALUES: 'RESET_COVERAGE_VALUES',
    SAVE_ACCOUNT_DATA: 'SAVE_ACCOUNT_DATA',
    SAVE_ACCOUNT_ID: 'SAVE_ACCOUNT_ID',
    SAVE_BALANCE_DATA: 'SAVE_BALANCE_DATA',
    SAVE_CARD_DATA: 'SAVE_CARD_DATA',
    SAVE_PAYMENT_DATA: 'SAVE_PAYMENT_DATA',
    SAVE_PAYMENT_HISTORY: 'SAVE_PAYMENT_HISTORY',
    SAVE_POLICY_DATA: 'SAVE_POLICY_DATA',
    SAVE_QUERY_STRING: 'SAVE_QUERY_STRING',
    SAVE_REGISTRATION_PROTECTION_PREF: 'SAVE_REGISTRATION_PROTECTION_PREF',
    SAVE_REWARD_BALANCE_DATA: 'SAVE_REWARD_BALANCE_DATA',
    SAVE_SUGGESTED_ADDRESS: 'SAVE_SUGGESTED_ADDRESS',
    SAVE_USER_DATA: 'SAVE_USER_DATA',
    SET_AVAILABLE_PACKAGES: 'SET_AVAILABLE_PACKAGES',
    SET_EMAIL: 'SET_EMAIL',
    SET_PHONE: 'SET_PHONE',
    SET_UNIT_TIME_SCALAR: 'SET_UNIT_TIME_SCALAR',
    SET_UNPAID_FEE_TOTAL: 'SET_UNPAID_FEE_TOTAL',
    UPDATE_COVERAGE_DATA: 'UPDATE_COVERAGE_DATA',
    UPDATE_OPEN_TICKETS: 'UPDATE_OPEN_TICKETS',
    UPDATE_REWARDS: 'UPDATE_REWARDS',
    SET_PENDING_REWRITE: 'policy:set-pending-rewrite',
    SET_PENDING_STATE_FILING_REQUEST: 'SET_PENDING_STATE_FILING_REQUEST',

    // UI
    APP_IDLE: 'APP_IDLE',
    CLOSE_MAIN_MENU: 'CLOSE_MAIN_MENU',
    DISMISS_BANNER: 'DISMISS_BANNER',
    HIDE_MODAL: 'HIDE_MODAL',
    OPEN_MAIN_MENU: 'OPEN_MAIN_MENU',
    SET_ACTIVE_URL: 'SET_ACTIVE_URL',
    SET_ANNOUNCEMENT: 'SET_ANNOUNCEMENT',
    SET_FLAGS: 'SET_FLAGS',
    SHOW_BANNER: 'SHOW_BANNER',
    SHOW_MODAL: 'SHOW_MODAL',
    WINDOW_FOCUSED: 'WINDOW_FOCUSED',
    SESSION_CHECKED: 'SESSION_CHECKED',
    SET_REINSTATE_INTENT: 'SET_REINSTATE_INTENT',
    SET_UPDATE_AVAILABLE: 'SET_UPDATE_AVAILABLE',
    SAVE_PWA_EVENT: 'SAVE_PWA_EVENT',

    // SIGNUP (QUOTE FLOW)
    SIGNUP_BACK: 'quote:back',
    SIGNUP_BIND: 'quote:bind',
    SIGNUP_INSURIFY: 'quote:insurify',
    SIGNUP_INITIALIZE: 'quote:initialize',
    SIGNUP_NAVIGATE: 'quote:navigate',
    SIGNUP_RESET: 'quote:reset',
    SIGNUP_SERVICE_ERROR: 'quote:service-error',
    SIGNUP_SET_META: 'quote:set-meta',
    SIGNUP_SET_QUOTE_CONTROLLER: 'quote:set-controller',
    SIGNUP_UPDATE: 'quote:update',
    SIGNUP_UPDATE_ERROR: 'quote:update-error',
    SIGNUP_TEMP_POLICY: 'quote:temp-policy',
    SIGNUP_TEMP_QUOTE: 'quote:temp-quote',

    ENDORSEMENT_RESET: 'endorsement:reset',
    ENDORSEMENT_UPDATE: 'endorsement:update',
    ENDORSEMENT_UPDATE_META: 'endorsement:update-meta',
    ENDORSEMENT_SET_CONTROLLER: 'endorsement:set-controller',
    ENDORSEMENT_NAVIGATE: 'endorsement:navigate',
    ENDORSEMENT_BACK: 'endorsement:back',
    ENDORSEMENT_ADD: 'endorsement:add',

    COVERAGE_PROPOSAL_UPDATE: 'proposal:update',
    COVERAGE_PROPOSAL_RESET: 'proposal:reset'
};
