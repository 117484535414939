// Utility for localStorage and sessionStorage

export const safeJsonParse = (value: string | null) => {
    try {
        return JSON.parse(value as string);
    } catch (e) {
        return value;
    }
};

// Better storage test than if (window.localStorage && window.sessionStorage)
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
export const isSupported = (storage: Storage) => {
    try {
        const key = '__STORAGE_TEST__';
        storage.setItem(key, key);
        storage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
};

function storageFactory(getStorage: () => Storage) {
    const memoryStorage: { [key: string]: string } = {};

    const setItem = (name: string, value: any) => {
        if (isSupported(getStorage())) {
            getStorage().setItem(name, JSON.stringify(value));
        } else {
            memoryStorage[name] = JSON.stringify(value);
        }
    };

    const getItem = (name: string) => {
        if (isSupported(getStorage())) {
            return safeJsonParse(getStorage().getItem(name));
        }
        if (memoryStorage[name]) {
            return memoryStorage[name];
        }
        return null;
    };

    const removeItem = (name: string) => {
        if (isSupported(getStorage())) {
            getStorage().removeItem(name);
        } else {
            delete memoryStorage[name];
        }
    };

    return {
        setItem,
        getItem,
        removeItem
    };
}

export const localStorage = storageFactory(() => window.localStorage);
export const sessionStorage = storageFactory(() => window.sessionStorage);
