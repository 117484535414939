// access-levels.js
// Lookup for Hugo App feature access levels.
// https://github.com/popularlab/product/issues/341

export default {
    // No valid insurace policy
    BASIC: 'BASIC',
    // Active insurance
    FULL: 'FULL',
    // Negative balance
    RESTRICTED: 'RESTRICTED',

    // Subsets:
    // No access to signup flow
    BASIC_WAITLIST: 'BASIC_WAITLIST',
    // Negative balance inside warning period
    RESTRICTED_OVERDRAWN: 'RESTRICTED_OVERDRAWN',
    // Negative balance past warning period
    RESTRICTED_LAPSED: 'RESTRICTED_LAPSED',
    // Past grace period and quote expired
    BASIC_CANCELED: 'BASIC_CANCELED'
};
