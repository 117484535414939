// https://gist.github.com/paullewis/55efe5d6f05434a96c36
const rICShim = (callback) => {
    const start = Date.now();
    return setTimeout(() => {
        callback({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
        });
    }, 1);
};

window.requestIdleCallback = window.requestIdleCallback || rICShim;
