import { Middleware } from 'redux';

import { actions } from '../constants';
import { StoreShape } from '../types/store.types';

const getTimezone = () => {
    try {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return tz;
    } catch (e) {
        return (new Date().getTimezoneOffset() / -60).toFixed(2);
    }
};

export const analytics: Middleware<{}, StoreShape> =
    ({ getState }) =>
    (next) =>
    (action) => {
        next(action);

        if (action.type === actions.SAVE_ACCOUNT_ID) {
            const { account } = getState();
            window.analytics.identify((account.accountId || '').toString(), {
                email: account.email,
                firstName: account.firstName,
                lastName: account.lastName,
                phone: account.phone,
                tz: getTimezone(),
                prefersDarkMode:
                    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
                prefersHighContrast:
                    window.matchMedia && window.matchMedia('(prefers-contrast: more)').matches,
                viewportScale: window.visualViewport && window.visualViewport.scale
            });

            if (process.env.HUGO_LOGROCKET) {
                import('logrocket').then(({ default: LogRocket }) => {
                    LogRocket.identify((account.accountId || '').toString(), {
                        email: account.email || '',
                        name: `${account.firstName} ${account.lastName}`,
                        phone: account.phone || '',
                        tz: getTimezone()
                    });
                });
            }
        }

        if (action.type === actions.SET_ACTIVE_URL) {
            window.analytics.page(action.payload.activeUrl);
        }

        if (action.type === actions.LOG_OUT) {
            window.analytics.reset();
        }
    };
