import { h, FunctionComponent, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { connect } from 'react-redux';

import { checkAppVersion } from '../../actions/api';
import { setUpdateAvailable } from '../../actions/ui';

export interface ICheckUpdateProps {
    dispatch: (action: any) => Promise<any>;
}

const CHECK_THRESHOLD = 10 * 60 * 1000;

const CheckUpdate: FunctionComponent<ICheckUpdateProps> = (props) => {
    const timeoutId = useRef(-1);
    const [counter, setCounter] = useState(1);
    const [currentVersion, setCurrentVersion] = useState<string | null>(null);
    // IOS hybernates setTimeout when app goes into background;
    // so we check every 10 seconds if a minute has passed
    const lastCheck = useRef<number>(Date.now());
    useEffect(() => {
        const shouldCheck = !currentVersion || Date.now() - lastCheck.current >= CHECK_THRESHOLD;
        const promise = shouldCheck
            ? new Promise<string>((resolve, reject) => {
                  props.dispatch(checkAppVersion()).then(resolve).catch(reject);
              })
            : Promise.resolve(currentVersion);
        promise.then((res: string | null) => {
            if (currentVersion && currentVersion !== res) {
                props.dispatch(setUpdateAvailable());
            } else {
                if (shouldCheck) {
                    lastCheck.current = Date.now();
                    setCurrentVersion(res);
                }
                timeoutId.current = window.setTimeout(
                    () => {
                        setCounter(counter + 1);
                    },
                    Number(10 * 1000)
                );
            }
        });
    }, [counter]);

    return <></>;
};

export default connect()(CheckUpdate);
