import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import classNames from 'classnames';

import { Tile } from '../components/Tile';
import Svg, { ISvg } from '../icons/Svg';

import { copyToClipboard } from '@/helpers/misc';

interface ICopyIcon extends ISvg {
    copied: boolean;
}

const CopyIcon: FunctionComponent<ICopyIcon> = ({ copied, ...props }) => (
    <Svg viewBox="0 0 32 32" fill="none" {...props}>
        <path
            stroke="currentColor"
            stroke-width="2"
            d="M11 9V7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-3"
        />
        <rect
            width="15"
            height="17"
            stroke="currentColor"
            stroke-width="2"
            rx="2"
            transform="matrix(-1 0 0 1 20 10)"
        />
        {copied && (
            <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m8.5 17.52 2.948 3.058L16 16"
            />
        )}
    </Svg>
);

const CopyClipboardTile: FunctionComponent<{ text: string }> = ({ text }) => {
    const [copied, setCopied] = useState(false);
    return (
        <Tile
            className={classNames('c-tile--copy', { 'is-copied': copied })}
            onClick={() => copyToClipboard(text, () => setCopied(true))}
        >
            <div className="c-media c-media--center">
                <div
                    className={classNames('c-media__content u-mr-2 u-font-bold u-txt-pink-100', {
                        'dark:u-txt-white': !copied,
                        'dark:u-txt-pink-30': copied
                    })}
                >
                    {text}
                </div>
                <CopyIcon
                    size="32"
                    copied={copied}
                    className={classNames({ 'dark:u-txt-pink-30': copied })}
                />
            </div>
        </Tile>
    );
};

export default CopyClipboardTile;
