import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface IBannerProps {
    className?: string;
    light?: boolean;
    small?: boolean;
}

const Banner: FunctionComponent<IBannerProps> = ({
    className,
    children,
    light,
    small,
    ...rest
}) => (
    <div
        className={classnames('c-banner', className, {
            'c-banner--light': light,
            'c-banner--small': small
        })}
        {...rest}
    >
        <div className="c-banner__inner">{children}</div>
    </div>
);

export default Banner;
