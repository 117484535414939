// Lookup for app alert system.

export enum Alerts {
    // Banners
    ACCOUNT_BALANCE_LOW = 'ACCOUNT_BALANCE_LOW',
    ACCOUNT_EMPTY = 'ACCOUNT_EMPTY',
    ACCOUNT_LAPSED = 'ACCOUNT_LAPSED',
    ACCOUNT_OVERDRAWN = 'ACCOUNT_OVERDRAWN',
    ANNOUNCEMENT = 'ANNOUNCEMENT',
    COVERAGE_EXTENSION = 'COVERAGE_EXTENSION',
    POLICY_CANCELED = 'POLICY_CANCELED',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    VERIFY_PHONE = 'VERIFY_PHONE',
    NEW_APP_VERSION = 'NEW_APP_VERSION',

    // Modals
    API_NOTIFICATIONS = 'API_NOTIFICATIONS',
    CARD_DECLINED = 'CARD_DECLINED',
    CONFIRM_FALLBACK_OFF = 'CONFIRM_FALLBACK_OFF',
    COVERAGE_EXTENSION_TOGGLE = 'COVERAGE_EXTENSION_TOGGLE',
    COVERAGE_TOGGLE = 'COVERAGE_TOGGLE',
    COVERAGE_TOGGLE_ENDORSEMENT_WARNING = 'COVERAGE_TOGGLE_ENDORSEMENT_WARNING',
    LOCATION_PERMISSION_DENIED = 'LOCATION_PERMISSION_DENIED',
    NOTIFICATION = 'NOTIFICATION',
    OVERDRAWN_BALANCE_CHANGED = 'OVERDRAWN_BALANCE_CHANGED',
    QUOTE_ALREADY_BOUND = 'QUOTE_ALREADY_BOUND',
    QUOTE_APPLICATION_EXPIRED = 'QUOTE_APPLICATION_EXPIRED',
    QUOTE_APPLICATION_UNAUTHORIZED = 'QUOTE_APPLICATION_UNAUTHORIZED',
    QUOTE_WELCOME_BACK = 'QUOTE_WELCOME_BACK',
    RENEWAL_TIME_WARNING = 'RENEWAL_TIME_WARNING',
    RESPOND_TO_CHARGEBACK = 'RESPOND_TO_CHARGEBACK',
    RETURN_TO_QUOTE = 'RETURN_TO_QUOTE',
    REVERIFY_PHONE = 'REVERIFY_PHONE',
    REWARD_EARNED = 'REWARD_EARNED',
    UNSAVED_CHANGES = 'UNSAVED_CHANGES',
    CHANGE_BROWSER = 'CHANGE_BROWSER'
}
