import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';

export interface IProposalState {
    autoReloadTriggerDate: Date | null;
    balanceRequired: number;
    currentDayRate: number;
    daysRequired: number;
    locationRequired?: boolean;
    newDayRate: number;
    postPurchaseBalance: number;
    proposalExpiry: number | null;
    proposalId: string | null;
    proposedCoverageEndTime?: Date;
    proposedCoverageStartTime?: Date;
    rateMightChange: boolean;
    requiresMoreInfo?: boolean;
    scheduledOffDate?: Date;
    premium: number;
    unpaidFeesTotal: number;
}

export const initialState: IProposalState = {
    autoReloadTriggerDate: null,
    balanceRequired: 0,
    currentDayRate: 0,
    daysRequired: 0,
    locationRequired: false,
    newDayRate: 0,
    postPurchaseBalance: 0,
    proposalExpiry: null,
    proposalId: null,
    proposedCoverageEndTime: undefined,
    proposedCoverageStartTime: undefined,
    rateMightChange: false,
    requiresMoreInfo: false,
    scheduledOffDate: undefined,
    premium: 0,
    unpaidFeesTotal: 0
};

export const actionMap = {
    [actions.COVERAGE_PROPOSAL_UPDATE]: updateState,

    [actions.COVERAGE_PROPOSAL_RESET]: (): IProposalState => ({
        ...initialState
    })
};

export default createReducer(initialState, actionMap);
