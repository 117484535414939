import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';

export interface IRewardsState {
    applyRewardsAtCheckout: boolean;
    rewardsBalance: number;
}

export interface IUpdateRewardsActions {
    type: typeof actions.UPDATE_REWARDS;
    payload: Array<{ value: number }>;
}

export const initialState: IRewardsState = {
    applyRewardsAtCheckout: false,
    rewardsBalance: 0
};

export const actionMap = {
    [actions.SAVE_REWARD_BALANCE_DATA]: updateState,

    [actions.UPDATE_REWARDS]: (
        state: IRewardsState,
        action: IUpdateRewardsActions
    ): IRewardsState => ({
        ...state,
        rewardsBalance: action.payload.reduce(
            (value, current) => value + current.value,
            state.rewardsBalance
        )
    })
};

export default createReducer(initialState, actionMap);
