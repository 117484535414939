import { actions } from '../constants';
import {
    QuoteApplication,
    QuoteFlowController,
    QuoteFlowStateMeta,
    QuoteFlowStep,
    ValidationErrorItem,
    ITempPolicyPayload,
    IQuotePayload
} from '../ui/steps/flows/flows.types';
import {
    ActionBack,
    ActionBind,
    ActionInitialize,
    ActionNavigate,
    ActionReset,
    ActionServiceError,
    ActionSetMeta,
    ActionSetQuoteController,
    ActionUpdate,
    ActionUpdateError,
    ActionSaveTempPolicy,
    ActionUseTempQuote
} from '../types/signup.types';
import { ILeadResults } from '../ui/types';
import { ISaveQuoteInsurifyAction } from '../types/action.types';

export const setQuoteController = (payload: QuoteFlowController): ActionSetQuoteController => ({
    type: actions.SIGNUP_SET_QUOTE_CONTROLLER,
    payload
});

export const initializeSignupFlow = (payload: QuoteApplication): ActionInitialize => ({
    type: actions.SIGNUP_INITIALIZE,
    payload
});

export const goBack = (payload = 1): ActionBack => ({ type: actions.SIGNUP_BACK, payload });

export const navigateTo = (payload: QuoteFlowStep | string): ActionNavigate => ({
    type: actions.SIGNUP_NAVIGATE,
    payload
});

export const updateQuoteApplication = (
    payload: QuoteApplication,
    inline: boolean,
    omitStepFromNavStack: boolean
): ActionUpdate => ({
    type: actions.SIGNUP_UPDATE,
    payload,
    inline,
    omitStepFromNavStack
});

export const updateError = (payload: ValidationErrorItem[]): ActionUpdateError => ({
    type: actions.SIGNUP_UPDATE_ERROR,
    payload
});

export const serviceError = (): ActionServiceError => ({ type: actions.SIGNUP_SERVICE_ERROR });

export const resetSignup = (): ActionReset => ({ type: actions.SIGNUP_RESET });

export const setMetaData = (payload: Partial<QuoteFlowStateMeta>): ActionSetMeta => ({
    type: actions.SIGNUP_SET_META,
    payload
});

export const signupBind = (): ActionBind => ({ type: actions.SIGNUP_BIND });

export const saveTempPolicy = (payload: ITempPolicyPayload): ActionSaveTempPolicy => ({
    type: actions.SIGNUP_TEMP_POLICY,
    payload
});

export const useTempQuote = (payload: IQuotePayload): ActionUseTempQuote => ({
    type: actions.SIGNUP_TEMP_QUOTE,
    payload
});

export const saveQuoteInsurify = (payload: ILeadResults): ISaveQuoteInsurifyAction => ({
    type: actions.SIGNUP_INSURIFY,
    payload
});
