import { h } from 'preact';
import classnames from 'classnames';

const LogoShort = ({ className, ...rest }) => (
    <svg
        className={classnames('c-logo c-logo--short', className)}
        fill="none"
        viewBox="0 0 188 62"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            class="c-logo__text"
            fill="#434254"
            d="M19.525 15.353v13.659H8.237V15.353H0V51.08h8.237V36.9h11.288v14.18h8.237V15.353h-8.237Z"
        />
        <path
            class="c-logo__text"
            fill="#434254"
            d="M46.697 25.556v13.952c0 3.618-1.97 5.248-4.792 5.248-2.46 0-4.462-1.467-4.462-4.661V25.556H29.73v15.68c0 6.878 4.43 10.561 9.615 10.561 3.38 0 5.972-1.239 7.35-3.13v2.413h7.712V25.556h-7.712Z"
        />
        <path
            class="c-logo__text"
            fill="#434254"
            d="M75.344 25.556v2.608c-1.706-2.086-4.167-3.325-7.514-3.325-7.285 0-12.634 5.933-12.634 12.974 0 7.04 5.349 12.973 12.634 12.973 3.347 0 5.808-1.238 7.514-3.324v2.347c0 3.618-2.198 5.476-5.808 5.476-3.38 0-4.89-1.434-5.874-3.227L57.1 55.839C59.462 60.012 63.925 62 69.274 62c6.825 0 13.552-3.618 13.552-12.191V25.556h-7.482ZM69.11 43.713c-3.643 0-6.203-2.412-6.203-5.933 0-3.52 2.56-5.933 6.203-5.933 3.642 0 6.234 2.445 6.234 5.966 0 3.52-2.56 5.9-6.234 5.9Z"
        />
        <path
            class="c-logo__text"
            fill="#434254"
            d="M97.265 24.84c-7.547 0-13.618 5.867-13.618 13.462s6.07 13.462 13.618 13.462 13.619-5.867 13.619-13.462-6.071-13.463-13.619-13.463Zm0 19.492c-3.347 0-5.907-2.412-5.907-6.03 0-3.618 2.56-6.03 5.907-6.03s5.907 2.412 5.907 6.03c0 3.65-2.56 6.03-5.907 6.03Z"
        />
        <path
            fill="#434254"
            d="M187.114 18.352h-22.905l1.739 6.78h.131a3.62 3.62 0 0 1 3.249-3.161l17.884-1.826c.46-.065.788-.424.788-.88 0-.522-.394-.913-.886-.913Z"
        />
        <path
            fill="#B6DD4C"
            d="M159.024 15.092a8.463 8.463 0 0 0-2.035-.228c-5.381 0-11.715 2.869-15.751 5.966 0 0-8.532 14.734-10.403 15.94a29.125 29.125 0 0 0-.82 6.813l-4.693 4.661-2.559 2.542h14.504c2.986 0 5.841-.456 8.532-1.27-.82-1.826 6.006-18.45 6.202-18.549-.164-.586 2.363-7.464 3.512-8.116h3.511v-4.499h1.214l-1.214-3.26Z"
        />
        <path
            fill="#A0CA28"
            d="M133.329 30.185a28.833 28.833 0 0 0-1.772 4.107c-.262.815-.525 1.63-.722 2.478a25.375 25.375 0 0 1 13.685-3.977c-2.527-.033-10.731-2.38-11.191-2.608Z"
        />
        <path
            fill="#D1F374"
            d="m147.079 26.6-5.841-5.803-8.204-8.15c2.625 2.608 4.233 19.07 4.233 19.07a25.322 25.322 0 0 0 7.252 1.043 3.577 3.577 0 0 0 2.56-1.043 3.588 3.588 0 0 0 1.05-2.542c0-1.011-.394-1.924-1.05-2.576Z"
        />
        <path
            fill="#6E66DB"
            d="M137.267 22.818c0-3.977-1.608-7.563-4.233-10.17l-.033-.033L120.269 0a24.914 24.914 0 0 0-1.149 7.53c0 .456 0 .88.033 1.304.492 9.388 6.137 17.407 14.176 21.319.493.228.985.456 1.477.651.788.326 1.608.62 2.461.848v-8.834Z"
        />
        <path
            fill="#EFEFEF"
            d="M152.001 30.967c-.196.098-.36.196-.557.326-.427.261-.854.555-1.247.848-.427.326-.854.684-1.215 1.043a14.298 14.298 0 0 0-4.462 10.399c0 2.118.459 4.107 1.279 5.932a29.2 29.2 0 0 0 15.063-10.79 30.512 30.512 0 0 0 2.198-3.553c-1.181.75-11.026-4.009-11.059-4.205Z"
        />
        <path
            fill="#ED56A1"
            d="M166.112 23.34c-.066-.685-.164-1.37-.361-2.021a9.198 9.198 0 0 0-3.577-4.857 9.293 9.293 0 0 0-3.183-1.402v3.26c2.002 0 1.674 6.747 0 6.747-1.673 0-3.117-.684-3.511-2.249a7.072 7.072 0 0 0-2.757 2.706 7.093 7.093 0 0 0-.984 3.585c0 .62.098 1.206.23 1.793.065.228.131.424.196.62.033.065.066.163.099.228.459 1.173 1.247 2.184 2.231 2.966a7.05 7.05 0 0 0 2.658 1.337 7.01 7.01 0 0 0 1.871.26 7.43 7.43 0 0 0 4.036-1.206 26.639 26.639 0 0 0 1.936-4.694 26.88 26.88 0 0 0 .854-3.65c.098-.587.196-1.174.262-1.794v-.13c.033-.293.033-.587.033-.88 0-.196 0-.424-.033-.62Z"
        />
        <path
            fill="#fff"
            d="M162.634 21.938a3.622 3.622 0 0 1-3.643 3.618 3.595 3.595 0 0 1-3.61-3.618 3.587 3.587 0 0 1 3.61-3.586c2.035 0 3.643 1.598 3.643 3.586Z"
        />
        <path
            fill="#434254"
            d="M161.715 21.938c0 1.5-1.214 2.706-2.724 2.706a2.709 2.709 0 0 1-2.724-2.706c0-1.5 1.215-2.706 2.724-2.706 1.51 0 2.724 1.24 2.724 2.706Z"
        />
    </svg>
);

export default LogoShort;
