import { StateCode } from '@popularlab/enums';

interface VersionGeoArray {
    [version: string]: StateCode[];
}

export const ACCEPTANCE_GEOS: VersionGeoArray = {
    '1': [
        StateCode.AL,
        StateCode.FL,
        StateCode.GA,
        StateCode.PA,
        StateCode.SC,
        StateCode.TN,
        StateCode.TX,
        StateCode.VA,
        StateCode.AZ,
        StateCode.MS
    ],
    '2': [StateCode.IN, StateCode.OH, StateCode.IL, StateCode.GA]
};

export const SAFE_AUTO_GEOS: VersionGeoArray = {
    '1': [StateCode.CA, StateCode.IL, StateCode.IN, StateCode.OH]
};

// The geos below specificy business use and frequently out of state on the driver level
const DRIVER_BUSINESS_USE_OOS_GEOS: VersionGeoArray = {
    '1': [StateCode.IN, StateCode.OH]
};
export const isDriverBusinessOutOfStateGeos = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    if (!geo || !controllerVersion) {
        return false;
    }

    const versions = DRIVER_BUSINESS_USE_OOS_GEOS[controllerVersion] || [];

    return versions.includes(geo);
};

const DRIVER_EMPLOYMENT_STATUS_GEOS: VersionGeoArray = {};
export const isDriverEmploymentStatusGeos = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    if (!geo || !controllerVersion) {
        return false;
    }

    const versions = DRIVER_EMPLOYMENT_STATUS_GEOS[controllerVersion] || [];

    return versions.includes(geo);
};

export const isAcceptance = (geo?: StateCode, controllerVersion?: string | null): boolean => {
    if (!geo || !controllerVersion) {
        return false;
    }

    const versioned = ACCEPTANCE_GEOS[controllerVersion] || [];
    return versioned.includes(geo);
};

/** Determines whether or not reinstate is disabled for a particular geo.
 * (In the future we may need to factor in controller version, policy type, etc) */
export const isReinstateDisabled = (geo?: StateCode): boolean => {
    return geo === StateCode.PA;
};

/** Acceptance geos that use the external rater api */
export const isAcceptanceExternal = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    // Note RL: excluding version GA V2 for now
    return (
        isAcceptance(geo, controllerVersion) && !(geo === StateCode.GA && controllerVersion === '2')
    );
};

export const isAcceptanceExclusiveInternal = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    // Note RL: excluding version GA V2 for now
    return (
        isAcceptance(geo, controllerVersion) && geo === StateCode.GA && controllerVersion === '2'
    );
};

export const isSafeAuto = (geo?: StateCode, controllerVersion?: string | null): boolean => {
    if (!geo || !controllerVersion) {
        return false;
    }

    const versioned = SAFE_AUTO_GEOS[controllerVersion] || [];
    return versioned.includes(geo);
};

export const areSelfServeEndorsementsEnabled = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    const allowedAcceptanceGeos = (
        process.env.HUGO_ALLOW_ACCEPTANCE_SELF_SERVE_ENDORSEMENTS || ''
    ).split(',');

    const isAcceptanceGeo = isAcceptance(geo, controllerVersion);

    if (isAcceptanceGeo && allowedAcceptanceGeos.includes(geo || '')) {
        return true;
    }

    return !isAcceptanceGeo;
};

export const isAcceptanceSelfServeDriverEndorsementsEnabled = (
    geo?: StateCode,
    controllerVersion?: string | null
): boolean => {
    if (!isAcceptance(geo, controllerVersion)) {
        return true;
    }

    const allowedGeos = (process.env.HUGO_ACCEPTANCE_DRIVER_ENDORSEMENT_GEOS || '').split(',');

    if (allowedGeos.includes(geo || '')) {
        return true;
    }

    return false;
};

export const isStateFilingEnabled = (geo: StateCode): boolean => {
    if (process.env.HUGO_NEW_STATE_FILING) {
        return true;
    }
    return ![StateCode.TX, StateCode.OH, StateCode.MS, StateCode.PA].includes(geo);
};
