import { h } from 'preact';
import { connect } from 'react-redux';
import { FC } from 'preact/compat';

import { getDueDate } from '../../selectors';
import withCoverage from '../hoc/withCoverage';
import { Banner, BannerTitle, BannerContent } from '../components/Banner';

import { StoreShape } from '@/types/store.types';

export interface BannerAccountEmptyProps {
    isActive?: boolean;
    dueDate?: string;
}
export const BannerAccountEmptyPure: FC<BannerAccountEmptyProps> = ({ isActive, dueDate }) => (
    <Banner>
        <BannerTitle>Your Hugo account is empty!</BannerTitle>
        <BannerContent>
            {isActive ? (
                <p>
                    Reload your account by your due date ({dueDate}), or your insurance will be
                    cancelled. Or turn your insurance OFF to avoid additional charges.
                </p>
            ) : (
                <p>Refill your account and turn your insurance ON before you drive.</p>
            )}
        </BannerContent>
    </Banner>
);

const bannerWithCoverage = withCoverage(BannerAccountEmptyPure);

const mapStateToProps = (state: StoreShape) => ({
    dueDate: getDueDate(state)
});

export default connect(mapStateToProps)(bannerWithCoverage);
