import { h, FunctionComponent, Fragment } from 'preact';
import classnames from 'classnames';

import { IconArrowRight } from '../../icons';
import { IButtonProps } from './Button.types';

const Button: FunctionComponent<IButtonProps> = ({
    block,
    children,
    className = '',
    component,
    href,
    icon,
    inverted,
    large,
    link,
    loading,
    minimal,
    outline,
    primary,
    progressArrow,
    secondary,
    subtle,
    tight,
    type = 'button',
    ...rest
}) => {
    let Element = component;
    if (!Element) {
        Element = href ? 'a' : 'button';
    }
    const Wrapper = progressArrow && !icon ? 'span' : Fragment;
    return (
        <Element
            className={classnames(className, {
                'c-link': link,
                'c-link--inverted': inverted && link,
                'c-btn': !link && !className.includes('c-link'),
                'c-btn--block': block || large,
                'c-btn--icon': icon || progressArrow,
                'c-btn--inverted': inverted && !link && !className.includes('c-link'),
                'c-btn--large': large,
                'c-btn--outline': outline,
                'c-btn--primary': primary,
                'c-btn--secondary': secondary,
                'c-btn--outline u-shadow-none': minimal,
                'c-btn--subtle': subtle,
                'c-btn--tight': tight,
                'c-spinner': loading,
                'has-arrow': progressArrow
            })}
            type={typeof href !== 'undefined' ? undefined : type}
            href={href}
            {...rest}
        >
            <Wrapper>{children}</Wrapper>
            {progressArrow && <IconArrowRight className="u-font-3" />}
        </Element>
    );
};

export default Button;
