// Determine main layout based on current state.

import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { layouts } from '../../constants';
import { getIsLogged, getActiveLayout } from '../../selectors';
import PortalLayout from './PortalLayout';
import LoginLayout from './LoginLayout';
import MinimalLayout from './MinimalLayout';
import QuoteLayout from './QuoteLayout';
import { StoreShape } from '../../types/store.types';

const Layout: FunctionComponent<{ layout: layouts; isLogged?: boolean }> = ({
    children,
    layout,
    isLogged
}) => {
    switch (layout) {
        case layouts.PORTAL:
            return <PortalLayout key={layouts.PORTAL}>{children}</PortalLayout>;

        case layouts.LOGIN:
            return <LoginLayout key={layouts.LOGIN}>{children}</LoginLayout>;

        case layouts.QUOTE:
            return <QuoteLayout key={layouts.QUOTE}>{children}</QuoteLayout>;

        // ADAPTIVE is non-existent layout, it just chooses real layout based off
        // user status. Check must be inside switch/case else preact would mount
        // new (<AdaptiveLayout>) component and re-trigger animations.
        case layouts.ADAPTIVE:
            return isLogged ? (
                <PortalLayout key={layouts.PORTAL}>{children}</PortalLayout>
            ) : (
                <MinimalLayout key={layouts.MINIMAL}>{children}</MinimalLayout>
            );

        // Set minimal as default to apply it for non-matching routes (404 error)
        case layouts.MINIMAL:
        default:
            return <MinimalLayout key={layouts.MINIMAL}>{children}</MinimalLayout>;
    }
};

const mapStateToProps = (state: StoreShape) => ({
    isLogged: getIsLogged(state),
    layout: getActiveLayout(state)
});

export default connect(mapStateToProps)(Layout);
