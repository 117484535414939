import { actions } from '../constants';
import { Quote, QuoteFlowController } from '../ui/steps/flows/flows.types';
import { EndorsementType } from '../constants/endorsements';
import {
    EndorsementFlowStep,
    IEndorsementMeta,
    EndorsementUpdate
} from '../ui/steps/flows/endorsements.types';
import { IPolicy } from '../ui/types';

export interface EndorsementControllerOptions {
    endorsementType: EndorsementType;
    data: {
        policy: IPolicy;
        options: { [key: string]: any };
        quote: Quote;
    };
    quoteController: QuoteFlowController;
}

export interface ActionUpdateEndorsement {
    type: typeof actions.ENDORSEMENT_UPDATE;
    payload: EndorsementUpdate;
}

export interface ActionResetEndorsement {
    type: typeof actions.ENDORSEMENT_RESET;
}

export interface ActionSetEndorsementController {
    type: typeof actions.ENDORSEMENT_SET_CONTROLLER;
    payload: EndorsementControllerOptions;
}
export interface ActionEndorsementNavigate {
    type: typeof actions.ENDORSEMENT_NAVIGATE;
    payload: EndorsementFlowStep | string;
}

export interface ActionEndorsementBack {
    type: typeof actions.ENDORSEMENT_BACK;
}
export interface ActionEndorsementAdd {
    type: typeof actions.ENDORSEMENT_ADD;
}

export interface ActionUpdateEndorsementMeta {
    type: typeof actions.ENDORSEMENT_UPDATE_META;
    payload: Partial<IEndorsementMeta>;
}

export const navigateTo = (payload: EndorsementFlowStep | string): ActionEndorsementNavigate => ({
    type: actions.ENDORSEMENT_NAVIGATE,
    payload
});

export const goBack = (): ActionEndorsementBack => ({ type: actions.ENDORSEMENT_BACK });

export const updateEndorsement = (payload: EndorsementUpdate): ActionUpdateEndorsement => ({
    type: actions.ENDORSEMENT_UPDATE,
    payload
});

export const updateEndorsementMeta = (
    payload: ActionUpdateEndorsementMeta['payload']
): ActionUpdateEndorsementMeta => ({
    type: actions.ENDORSEMENT_UPDATE_META,
    payload
});

export const resetEndorsement = (): ActionResetEndorsement => ({
    type: actions.ENDORSEMENT_RESET
});

export const setEndorsementController = (
    payload: EndorsementControllerOptions
): ActionSetEndorsementController => ({
    type: actions.ENDORSEMENT_SET_CONTROLLER,
    payload
});

export const addEndorsement = (): ActionEndorsementAdd => ({
    type: actions.ENDORSEMENT_ADD
});
