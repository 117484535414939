// middleware.js
// Export all Redux middleware in single array.

import thunk from 'redux-thunk';

import { displayBanner } from './ui';
import { errorLogger, actionArray, clearRedirect } from './misc';
import { analytics } from './analytics';
import { normalizeUserData } from './normalize';
import {
    logOutOnError,
    updateInsuranceStatus,
    hydrateWhileIdle,
    redirectOnMaintenance,
    showWarningModal
} from './api';
import { saveUserSession, removeUserSession } from './session';
import { clearCartOnSignup } from './signup';

// Middleware is executed in order listed.
const allMiddleware = [
    errorLogger,

    // Make sure thunk middleware comes first else bad things happen.
    thunk,

    // Run this middleware
    // to catch valid action type.
    actionArray,

    redirectOnMaintenance,

    clearRedirect,

    analytics,
    displayBanner,
    logOutOnError,
    normalizeUserData,
    showWarningModal,
    removeUserSession,
    saveUserSession,
    updateInsuranceStatus,
    hydrateWhileIdle,
    clearCartOnSignup
];

export default allMiddleware;
