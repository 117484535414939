const loadAnalytics = () => {
    // Comment out to test analytics locally.
    if (window.location.host.includes('localhost')) {
        return;
    }

    if (process.env.HUGO_LOGROCKET) {
        import('logrocket').then(({ default: LogRocket }) => {
            // Specifing rootHostname enables tracking session accross subdomains (ie. from www to app).
            LogRocket.init(process.env.HUGO_LOGROCKET, {
                rootHostname: 'withhugo.com'
            });
        });
    }

    if (process.env.HUGO_SEGMENT) {
        if (window.analytics.load) {
            window.analytics.load(process.env.HUGO_SEGMENT, {
                integrations: {
                    'Visual Tagger': false
                }
            });
        }
    }
};

export const runAnalytics = () => {
    requestIdleCallback(loadAnalytics, { timeout: 1000 });
};
