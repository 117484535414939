// App layout used for login and registration pages.

import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { alerts } from '../../constants';
import asyncComponent from '../hoc/AsyncComponent';
import { LogoMin } from '../logo';
import MinimalLayout from './MinimalLayout';
import { StoreShape } from '../../types/store.types';

const BannerAnnouncement = asyncComponent(() => import('../alerts/BannerAnnouncement'));

export const LoginLayout: FunctionComponent<{ announcementIsVisible?: boolean }> = ({
    children,
    announcementIsVisible
}) => (
    <div className="u-full-height u-flex u-flex-column">
        {announcementIsVisible && <BannerAnnouncement />}
        <MinimalLayout hideHeader={true}>
            <LogoMin className="u-mb-3 u-mx-auto u-block" width="50" />
            {children}
        </MinimalLayout>
    </div>
);

const mapStateToProps = (state: StoreShape) => ({
    announcementIsVisible: Boolean(state.ui.visibleBanner === alerts.ANNOUNCEMENT)
});

export default connect(mapStateToProps)(LoginLayout);
