// error-codes.js
// Error codes returned from API or thrown inside client.
// NOTE: Not full list, just ones used for client mapping.

export default {
    // Generic codes, not from API but assigned on response.

    NO_ERROR: 'NO_ERROR',

    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    MISSING_ENDPOINT: 'MISSING_ENDPOINT',
    NETWORK_ERROR: 'NETWORK_ERROR',
    UNEXPECTED_RESPONSE_TYPE: 'UNEXPECTED_RESPONSE_TYPE',
    UNKNOWN_API_ERROR: 'UNKNOWN_API_ERROR',
    RATE_LIMIT: 'HttpRateLimitError',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',

    PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
    TECHNICAL_ERROR: 'TECHNICAL_ERROR',

    // System Errors
    MAINTENANCE: 'DownForMaintenance',

    // Login/registration errors
    REFRESH_TOKEN_MISSING: 'RefreshTokenMissingError',
    REFRESH_TOKEN_NOT_FOUND: 'RefreshTokenNotFoundError',
    UNAUTHORIZED: 'HttpUnauthorizedError',

    ACCOUNT_ALREADY_EXISTS: 'AccountAlreadyExistsError',
    ACCOUNT_NOT_FOUND: 'AccountNotFoundError',
    ACCOUNT_NOT_FOUND_GOOGLE: 'ACCOUNT_NOT_FOUND_GOOGLE',
    ACCOUNT_NOT_FOUND_SMS: 'ACCOUNT_NOT_FOUND_SMS',
    NOT_SIGNED: 'NOT_SIGNED',
    PHONE_NUMBER_IS_LANDLINE: 'SendSmsVerificationLandlinePhoneNumberError',
    PHONE_NUMBER_IS_ALREADY_IN_USE: 'PhoneNumberAlreadyInUseError',
    PHONE_NUMBER_INVALID: 'SendSmsVerificationInvalidPhoneNumberError',
    SMS_TOKEN_ALREADY_USED: 'SMS_TOKEN_ALREADY_USED',
    SMS_TOKEN_ERROR: 'ValidateSmsVerificationCodeError',
    SMS_TOKEN_EXPIRED: 'SMS_TOKEN_EXPIRED',
    SMS_TOKEN_MISMATCH: 'ValidateSmsVerificationCodeIncorrectError',
    EMAIL_VERIFICATION_EXPIRED: 'EmailVerificationExpiredError',

    // Dashboard errors
    COVERAGE_ALREADY_SCHEDULE_OFF: 'CoverageScheduleOffFailedAlreadyPendingOffError',
    COVERAGE_ALREADY_ON: 'CoverageTurnOnFailedAlreadyOnError',

    // Stripe errors
    CARD_DECLINED: 'StripeDeclinedError',
    CARD_DECLINED_CURRENCY: 'StripeUnsupportedCurrencyError',
    CARD_DECLINED_EXPIRED: 'StripeCardExpiredError',
    CARD_DECLINED_INSUFFICIENT_FUNDS: 'StripeInsufficientFundsError',
    CARD_DECLINED_INVALID_CVC: 'StripeInvalidCVCError',
    CARD_DECLINED_INVALID_EXPIRATION: 'StripeInvalidExpirationError',
    CARD_DECLINED_INVALID_NUMBER: 'StripeInvalidNumberError',
    CARD_DECLINED_INVALID_PAYMENT_METHOD: 'StripeInvalidPaymentMethodError',
    CARD_DECLINED_INVALID_ZIP: 'StripeInvalidZipCodeError',
    CARD_DECLINED_INVALID_ACCOUNT: 'StripeInvalidAccountError',
    CARD_REFUND_MISSING_CARD: 'RefundChargeMissingCardError',

    // Checkout errors
    OVERDRAWN_BALANCE_CHANGED: 'PolicyAddFundsFailedOverdrawnBalanceOutdatedError',
    EXPECTED_BALANCE_MISMATCH: 'ExpectedBalanceMismatchError',

    // Quote flow errors
    ADDRESS_INVALID_ZIP_CODE_ERROR: 'AddressInvalidZipCodeError',
    ADDRESS_VALIDATION_ERROR: 'AddressValidationFailedError',
    ADDRESS_PARKING_NOT_ALLOWED_ERROR: 'AddressParkingValidationFailedError',
    ADDRESS_PARKING_COMMERCIAL_ERROR: 'AddressParkingCommercialValidationFailedError',
    ADDRESS_PARKING_CMRA_ERROR: 'AddressParkingCMRAValidationFailedError',
    ADDRESS_PARKING_POBOX_ERROR: 'AddressParkingPOBoxValidationFailedError',
    ADDRESS_HOME_NOT_ALLOWED_ERROR: 'AddressHomeValidationFailedError',
    ADDRESS_HOME_COMMERCIAL_ERROR: 'AddressHomeCommercialValidationFailedError',
    ADDRESS_HOME_CMRA_ERROR: 'AddressHomeCMRAValidationFailedError',
    ADDRESS_HOME_POBOX_ERROR: 'AddressHomePOBoxValidationFailedError',
    POLK_VEHICLE_REPORT_ERROR: 'PolkVehicleReportError',
    POLK_VEHICLE_REPORT_VIN_DECODE_ERROR: 'PolkVehicleReportVinDecodeError',
    POLK_VEHICLE_REPORT_MMY_COUNTRY_CODE_ERROR: 'PolkVehicleReportMmyDecodeSaleCountryError',
    POLK_VEHICLE_REPORT_VIN_COUNTRY_CODE_ERROR: 'PolkVehicleReportVinDecodeSaleCountryError',
    QUOTE_APPLICATION_DUPLICATE_COVERAGE: 'QuoteApplicationDuplicateCoverageError',
    QUOTE_APPLICATION_DUPLICATE_DRIVER_COVERAGE: 'QuoteApplicationDuplicateDriverCoverageError',
    QUOTE_APPLICATION_DUPLICATE_VEHICLE_COVERAGE: 'QuoteApplicationDuplicateVehicleCoverageError',
    QUOTE_APPLICATION_EXPIRED: 'QuoteApplicationExpiredError',
    QUOTE_APPLICATION_NOT_FOUND: 'QuoteApplicationNotFoundError',
    QUOTE_APPLICATION_MULTIPLE_POLICIES: 'QuoteApplicationMultiplePoliciesError',
    QUOTE_APPLICATION_TOKEN: 'QuoteApplicationTokenError',
    QUOTE_APPLICATION_UNAUTHORIZED: 'QuoteApplicationUnauthorizedError',
    QUOTE_APPLICATION_TOO_YOUNG_ERROR: 'QuoteApplicationDriverTooYoungError',
    QUOTE_APPLICATION_VALIDATION_ERROR: 'QuoteApplicationValidationError',
    QUOTE_APPLICATION_SERVICE_ERROR: 'QuoteApplicationServiceUnavailableError',
    QUOTE_APPLICATION_RTQ_ERROR: 'QuoteApplicationRtqError',
    QUOTE_APPLICATION_PREMIUM_HAS_CHANGED_ERROR: 'QuoteApplicationPremiumHasChangedError',
    QUOTE_APPLICATION_CLOSED_FOR_BUSINESS_ERROR: 'QuoteApplicationClosedForNewBusinessError',
    QUOTE_APPLICATION_MISMATCH_ERROR: 'QuoteApplicationIdMismatchError',
    QUOTE_ALREADY_BOUND: 'QuoteApplicationAccountAlreadyBoundError',
    QUOTE_UNSUPPORTED_GEO_ERROR: 'QuoteApplicationUnsupportedGeoError',
    QUOTE_UNSUPPORTED_ZIP_CODE_ERROR: 'QuoteApplicationUnsupportedZipCodeError',
    SAMBA_DRIVER_NOT_FOUND_ERROR: 'SambaDriverNotFoundError',
    SAMBA_DOB_MISMATCH_ERROR: 'SambaDOBMismatchError',
    SAMBA_NAME_MISMATCH_ERROR: 'SambaNameMismatchError',
    SAMBA_LICENSE_MISMATCH_ERROR: 'SambaLicenseMismatchError',
    SAMBA_INVALID_LICENSE_ERROR: 'SambaInvalidLicenseError',
    SAMBA_LICENSE_RETRIEVAL_ERROR: 'SambaLicenseRetrievalError',
    SAMBA_LICENSE_SUSPENDED_ERROR: 'SambaLicenseSuspendedError',
    SAMBA_SYSTEM_IS_DOWN_ERROR: 'SambaSystemIsDownError',

    // Incentive code errors
    INCENTIVE_CODE_ALREADY_APPLIED: 'IncentiveCodeAlreadyApplied',
    INCENTIVE_CODE_DOES_NOT_EXIST: 'IncentiveCodeDoesNotExist',
    INCENTIVE_CODE_INVALID: 'IncentiveCodeInvalid',
    ACCOUNT_CODE_INVALID: 'AccountCodeInvalidError',
    ACCOUNT_CODE_ALREADY_SUBMITTED: 'AccountCodeAlreadySubmittedError',

    // Reward balance transfer errors
    REWARD_BALANCE_TRANSFER_OWED: 'RewardBalanceTransferBalanceOwedError',
    REWARD_BALANCE_TRANSFER_INVALID: 'RewardBalanceTransferInvalidAmountError',
    REWARD_BALANCE_TRANSFER_POLICY: 'RewardBalanceTransferNoActivePolicyError',
    REWARD_BALANCE_TRANSFER_GIFT_CARD: 'AmazonGiftCardCreateRequestError',

    // Endorsements
    ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION: 'ActiveCoverageSessionNotFoundError',
    ENDORSEMENT_DUPLICATE_VEHICLE: 'PolicyEndorsementDuplicateVehicleError',
    ENDORSEMENT_SERVICE_UNAVAILABLE: 'PolicyEndorsementServiceUnavailableError',
    ENDORSEMENT_PROPOSAL_NOT_FOUND: 'EndorsementProposalNotFoundError',
    ENDORSEMENT_INVALID: 'EndorsementInvalidError',
    ENDORSEMENT_QUOTE_APPLICATION_EXPIRED: 'PolicyEndorsementMissingQuoteApplicationError',
    ENDORSEMENT_BLACKBOARD_SUBMISSION: 'PolicyEndorsementBlackboardSubmissionError',
    ENDORSEMENT_PROHIBITED_VEHICLE: 'PolicyEndorsementProhibitedVehicleError',
    ENDORSEMENT_PROPOSAL_STALE: 'PolicyEndorsementBindFailedStaleStateError',
    ENDORSEMENT_INVALID_EFFECTIVE_DATE: 'PolicyEndorsementBlackboardInvalidEffectiveDateError',

    // LPP
    INSUFFICIENT_BALANCE: 'CoverageProposalInsufficientBalanceError',
    PROPOSAL_NOT_FOUND: 'CoverageProposalNotFoundError',
    AR_INSUFFICIENT_BALANCE: 'AccountAutoReloadPreferencesInsufficientBalance',
    REQUIRES_MORE_INFO: 'PolicyRewriteRequiresMoreInfoError',
    RATE_CHANGE: 'PolicyAddFundsFailedChargeAmountMismatchError',

    // Coverage error (on multiple routes)
    REFRESH_REQUIRED: 'CoverageSessionRefreshRequiredError',
    REFRESH_FAILED: 'CoverageSessionRefreshFailedError',
    POLICY_NOT_ACTIVE: 'PolicyIsNotActiveError',

    // Acceptance errors
    ACCEPTANCE_ENDORSER_CYCLE_IS_ACTIVE_ERROR: 'AcceptanceEndorserCycleIsActiveError',
    ACCEPTANCE_ENDORSER_CYCLE_REQUIRED_ERROR: 'AcceptanceEndorserCycleRequiredError',
    ACCEPTANCE_ENDORSER_INELIGIBLE_VEHICLE_ERROR: 'AcceptanceEndorserIneligibleVehicleError',
    ACCEPTANCE_ENDORSER_POLICY_IS_NOT_ACTIVE_ERROR: 'AcceptanceEndorserPolicyIsNotActiveError',
    ACCEPTANCE_ENDORSER_INVALID_DATE_ERROR: 'AcceptanceEndorserInvalidDateError',
    ACCEPTANCE_ENDORSER_INVALID_PRICE_ERROR: 'AcceptanceEndorserInvalidPriceError',
    ACCEPTANCE_ENDORSER_POLICY_IS_IN_USE: 'AcceptanceEndorserPolicyIsInUse',

    // Tritech errors
    TRITECH_ADDRESS_ERROR: 'TritechAddressError',

    // Policy termination
    INVALID_RECAPTCHA_ERROR: 'InvalidRecaptchaError',

    SUPPORT_TICKET_ALREADY_EXISTS: 'SupportTicketLinkAlreadyExists',
    INVALID_ADDRESS_TYPE_ERROR: 'InvalidAddressTypeError',
    FEEDBACK_ALREADY_EXISTS: 'CustomerFeedbackAlreadyExistsError',

    // Transunion errors
    TU_DRIVER_RISK_INCORRECT_DL_FORMAT: 'TUDriverRiskIncorrectDLFormat'
};
