// helpers/redux.js
// Utility functions used in redux.

import { isObject } from './value-checkers';

// Helper function that takes in initial state and lookup table of
// functions for each action type to produce next state.
// https://redux.js.org/recipes/reducing-boilerplate#generating-reducers
// See also: https://redux-starter-kit.js.org/api/createreducer
export const createReducer = (initialState = {}, handlers = {}) => {
    return (state = initialState, action = {}) => {
        if (handlers[action.type]) {
            return handlers[action.type](state, action);
        }

        return state;
    };
};

// Validates is action FSA
// https://github.com/redux-utilities/flux-standard-action
// @param {Object} Redux action
// @returns {Boolean}
export const isFSA = (action) => {
    return (
        isObject(action) &&
        typeof action.type === 'string' &&
        Object.keys(action).every((key) => ['type', 'payload', 'error', 'meta'].includes(key))
    );
};

// Returns new state object with updated slice from action payload prop.
// @param {Object} State to update
// @param {Object} Redux action in FSA shape
// @returns {Object} New state
export const updateState = (state, action) => {
    if (isFSA(action) === false || isObject(action.payload) === false) {
        console.warn('Invalid action. Check that payload propery exists and is object.');
        return state;
    }

    return { ...state, ...action.payload };
};
