// Header variation for main app layout.

import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { routes } from '../../../routes';
import { openMenu } from '../../../actions/ui';
import { LogoMin } from '../../logo';
import { Button, ButtonHelp } from '../../components/Button';
import { Header, HeaderRight } from '../Header';
import { IconMenu } from '../../icons';
import { StoreShape } from '../../../types/store.types';

interface IPortalHeaderProps {
    dispatch: Function;
    isMenuVisible: boolean;
}

const PortalHeader: FunctionComponent<IPortalHeaderProps> = (props) => (
    <Header>
        <a href={routes.DASHBOARD.path}>
            <LogoMin width="45" className="" />
        </a>

        <HeaderRight>
            <ButtonHelp className="track:ticket-open" href={routes.HELPDESK.path} size={30} />

            <Button
                aria-controls="main-navigation"
                aria-expanded={props.isMenuVisible}
                // Menu icon is optimized for 24x24 display
                className="u-font-4"
                icon
                minimal
                onClick={() => props.dispatch(openMenu())}
                primary
            >
                <IconMenu title="Show main navigation" />
            </Button>
        </HeaderRight>
    </Header>
);

const mapStateToProps = (state: StoreShape) => ({
    isMenuVisible: state.ui.isMenuVisible
});

export default connect(mapStateToProps)(PortalHeader);
