// parsers.js
// Helper function for parsing data.

import { isObject } from './value-checkers';
import { objectMap } from './mappers';

// Converts primitives to boolean values.
// @param {Any} value to convert
// @return {Boolean} Returns the results of conversion.
export const parseBoolean = (value) => {
    if (value === 'false' || value === '0') {
        return false;
    }

    return Boolean(value);
};

// Parses object values to Boolean and returns new object.
// @param {Object} Object to be parsed.
// @return {Object} New object with boolean values.
export const parseBoolFromObject = (object) => {
    if (isObject(object) === false) {
        console.warn('Passed value is not an object.');
        return object;
    }

    return objectMap(object, parseBoolean);
};

// Parse URL query string into object.
// Similar useful HTML API: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
// @param {String} query string.
// @return {Object} data object.
export const parseQuery = (queryString) => {
    const query = {};

    if (!queryString || typeof queryString !== 'string') {
        return query;
    }

    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(/&|;/g);

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent([...pair.slice(1)].join('=') || '');
    }

    return query;
};

// Filters duplicate values from array by sorting it
// and removing following item if same as current.
// @param {Array} Array to filer
// @returns {Array} Filtered array
export const filterDuplicates = (array = []) => {
    return array.sort().filter((item, pos, ary) => !pos || item !== ary[pos - 1]);
};
