import { StateCode } from '@popularlab/enums';

export const GATED_DAYS = 3;
export const FAC_GEOS = [StateCode.FL, StateCode.GA, StateCode.VA];
export const HUGO_GEOS = [StateCode.IL, StateCode.SC, StateCode.IN, StateCode.AZ];
export const LOE_GEOS = [StateCode.TX, StateCode.MS, StateCode.AL, StateCode.OH, StateCode.PA];
export const DEC_GEOS = [StateCode.TN];

export enum FileFormState {
    UPLOAD = 'UPLOAD',
    REQUIRE_COVERAGE = 'REQUIRE_COVERAGE',
    DEFAULT = 'DEFAULT',
    GATED = 'GATED',
    REQUIRE_VERIFIED_EMAIL = 'VERIFIED_EMAIL',
    REQUEST_PENDING = 'REQUEST_PENDING',
    INELIGIBLE = 'INELIGIBLE',
    UNSERVICED = 'UNSERVICED'
}
