// value-checkers.js
// Helpers for checking JS values and types.

import { states } from '../constants';

const MINIMUM_REFERRAL_CODE_LENGTH = 6;
const MAXIMUM_REFERRAL_CODE_LENGTH = 12;
const MAXIMUM_STATE_NOTICE_LENGTH = 20;
const EXACT_TOKEN_LENGTH = 6;
const EXACT_PHONE_LENGTH = 10;
const EMAIL_REGEXP =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[A-z0-9-.]+(?:\.[a-zA-Z0-9-]+)$/;

// Check if value is either undefined or null
// @param {Any}
// @return {Boolean}
export const isNil = (value) => value === undefined || value === null;

// Check if value is undefined
// @param {Any}
// @return {Boolean}
export const isUndefined = (value) => value === undefined;

// Check if value is "true" object. Excludes arrays.
// @param {Any}
// @return {Boolean}
export const isObject = (value) => {
    return value === Object(value) && Array.isArray(value) === false;
};

/**
 * Check if value is a function
 * @param {*} value Value to check
 */
export const isFunction = (value) => typeof value === 'function';

// Check if value is primitive.
// @param {Any}
// @return {Boolean}
export const isPrimitive = (value) => {
    return value !== Object(value);
};

// Check if value is integer
// @param {Any}
// @return {Boolean}
export const isInteger = (value) => {
    return /^\d+$/.test(value);
};

// Check if value is integer and >= the min value given
// @param {Any}
// @param {Object} min value to check against
// @return {Boolean}
export const isMinInteger = (value, { min } = { min: 0 }) => {
    return isInteger(value) && parseInt(value) >= min;
};

// Check if value is integer and <= the max value given
// @param {Any}
// @param {Object} max value to check against
// @return {Boolean}
export const isMaxInteger = (value, { max } = { max: 0 }) => {
    return isInteger(value) && parseInt(value) <= max;
};

// Check if value is exact length.
// @param {Any}
// @param {Number} required length
// @return {Boolean}
export const isExactLength = (value = '', requiredLength = 0) => {
    return value.toString().trim().length === requiredLength;
};

// Does shallow comparison of two objects
// @param {Object} first object to compare against
// @param {Object} second object to compare with
// @return {Boolean} result of shallow comparison
export const areShallowEqual = (a = {}, b = {}) => {
    const aKeys = Object.keys(a);
    if (aKeys.length !== Object.keys(b).length) {
        return false;
    }

    if (aKeys.some((key) => a[key] !== b[key])) {
        return false;
    }

    return true;
};

/**
 * Does a deep comparison of two objects, recursing into objects to check equality
 * @param {any} a First object to compare
 * @param {any} b Second object to compare
 * @returns {Boolean} result of deep comparison
 */
export const areDeepEqual = (a, b = {}) => {
    if ((a instanceof Object && b instanceof Object) === false) {
        return a === b;
    }

    const aKeys = Object.keys(a);
    if (aKeys.length !== Object.keys(b).length) {
        return false;
    }

    return aKeys.every((key) => {
        const aValue = a[key];
        const bValue = b[key];

        return areDeepEqual(aValue, bValue);
    });
};

// Check that object is either empty or that
// all properties are falsey.
// @param {Object}
// @return {Boolean}
export const isObjectEmpty = (obj = {}) => {
    return Object.keys(obj).length === 0 || Object.keys(obj).every((key) => !obj[key]);
};

// Check if value contains only letters and numbers.
// @param {Any}
// @return {Boolean}
export const isAlphaNumeric = (value) => {
    return /^[a-z0-9]+$/i.test(value);
};

/**
 * Check if value contains only numbers
 * @param {String} value Value to test
 * @returns {Boolean}
 */
export const isNumeric = (value) => /^[0-9]+$/i.test(value);

// Check if value is phone number.
// @param {Any}
// @return {Boolean}
export const isPhoneNumber = (phoneNumber) => {
    // Phone number contains only numbers and is exactly 10 chars. long.
    return isInteger(phoneNumber) && isExactLength(phoneNumber, EXACT_PHONE_LENGTH);
};

// Check if value is SMS confirmation code.
// @param {Any}
// @return {Boolean}
export const isConfirmationCode = (code) => {
    return isNumeric(code) && isExactLength(code, EXACT_TOKEN_LENGTH);
};

// Check if value is valid email address.
// @param {Any}
// @return {Boolean}
export const isEmail = (email = '') => {
    return EMAIL_REGEXP.test(email);
};

// Valid promo code contains only uppercase letters and numbers
// and has between 3 and 100 chars.
export const isPromoCode = (value) => {
    return /^([A-Z0-9]{3,100})$/.test(value);
};

export const isLicense = (value) => {
    // https://github.com/popularlab/product/issues/1554#issuecomment-756979780
    return /^[a-z0-9-\s,]+$/i.test(value);
};

// Check if value can be parsed as number without returning NaN.
// Will return positive for booleans, null and undefined.
export const isNotANumber = (value) => Number.isNaN(parseFloat(value));

// Check if value is valid US ZIP code.
// @param {Any}
// @return {Boolean}
export const isZipCode = (zip) => {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
};

// Check for non-allowed charachters in name input.
// @param {String}
// @return {Boolean}
export const isValidName = (value) => {
    return !/(\d|=|\(|\)|\}|\{|\[|\])/.test(value);
};

// Is date valid
// @param {String}
// @return {Boolean}
export const isDate = (date) => {
    return /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(date);
};

// Is date older or equal than current date.
// @param {String} data in YYYY-MM-DD format
// @return {Boolean}
export const isPastDate = (date) => {
    if (isDate(date) === false) {
        return false;
    }

    const [year, month, day] = date.split('-');
    const parsedDate = new Date(year, month - 1, day);
    const now = new Date();

    if (parsedDate > now) {
        return false;
    }

    return true;
};

/**
 * @typedef Dates
 * @param {Date} min begining of the date range
 * @param {Date} max end of the date range
 */

/**
 * Does date fall within defined range
 * @param {String} data in YYYY-MM-DD format
 * @param {Dates} min and max Date values
 * @return {Boolean}
 */
export const isDateInRange = (date, { min, max } = { min: Date.now(), max: Date.now() }) => {
    if (isDate(date) === false) {
        return false;
    }

    const [year, month, day] = date.split('-');
    const parsedDate = new Date(year, month - 1, day);

    if (parsedDate > max || parsedDate < min) {
        return false;
    }

    return true;
};

// Does passed value starts with a number.
// @param {String}
// @return {Boolean}
export const startsWithNumber = (string = '') => {
    const testValue = string.toString().trim();
    return /^\d/.test(testValue);
};

// Is value valid US social security number.
// @param {Any}
// @return {Boolean}
export const isValidSSN = (value) => {
    const regex = /([\d*]{3})([\d*]{2})(\d{4})/;
    const number = value.toString().replace(/[^\d*]/g, '');
    const result = regex.exec(number);

    if (result && result[1] === '***' && result[2] === '**') {
        // Probably came from the server (prefilled)
        return true;
    }

    // Some special numbers are never allocated:
    // Numbers with all zeros in any digit group (000-##-####, ###-00-####, ###-##-0000).
    // Numbers with 666 or 900–999 in the first digit group.
    if (
        !result ||
        (!result[1] === '***' && !result[2] === '**') ||
        (process.env.HUGO_ENV === 'production' &&
            (result[1] === '666' || parseInt(result[1]) >= 900)) ||
        result[1] === '000' ||
        result[2] === '00' ||
        result[3] === '0000'
    ) {
        return false;
    }

    return true;
};

// Check is minimum pre-1981 VIN length.
// @param {String} pre-1981 VIN
// @return {Boolean}
export const minimumPre1981VinLength = (vin = '') => {
    const MIN_VIN_LENGTH = 5;
    return vin.toString().trim().length >= MIN_VIN_LENGTH;
};

// Check is VIN correct length.
// @param {String} VIN
// @return {Boolean}
export const vinLength = (vin = '') => {
    const EXACT_VIN_LENGTH = 17;
    return isExactLength(vin, EXACT_VIN_LENGTH);
};

// Check if value contains only valid VIN characters.
// @param {String} VIN
// @return {Boolean}
export const vinChars = (vin = '') => {
    const hasInvalidChars = /[OIQ]+/i.test(vin);
    return hasInvalidChars === false;
};

const VIN_DIGIT_CHECK_EXCEPTION_LIST = [
    {
        first12: 'ZACCJ---0FPB',
        sequenceFrom: 52184,
        sequenceTo: 76827
    },
    {
        first12: 'ZFBCF---0GP3',
        sequenceFrom: 17640,
        sequenceTo: 38891
    }
];

// Check if value contains valid VIN check digit.
// https://stackoverflow.com/questions/26407015/javascript-jquery-vin-validator
// @param {String} VIN
// @return {Boolean}
export const vinCheckDigit = (vin = '') => {
    const transliterate = (c) => '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;

    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;

    for (let i = 0; i < 17; ++i) {
        sum += transliterate(vin[i]) * map.indexOf(weights[i]);
    }

    const hasPassedCheckDigit = map[sum % 11] === vin[8];

    if (hasPassedCheckDigit) {
        return true;
    }

    // fallback based off Jeep advisory
    // https://www.jeeprenegadeforum.com/attachments/2015-jeep-renegade-check_digit_advisory_letter-pdf.2396353570/
    const last5 = parseInt(vin.substring(vin.length - 5));
    if (Number.isFinite(last5)) {
        const first12 = vin.substring(0, 12);
        const processedFirst12 = `${first12.substring(0, 5)}---${first12.substring(8, 12)}`;

        const matchException =
            VIN_DIGIT_CHECK_EXCEPTION_LIST.findIndex(
                (v) =>
                    processedFirst12 === v.first12 &&
                    last5 >= v.sequenceFrom &&
                    last5 <= v.sequenceTo
            ) >= 0;

        return matchException;
    }

    return false;
};

export const vinVehicleId = (vin = '') => {
    const vehicleId = vin.slice(-6);
    return vehicleId !== '000000';
};

// Combine multiple checks to validate VIN.
// @param {String} VIN
// @return {Boolean}
export const isVin = (vin) => {
    return (
        vinLength(vin) &&
        vinChars(vin) &&
        isAlphaNumeric(vin) &&
        vinCheckDigit(vin) &&
        !vinVehicleId(vin)
    );
};

export const isUSState = (string) => {
    return Object.keys(states).some((stateCode) => states[stateCode] === string);
};

// Check if timestap exceeded or equal current time.
// @param {Date}
// @return {Boolean}
export const isTimeExpired = (date) => {
    if (date && new Date(date)) {
        return new Date(date).getTime() <= Date.now();
    }

    return false;
};

// Checks if given date object is a valid date.
// @param {Date}
// @return {Boolean}
export const isValidDateObject = (date) => {
    if (date instanceof Date && !Number.isNaN(date.getDate())) {
        return true;
    }

    return false;
};

/**
 * Checks to see if the passed value is a whole dollar amount
 *
 * Must be passed as value in cents
 * @param {number | string} value Money value to check, in cents
 * @returns True if value is whole dollar amount, false otherwise
 */
export const isWholeDollarAmount = (value) => {
    const parsed = parseInt(value);
    if (Number.isNaN(parsed)) {
        console.warn('Value passed is not a valid integer');
        return false;
    }
    const cents = parsed % 100;
    return cents === 0;
};

export const isReferralCode = (value) => {
    return (
        isAlphaNumeric(value) &&
        value.trim().length >= MINIMUM_REFERRAL_CODE_LENGTH &&
        value.trim().length <= MAXIMUM_REFERRAL_CODE_LENGTH
    );
};

// https://github.com/popularlab/product/issues/4519
// State notice reference/audit/id numbers vary
// so we validate for alpha-numberic+dash+underscore and max length
export const isStateNoticeNumber = (value) => {
    return /^[\w-]+$/i.test(value) && value.trim().length <= MAXIMUM_STATE_NOTICE_LENGTH;
};
