import { IUpdateClientPermissionsAction } from '../actions/app';
import { IActiveUrlAction } from '../actions/ui';
import { actions, alerts } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import { IShowModalAction, IShowBannerAction, ISetAnnouncementAction } from '../types/action.types';

export interface IUiState {
    activeUrl: string | null;
    announcement: string;
    clientPermissions: {
        /**
         * The current permission status for push notifications
         * `unknown` indicates that the service worker may not be up to date, so
         * push notifications wouldn't work. This gets set once the App initializes
         * and tries to communicate with the service worker
         */
        notifications: PermissionState | 'unknown';
        geolocation: PermissionState | 'unknown';
    };
    forceError?: boolean;
    hasEverBound: boolean;
    hasRateSafeRemindersDisabled: boolean;
    hasSeenCaliforniaExitAlert: boolean;
    hasSeenFullTour: boolean;
    hasSeenGeoMigrationTour: boolean;
    hasSeenNewLookAlert: boolean;
    hasSeenPurchaseTour: boolean;
    hasSeenQuoteWelcomeBack: boolean;
    history: string[];
    isDownForMaintenance: boolean;
    isMenuVisible: boolean;
    newVersionAvailable: boolean;
    reinstateIntent: boolean;
    sessionChecked: boolean;
    visibleBanner: null | alerts;
    visibleModal: null | alerts;
    visibleModalProps: null | any;
    pwaInstallEvent: null | BeforeInstallPromptEvent;
}

export const initialState: IUiState = {
    activeUrl: null,
    announcement: '',
    clientPermissions: {
        notifications: 'unknown',
        geolocation: 'unknown'
    },
    hasEverBound: false,
    hasRateSafeRemindersDisabled: false,
    hasSeenCaliforniaExitAlert: false,
    hasSeenFullTour: false,
    hasSeenGeoMigrationTour: false,
    hasSeenNewLookAlert: false,
    hasSeenPurchaseTour: false,
    hasSeenQuoteWelcomeBack: false,
    history: [],
    isDownForMaintenance: false,
    isMenuVisible: false,
    newVersionAvailable: false,
    reinstateIntent: false,
    sessionChecked: false,
    visibleBanner: null,
    visibleModal: null,
    visibleModalProps: null,
    pwaInstallEvent: null
};

export const actionMap = {
    [actions.SET_ANNOUNCEMENT]: (state: IUiState, action: ISetAnnouncementAction): IUiState => ({
        ...state,
        announcement: action.payload.message
    }),

    [actions.OPEN_MAIN_MENU]: (state: IUiState): IUiState => ({ ...state, isMenuVisible: true }),

    [actions.CLOSE_MAIN_MENU]: (state: IUiState): IUiState => ({ ...state, isMenuVisible: false }),

    [actions.SHOW_MODAL]: (state: IUiState, action: IShowModalAction): IUiState => ({
        ...state,
        visibleModal: action.id,
        visibleModalProps: action.payload
    }),

    [actions.HIDE_MODAL]: (state: IUiState): IUiState => ({
        ...state,
        visibleModal: null,
        visibleModalProps: null
    }),

    [actions.SHOW_BANNER]: (state: IUiState, action: IShowBannerAction): IUiState => ({
        ...state,
        visibleBanner: action.payload
    }),

    [actions.DISMISS_BANNER]: (state: IUiState): IUiState => ({ ...state, visibleBanner: null }),

    [actions.SET_ACTIVE_URL]: (state: IUiState, action: IActiveUrlAction): IUiState => ({
        ...state,
        activeUrl: action.payload.activeUrl,
        history: state.activeUrl ? [state.activeUrl, ...state.history].slice(0, 20) : state.history
    }),

    [actions.SET_FLAGS]: updateState,

    [actions.SESSION_CHECKED]: (state: IUiState): IUiState => ({ ...state, sessionChecked: true }),

    [actions.SET_REINSTATE_INTENT]: updateState,

    [actions.SET_UPDATE_AVAILABLE]: (state: IUiState): IUiState => ({
        ...state,
        newVersionAvailable: true
    }),

    [actions.UPDATE_PERMISSIONS]: (
        state: IUiState,
        action: IUpdateClientPermissionsAction
    ): IUiState => {
        return {
            ...state,
            clientPermissions: {
                ...state.clientPermissions,
                ...action.payload
            }
        };
    },

    [actions.SAVE_PWA_EVENT]: updateState
};

export default createReducer(initialState, actionMap);
