import { actions } from '../constants';
import { createReducer } from '../helpers/redux';
import {
    IApiEndAction,
    IApiStartAction,
    IApiErrorAction,
    ISetHydrationStatusAction,
    ISetApiVersionAction
} from '../types/action.types';

export interface IApiState {
    error: null | any;
    loading: string[];
    isHydrating: boolean;
    version: string;
}

export const initialState: IApiState = {
    error: null,
    loading: [],
    isHydrating: false,
    version: ''
};

export const actionMap = {
    [actions.API_START]: (state: IApiState, action: IApiStartAction): IApiState => ({
        ...state,
        error: null,
        loading: [...state.loading, action.url]
    }),

    [actions.API_END]: (state: IApiState, action: IApiEndAction): IApiState => {
        const loading = state.loading.filter((url) => url !== action.url);
        return { ...state, loading };
    },

    [actions.API_ERROR]: (state: IApiState, action: IApiErrorAction): IApiState => {
        let error;
        if (typeof action.error === 'string') {
            error = action.error;
        } else {
            const { stack, ...rest } = action.error;
            error = rest;
        }
        return { ...state, error };
    },

    [actions.HYDRATION_STATUS]: (
        state: IApiState,
        action: ISetHydrationStatusAction
    ): IApiState => ({
        ...state,
        isHydrating: action.payload
    }),

    [actions.SET_API_VERSION]: (state: IApiState, action: ISetApiVersionAction): IApiState => ({
        ...state,
        version: action.payload
    })
};

export default createReducer(initialState, actionMap);
