import { h, FunctionComponent } from 'preact';
import { route } from 'preact-router';
import { useDispatch } from 'react-redux';

import { resetEndorsement } from '../../actions/endorsement';
import { IconAlertColored } from '../icons';
import { Modal, ModalTitle, ModalContent, ModalIcon, ModalActions } from '../components/Modal';
import { ButtonCta, Button } from '../components/Button';

interface IModalNotificationProps {
    onHide: () => void;
    url: string;
}

const ModalUnsavedChanges: FunctionComponent<IModalNotificationProps> = ({ onHide, url }) => {
    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(resetEndorsement());
        route(url);
        onHide();
    };

    return (
        <Modal onHide={onHide}>
            <ModalIcon>
                <IconAlertColored
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="42"
                />
            </ModalIcon>
            <ModalTitle>Exit without changes?</ModalTitle>
            <ModalContent>
                <p>
                    You haven’t made any changes to your policy yet. To complete your policy change,
                    you must continue to checkout.
                </p>
                <p>
                    <b>By exiting now, no policy changes will be applied.</b>
                </p>
            </ModalContent>
            <ModalActions>
                <ButtonCta className="track:unsaved-changes__stay" onClick={() => onHide()}>
                    Return to modify policy
                </ButtonCta>
                <Button className="track:unsaved-changes__cancel" block link onClick={onCancel}>
                    I don’t want to modify my policy
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default ModalUnsavedChanges;
