import { h } from 'preact';
import { Suspense, lazy } from 'preact/compat';

const asyncComponent = (importComponent, loader) => {
    const Component = lazy(importComponent);
    return (props) => (
        <Suspense fallback={loader}>
            <Component {...props} />
        </Suspense>
    );
};

export default asyncComponent;
