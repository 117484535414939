// Generic Card component named Tile to avoid conflicts
// with product-specific constructs like gift tiles etc.
// https://material.io/design/components/tiles.html
// https://getbootstrap.com/docs/4.0/components/tile/

import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

import { IconCheck, IconAlertRound, IconClose } from '../../icons';

const getElement = (href?: string, onClick?: Function) => {
    if (href) {
        return 'a';
    }

    if (onClick) {
        return 'button';
    }

    return 'div';
};

export interface ITileProps extends Omit<h.JSX.HTMLAttributes, 'className'> {
    bgColor?: 'white' | 'purple' | 'pink';
    border?: boolean;
    box?: boolean;
    className?: string | null;
    disabled?: boolean;
    error?: boolean;
    href?: string;
    id?: string;
    noBorder?: boolean;
    onClick?: h.JSX.MouseEventHandler<any> | undefined;
    raised?: boolean;
    rel?: 'noopener noreferrer';
    remove?: boolean;
    success?: boolean;
    tabIndex?: number;
    target?: '_blank';
    type?: 'button';
    warning?: boolean;
}

const Tile: FunctionComponent<ITileProps> = ({
    bgColor,
    border,
    box,
    children,
    className,
    disabled,
    error,
    href,
    noBorder,
    onClick,
    raised,
    remove,
    success,
    warning,
    ...rest
}) => {
    const Element = getElement(href, onClick);
    return (
        <Element
            className={classnames('c-tile', className, {
                'c-tile--active': onClick || href,
                'c-tile--border': border,
                'c-tile--disabled': disabled,
                'c-tile--error': error,
                'c-tile--raised': raised,
                'c-tile--remove c-tile--active': remove,
                'c-tile--success': success,
                'c-tile--warning': warning,
                'u-border-none': noBorder, // @DESIGN TODO: depreacate in favor of border
                [`c-tile--${bgColor}`]: bgColor,
                'c-tile--box': box
            })}
            onClick={onClick}
            href={href}
            type={onClick ? 'button' : undefined}
            {...rest}
        >
            <div className="c-tile__content">{children}</div>
            {
                // error and warning has same icon but
                // color is changed via css.
                (error || warning) && <IconAlertRound />
            }
            {success && <IconCheck />}
            {remove && <IconClose />}
        </Element>
    );
};

export default Tile;
