/* eslint-disable import/no-named-as-default */

import { h, Fragment, FunctionComponent } from 'preact';
import { useMemo, useState, useEffect, useRef } from 'preact/hooks';
import { connect } from 'react-redux';

import { waitAtLeast } from '../../../helpers/misc';
import { status as CoverageStatus, errorCodes } from '../../../constants';
import {
    getCoverageStatusData,
    getActivePolicyId,
    getIsAutoReloadEnabled,
    getBalance,
    getLoadingArray
} from '../../../selectors';
import { createCoverageProposal } from '../../../actions/api';
import asyncComponent from '../../hoc/AsyncComponent';
import { Loader } from '../../components/Loader';
import { Modal, ModalIcon } from '../../components/Modal';
import { StoreShape } from '../../../types/store.types';

const AddFunds = asyncComponent(() => import('./AddFunds'));
const AddFundsRateChange = asyncComponent(() => import('./AddFundsRateChange'));
const ClearPendingOff = asyncComponent(() => import('./ClearPendingOff'));
const NeedsInfo = asyncComponent(() => import('./NeedsInfo'));
const TurnOff = asyncComponent(() => import('./TurnOff'));
const TurnOffRateChange = asyncComponent(() => import('./TurnOffRateChange'));
const TurnOn = asyncComponent(() => import('./TurnOn'));
const TurnOnRateChange = asyncComponent(() => import('./TurnOnRateChange'));
const RescheduleOff = asyncComponent(() => import('./RescheduleOff'));

// NOTE: Reference documents
// https://www.figma.com/file/NOmUBckUNwwpZIcxxPVexh/Master_Flows?node-id=4168%3A16034
// https://docs.google.com/spreadsheets/d/16tDecKAAcFqOnLVsYGgmqodJJjELPA4zEqLBn2zWfWw/

interface IModalCoverageToggleProps {
    apiActive?: boolean;
    autoReloadEnabled: boolean;
    balance: number;
    balanceRequired: number;
    dayRate: number;
    dispatch: (action: any) => Promise<any>;
    forceError?: boolean;
    newDayRate: number;
    onHide: () => void;
    policyId?: number;
    proposalExpiry: number | null;
    rateMightChange: boolean;
    requiresMoreInfo?: boolean;
    status: CoverageStatus;
}

export const LoadingState: FunctionComponent = () => (
    <Fragment>
        <ModalIcon className="u-mt-4">
            <Loader loading alternative className="u-inline-block" />
        </ModalIcon>
    </Fragment>
);

export const ModalCoverageTogglePure: FunctionComponent<IModalCoverageToggleProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const timeoutId = useRef<number>(-1);

    const rateChanged = useMemo(() => {
        return props.newDayRate !== props.dayRate;
    }, [props.newDayRate, props.dayRate]);

    const needsRefill = useMemo(() => {
        return props.balance < props.balanceRequired;
    }, [props.balance, props.balanceRequired]);

    const CoverageProposal: any = useMemo(() => {
        if (props.requiresMoreInfo && props.status === CoverageStatus.PAUSED) {
            return NeedsInfo;
        }
        if (needsRefill && props.status !== CoverageStatus.ACTIVE) {
            return rateChanged ? AddFundsRateChange : AddFunds;
        }
        if (!props.autoReloadEnabled && props.balance > 0) {
            if (props.status === CoverageStatus.PENDING_PAUSE) {
                return RescheduleOff;
            }
            if (props.status === CoverageStatus.ACTIVE) {
                return props.rateMightChange ? TurnOffRateChange : TurnOff;
            }
            if (props.status === CoverageStatus.PAUSED) {
                return rateChanged ? TurnOnRateChange : TurnOn;
            }
        }
        if (props.status === CoverageStatus.PENDING_PAUSE) {
            return ClearPendingOff;
        }
        if (props.status === CoverageStatus.ACTIVE) {
            return props.rateMightChange ? TurnOffRateChange : TurnOff;
        }
        if (props.status === CoverageStatus.PAUSED) {
            return rateChanged ? TurnOnRateChange : TurnOn;
        }
        return null;
    }, [
        needsRefill,
        props.status,
        props.requiresMoreInfo,
        rateChanged,
        props.autoReloadEnabled,
        props.rateMightChange
    ]);

    // Closing modal after proposal expires
    useEffect(() => {
        clearTimeout(timeoutId.current);
        if (!props.apiActive && props.proposalExpiry) {
            timeoutId.current = window.setTimeout(
                () => props.onHide(),
                Number(props.proposalExpiry - 1) * 1000
            );
        }
        return () => clearTimeout(timeoutId.current);
    }, [props.apiActive, props.proposalExpiry]);

    useEffect(() => {
        const data = {
            policyId: props.policyId,
            targetCoverageStatus:
                props.status === CoverageStatus.ACTIVE
                    ? CoverageStatus.PAUSED
                    : CoverageStatus.ACTIVE,
            forceError: props.forceError
        };

        setLoading(true);
        waitAtLeast(1000, () => props.dispatch(createCoverageProposal(data)))
            .catch((error) => {
                if (
                    error.error === errorCodes.INSUFFICIENT_BALANCE ||
                    error.error === errorCodes.AR_INSUFFICIENT_BALANCE ||
                    error.error === errorCodes.REQUIRES_MORE_INFO ||
                    error.error === errorCodes.POLICY_NOT_ACTIVE
                ) {
                    return;
                }
                props.onHide();
            })
            .finally(() => setLoading(false));
    }, [props.policyId]);

    return (
        <Modal closeButton={false} onHide={loading ? undefined : props.onHide}>
            {loading ? <LoadingState /> : <CoverageProposal />}
        </Modal>
    );
};

ModalCoverageTogglePure.defaultProps = {
    apiActive: false,
    autoReloadEnabled: true,
    balance: 0,
    balanceRequired: 0,
    dayRate: 0,
    forceError: undefined,
    newDayRate: 0,
    onHide: () => {},
    policyId: undefined,
    proposalExpiry: 0,
    rateMightChange: false,
    requiresMoreInfo: false,
    status: undefined
};

const mapStateToProps = (state: StoreShape) => ({
    apiActive:
        getLoadingArray(state).includes('coverage:bind') ||
        getLoadingArray(state).includes('coverage:proposal'),
    autoReloadEnabled: getIsAutoReloadEnabled(state),
    balance: getBalance(state),
    balanceRequired: state.proposal.balanceRequired,
    dayRate: state.proposal.currentDayRate,
    forceError: state.ui.forceError,
    newDayRate: state.proposal.newDayRate,
    policyId: getActivePolicyId(state),
    proposalExpiry: state.proposal.proposalExpiry,
    rateMightChange: state.proposal.rateMightChange,
    requiresMoreInfo: state.proposal.requiresMoreInfo,
    status: getCoverageStatusData(state).status
});

export default connect(mapStateToProps)(ModalCoverageTogglePure);
