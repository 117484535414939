import { h } from 'preact';
import { connect } from 'react-redux';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';

import { getAccessLevelData } from '../../selectors';
import { normalizeTitle } from '../../helpers/formatters';
import { routes } from '../../routes';
import { saveQueryStringData } from '../../actions/data';

const Auth = ({ access, isDownForMaintenance, ...props }) => {
    const isAllowed = props.isAllowed(access);
    const { isLogged } = access;

    useEffect(() => {
        if (isAllowed) {
            document.title = normalizeTitle(`Hugo App - ${props.name}`);
        }
    }, [isAllowed, props.name]);

    if (isDownForMaintenance && props.path !== routes.MAINTENANCE.path) {
        return route(routes.MAINTENANCE.path, true);
    }

    if (!isAllowed) {
        if (!isLogged) {
            // Save current URL as origin.
            props.dispatch(saveQueryStringData({ origin: props.url }));
            // Redirect non-logged users and preserve query string data
            // so it can be parsed by analytics services that might
            // load after this redirect happens.
            return route(`${routes.LOGIN.path}${window.location.search}`, true);
        }

        return route(routes.DASHBOARD.path, true);
    }

    const { view: View, name, path } = props;
    return <View name={name} path={path} />;
};

const mapStateToProps = (state) => ({
    access: getAccessLevelData(state),
    isDownForMaintenance: state.ui.isDownForMaintenance
});

export default connect(mapStateToProps)(Auth);
