// copy.js
// App copy and content in JS strings or template literals.

import errorCodes from './error-codes';

export default {
    formErrors: {
        address: {
            missingNumber: 'Oops, a valid address must begin with a number.'
        },
        ageFirstLicensed: {
            isInvalid: 'Oops! Please enter a valid age.',
            maximumAge:
                'Oops! The age you were first licensed cannot be higher than your current age.'
        },
        annualMiles: {
            oneWayMilesIsInvalid:
                'Oops! Please enter the number of one-way miles you drive daily. Best guess.',
            weeklyDrivenDaysIsInvalid:
                'Oops! Please enter your best guess for the number of days you drive in a week.',
            editAnnualMilesIsInvalid:
                'Oops! Please enter your best guess for the number of miles this vehicle will be driven annually.'
        },
        code: {
            isInvalid: 'Please enter a 6-digit code with only numbers.'
        },
        date: {
            isFuture: 'Sorry, we can’t insure time-travelers at this moment.',
            isInvalid: 'Oops, make sure date is in correct format.',
            isInvalidTime: 'Oops, that is not a vaild date.'
        },
        email: {
            isInvalid: 'Oops, that is not a valid email address.',
            noPhone:
                'Oops! You must add a verified phone number to your account before you can update your email.'
        },
        license: {
            isInvalid: 'Oops, that is not a valid driver’s licence.',
            alreadyIncluded:
                'Oops, a person with that driver license is already included in your quote application!'
        },
        name: {
            isInvalid: 'Please enter a valid name.'
        },
        phone: {
            isInvalid: 'Oops, US phone number only includes numbers and is 10 digits long.',
            alreadyInUse:
                'Oops! That phone number is already associated with an existing Hugo account.'
        },
        promoCode: {
            isInvalid: 'Oops, that is not a valid promo code.'
        },
        rtq: {
            isInvalid:
                'Oops, some of the details are incorrect. Please double-check your info, and your link'
        },
        state: {
            isInvalid: 'Oops, that is not a valid US state name.'
        },
        ssn: {
            isInvalid: 'Oops, that is not a valid social security number.'
        },
        vin: {
            forbiddenChars:
                'Oops! VINs never use the following letters: O (o), I (i), or Q (q). Check your VIN and try again.',
            invalidChars: 'Oops, a valid VIN contains only letters or numbers.',
            invalidCheckDigit: 'Oops, that is not a valid VIN. Check your VIN and try again.',
            invalidLength:
                'Oops! VINs are always 17 characters long. Check your VIN and try again.',
            notFound: 'We could not find a vehicle for this VIN. Double check and try again.',
            alreadyIncluded: 'You already have a vehicle with this VIN in your quote application!',
            invalidVehicleId: 'Oops, that is not a valid VIN. Check your VIN and try again.'
        },
        pre1981Vin: {
            invalidLength:
                'Oops! Pre-1981 VINs are at least 5 characters long. Check your VIN and try again.'
        },
        zip: {
            isInvalid: 'Oops, that is not a valid ZIP code.'
        },
        referralCode: {
            isInvalid: 'Opps, please enter valid referral code.'
        },
        api: {
            GENERIC: 'Oops! Something went wrong. Please try again.',
            [errorCodes.RATE_LIMIT]: (email: string) =>
                `We’ve already sent a verification link to ${email}. Please wait several minutes for it to arrive, and check your spam folder if you have not received it.`,
            [errorCodes.ADDRESS_VALIDATION_ERROR]:
                'We could not find a match for the address you provided. Double check it and try again.',
            [errorCodes.ACCOUNT_NOT_FOUND_SMS]:
                'Sorry, we can’t find any account associated with that phone number.',
            [errorCodes.ACCOUNT_NOT_FOUND_GOOGLE]:
                'Oops! If you created your account with your phone number, try logging in with that instead',
            // TODO: error codes copied from old API enums.
            [errorCodes.ACCOUNT_ALREADY_EXISTS]:
                'It looks like you already have an account! Use the login link instead',
            [errorCodes.NOT_SIGNED]: 'Sorry, only Hugo members can use this service.',
            [errorCodes.SMS_TOKEN_MISMATCH]:
                'Oops! The code you entered is invalid. Please try again.',
            [errorCodes.SMS_TOKEN_ALREADY_USED]:
                'Oops, you already used that number. Check your messages for new one or hit the link below.',
            [errorCodes.SMS_TOKEN_EXPIRED]:
                'Oops, your confirmation number expired. Please request new one from the link below.',
            [errorCodes.CARD_DECLINED]:
                'Oops! Your card was declined. Please use a different card and try again, or contact your bank.',
            [errorCodes.CARD_DECLINED_CURRENCY]:
                'Oops! It looks like your card doesn’t support U.S. dollars. Please change cards and try again.',
            [errorCodes.CARD_DECLINED_EXPIRED]:
                'Oops! It looks like your card is expired. Please change cards and try again. ',
            [errorCodes.CARD_DECLINED_INSUFFICIENT_FUNDS]:
                'Oops! Your card was declined. Please add funds, or use a different card and try again.',
            [errorCodes.CARD_DECLINED_INVALID_ACCOUNT]:
                'Oops! The card, or the account the card is connected to, is invalid. You may need to contact your card issuer to check that your card is working correctly.',
            [errorCodes.CARD_DECLINED_INVALID_CVC]:
                'The CVC you entered is invalid. Please check your card details and try again.',
            [errorCodes.CARD_DECLINED_INVALID_EXPIRATION]:
                'The expiration date you entered is invalid. Please check your card details and try again.',
            [errorCodes.CARD_DECLINED_INVALID_NUMBER]:
                'The card number you entered is invalid. Please check your card details and try again.',
            [errorCodes.CARD_DECLINED_INVALID_PAYMENT_METHOD]:
                'Your payment details need to be updated before you can make a purchase. Please update your card details and try again.',
            [errorCodes.CARD_DECLINED_INVALID_ZIP]:
                'Oops! It looks like the zip code you entered is invalid. Please check your card details and try again.',
            [errorCodes.INCENTIVE_CODE_DOES_NOT_EXIST]:
                'Sorry, the promo code you entered is invalid or expired.',
            [errorCodes.INCENTIVE_CODE_INVALID]: 'Sorry, the promo code you entered is invalid.',
            [errorCodes.INCENTIVE_CODE_ALREADY_APPLIED]:
                'Oops, the promo code you entered was already used.',
            [errorCodes.EMAIL_VERIFICATION_EXPIRED]:
                'Oops, your email verification has expired. Please reenter your email address and try again.',
            [errorCodes.QUOTE_APPLICATION_DUPLICATE_COVERAGE]: {
                driver: 'Oops, this person is already covered by a Hugo insurance policy!',
                license: 'Oops, this license is already covered by a Hugo insurance policy!',
                ssn: 'Oops, this person is already covered by a Hugo insurance policy!',
                vehicle:
                    'Oops, this quote application has a vehicle that is already covered by a Hugo insurance policy!',
                vin: 'Oops, this vehicle is already covered by a Hugo insurance policy!'
            },
            [errorCodes.QUOTE_APPLICATION_MULTIPLE_POLICIES]: 'You already have an active policy!',
            [errorCodes.PHONE_NUMBER_IS_LANDLINE]:
                'Oops, we can only support phone numbers that receive text messages.',
            [errorCodes.PHONE_NUMBER_INVALID]:
                'Oops, it doesn’t look like that is a valid phone number, or we can’t send text messages to that number.',
            [errorCodes.ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION]:
                'Before you can make changes to your policy, you must turn coverage on.',
            [errorCodes.ENDORSEMENT_DUPLICATE_VEHICLE]:
                'Oops, this vehicle is already covered by a Hugo insurance policy!',
            [errorCodes.ENDORSEMENT_SERVICE_UNAVAILABLE]:
                'Oops, one of our partners is currently down. Please try again later. We apologize for the inconvenience.',
            [errorCodes.ENDORSEMENT_QUOTE_APPLICATION_EXPIRED]:
                'Oops, your new quote has expired. Please try again.',
            [errorCodes.ENDORSEMENT_PROHIBITED_VEHICLE]:
                'Oops, we do not offer coverage on this vehicle.',

            [errorCodes.ACCEPTANCE_ENDORSER_CYCLE_IS_ACTIVE_ERROR]:
                'Oops, we are performing a scheduled update. Please try again in a few hours.',
            [errorCodes.ACCEPTANCE_ENDORSER_POLICY_IS_NOT_ACTIVE_ERROR]:
                'Oops, we are currently processing your policy changes. This can take up to 24 hours. Please come back later.',
            [errorCodes.ACCEPTANCE_ENDORSER_CYCLE_REQUIRED_ERROR]:
                'Oops, we are currently processing your policy changes. This can take up to 24 hours. Please come back later.',
            [errorCodes.ACCEPTANCE_ENDORSER_INVALID_DATE_ERROR]:
                'Oops, this endorsement is not valid anymore. Please refresh your changes and try again.',
            [errorCodes.ACCEPTANCE_ENDORSER_INELIGIBLE_VEHICLE_ERROR]:
                'Oops, we do not offer coverage on this vehicle.',
            [errorCodes.ACCEPTANCE_ENDORSER_INVALID_PRICE_ERROR]:
                'Oops, there seems to be a problem. Please contact our support team',
            [errorCodes.ACCEPTANCE_ENDORSER_POLICY_IS_IN_USE]:
                'Oops, we are currently processing your policy changes. This can take up to 24 hours. Please come back later.',

            [errorCodes.TRITECH_ADDRESS_ERROR]:
                'Sorry, we cannot offer insurance to policies using this home address.',

            [errorCodes.ACCOUNT_CODE_INVALID]: 'Sorry, the code you entered is invalid.',
            [errorCodes.ACCOUNT_CODE_ALREADY_SUBMITTED]: 'Oops! You already submitted a code.',
            [errorCodes.QUOTE_APPLICATION_PREMIUM_HAS_CHANGED_ERROR]:
                'Sorry, your premium has changed. Please review your new rate.'
        },
        data: {
            policy: {
                garagingAddress: {
                    custom: {
                        invalid: {
                            [errorCodes.ADDRESS_PARKING_NOT_ALLOWED_ERROR]:
                                'Sorry, you cannot use that address as a parking location.',
                            [errorCodes.ADDRESS_PARKING_COMMERCIAL_ERROR]:
                                'Sorry, we cannot accept a commercial address as a parking location when your home address is a P.O. Box.',
                            [errorCodes.ADDRESS_PARKING_CMRA_ERROR]:
                                'Sorry, we cannot accept a CMRA address as a parking location.',
                            [errorCodes.ADDRESS_PARKING_POBOX_ERROR]:
                                'Sorry, we cannot accept a P.O. Box address as a parking location.'
                        }
                    }
                },
                homeAddress: {
                    custom: {
                        invalid: {
                            [errorCodes.ADDRESS_PARKING_COMMERCIAL_ERROR]:
                                'Sorry, we cannot accept a P.O. Box as your home address when your parking location is a commercial address.'
                        }
                    }
                }
            },
            pni: {
                homeAddress: {
                    custom: {
                        invalid: {
                            [errorCodes.ADDRESS_HOME_NOT_ALLOWED_ERROR]:
                                'Sorry, we cannot accept that location as a home address.',
                            [errorCodes.ADDRESS_HOME_COMMERCIAL_ERROR]:
                                'Sorry, we cannot accept a commercial address as a home address.',
                            [errorCodes.ADDRESS_HOME_CMRA_ERROR]:
                                'Sorry, we cannot accept a CMRA address as a home address.',
                            [errorCodes.ADDRESS_HOME_POBOX_ERROR]:
                                'Sorry, we cannot accept a P.O. Box address as a home address.'
                        }
                    }
                }
            }
        }
    },
    errorView: {
        title: {
            [errorCodes.PAGE_NOT_FOUND]: 'Looks like you took a wrong turn...',
            [errorCodes.NETWORK_ERROR]: 'Oops! There appears to be trouble with the network',
            [errorCodes.TECHNICAL_ERROR]: 'Oh dear, something is broken'
        },
        subtitle: {
            [errorCodes.PAGE_NOT_FOUND]:
                'Sorry, we can’t find the page you’re looking for. Let us know if we can help, or return to your dashboard below.',
            [errorCodes.NETWORK_ERROR]: 'Please check your internet connection or come back later.',
            [errorCodes.TECHNICAL_ERROR]:
                'Ouch! We experienced a technical error. Please try again or contact support if the issue persists.'
        }
    },
    compare: {
        noFeesTraditional:
            'No upfront fees: we hate fees as much as you do. That’s why with Hugo, what you see is what you pay. You’ll pay your flat daily rate for coverage, and never more.',

        noFeesFlex:
            'No fees ever: No installment fees, no cancelation fees, no late fees — this is our promise, because we hate fees as much as you do. We guarantee that all you will ever pay for at Hugo is your coverage.',

        turnOff:
            'Not driving for a few days? Switch OFF your coverage and pay nothing until you turn it back ON. After all, liability coverage only matters when you’re driving.',

        noDelays:
            'No more checks in the mail or bank transfer delays. When a claim is settled you’ll get paid that same day.',

        coverageExtension: 'We’ll keep you covered and give you a few extra days to pay',

        overdraftProtection:
            'Never spend more than what’s in your account by automatically turning off your coverage when your account balance runs out. Then reload your account to restart coverage instantly.',

        proofOfInsurance:
            'At Hugo, you get an active, up-to-date, digital Proof of Insurance card as soon as you sign up.'
    },
    coverages: {
        generateGenericCoveragesMessage: (fullStateName: string): string =>
            `Great, we’ve updated some of the other coverages you have selected, as required by ${fullStateName} law.`
    }
};
