import { StateCode, SupportTicketType } from '@popularlab/enums';

import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import {
    ISaveRegistrationProtectionPrefAction,
    ISaveQuoteInsurifyAction
} from '../types/action.types';
import { ISetPendingStateFilingRequestAction } from '../actions/data';
import { RegistrationProtectionPref } from '../ui/views/RegistrationProtection/RegistrationProtection.types';
import { ILeadResults } from '../ui/types';

export interface IAccountState {
    accountId: number | null;
    email: string | null;
    firstName: string | null;
    isEmailVerified: boolean;
    lastName: string | null;
    phone: string | null;
    accountFeeDayRate: number;
    dayRate: number;
    policyDayRate: number;
    referralCode: string;
    openTickets: SupportTicketType[];
    registrationProtectionPref: null | RegistrationProtectionPref;
    lastCoverageSession: null | { daysSinceEnd: number };
    insurifyQuotes: ILeadResults | null;
    lastKnownGeo?: StateCode;
    lastKnownControllerVersion?: string;
    lastActivePolicyId?: number;
    daysForRewrite: number;
    daysForReinstateFeeForgiveness: number;
    futureDailyFeeForReinstate: number;
    futureTotalFeeForReinstate: number;
    feeForgivenessEndDateTime: Date | null;
    pendingStateFilingRequest?: boolean;
    serviceNumber?: string;
}

export const initialState: IAccountState = {
    // HUGO ACCOUNT DATA
    accountId: null,
    email: null,
    firstName: null,
    isEmailVerified: false,
    lastName: null,
    phone: null,

    // QUOTE DATA
    accountFeeDayRate: 0,
    dayRate: 0,
    policyDayRate: 0,

    // TODO: move to rewards reducer
    referralCode: '',

    // ZenDesk tickets
    openTickets: [],

    registrationProtectionPref: null,

    lastCoverageSession: null,

    insurifyQuotes: null,

    lastKnownGeo: undefined,
    lastKnownControllerVersion: undefined,
    lastActivePolicyId: undefined,

    daysForRewrite: 10,
    daysForReinstateFeeForgiveness: 3,

    futureDailyFeeForReinstate: 0,
    futureTotalFeeForReinstate: 0,
    feeForgivenessEndDateTime: null,
    pendingStateFilingRequest: undefined
};

export const actionMap = {
    [actions.SAVE_ACCOUNT_ID]: updateState,

    [actions.SET_PHONE]: updateState,

    [actions.SET_EMAIL]: updateState,

    [actions.SAVE_ACCOUNT_DATA]: updateState,

    [actions.UPDATE_OPEN_TICKETS]: updateState,

    [actions.SAVE_REGISTRATION_PROTECTION_PREF]: (
        state: IAccountState,
        action: ISaveRegistrationProtectionPrefAction
    ): IAccountState => ({
        ...state,
        registrationProtectionPref: action.payload
    }),

    [actions.SIGNUP_INSURIFY]: (
        state: IAccountState,
        action: ISaveQuoteInsurifyAction
    ): IAccountState => ({
        ...state,
        insurifyQuotes: action.payload
    }),

    [actions.SIGNUP_INITIALIZE]: (state: IAccountState): IAccountState => ({
        ...state,
        insurifyQuotes: null
    }),

    [actions.SIGNUP_UPDATE]: (state: IAccountState): IAccountState => ({
        ...state,
        insurifyQuotes: null
    }),

    [actions.SET_PENDING_STATE_FILING_REQUEST]: (
        state: IAccountState,
        action: ISetPendingStateFilingRequestAction
    ): IAccountState => ({
        ...state,
        pendingStateFilingRequest: action.payload
    })
};

export default createReducer(initialState, actionMap);
