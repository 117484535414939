import { useCallback } from 'preact/compat';

import { resetQuoteApplication } from '@/actions/api';
import { resetSignup, setMetaData } from '@/actions/signup';
import { showDoneModal } from '@/actions/ui';

/**
 * Resets the quote application and signup
 * @param dispatch redux dispatch function
 * @param setIsLoading invoked when the loading state changes
 * @param onHide invoked when the reset actions completes
 * @returns a function to initiate the quote reset
 */
export const useResetQuote: (
    dispatch: (action: any) => any,
    setIsLoading: (loading: boolean) => void,
    onComplete?: () => void
) => () => void = (dispatch, setIsLoading, onComplete) => {
    const resetQuote = useCallback(() => {
        setIsLoading(true);
        Promise.resolve(dispatch(resetQuoteApplication()))
            .then(() => {
                dispatch(resetSignup());
                dispatch(setMetaData({ statusChecked: true }));
                if (onComplete) onComplete();
                dispatch(
                    showDoneModal({
                        message: 'Your quote application has been removed.'
                    })
                );
            })
            .finally(() => setIsLoading(false));
    }, []);
    return resetQuote;
};
