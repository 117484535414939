// App routes defined by name, path, layout and permission fn.

import { StateCode } from '@popularlab/enums';

import { isAcceptance, isStateFilingEnabled } from './helpers/geos';
import { layouts } from './constants';
import {
    Accident,
    Account,
    AccountEmail,
    AccountPhone,
    AccountPushNotifications,
    AccountVerifyEmail,
    Dashboard,
    Documents,
    DocumentsDeclarationsPage,
    DocumentsLetterOfExperience,
    DocumentsPolicyBinder,
    DocumentsPolicyPacket,
    DocumentsRegisterVehicle,
    DocumentsStateFiling,
    DocumentsStateFilingOld,
    ErrorView,
    Help,
    HelpClaims,
    HelpHugoText,
    Helpdesk,
    HelpdeskFeedback,
    Insurance,
    InsuranceDiscounts,
    InsuranceExtension,
    InsuranceModify,
    InsurancePolicy,
    InsuranceProof,
    InsuranceRateBreakdown,
    InsuranceRateSafe,
    InsuranceRenewal,
    InsuranceTerminate,
    InsuranceTerminateToChange,
    InsuranceTerminateToChangeAddress,
    Login,
    LoginHelp,
    Maintenance,
    Payment,
    PaymentCard,
    PaymentCheckout,
    PaymentHistory,
    PaymentPurchase,
    PaymentRefillAndOn,
    PaymentRefund,
    PaymentRepayOwedBalance,
    PaymentSettings,
    Quote,
    Register,
    RegistrationProtection,
    ReturnToQuote,
    Rewards,
    RewardsShare,
    RewardsTerms,
    Testing
} from './ui/views';
import { IAccessProps } from './ui/types';

// Permission helpers; run when isAllowed is called.
const isPublic = (): boolean => true;
const isLogged = (access: IAccessProps): boolean => access.isLogged;
const isBinded = (access: IAccessProps): boolean => access.notBasic;
const hasOverdrawnBalance = (access: IAccessProps): boolean => access.hasOverdrawnBalance;
const isNotCanceled = (access: IAccessProps): boolean =>
    access.isFull || access.isRestrictedAndOverdrawn;
const isBindedOrCancelled = (access: IAccessProps): boolean => access.notBasic || access.isCanceled;
const isCalifornia = (access: IAccessProps): boolean => access.activeGeo === StateCode.CA;
const isExtensionAvailable = (access: IAccessProps): boolean =>
    access.notBasic && access.isExtensionAvailable;
const isExtensionActive = (access: IAccessProps): boolean =>
    access.notBasic && access.isExtensionActive;
const isCoverageActive = (access: IAccessProps): boolean =>
    access.notBasic && access.isCoverageActive;
const isRenewalWindow = (access: IAccessProps): boolean => access.isRenewalWindow;

// WARNING: If changing route definitions make sure to notify
// product that analytics tracking will change.

interface Routes {
    [key: string]: {
        name: string;
        path: string;
        layout: string;
        view: any;
        isAllowed: (access: IAccessProps) => boolean;
        default?: boolean;
    };
}

const routes: Routes = {
    MAINTENANCE: {
        name: 'Maintenance',
        path: '/maintenance',
        layout: layouts.MINIMAL,
        view: Maintenance,
        isAllowed: isPublic
    },
    QUOTE: {
        name: 'Get Quote',
        path: '/quote',
        layout: layouts.QUOTE,
        view: Quote,
        isAllowed: isPublic
    },
    LOGIN: {
        name: 'Login',
        path: '/login',
        layout: layouts.LOGIN,
        view: Login,
        isAllowed: isPublic
    },
    LOGIN_HELP: {
        name: 'Login Help',
        path: '/login/help',
        layout: layouts.MINIMAL,
        view: LoginHelp,
        isAllowed: isPublic
    },
    REGISTER: {
        name: 'Join',
        path: '/join',
        layout: layouts.LOGIN,
        view: Register,
        isAllowed: isPublic
    },
    ERROR: {
        name: 'Error',
        path: '/error',
        layout: layouts.ADAPTIVE,
        view: ErrorView,
        isAllowed: isPublic,
        // error is default route so we pass route prop here
        default: true
    },
    RETURN_TO_QUOTE: {
        name: 'Get your quote',
        path: '/resume-quote',
        layout: layouts.LOGIN,
        view: ReturnToQuote,
        isAllowed: isPublic
    },
    HELP: {
        name: 'Get help',
        path: '/help',
        layout: layouts.ADAPTIVE,
        view: Help,
        isAllowed: isPublic
    },
    HELP_CLAIMS: {
        name: 'File a claim',
        path: '/help/claims',
        layout: layouts.ADAPTIVE,
        view: HelpClaims,
        isAllowed: isPublic
    },
    HELPDESK: {
        name: 'Help',
        path: '/helpdesk',
        layout: layouts.ADAPTIVE,
        view: Helpdesk,
        isAllowed: isPublic
    },
    HELPDESK_FEEDBACK: {
        name: 'Leave Feedback',
        path: '/helpdesk/feedback',
        layout: layouts.PORTAL,
        view: HelpdeskFeedback,
        isAllowed: () => false
    },
    HELP_HUGO_TEXT: {
        name: 'Use Hugo with Text',
        path: '/help/hugo-text',
        layout: layouts.ADAPTIVE,
        view: HelpHugoText,
        isAllowed: isBinded
    },
    DASHBOARD: {
        name: 'Dashboard',
        path: '/',
        layout: layouts.PORTAL,
        view: Dashboard,
        isAllowed: isLogged
    },
    ACCOUNT: {
        name: 'My Info',
        path: '/account',
        layout: layouts.PORTAL,
        view: Account,
        isAllowed: isLogged
    },
    ACCOUNT_EMAIL: {
        name: 'Manage Email',
        path: '/account/email',
        layout: layouts.PORTAL,
        view: AccountEmail,
        isAllowed: isLogged
    },
    ACCOUNT_VERIFY_EMAIL: {
        name: 'Manage Email',
        path: '/account/verify-email',
        layout: layouts.PORTAL,
        view: AccountVerifyEmail,
        isAllowed: isLogged
    },
    ACCOUNT_PHONE: {
        name: 'Manage Phone Number',
        path: '/account/phone',
        layout: layouts.PORTAL,
        view: AccountPhone,
        isAllowed: isLogged
    },
    ACCOUNT_PUSH_NOTIFICATIONS: {
        name: 'Push Notifications',
        path: '/account/push-notifications',
        layout: layouts.PORTAL,
        view: AccountPushNotifications,
        isAllowed: (access) => isLogged(access)
    },
    ACCOUNT_REGISTRATION_PROTECTION: {
        name: 'Registration Protection',
        path: '/account/registration-protection',
        layout: layouts.PORTAL,
        view: RegistrationProtection,
        isAllowed: (access: IAccessProps): boolean =>
            isBinded(access) && !isAcceptance(access.activeGeo, access.activeVersion)
    },
    ACCIDENT: {
        name: 'Report Accident',
        path: '/accident',
        layout: layouts.PORTAL,
        view: Accident,
        isAllowed: isBindedOrCancelled
    },
    PAYMENT: {
        name: 'My Account',
        path: '/payment',
        layout: layouts.PORTAL,
        view: Payment,
        isAllowed: isBindedOrCancelled
    },
    PAYMENT_PURCHASE: {
        name: 'Fund your Hugo account',
        path: '/payment/purchase',
        layout: layouts.PORTAL,
        view: PaymentPurchase,
        isAllowed: isCoverageActive
    },
    PAYMENT_CHECKOUT: {
        name: 'Checkout',
        path: '/payment/checkout',
        layout: layouts.PORTAL,
        view: PaymentCheckout,
        isAllowed: isBinded
    },
    PAYMENT_REFILL_AND_ON: {
        name: 'Refill And Turn ON',
        path: '/payment/refill-and-on',
        layout: layouts.PORTAL,
        view: PaymentRefillAndOn,
        isAllowed: isBinded
    },
    PAYMENT_REPAY_OWED: {
        name: 'Repay Owed Balance',
        path: '/payment/repay-owed',
        layout: layouts.PORTAL,
        view: PaymentRepayOwedBalance,
        isAllowed: hasOverdrawnBalance
    },
    PAYMENT_SETTINGS: {
        name: 'Payment Settings',
        path: '/payment/settings',
        layout: layouts.PORTAL,
        view: PaymentSettings,
        isAllowed: isLogged
    },
    PAYMENT_CARD: {
        name: 'Manage Payment Card',
        path: '/payment/card',
        layout: layouts.PORTAL,
        view: PaymentCard,
        isAllowed: isBindedOrCancelled
    },
    PAYMENT_REFUND: {
        name: 'Refund Money',
        path: '/payment/refund',
        layout: layouts.PORTAL,
        view: PaymentRefund,
        isAllowed: isBindedOrCancelled
    },
    PAYMENT_HISTORY: {
        name: 'Balance History',
        path: '/payment/history',
        layout: layouts.PORTAL,
        view: PaymentHistory,
        isAllowed: isBindedOrCancelled
    },
    DOCUMENTS: {
        name: 'Documents and services',
        path: '/documents',
        layout: layouts.PORTAL,
        view: Documents,
        isAllowed: isBindedOrCancelled
    },
    DOCUMENTS_POLICY_PACKET: {
        name: 'My policy packet',
        path: '/documents/policy-packet',
        layout: layouts.PORTAL,
        view: DocumentsPolicyPacket,
        isAllowed: isBindedOrCancelled
    },
    DOCUMENTS_POLICY_BINDER: {
        name: 'My policy binder',
        path: '/documents/policy-binder',
        layout: layouts.PORTAL,
        view: DocumentsPolicyBinder,
        isAllowed: (access: IAccessProps) =>
            isNotCanceled(access) && access.activeGeo === StateCode.GA
    },
    DOCUMENTS_REGISTER_VEHICLE: {
        name: 'Register my vehicle',
        path: '/documents/register-vehicle',
        layout: layouts.PORTAL,
        view: DocumentsRegisterVehicle,
        isAllowed: isNotCanceled
    },
    DOCUMENTS_STATE_FILING: {
        name: 'Submit my policy to state',
        path: '/documents/state-filing',
        layout: layouts.PORTAL,
        view: process.env.HUGO_NEW_STATE_FILING ? DocumentsStateFiling : DocumentsStateFilingOld,
        isAllowed: (access: IAccessProps) =>
            isNotCanceled(access) && !!access.activeGeo && isStateFilingEnabled(access.activeGeo)
    },
    DOCUMENTS_DECLARATIONS_PAGE: {
        name: 'My declarations page',
        path: '/documents/declarations-page',
        layout: layouts.PORTAL,
        view: DocumentsDeclarationsPage,
        isAllowed: isNotCanceled
    },
    DOCUMENTS_LETTER_OF_EXPERIENCE: {
        name: 'Formal letter of experience',
        path: '/documents/letter-of-experience',
        layout: layouts.PORTAL,
        view: DocumentsLetterOfExperience,
        isAllowed: isBindedOrCancelled
    },
    INSURANCE: {
        name: 'My Insurance',
        path: '/insurance',
        layout: layouts.PORTAL,
        view: Insurance,
        isAllowed: isBinded
    },
    INSURANCE_POLICY: {
        name: 'Policy Details',
        path: '/insurance/policy',
        layout: layouts.PORTAL,
        view: InsurancePolicy,
        isAllowed: isBinded
    },
    INSURANCE_MODIFY: {
        name: 'Modify policy',
        path: '/insurance/modify',
        layout: layouts.PORTAL,
        view: InsuranceModify,
        isAllowed: (access: IAccessProps): boolean => isBinded(access)
    },
    INSURANCE_PROOF: {
        name: 'Proof of Insurance',
        path: '/insurance/proof',
        layout: layouts.PORTAL,
        view: InsuranceProof,
        isAllowed: isBinded
    },
    INSURANCE_DISCOUNTS: {
        name: 'Find Discounts',
        path: '/insurance/discounts',
        layout: layouts.PORTAL,
        view: InsuranceDiscounts,
        isAllowed: (access: IAccessProps): boolean =>
            isBinded(access) && !isAcceptance(access.activeGeo, access.activeVersion)
    },
    INSURANCE_RATE_BREAKDOWN: {
        name: 'Daily Rate Breakdown',
        path: '/insurance/rate-breakdown',
        layout: layouts.PORTAL,
        view: InsuranceRateBreakdown,
        isAllowed: isBinded
    },
    INSURANCE_RATE_SAFE: {
        name: 'Rate Safe',
        path: '/insurance/rate-safe',
        layout: layouts.PORTAL,
        view: InsuranceRateSafe,
        isAllowed: (access: IAccessProps): boolean =>
            isBinded(access) && isAcceptance(access.activeGeo, access.activeVersion)
    },
    INSURANCE_EXTEND_COVERAGE: {
        name: 'Coverage Extension',
        path: '/insurance/extension',
        layout: layouts.PORTAL,
        view: InsuranceExtension,
        isAllowed: (access: IAccessProps): boolean =>
            isExtensionAvailable(access) || isExtensionActive(access)
    },
    INSURANCE_TERMINATE: {
        name: 'Terminate and rebind insurance',
        path: '/insurance/terminate',
        layout: layouts.PORTAL,
        view: InsuranceTerminate,
        isAllowed: isBinded
    },
    INSURANCE_TERMINATE_TO_CHANGE: {
        name: 'Cancel policy to change coverages',
        path: '/insurance/cancel-to-change',
        layout: layouts.PORTAL,
        view: InsuranceTerminateToChange,
        isAllowed: (access: IAccessProps): boolean => isBinded(access)
    },
    INSURANCE_TERMINATE_TO_CHANGE_ADDRESS: {
        name: 'Cancel policy to change address',
        path: '/insurance/cancel-to-change-address',
        layout: layouts.PORTAL,
        view: InsuranceTerminateToChangeAddress,
        isAllowed: isBinded
    },
    INSURANCE_RENEWAL: {
        name: 'Policy Renewal',
        path: '/insurance/renewal',
        layout: layouts.PORTAL,
        view: InsuranceRenewal,
        isAllowed: isRenewalWindow
    },
    REWARDS: {
        name: 'Rewards',
        path: '/rewards',
        layout: layouts.PORTAL,
        view: Rewards,
        isAllowed: isLogged
    },
    REWARDS_SHARE: {
        name: 'Share Hugo and Earn',
        path: '/rewards/share',
        layout: layouts.PORTAL,
        view: RewardsShare,
        isAllowed: (access: IAccessProps): boolean =>
            isBindedOrCancelled(access) && !isCalifornia(access) && access.hasReferralCode
    },
    REWARDS_TERMS: {
        name: 'Referral Program Terms and Conditions',
        path: '/rewards/terms',
        layout: layouts.PORTAL,
        view: RewardsTerms,
        isAllowed: isLogged
    }
};

if (process.env.HUGO_ENV !== 'production') {
    routes.TESTING = {
        name: 'Test',
        path: '/testing',
        layout: layouts.QUOTE,
        view: Testing,
        isAllowed: isPublic
    };
}

const ROUTE_KEYS = Object.keys(routes);

export { routes, ROUTE_KEYS };
