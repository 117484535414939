import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    getAccessLevelData,
    getAccountId,
    getActivePolicyId,
    getCoverageStatusData,
    getDayRate,
    getEndCoverageTimestamp,
    getNeedsRefillPaused,
    getLoadingArray,
    isApiLoading
} from '../../selectors';
import { StoreShape } from '../../types/store.types';

function withCoverage<T>(WrappedComponent: FunctionComponent<T>): (props: T) => h.JSX.Element {
    const WithCoverage = (props: T) => <WrappedComponent {...props} key={WrappedComponent.name} />;
    return WithCoverage;
}

const mapStateToProps = (state: StoreShape) => ({
    ...getCoverageStatusData(state),
    accountId: getAccountId(state),
    carrier: state.coverage.carrier,
    dayRate: getDayRate(state),
    endCoverageTime: getEndCoverageTimestamp(state),
    hasFutureEndorsement: state.policy.hasFutureEndorsement,
    isHydrating: state.api.isHydrating,
    isLapsed: getAccessLevelData(state).isLapsed,
    loading: getLoadingArray(state).includes('coverage:bind'),
    loadingData: isApiLoading(state).ACCOUNT_DASHBOARD,
    naic: state.coverage.naic,
    needsRefillPaused: getNeedsRefillPaused(state),
    policyId: getActivePolicyId(state),
    sessionId: state.coverage.id
});

export default compose(connect(mapStateToProps), withCoverage);
