// Actions handling API calls
// (async thunks for making API calls and actions reflecting state of network request).

import { stringify } from 'querystring';

import Cookies from 'js-cookie';
import { SupportTicketType } from '@popularlab/enums';

import { actions, alerts, apiPaths, copy, errorCodes } from '../constants';
import { fetchPromise } from '../helpers/fetch';
import { isObjectEmpty } from '../helpers/value-checkers';
import { toCamelCase } from '../helpers/formatters';
import { GiftCardTransferRequestResult } from '../ui/views/Rewards/types';
import { get } from '../helpers/misc';
import { checkForAlerts, mapApiAccountData, mapApiDashboardData } from './app';
import {
    saveAccountId,
    setAvailablePackages,
    setPhone,
    updateOpenTickets,
    savePaymentHistory,
    saveRegistrationProtectionPref,
    updateRewards,
    updateCoverageProposal,
    resetCoverageProposal,
    saveBalanceData,
    setUnpaidFeeTotal,
    setPendingStateFilingRequest
} from './data';
import { resetEndorsement } from './endorsement';
import {
    setFlags,
    setSessionChecked,
    showApiFailModal,
    showErrorModal,
    showModal,
    setAnnouncement
} from './ui';
import { saveQuoteInsurify } from './signup';

// Start of API call
export const apiStart = (url) => ({ type: actions.API_START, url });

// Error returned from failed API call
export const apiError = (error) => ({ type: actions.API_ERROR, error });

// End of API call
export const apiEnd = (url) => ({ type: actions.API_END, url });

// Canceled API call
export const apiCancel = () => ({ type: actions.API_CANCEL });

export const setHydrationStatus = (status) => ({ type: actions.HYDRATION_STATUS, payload: status });

export const setApiVersion = (version) => ({
    type: actions.SET_API_VERSION,
    payload: version
});

// Thunk wrapper for making API calls
// @param {Object} data - data required for making fetch req. in following format
// @param {String} data.url - Absolute API url
// @param {Object} data.data - set as options.body
// @param {Function} data.onSuccess - dispatches on successful req.
// @param {Function} data.onFailure - dispatches on failed req.
// @param {String} data.label - optional label to replace api path as start/stop identifier
// @param {Any} window.fetch options
// @returns {Promise} resolution of POST req. (resolved as response or rejected as error)
export const apiCall = ({
    url = '',
    data = null,
    onSuccess = null,
    onFailure = null,
    label = null,
    ...rest
}) => {
    return (dispatch) => {
        // Remove query string from GET req.
        const queryId = label || url.split('?')[0];

        dispatch(apiStart(queryId));
        return fetchPromise(url, { ...rest }, data)
            .then((response) => {
                if (response.version) {
                    dispatch(setApiVersion(response.version));
                }

                const promise = onSuccess
                    ? Promise.resolve(dispatch(onSuccess(response)))
                    : Promise.resolve();

                return promise.then(() => {
                    if (response.announcement) {
                        dispatch(setAnnouncement(response.announcement));
                    }

                    if (response.notifications && response.notifications.length) {
                        dispatch(
                            showModal(alerts.API_NOTIFICATIONS, {
                                notifications: response.notifications
                            })
                        );
                    }

                    if (response.rewards && response.rewards.length) {
                        dispatch(updateRewards(response.rewards));
                        // Temp. disable rewards alert
                        // https://github.com/popularlab/product/issues/3888
                        // dispatch(
                        //     showModal(alerts.REWARD_EARNED, {
                        //         rewards: response.rewards
                        //     })
                        // );
                    }

                    return response;
                });
            })
            .catch((error) => {
                if (error.version) {
                    dispatch(setApiVersion(error.version));
                }

                dispatch(apiError(error));
                if (onFailure) {
                    const failureAction = Boolean(onFailure(error));
                    if (failureAction) {
                        dispatch(onFailure(error));
                    }
                }
                if (error.announcement) {
                    dispatch(setAnnouncement(error.announcement));
                }
                throw error;
            })
            .finally(() => dispatch(apiEnd(queryId)));
    };
};

// Thunk wrapper for aborting API call.
// @param {String} Error message to display in console.
// @returns {Promise} Rejected promise to be chained further.
export const apiAbort = (message) => {
    console.warn(message);
    return (dispatch) => {
        dispatch(apiCancel());
        return Promise.reject(message);
    };
};

// Takes phone number and sends token via SMS.
// @param {String} Phone number strip of any special chars.
// @returns {Promise} resolution of POST req.
const sendSmsTokenExpectedErrors = [
    errorCodes.PHONE_NUMBER_IS_LANDLINE,
    errorCodes.PHONE_NUMBER_INVALID
];
export const sendSmsToken = (phoneNumber) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SEND_SMS_TOKEN,
        data: { phoneNumber },
        onFailure: (error) => {
            if (sendSmsTokenExpectedErrors.includes(error.error)) {
                return false;
            }

            return showApiFailModal();
        }
    });

export const sendEmailLoginLink = (email) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SEND_EMAIL_LOGIN_LINK,
        data: { email }
    });

export const getBalance = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.BALANCE,
        onSuccess: (result) =>
            saveBalanceData({
                balance: result.data,
                overdrawnBalance: result.data < 0 ? Math.abs(result.data) : 0
            })
    });

/**
 * Returns user data from backend.
 * @returns {Promise} resolution of GET req.
 */
export const getAccountData = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.ACCOUNT,
        onSuccess: (result) => [
            mapApiAccountData(result.data),
            saveAccountId(result.data.id),
            getBalance()
        ]
    });

/**
 * Returns all user-related data from backend (account, policies etc).
 * @returns {Promise} resolution of GET req.
 */
export const getDashboardData = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.ACCOUNT_DASHBOARD,
        onSuccess: (result) => [
            mapApiDashboardData(result.data),
            saveAccountId(result.data.account.id)
        ]
    });

export const logOut = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.ACCOUNT_LOGOUT,
        onSuccess: () => [{ type: actions.LOG_OUT }, setSessionChecked()],
        onFailure: () => [{ type: actions.LOG_OUT }, setSessionChecked()]
    });

/**
 * Verifies and updates phone number
 * @param {String} code SMS Code used to verify phone number
 * @param {String} phoneNumber Phone number to verify
 */
export const verifyPhone = (code, phoneNumber) =>
    apiCall({
        method: 'PUT',
        url: apiPaths.VERIFY_PHONE,
        data: { code, phoneNumber },
        onSuccess: () => [setPhone(phoneNumber), checkForAlerts()],
        onFailure: (error) => {
            if (
                error.error === errorCodes.SMS_TOKEN_MISMATCH ||
                error.error === errorCodes.PHONE_NUMBER_IS_ALREADY_IN_USE
            ) {
                return false;
            }

            if (error.error === errorCodes.ACCOUNT_NOT_FOUND) {
                const loginError = new Error();
                loginError.error = errorCodes.ACCOUNT_NOT_FOUND_SMS;
                throw loginError;
            }

            return showApiFailModal();
        }
    });

// Sends verification mail to provided address.
// @param {String} User email address
// @returns {Promise} Resolution of POST req.
export const startEmailVerification = (email) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SEND_VERIFICATION_EMAIL,
        data: { email },
        onSuccess: () => checkForAlerts(),
        onFailure: (error) => {
            if (error.error === errorCodes.RATE_LIMIT) {
                const message = copy.formErrors.api[errorCodes.RATE_LIMIT](email);
                return showErrorModal({ message });
            }
            return showApiFailModal();
        }
    });

/**
 * Submits the token to the API to verify an email address
 * @param {String} token The JWT used to verify the account's email address
 */
export const verifyEmailToken = (token) =>
    apiCall({
        method: 'POST',
        url: apiPaths.VERIFY_EMAIL,
        data: { token },
        onSuccess: ({ data }) => [mapApiAccountData(data), checkForAlerts()],
        onFailure: (error) => {
            if (error.error === errorCodes.EMAIL_VERIFICATION_EXPIRED) {
                const message = copy.formErrors.api[errorCodes.EMAIL_VERIFICATION_EXPIRED];
                return showErrorModal({ message });
            }

            if (error.error === errorCodes.ACCOUNT_ALREADY_EXISTS) {
                // Handled by the caller
                return;
            }

            return showApiFailModal();
        }
    });

/**
 * Fetches the available packages for purchase for the account's active policy
 * @return {Promise<{
 *   data: {
 *      chargeOptions: [{
 *          days: number,
 *          cost: number
 *      }]
 *   }
 * }>}
 */
export const getAvailablePackages = (policyId) =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_AVAILABLE_PACKAGES(policyId),
        onSuccess: (response) => [
            setAvailablePackages(response.data.chargeOptions),
            setUnpaidFeeTotal(response.data.unpaidFeeTotal)
        ]
    });

/**
 * Confirms the SetupIntent session when a member adds a new payment method
 * @param {Object} params
 * @returns {Promise} Card
 */
export const confirmStripeSetupIntent = ({ clientSecret, id }) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.CONFIRM_STRIPE_PAYMENT_SETUP,
        data: {
            clientSecret,
            id
        }
    });
};

// Retuns list of open CS tickets
export const getTickets = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_TICKETS,
        onSuccess: (result) => updateOpenTickets(result.data)
    });

// Submit request to update policy details
// @param {Number} policyId
// @param {String} user-submitted message
// @param {String?} ticket type
export const requestPolicyUpdate = (policyId, comment, ticketType) =>
    apiCall({
        method: 'POST',
        url: apiPaths.UPDATE_POLICY,
        data: {
            policyId,
            comment,
            ticketType: ticketType || SupportTicketType.POLICY_UPDATE
        },
        onSuccess: getTickets,
        onFailure: showApiFailModal
    });

// Request return funds in Hugo account back to the user.
export const requestWithdrawFunds = (amount, useGiftCard = false) =>
    apiCall({
        method: 'POST',
        url: apiPaths.RETURN_FUNDS,
        data: {
            amount,
            useGiftCard,
            ticketType: SupportTicketType.WITHDRAWAL_REQUEST
        },
        onSuccess: getTickets,
        onFailure: showApiFailModal
    });

/**
 * @typedef RefundInfo
 * @type {Object}
 * @property {Number} currentBalance The current balance for the account
 * @property {Number} requiredAmount The amount required to be in the account after refund
 * @property {Number} refundableAmount The maximum amount that can be refunded
 * @property {Boolean} hasPendingRefunds Whether a refund/withdrawal is already pending
 */

/**
 * @returns {Promise<{ data: RefundInfo }>}
 */
export const getRefundInfo = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.REFUND_INFO
    });

/**
 * @typedef RefundMoneyRequest
 * @type {Object}
 * @property {Number} amount The amount to refund
 * @property {Number} expectedBalance The expected balance after the refund
 * @property {Date | null} triggerDate Trigger date shown to member, confirmed on server side
 * @property {String} idempotencyKey Idempotency key for this request
 * @property {import('../helpers/misc').Screenshot | undefined} screenshot Screenshot of action taken
 */

/**
 * @typedef RefundResult
 * @type {Object}
 * @property {Number} balance The updated balance
 * @property {Number} refunded The amount that was actually refunded
 * @property {Date | null} scheduledOffDate The updated scheduled off date
 */

/**
 * Refunds money from Hugo account to card on file
 * @param {RefundMoneyRequest} data
 * @returns {Promise<{ data: RefundResult }>}
 */
export const refundMoney = (data) => {
    return apiCall({
        data,
        method: 'POST',
        url: apiPaths.REFUND_BALANCE,
        onFailure: showApiFailModal
    });
};

/**
 * @typedef CardsData list of cards that will be used for refund and amounts added on each
 * @type {Array}
 * @property {String} paymentCardString
 * @property {String} paymentCardExpDate
 * @property {Number} refundAmount
 */

/**
 * @property {Number} amount amount asked to be refunded
 * @returns {Promise<{ data: { cards: CardsData, scheduledOffDate: string | null }>}
 */
export const getRefundCardsData = (amount) => {
    return apiCall({
        method: 'POST',
        data: {
            amount
        },
        url: apiPaths.REFUND_INFO
    });
};

/**
 * Generates the policy number for the quote application for use in ESig contract
 */
export const preBindQuoteApplication = () => {
    return apiCall({
        method: 'PUT',
        url: apiPaths.PRE_BIND_QUOTE_APPLICATION,
        label: 'quoteFlow:preBindQuoteApplication',
        onFailure: (error) => {
            if (
                error.error === errorCodes.QUOTE_APPLICATION_DUPLICATE_COVERAGE ||
                error.error === errorCodes.QUOTE_APPLICATION_DUPLICATE_DRIVER_COVERAGE ||
                error.error === errorCodes.QUOTE_APPLICATION_DUPLICATE_VEHICLE_COVERAGE
            ) {
                const violation =
                    error.violations && error.violations.length > 0
                        ? error.violations[0]
                        : { type: 'vin' };

                if ((violation.type || '').toLowerCase() !== 'vin') {
                    return showModal(alerts.QUOTE_ALREADY_BOUND);
                }

                const message =
                    copy.formErrors.api[errorCodes.QUOTE_APPLICATION_DUPLICATE_COVERAGE].vehicle;
                return showErrorModal({
                    message
                });
            }

            return showApiFailModal();
        }
    });
};

/**
 * @typedef PolicyProcessingInfo
 * @type {Object}
 * @property {Boolean} isProcessing Is the endorsement disabled because the policy is being processed
 * @property {Boolean} isSubscribed Is the user subscribed to be notified that endorsements are available
 * @property {Boolean} canSubscribe Can the user subscribe to be notified that endorsements will be available in the future.
 */

/**
 * Gets policy processing info for the given user and their active policy
 * @param {Number} policyId
 * @returns {Promise<{ data: PolicyProcessingInfo }>}
 */
export const getPolicyProcessing = (policyId) =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_POLICY_PROCESSING(policyId)
    });

export const subscribeToPolicyProcessedNotification = (policyId) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SUBSCRIBE_POLICY_PROCESSED(policyId)
    });

export const createQuoteApplication = (data) => {
    const expectedErrors = [
        errorCodes.ADDRESS_INVALID_ZIP_CODE_ERROR,
        errorCodes.QUOTE_ALREADY_BOUND,
        errorCodes.QUOTE_UNSUPPORTED_GEO_ERROR,
        errorCodes.QUOTE_UNSUPPORTED_ZIP_CODE_ERROR,
        errorCodes.QUOTE_APPLICATION_CLOSED_FOR_BUSINESS_ERROR
    ];

    return apiCall({
        method: 'POST',
        url: apiPaths.CREATE_QUOTE_APPLICATION,
        data,
        onFailure: (error) => {
            if (expectedErrors.includes(error.error)) {
                return false;
            }

            return showApiFailModal();
        },
        label: 'quoteFlow:createQuoteApplication'
    });
};

// Update quote flow status and get next step id
const ACCEPTABLE_UPDATE_QUOTE_ERRORS = [
    errorCodes.POLK_VEHICLE_REPORT_ERROR,
    errorCodes.QUOTE_APPLICATION_DUPLICATE_COVERAGE,
    errorCodes.QUOTE_APPLICATION_DUPLICATE_DRIVER_COVERAGE,
    errorCodes.QUOTE_APPLICATION_DUPLICATE_VEHICLE_COVERAGE,
    errorCodes.QUOTE_APPLICATION_SERVICE_ERROR,
    errorCodes.QUOTE_APPLICATION_VALIDATION_ERROR,
    errorCodes.SAMBA_DOB_MISMATCH_ERROR,
    errorCodes.SAMBA_DRIVER_NOT_FOUND_ERROR,
    errorCodes.SAMBA_INVALID_LICENSE_ERROR,
    errorCodes.SAMBA_LICENSE_SUSPENDED_ERROR,
    errorCodes.SAMBA_NAME_MISMATCH_ERROR,
    errorCodes.SAMBA_LICENSE_MISMATCH_ERROR,
    errorCodes.TRITECH_ADDRESS_ERROR,
    errorCodes.TU_DRIVER_RISK_INCORRECT_DL_FORMAT
];

export const patchQuoteApplication = (quoteId, data = {}, isSuspicious = false) => {
    if (isObjectEmpty(data)) {
        return () => Promise.resolve({});
    }

    return apiCall({
        method: 'PATCH',
        url: apiPaths.UPDATE_QUOTE_APPLICATION,
        data,
        headers: {
            'x-sus': isSuspicious ? 1 : 0,
            'x-quoteapplication-id': quoteId
        },
        onFailure: (error) => {
            if (ACCEPTABLE_UPDATE_QUOTE_ERRORS.includes(error.error)) {
                return false;
            }

            return showApiFailModal();
        },
        label: 'quoteFlow:updateQuoteApplication'
    });
};

export const resetQuoteApplication = () => {
    return apiCall({
        method: 'DELETE',
        url: apiPaths.DELETE_QUOTE_APPLICATION,
        onFailure: showApiFailModal,
        label: 'quoteFlow:resetQuoteApplication'
    });
};

export const deleteQuoteApplicationItem = (path) => {
    return apiCall({
        method: 'DELETE',
        url: apiPaths.UPDATE_QUOTE_APPLICATION,
        data: { path },
        onFailure: (error) => {
            if (ACCEPTABLE_UPDATE_QUOTE_ERRORS.includes(error.error)) {
                return false;
            }

            return showApiFailModal();
        },
        label: 'quoteFlow:deleteQuoteApplicationItem'
    });
};

export const calculateQuoteForPolicy = ({
    policyCoverageOptions,
    vehicleCoverageOptions,
    policyType
}) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.QUOTE_CALCULATOR,
        data: {
            policyCoverageOptions,
            vehicleCoverageOptions,
            policyType
        },
        onFailure: showApiFailModal,
        label: 'quoteFlow:calculateQuoteForPolicy'
    });
};

export const calculateQuoteForEndorsement = ({ policyId, effectiveDate, endorsement }) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.ENDORSEMENT_QUOTE_CALCULATOR,
        data: {
            policyId,
            effectiveDate,
            endorsement
        },
        onFailure: showApiFailModal,
        label: 'endorsement:calculateQuoteForEndorsement'
    });
};

export const startEsigForEndorsement = ({ policyId, endorsement }) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.ENDORSEMENT_START_ESIG,
        data: {
            policyId,
            endorsement
        },
        onFailure: showApiFailModal,
        label: 'endorsement:startEsig'
    });
};

export const sendRtqMessage = (shouldBeScheduled = false) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.SEND_RTQ_MESSAGE,
        data: {
            shouldBeScheduled
        },
        label: 'quoteFlow:sendRtqMessage',
        onFailure: showApiFailModal
    });
};

// Get id for current step in quote flow
export const getQuoteStatus = (token) =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_QUOTE_APPLICATION + (token ? `?token=${token}` : ''),
        onFailure: (error) => {
            if (error.error === errorCodes.QUOTE_APPLICATION_NOT_FOUND) {
                // Do nothing, this is expected when starting a new application
                // or if the user has cleared their cookies
                return false;
            }

            if (error.error === errorCodes.QUOTE_APPLICATION_UNAUTHORIZED) {
                return showModal(alerts.QUOTE_APPLICATION_UNAUTHORIZED);
            }

            if (error.error === errorCodes.QUOTE_APPLICATION_EXPIRED) {
                return showModal(alerts.QUOTE_APPLICATION_EXPIRED);
            }

            if (error.status === 401) {
                throw error;
            }

            return showApiFailModal();
        },
        label: 'quoteFlow:getQuoteStatus'
    });

export const getQuoteInsurify = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_QUOTE_APPLICATION_LEAD_INSURIFY,
        onSuccess: ({ data }) => saveQuoteInsurify(data)
    });

export const getQuoteInsurifyForAccount = () =>
    apiCall({
        method: 'GET',
        url: apiPaths.GET_ACCOUNT_LEAD_INSURIFY,
        onSuccess: ({ data }) => saveQuoteInsurify(data)
    });

// validates a shortcode, returns a ShortCodeStatus
export const validateShortCode = (shortCode) =>
    apiCall({
        method: 'POST',
        url: apiPaths.VALIDATE_SHORTCODE,
        data: { shortCode },
        label: 'quoteFlow:validateShortCode'
    });

// stores a marketing touchpoint
export const addMarketingTouchpoint = (queryParams, referrer) =>
    apiCall({
        method: 'POST',
        url: apiPaths.ANALYTICS_MARKETING_TOUCHPOINT,
        data: { queryParams, referrer },
        label: 'analytics:marketingTouchpoint'
    });

export const resumeQuoteApplication = (shortCode, lastName, zipCode, dateOfBirth) =>
    apiCall({
        method: 'POST',
        url: apiPaths.RESUME_QUOTE_APPLICATION,
        data: { shortCode, lastName, zipCode, dateOfBirth },
        label: 'quoteFlow:resumeQuoteApplication'
    });

// For provided address, hit Google Places to validate
// or return possible suggestion.
export const validateAddress = (formData) =>
    apiCall({
        method: 'GET',
        url: `${apiPaths.VALIDATE_ADDRESS}?${stringify(formData)}`,
        onFailure: (error) => {
            if (error.error === errorCodes.ADDRESS_VALIDATION_ERROR) {
                return false;
            }

            return showApiFailModal();
        }
    });

// For provided VIN, returns vehicle make, model and year
export const validateVin = (vin, apiPath) =>
    apiCall({
        method: 'GET',
        url: `${apiPath}/${vin}`,
        onFailure: (error) => {
            if (
                error.error === errorCodes.POLK_VEHICLE_REPORT_ERROR ||
                error.error === errorCodes.POLK_VEHICLE_REPORT_VIN_DECODE_ERROR ||
                error.error === errorCodes.POLK_VEHICLE_REPORT_MMY_COUNTRY_CODE_ERROR ||
                error.error === errorCodes.POLK_VEHICLE_REPORT_VIN_COUNTRY_CODE_ERROR ||
                error.error === errorCodes.QUOTE_APPLICATION_SERVICE_ERROR
            ) {
                return false;
            }

            return showApiFailModal();
        }
    });

export const quoteValidateVin = (vin) => validateVin(vin, apiPaths.VALIDATE_VIN);

export const endorsementValidateVin = (vin) => validateVin(vin, apiPaths.ENDORSEMENT_VALIDATE_VIN);

/**
 * Sets the specified flag for the account
 * @param {String} flag The flag to update
 * @param {boolean} isEnabled Whether to mark the flag as enabled (on)
 */
export const setAccountFlag = (flag, isEnabled = true) =>
    apiCall({
        url: `${apiPaths.SET_ACCOUNT_FLAG}/${flag}`,
        method: 'PUT',
        data: {
            isEnabled
        },
        onSuccess: () => {
            return (dispatch) => {
                const flagKey = toCamelCase(flag);
                dispatch(setFlags({ [flagKey]: isEnabled }));
            };
        }
    });

let paramSaved = false;
// multi-touchpoint analytics, this should run as soon we have either a quoteApplicationId,
// or an account ID
export const checkMarketingCookie = () => {
    return (dispatch) => {
        let utmCookie = Cookies.get('utm_tracking');
        if (!utmCookie && window.location.search) {
            utmCookie = btoa(window.location.search);
        }
        let utmReferrer = Cookies.get('utm_referrer');
        if (!utmReferrer && document.referrer) {
            const ref = new URL(document.referrer);
            if (!ref.hostname.includes('withhugo.com')) {
                utmReferrer = ref.hostname;
            }
        }
        if (!paramSaved && (utmCookie || utmReferrer)) {
            dispatch(addMarketingTouchpoint(utmCookie, utmReferrer))
                .then(({ data }) => {
                    if (data.saved) {
                        Cookies.remove('utm_tracking', { domain: 'withhugo.com' });
                        Cookies.remove('utm_referrer', { domain: 'withhugo.com' });
                        paramSaved = true;
                    }
                })
                .catch((err) => {
                    if (err === errorCodes.MISSING_ENDPOINT) {
                        return;
                    }
                    throw err;
                });
        }
    };
};

/**
 * Hit API for fresh tokens.
 * @param  {Number} accountId
 * @returns {Promise} resolution of POST req.
 */
export const refreshSession = (accountId) => {
    if (!accountId) {
        return apiAbort('Clean-slate visit. Ignore errors.');
    }

    return apiCall({
        method: 'POST',
        url: apiPaths.REFRESH_LOGIN,
        data: { accountId }
    });
};

/**
 * Thunk that combines all requests needed to get user data from API.
 * @return {AnyAction} resolution of all requests.
 */
export const hydrateData = () => {
    return (dispatch) => {
        dispatch(setHydrationStatus(true));
        // Fire off user data first to save following requests if it fails.
        return dispatch(getDashboardData()).finally(() => {
            dispatch(checkForAlerts());
            dispatch(checkMarketingCookie());
            // Make sure this is very last event dispatched after
            // all other possible dispatch(hydrateData()).finally calls.
            setTimeout(() => dispatch(setHydrationStatus(false)), 0);
        });
    };
};

/**
 * @typedef AccountData
 * @type {Object}
 * @property {String} [firstName]
 * @property {String} [lastName]
 * @property {String} [dateOfBirth]
 * @property {String} [googleToken] Token returned from Google Auth
 * @property {String} [code] SMS code sent to a phone number
 * @property {String|Number} [phoneNumber]
 * @property {String} [signupCode]
 */

/**
 * Posts the supplied data to the account creation endpoint
 * @param {AccountData} data Data to create account with
 * @returns {Promise} resolution of POST req.
 */
export const createAccount = (data) =>
    apiCall({
        data,
        method: 'POST',
        url: apiPaths.ACCOUNT_CREATE,
        onFailure: (error) => {
            if (error.error === errorCodes.ACCOUNT_ALREADY_EXISTS) {
                return showErrorModal({
                    message: copy.formErrors.api[errorCodes.ACCOUNT_ALREADY_EXISTS]
                });
            }

            if (error.error === errorCodes.SMS_TOKEN_MISMATCH) {
                return false;
            }

            return showApiFailModal();
        }
    });

/**
 * Creates or logs in an account and links it to a quote application
 * @param {AccountData} data Data to create account with (or log an account in)
 * @returns {Promise} resolution of POST req.
 */
export const linkAccountToQuoteApplication = (data) =>
    apiCall({
        method: 'POST',
        url: apiPaths.LINK_ACCOUNT_QUOTE_APPLICATION,
        data,
        // On successful registration inside quote flow,
        // we want contact method (phone or email) available in redux state.
        onSuccess: getAccountData,
        onFailure: (error) => {
            if (error.error === errorCodes.SMS_TOKEN_MISMATCH) {
                return false;
            }

            return showApiFailModal();
        }
    });

/**
 * Submits the supplied googleToken parameter to attempt to log the current user
 * in
 * @param {String} googleToken Token received from Google Signin
 */
export const loginWithGoogle = (googleToken) =>
    apiCall({
        method: 'POST',
        url: apiPaths.ACCOUNT_LOGIN_GOOGLE,
        data: { googleToken },
        onSuccess: hydrateData,
        onFailure: (error) => {
            if (error.error === errorCodes.ACCOUNT_NOT_FOUND) {
                return showErrorModal({
                    message: copy.formErrors.api[errorCodes.ACCOUNT_NOT_FOUND_GOOGLE]
                });
            }

            return showApiFailModal();
        }
    });

/**
 * Submits the supplied googleToken parameter to authenticate Google with this
 * account. Optionally sets the users email address
 * @param {String} googleToken Token received from Google Signin
 * @param {Boolean} updateEmailFromGoogle If true, overwrites the current email address with the one from Google
 */
export const addGoogleLoginMethod = (googleToken, updateEmailFromGoogle = false) =>
    apiCall({
        method: 'POST',
        url: apiPaths.ACCOUNT_ADD_GOOGLE_AUTH,
        data: { googleToken, setEmail: updateEmailFromGoogle },
        onSuccess: hydrateData,
        onFailure: (error) => {
            if (error.error === errorCodes.ACCOUNT_ALREADY_EXISTS) {
                // Handled by the caller
                return;
            }
            return showApiFailModal();
        }
    });

// Takes SMS token and phone number and validates for exiting account.
// @param {String} phone number strip of any special chars
// @param {String} confirmation code recieved on provided phone number via SMS
// @returns {Promise} resolution of POST req.
export const loginWithPhone = (phoneNumber, code) =>
    apiCall({
        method: 'POST',
        url: apiPaths.ACCOUNT_LOGIN_PHONE,
        data: { phoneNumber, code },
        onSuccess: hydrateData,
        onFailure: (error) => {
            if (error.error === errorCodes.SMS_TOKEN_MISMATCH) {
                return false;
            }

            if (error.error === errorCodes.ACCOUNT_NOT_FOUND) {
                const loginError = new Error();
                loginError.error = errorCodes.ACCOUNT_NOT_FOUND_SMS;
                throw loginError;
            }

            return showApiFailModal();
        }
    });

/**
 * @return {Promise<{
 *   data: PaymentHistoryData
 * }>}
 */
export const getPaymentHistory = (offset = 0) =>
    apiCall({
        method: 'GET',
        url: `${apiPaths.GET_PAYMENT_HISTORY}?offset=${offset}`,
        onSuccess: ({ data }) => savePaymentHistory(data),
        onFailure: showApiFailModal
    });

export const getRegistrationProtectionPreference = () =>
    apiCall({
        method: 'GET',
        url: `${apiPaths.REGISTRATION_PROTECTION_PREF}`,
        onSuccess: ({ data }) => saveRegistrationProtectionPref(data),
        onFailure: showApiFailModal
    });

export const putRegistrationProtectionPreference = (isEnabled) =>
    apiCall({
        method: 'PUT',
        url: `${apiPaths.REGISTRATION_PROTECTION_PREF}`,
        data: {
            isEnabled
        },
        onSuccess: ({ data }) => saveRegistrationProtectionPref(data),
        onFailure: showApiFailModal
    });

/**
 * Transfers the specified reward balance amount to the user's main Hugo balance
 * @param {number} balanceToTransfer Amount of reward balance to transfer
 */
export const transferRewardsToHugoBalance = (balanceToTransfer) => {
    if (!balanceToTransfer) {
        return apiAbort('Invalid reward balance amount specified to transfer');
    }

    return apiCall({
        method: 'POST',
        url: apiPaths.TRANSFER_REWARD_BALANCE_TO_HUGO_BALANCE,
        data: {
            rewardBalance: balanceToTransfer
        }
    });
};

/**
 * Transfers the specified reward balance amount to an Amazon giftcard
 * @param {number} balanceToTransfer Amount of reward balance to transfer
 * @param {string} requestId Idempotency key for withdrawal request
 */
export const transferRewardsToAmazonGiftCard = (balanceToTransfer, requestId) => {
    if (!balanceToTransfer) {
        return apiAbort('Invalid reward balance amount specified to transfer');
    }

    return apiCall({
        method: 'POST',
        url: apiPaths.TRANSFER_REWARD_BALANCE_TO_AMAZON_GIFT_CARD,
        data: {
            requestId,
            rewardBalance: balanceToTransfer
        },
        onSuccess: ({ data }) => {
            if (data === GiftCardTransferRequestResult.TICKET_CREATED) {
                return getTickets();
            }
            return [];
        }
    });
};

/**
 * Terminates the existing policy, binds to the newly created rewrite quote application.
 * Also automatically turns on coverage and updates AR prefrences.
 * @param {Number=} policyId
 */
export const bindRewriteQuoteApplication = (policyId) => {
    if (!policyId) {
        return apiAbort('Invalid policyId');
    }

    return apiCall({
        method: 'PUT',
        url: apiPaths.REWRITE(policyId),
        onSuccess: hydrateData,
        label: 'rewrite:bind'
    });
};

/**
 * @param {import('../ui/proposal.types.ts').CoverageProposalParams} data
 * @returns {Promise} resolution of POST req.
 */
export const createCoverageProposal = ({
    policyId,
    targetCoverageStatus,
    chargeAmount,
    excessBalance,
    autoreload,
    reloadDays,
    days,
    rewardsAmount,
    forceError,
    coverageExtension,
    location,
    radarSession
}) => {
    if (!policyId) {
        return apiAbort('Invalid policyId');
    }

    const data = {
        autoreload,
        reloadDays,
        chargeAmount,
        excessBalance,
        days,
        targetCoverageStatus,
        coverageExtension,
        rewardsAmount: rewardsAmount || undefined,
        location,
        radarSession
    };

    if (process.env.HUGO_ENV !== 'production') {
        data.forceError = forceError;
    }

    return apiCall({
        method: 'POST',
        data,
        url: apiPaths.COVERAGE_PROPOSAL(policyId),
        label: 'coverage:proposal',
        onSuccess: ({ data: updatedProposalData }) => updateCoverageProposal(updatedProposalData),
        onFailure: ({ error, data: updatedProposalData }) => {
            // Errors handled at AR and coverage modal toggles,
            // these should not happen on checkout. If they do,
            // they will result with coverageProposalId error on bind.
            // Hydrating client should pull in correct day rate
            // update checkout view in place and trigger new proposal
            if (
                error === errorCodes.INSUFFICIENT_BALANCE ||
                error === errorCodes.AR_INSUFFICIENT_BALANCE ||
                error === errorCodes.REQUIRES_MORE_INFO ||
                error === errorCodes.RATE_CHANGE
            ) {
                return [hydrateData(), updateCoverageProposal(updatedProposalData)];
            }

            if (error === errorCodes.POLICY_NOT_ACTIVE) {
                return [hydrateData()];
            }

            if (error === errorCodes.REFRESH_REQUIRED) {
                return false;
            }

            return showApiFailModal();
        }
    });
};

export const bindCoverageProposal = (
    policyId,
    coverageProposalId,
    stripeIdempotencyKey,
    consentForFallbackOff,
    screenshot
) => {
    const data = {
        coverageProposalId,
        stripeIdempotencyKey,
        consentForFallbackOff,
        screenshot
    };

    return apiCall({
        method: 'POST',
        data,
        url: apiPaths.COVERAGE_BIND(policyId),
        label: 'coverage:bind',
        onSuccess: () => (dispatch) => {
            return dispatch(hydrateData()).then(() => {
                dispatch(resetCoverageProposal());
            });
        }
    });
};

/**
 * @param {import('../ui/proposal.types.ts').BindProposalParams} data
 * @returns {Promise} resolution of POST req.
 */
export const createBindProposal = ({
    quoteApplicationId,
    chargeAmount,
    autoreload,
    reloadDays,
    excessBalance,
    days,
    rewardsAmount,
    location,
    radarSession
}) => {
    return apiCall({
        method: 'POST',
        data: {
            quoteApplicationId,
            autoreload,
            reloadDays,
            chargeAmount,
            excessBalance,
            days,
            rewardsAmount: rewardsAmount || undefined,
            location,
            radarSession
        },
        url: apiPaths.BIND_PROPOSAL,
        label: 'quoteFlow:createBindProposal',
        onSuccess: ({ data }) => updateCoverageProposal(data),
        onFailure: ({ error, data }) => {
            // Checkout error due to day rate change.
            // Hydrating client should pull in correct day rate
            // update checkout view in place and trigger new proposal
            if (error === errorCodes.RATE_CHANGE) {
                return [hydrateData(), updateCoverageProposal(data)];
            }
            if (error === errorCodes.REFRESH_REQUIRED) {
                return false;
            }

            return showApiFailModal();
        }
    });
};

/**
 * Finalizes a quote application, with given bind proposal
 * @param {String} stripeIdempotencyKey UUID key representing this request (send the same one always unless an error
 * @param {Boolean} consentForFallbackOff Indicates the member saw the fallback off modal and consented to it
 * @param {import('../helpers/misc').Screenshot | undefined} screenshot Screenshot at the time of bind click
 */
export const bindQuoteApplication = (
    bindProposalId,
    stripeIdempotencyKey,
    consentForFallbackOff,
    screenshot
) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.BIND_QUOTE_APPLICATION,
        data: {
            bindProposalId,
            stripeIdempotencyKey,
            consentForFallbackOff,
            screenshot
        },
        label: 'quoteFlow:bindQuoteApplication'
    });
};

export const getEndorsementPolicyData = () => {
    return apiCall({
        method: 'POST',
        url: apiPaths.GET_ENDORSEMENT_POLICY_DATA,
        onFailure: (error) => {
            if (error.error === errorCodes.ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION) {
                const message =
                    copy.formErrors.api[errorCodes.ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION];
                return showErrorModal({ message });
            }
            if (error.error === errorCodes.REFRESH_REQUIRED) {
                return false;
            }
            return showApiFailModal();
        },
        label: 'endorsement:getEndorsementPolicyData'
    });
};

// these errors are handled in views directly
const ACCEPTABLE_UPDATE_ENDORSEMENT_ERRORS = [
    errorCodes.ACCEPTANCE_ENDORSER_INELIGIBLE_VEHICLE_ERROR,
    errorCodes.ADDRESS_HOME_CMRA_ERROR,
    errorCodes.ADDRESS_HOME_COMMERCIAL_ERROR,
    errorCodes.ADDRESS_HOME_NOT_ALLOWED_ERROR,
    errorCodes.ADDRESS_HOME_POBOX_ERROR,
    errorCodes.ADDRESS_PARKING_CMRA_ERROR,
    errorCodes.ADDRESS_PARKING_COMMERCIAL_ERROR,
    errorCodes.ADDRESS_PARKING_NOT_ALLOWED_ERROR,
    errorCodes.ADDRESS_PARKING_POBOX_ERROR,
    errorCodes.ADDRESS_VALIDATION_ERROR,
    errorCodes.ENDORSEMENT_BLACKBOARD_SUBMISSION,
    errorCodes.ENDORSEMENT_DUPLICATE_VEHICLE,
    errorCodes.ENDORSEMENT_PROHIBITED_VEHICLE,
    errorCodes.SAMBA_DOB_MISMATCH_ERROR,
    errorCodes.SAMBA_DRIVER_NOT_FOUND_ERROR,
    errorCodes.SAMBA_INVALID_LICENSE_ERROR,
    errorCodes.SAMBA_LICENSE_MISMATCH_ERROR,
    errorCodes.SAMBA_NAME_MISMATCH_ERROR,
    errorCodes.SAMBA_SYSTEM_IS_DOWN_ERROR,
    errorCodes.TRITECH_ADDRESS_ERROR,
    errorCodes.TU_DRIVER_RISK_INCORRECT_DL_FORMAT
];

export const updateEndorsementApplication = (data = {}) => {
    if (isObjectEmpty(data)) {
        return () => Promise.resolve({});
    }

    return apiCall({
        method: 'PATCH',
        url: apiPaths.UPDATE_ENDORSEMENT,
        data,
        onFailure: (error) => {
            if (ACCEPTABLE_UPDATE_ENDORSEMENT_ERRORS.includes(error.error)) {
                return false;
            }
            if (
                error.error === errorCodes.ENDORSEMENT_SERVICE_UNAVAILABLE ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_CYCLE_IS_ACTIVE_ERROR ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_POLICY_IS_NOT_ACTIVE_ERROR ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_CYCLE_REQUIRED_ERROR ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_INVALID_DATE_ERROR ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_INVALID_PRICE_ERROR ||
                error.error === errorCodes.ACCEPTANCE_ENDORSER_POLICY_IS_IN_USE
            ) {
                return showErrorModal({
                    message: copy.formErrors.api[error.error]
                });
            }
            if (error.error === errorCodes.ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION) {
                const message =
                    copy.formErrors.api[errorCodes.ENDORSEMENT_NO_ACTIVE_COVERAGE_SESSION];
                return [hydrateData(), showErrorModal({ message })];
            }
            if (error.error === errorCodes.ENDORSEMENT_INVALID_EFFECTIVE_DATE) {
                return [hydrateData()];
            }
            if (error.error === errorCodes.REFRESH_REQUIRED) {
                return false;
            }
            if (error.error === errorCodes.ENDORSEMENT_QUOTE_APPLICATION_EXPIRED) {
                const message =
                    copy.formErrors.api[errorCodes.ENDORSEMENT_QUOTE_APPLICATION_EXPIRED];
                return [hydrateData(), showErrorModal({ message }), resetEndorsement()];
            }
            return showApiFailModal();
        },
        label: 'endorsement:updateEndorsementApplication',
        onSuccess: ({ data: updatedEndorsement }) => {
            if (!get(updatedEndorsement, 'checkout.scheduledOffDate')) {
                return [];
            }
            return [
                resetCoverageProposal(),
                updateCoverageProposal({
                    scheduledOffDate: get(updatedEndorsement, 'checkout.scheduledOffDate'),
                    autoReloadTriggerDate: get(updatedEndorsement, 'checkout.autoReloadTriggerDate')
                })
            ];
        }
    });
};

export const bindEndorsement = (data = {}) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.BIND_ENDORSEMENT,
        data,
        label: 'endorsement:bindEndorsement',
        onSuccess: hydrateData
    });
};

/**
 * Terminates the policy, allowing for instant re-bind
 * @param {Number} policyId
 */
export const terminatePolicy = (policyId, screenshot) => {
    return apiCall({
        method: 'POST',
        data: { screenshot },
        label: 'policy:terminate',
        onSuccess: hydrateData,
        url: apiPaths.TERMINATE_POLICY(policyId)
    });
};

export const checkAppVersion = () => {
    return () => {
        return fetch('/index.html', {
            cache: 'no-store',
            method: 'HEAD'
        }).then(
            (res) =>
                res.headers.get('x-amz-meta-codebuild-content-sha256') || res.headers.get('etag')
        );
    };
};

/**
 * Makes an arbitrary payment, mainly used for repaying owed balances while
 * the member has no active policy
 * @param {number} amount The amount to charge
 * @param {number} rewardsAmount Amount of rewards to be applied
 * @param {number} expectedBalance The expected balance after the charge
 * @param {string} idempotencyKey Idempotency key
 * @param {string|undefined} radarSession Stripe Radar session
 */
export const makePayment = (
    amount,
    rewardsAmount,
    expectedBalance,
    idempotencyKey,
    radarSession
) => {
    return apiCall({
        method: 'POST',
        data: {
            amount,
            rewardsAmount,
            expectedBalance,
            idempotencyKey,
            radarSession
        },
        label: 'payment:create',
        url: apiPaths.MAKE_PAYMENT,
        onSuccess: hydrateData
    });
};

/**
 * Exchanges the supplied parameters for a temporary access token (parameters
 * are answers to challenge questions)
 * @param {Object} parameters Validation parameters to exchange for temporary access token
 */
export const getTemporaryAccessToken = (parameters) => {
    return apiCall({
        method: 'POST',
        data: parameters,
        label: 'support:get-temporary-access-token',
        url: apiPaths.SUPPORT_GET_TEMPORARY_ACCESS_TOKEN
    });
};

/**
 * API to update an account's phone number using a short lived temporary access token
 * @param {Object} parameters Validation parameters to exchange for temporary access token
 */
export const updatePhoneWithTemporaryAccessToken = (phone, code, accessToken) => {
    return apiCall({
        method: 'POST',
        data: {
            phone,
            code,
            accessToken
        },
        label: 'support:update-phone',
        url: apiPaths.SUPPORT_UPDATE_PHONE_NUMBER,
        onFailure: (error) => {
            if (
                error.error === errorCodes.SMS_TOKEN_MISMATCH ||
                error.error === errorCodes.PHONE_NUMBER_IS_ALREADY_IN_USE
            ) {
                return false;
            }

            if (error.error === errorCodes.ACCOUNT_NOT_FOUND) {
                const loginError = new Error();
                loginError.error = errorCodes.ACCOUNT_NOT_FOUND_SMS;
                throw loginError;
            }

            return showApiFailModal();
        }
    });
};

export const createWaitlistUser = (email, zipCode) => {
    return apiCall({
        method: 'POST',
        data: {
            email,
            zipCode
        },
        label: 'waitlist:create',
        url: apiPaths.WAITLIST_CREATE_USER
    });
};

export const submitPromoCode = (code, location) => {
    return apiCall({
        method: 'POST',
        url: apiPaths.SUBMIT_CODE,
        data: { code, location },
        onFailure: (error) => {
            if (
                error.error === errorCodes.ACCOUNT_CODE_INVALID ||
                error.error === errorCodes.ACCOUNT_CODE_ALREADY_SUBMITTED
            ) {
                return false;
            }

            return showApiFailModal();
        }
    });
};

export const getProofOfInsuranceAvailable = (policyId) => {
    return apiCall({
        method: 'GET',
        url: apiPaths.PROOF_OF_INSURANCE_AVAILABLE(policyId)
    });
};

/**
 * Creates LOE for passed date
 * @api {POST}
 * @apiParam {String} letterDate
 * @returns {Promise} resolution of POST req.
 */
export const createLetterOfExperience = (letterDate, fullHistory = false) =>
    apiCall({
        method: 'POST',
        url: apiPaths.CREATE_LETTER_OF_EXPERIENCE,
        data: { letterDate, fullHistory }
    });

/** Send LOE to mail or fax
 * @api {POST}
 * @apiParam {String} address
 * @apiParam {String} letterDate
 * @apiParam {Number} letterFileId
 * @apiParam {String} recipientName
 * @returns {Promise} resolution of POST req.
 */
export const sendLetterOfExperience = (address, letterDate, letterFileId, recipientName) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SEND_LETTER_OF_EXPERIENCE,
        data: { address, letterFileId, letterDate, recipientName }
    });

/** Send declarations page to mail or fax
 * @api {POST}
 * @apiParam {String} address
 * @apiParam {String} recipientName
 * @apiParam {Number} policyId
 * @returns {Promise} resolution of POST req.
 */
export const sendDeclarationsPage = (address, recipientName, policyId) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SEND_DECLARATIONS_PAGE(policyId),
        data: { address, recipientName }
    });

/** Submit documents for zendesk state filing ticket
 * @param {Number} accountId
 * @param {*} document
 * @returns {Promise} resolution of POST req.
 */
export const submitDocument = (accountId, document) =>
    apiCall({
        method: 'POST',
        url: apiPaths.SUBMIT_DOCUMENT,
        data: {
            accountId,
            noFile: !document.file,
            ...document
        },
        onSuccess: () => setPendingStateFilingRequest(true),
        hasFiles: true // omit content-type: application/json from headers
    });

/** Submit CS ticket to Zendesk
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {String} phone
 * @param {String} message
 * @param {String} url
 * @param {Array} tags
 * @returns {Promise} resolution of POST req.
 */
export const createTicket = (firstName, lastName, email, phone, message, url, tags) =>
    apiCall({
        method: 'POST',
        url: apiPaths.CREATE_TICKET,
        data: { firstName, lastName, email, phone, message, url, tags }
    });
