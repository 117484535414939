import { h, Fragment } from 'preact';
import { connect } from 'react-redux';
import { FC } from 'preact/compat';

import { getOverdrawnBalance, getLoadingArray, getCoverageStatusData } from '../../selectors';
import { moneyString, dateTimeString } from '../../helpers/formatters';
import { Banner, BannerTitle, BannerContent } from '../components/Banner';

import { StoreShape } from '@/types/store.types';

const ActiveAlert = (props: BannerOverdrawnProps) => {
    return (
        <Fragment>
            <p>
                We’ve kept you insured, but you must repay {moneyString(props.overdrawnBalance)} and
                add money to your account, or your insurance will be cancelled on{' '}
                {dateTimeString(props.cancellationNonpaymentDate || props.scheduledOffDate)}.
            </p>
            <p>Turn your insurance OFF to avoid additional charges.</p>
        </Fragment>
    );
};

const InactiveAlert = (props: BannerOverdrawnProps) => (
    <Fragment>
        <p>
            To restart your insurance, you must repay {moneyString(props.overdrawnBalance)}, which
            we paid to keep you covered after your balance hit 0.
        </p>
    </Fragment>
);

interface BannerOverdrawnProps {
    loading?: boolean;
    isActive?: boolean;
    overdrawnBalance?: number;
    notPaused?: boolean;
    cancellationNonpaymentDate?: Date;
    scheduledOffDate?: string;
}

export const BannerOverdrawnPure: FC<BannerOverdrawnProps> = (props) => {
    // Don't render alert while coverage toggle is in progress.
    if (props.loading) {
        return null;
    }

    return (
        <Banner>
            <BannerTitle>Your account is overdrawn!</BannerTitle>
            <BannerContent>
                {props.isActive ? <ActiveAlert {...props} /> : <InactiveAlert {...props} />}
            </BannerContent>
        </Banner>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    cancellationNonpaymentDate: state.balance.cancellationNonpaymentDate,
    overdrawnBalance: getOverdrawnBalance(state),
    loading: getLoadingArray(state).includes('coverage:bind'),
    isActive: getCoverageStatusData(state).isActive,
    scheduledOffDate: state.payment.scheduledOffDate
});

export default connect(mapStateToProps)(BannerOverdrawnPure);
