import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { getOverdrawnBalance, getLoadingArray } from '../../selectors';
import { moneyString, dateTimeString } from '../../helpers/formatters';
import { Banner, BannerTitle, BannerContent } from '../components/Banner';
import { StoreShape } from '../../types/store.types';

interface BannerCoverageExtensionProps {
    overdrawnBalance?: number;
    loading: boolean;
    scheduledOffDate: Date | null;
}

export const BannerCoverageExtension: FunctionComponent<BannerCoverageExtensionProps> = (props) => {
    // Don't render alert while coverage toggle is in progress.
    if (props.loading) {
        return null;
    }
    return (
        <Banner>
            <BannerTitle>Coverage extension active</BannerTitle>
            <BannerContent>
                Hugo is keeping you covered, but you must repay&nbsp;
                {props.overdrawnBalance && props.overdrawnBalance > 0
                    ? moneyString(props.overdrawnBalance)
                    : 'your balance'}
                &nbsp; by {dateTimeString(props.scheduledOffDate)} or your insurance will turn OFF
                as scheduled.
            </BannerContent>
        </Banner>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    overdrawnBalance: getOverdrawnBalance(state),
    loading: getLoadingArray(state).includes('coverage:bind'),
    scheduledOffDate: state.payment.scheduledOffDate
});

export default connect(mapStateToProps)(BannerCoverageExtension);
