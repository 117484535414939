import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';

import { moneyString } from '../../helpers/formatters';
import { getOverdrawnBalance } from '../../selectors';
import { Banner, BannerTitle, BannerContent } from '../components/Banner';
import { StoreShape } from '../../types/store.types';

export const BannerLapsed: FunctionComponent<{ overdrawnBalance?: number }> = (props) => (
    <Banner>
        <BannerTitle>Your coverage has been cancelled for nonpayment.</BannerTitle>
        <BannerContent>
            <p>
                The DMV will now cancel your vehicle registration. To restart your insurance and
                drive safely, repay {moneyString(props.overdrawnBalance)} and add money to your
                account.
            </p>
        </BannerContent>
    </Banner>
);

const mapStateToProps = (state: StoreShape) => ({
    overdrawnBalance: getOverdrawnBalance(state)
});

export default connect(mapStateToProps)(BannerLapsed);
