import { h } from 'preact';
import { connect } from 'react-redux';

import { logOut } from '../../actions/api';
import { saveQueryStringData } from '../../actions/data';
import { Button, ButtonCta } from '../components/Button';
import { Modal, ModalContent, ModalActions, ModalTitle } from '../components/Modal';

const ModalQuoteUnauthorized = (props) => (
    <Modal>
        <ModalTitle>Oops...</ModalTitle>
        <ModalContent>
            <p className="u-font-bold">
                It looks like you are trying to access a quote application associated with a
                different account.
            </p>
            <p>
                You can either start a new quote application, or log out and then log in to the
                correct account.
            </p>
        </ModalContent>
        <ModalActions>
            <ButtonCta onClick={props.onHide}>Start a new quote application</ButtonCta>
            <Button
                link
                onClick={() => {
                    props.dispatch(
                        saveQueryStringData({
                            origin: `${window.location.pathname}${window.location.search}`
                        })
                    );
                    props.dispatch(logOut());
                }}
            >
                Log out
            </Button>
        </ModalActions>
    </Modal>
);

export default connect()(ModalQuoteUnauthorized);
