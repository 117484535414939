// Boilerplate code for setting up Redux store.

import { createStore, applyMiddleware, compose } from 'redux';
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares';

import createReducer from './reducers';
import middleware from './middleware';
import { IReducer, IStore, NamespaceKey, ReducerMap } from './types/store.types';

const composeEnhancers =
    (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || // eslint-disable-line no-underscore-dangle
    compose;

if (process.env.HUGO_LOGROCKET) {
    middleware.push(dynamicMiddlewares);

    import('logrocket').then(({ default: LogRocket }) => {
        addMiddleware(LogRocket.reduxMiddleware());
    });
}

// Middleware is executed from left to right in order listed.
const enhancer = composeEnhancers(applyMiddleware(...middleware));

// https://redux.js.org/usage/code-splitting#defining-an-injectreducer-function
const initializeStore = (): IStore => {
    // https://redux.js.org/api/createstore#createstorereducer-preloadedstate-enhancer
    const store = createStore(createReducer(), {}, enhancer) as IStore;

    store.asyncReducers = {};
    store.injectReducer = (key: NamespaceKey, reducer: IReducer) => {
        store.asyncReducers[key] = reducer;
        store.replaceReducer(createReducer(store.asyncReducers as ReducerMap));
        return store;
    };

    return store;
};

export default initializeStore();
