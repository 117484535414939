import { h, Fragment, FunctionComponent } from 'preact';
import classnames from 'classnames';

import { mediaQuery } from '../../../constants';
import { routes } from '../../../routes';
import { useMediaQuery } from '../../../hooks';
import { IconCard, IconHome, IconHelp, IconShieldCheck } from '../../icons';
import RouterLink from './RouterLink';

export const ACTIONS_DESKTOP = [
    {
        link: routes.INSURANCE.path,
        icon: <IconShieldCheck />,
        label: 'My Policy'
    },
    {
        link: routes.PAYMENT.path,
        icon: <IconCard />,
        label: 'Payment'
    },
    {
        link: routes.HELP.path,
        icon: <IconHelp />,
        label: 'Help'
    }
];

export const ACTIONS_MOBILE = [
    {
        link: routes.DASHBOARD.path,
        icon: <IconHome />,
        label: 'Home'
    },
    ...ACTIONS_DESKTOP
];

interface IAction {
    icon: h.JSX.Element;
    id?: string; // `id` is passed for purpose of guided tour
    label: string;
    link: string;
}

interface IActions {
    actions: IAction[];
    className?: string;
}

export const ActionBar: FunctionComponent<IActions> = ({ className, actions }) => (
    <nav className={classnames('c-action-bar', className)} role="navigation">
        {actions.map(({ link, icon, label, id }, index) => (
            <div className="a-action-link c-action-bar__item" id={id} key={index}>
                <RouterLink className="c-action-bar__link" href={link}>
                    <div className="c-action-bar__icon">{icon}</div>
                    <div className="c-action-bar__label">{label}</div>
                </RouterLink>
            </div>
        ))}
    </nav>
);

export const ActionPill: FunctionComponent<IActions> = ({ className, actions }) => (
    <nav className={classnames('c-action-pill', className)} role="navigation">
        {actions.map(({ link, icon, label, id }: IAction, index) => (
            <div className="a-action-link" id={id} key={index}>
                <RouterLink className="c-action-pill__link" href={link}>
                    <div className="c-action-pill__icon">{icon}</div>
                    <div className="c-action-pill__label">{label}</div>
                </RouterLink>
            </div>
        ))}
    </nav>
);

const PortalActions = () => {
    const isMobile = useMediaQuery(mediaQuery.MAX_MEDIUM);
    return (
        <Fragment>
            {isMobile ? (
                <ActionBar className="a-action-bar" actions={ACTIONS_MOBILE} />
            ) : (
                <ActionPill actions={ACTIONS_DESKTOP} />
            )}
        </Fragment>
    );
};

export default PortalActions;
