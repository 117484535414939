/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Actions that don't result in direct state changes but trigger middleware.

import { actions } from '../constants';
import { IUiState } from '../reducers/ui';

interface IMapApiAccountDataAction {
    type: typeof actions.MAP_API_ACCOUNT_DATA;
    data: {
        account: any;
    };
}

interface IMapApiDashboardDataAction {
    type: typeof actions.MAP_API_ACCOUNT_DATA;
    data: any;
}

interface ICheckForAlertsAction {
    type: typeof actions.CHECK_FOR_ALERTS;
}

// Triggers middleware that maps and formats data into final output.
export const mapApiAccountData = (data: any): IMapApiAccountDataAction => ({
    type: actions.MAP_API_ACCOUNT_DATA,
    data: { account: data }
});

export const mapApiDashboardData = (data: any): IMapApiDashboardDataAction => ({
    type: actions.MAP_API_DASHBOARD_DATA,
    data
});

export const checkForAlerts = (): ICheckForAlertsAction => ({ type: actions.CHECK_FOR_ALERTS });

export interface IUpdateClientPermissionsAction {
    type: typeof actions.UPDATE_PERMISSIONS;
    payload: Partial<IUiState['clientPermissions']>;
}

export const updateClientPermissions = (
    payload: Partial<IUiState['clientPermissions']>
): IUpdateClientPermissionsAction => ({
    type: actions.UPDATE_PERMISSIONS,
    payload
});
