import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

import Button from './Button';
import Svg from '../../icons/Svg';
import { IButtonProps } from './Button.types';

interface IButtonHelpProps extends IButtonProps {
    size: number;
}

const IconHelpBubble = (props: any) => (
    <Svg viewBox="0 0 32 32" fill="none" {...props}>
        <style>
            {`
                .icon-help-bubble-question { fill: #6E66DB; }
                .icon-help-bubble-circle { fill: #F9F9F9; }
                .icon-help-bubble-triangle { stroke: #F9F9F9; }
                @media (prefers-color-scheme: dark) {
                    .icon-help-bubble-question { fill: #D4D1F4; }
                    .icon-help-bubble-circle { fill: #4D4D55; }
                    .icon-help-bubble-triangle { stroke: #4D4D55; }
                }
            `}
        </style>
        <path
            d="M30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16Z"
            class="icon-help-bubble-circle"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7896 27.4216L26.8449 30.369L25.8885 23.3535L20.7896 27.4216Z"
            class="icon-help-bubble-circle icon-help-bubble-triangle"
            stroke-width="2"
            stroke-linejoin="round"
        />
        <path
            d="M14.5707 17.8422H17.4002C17.4002 16.622 20.2296 15.8085 20.2296 13.1736C20.2296 10.8746 18.2136 9.63672 16.1976 9.63672C14.2347 9.63672 12.5193 10.6624 11.7412 12.4839L14.1816 13.8809C14.5353 13.0498 15.1189 12.3955 16.0915 12.3955C16.9934 12.3955 17.4002 12.8022 17.4002 13.4211C17.4002 14.9774 14.5707 15.7731 14.5707 17.8422V17.8422ZM15.9854 22.5108C16.4301 22.5079 16.8557 22.33 17.1702 22.0156C17.4846 21.7011 17.6625 21.2755 17.6654 20.8308C17.6625 20.3862 17.4846 19.9605 17.1702 19.6461C16.8557 19.3317 16.4301 19.1537 15.9854 19.1508C15.5408 19.1537 15.1151 19.3317 14.8007 19.6461C14.4862 19.9605 14.3083 20.3862 14.3054 20.8308C14.3083 21.2755 14.4862 21.7011 14.8007 22.0156C15.1151 22.33 15.5408 22.5079 15.9854 22.5108V22.5108Z"
            class="icon-help-bubble-question"
        />
    </Svg>
);

const ButtonHelp: FunctionComponent<IButtonHelpProps> = ({ className, size = 40, ...rest }) => {
    return (
        <Button className={classnames('c-btn--help', className)} icon {...rest}>
            <IconHelpBubble aria-label="Need help?" className="u-mx-0" height={size} width={size} />
            <span
                aria-hidden="true"
                className="u-mr-2 xs:u-hide sm:u-hide u-txt-purple-100 dark:u-txt-purple-30"
            >
                Help
            </span>
        </Button>
    );
};

export default ButtonHelp;
