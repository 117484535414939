// Slice of state dealing with purchase/payment/checkout flow.

import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import {
    ICard,
    ISaveCardDataAction,
    IPackage,
    IAddToCartAction,
    ISetAvailablePackagesAction,
    ISetUnitTimeScalarAction,
    ISetUnpaidFeeTotalAction
} from '../types/action.types';

export interface IPaymentState {
    autoReload: boolean;
    consentForFallbackOff: boolean;
    reloadDays: number;
    scheduledOffDate: null | Date;
    unitTimeScalar: number;
    card: Partial<ICard>;
    package: Partial<IPackage>;
    packages: IPackage[];
    unpaidFeeTotal: number;
}

export const initialState: IPaymentState = {
    autoReload: false,
    consentForFallbackOff: false,
    reloadDays: 3,
    scheduledOffDate: null,
    unitTimeScalar: 1,
    card: {},
    package: {},
    packages: [],
    unpaidFeeTotal: 0
};

export const actionMap = {
    [actions.SAVE_PAYMENT_DATA]: updateState,

    [actions.SAVE_CARD_DATA]: (
        state: IPaymentState,
        action: ISaveCardDataAction
    ): IPaymentState => ({
        ...state,
        card: action.payload
    }),

    [actions.ADD_TO_CART]: (state: IPaymentState, action: IAddToCartAction): IPaymentState => ({
        ...state,
        package: action.payload
    }),

    [actions.CLEAR_CART]: (state: IPaymentState): IPaymentState => ({
        ...state,
        package: initialState.package
    }),

    // Clear out previous packages after we update dashboard data to make sure
    // we get accurate pricing information for purchase pages
    [actions.MAP_API_DASHBOARD_DATA]: (state: IPaymentState): IPaymentState => ({
        ...state,
        packages: []
    }),

    [actions.SET_AVAILABLE_PACKAGES]: (
        state: IPaymentState,
        action: ISetAvailablePackagesAction
    ): IPaymentState => ({ ...state, packages: action.payload }),

    [actions.SET_UNPAID_FEE_TOTAL]: (
        state: IPaymentState,
        action: ISetUnpaidFeeTotalAction
    ): IPaymentState => ({ ...state, unpaidFeeTotal: action.payload }),

    [actions.SET_UNIT_TIME_SCALAR]: (
        state: IPaymentState,
        action: ISetUnitTimeScalarAction
    ): IPaymentState => ({ ...state, unitTimeScalar: action.payload })
};

export default createReducer(initialState, actionMap);
