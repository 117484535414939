import { alerts } from '../../constants';
import asyncComponent from '../hoc/AsyncComponent';
import ModalCoverageToggle from '../shared/CoverageToggle/ModalCoverageToggle';
import ModalExtensionToggle from '../views/InsuranceExtension/ModalExtensionToggle';
import BannerAccountEmpty from './BannerAccountEmpty';
import BannerAccountLow from './BannerAccountLow';
import BannerCanceled from './BannerCanceled';
import BannerLapsed from './BannerLapsed';
import BannerNewAppVersion from './BannerNewAppVersion';
import BannerOverdrawn from './BannerOverdrawn';
import BannerCoverageExtension from './BannerCoverageExtension';
import BannerVerifyEmail from './BannerVerifyEmail';
import BannerVerifyPhone from './BannerVerifyPhone';
import ModalApiNotifications from './ModalApiNotifications';
import ModalCardDeclined from './ModalCardDeclined';
import ModalNotification from './ModalNotification';
import ModalOverdrawnBalanceChanged from './ModalOverdrawnBalanceChanged';
import ModalQuoteExpired from './ModalQuoteExpired';
import ModalQuoteUnauthorized from './ModalQuoteUnauthorized';
import ModalQuoteWelcomeBack from './ModalQuoteWelcomeBack';
import ModalReturnToQuote from './ModalReturnToQuote';
import ModalReverifyPhone from './ModalReverifyPhone';
import ModalUnsavedChanges from './ModalUnsavedChanges';
import ModalChangeBrowser from './ModalChangeBrowser';

const BannerAnnouncement = asyncComponent(() => import('./BannerAnnouncement'));
const ModalAlreadyBound = asyncComponent(() => import('./ModalAlreadyBound'));
const ModalCoverageToggleEndorsementWarning = asyncComponent(
    () => import('./ModalCoverageToggleEndorsementWarning')
);
const ModalConsentForFallbackOff = asyncComponent(() => import('./ModalConsentForFallbackOff'));
const ModalRespondToChargeback = asyncComponent(() => import('./ModalRespondToChargeback'));
const ModalRewardEarned = asyncComponent(() => import('./ModalRewardEarned'));
const ModalRenewalTimeWarning = asyncComponent(() => import('./ModalRenewalTimeWarning'));
const ModalLocationDenied = asyncComponent(() => import('../shared/Location/ModalLocationDenied'));

export const bannersMap = {
    [alerts.ACCOUNT_BALANCE_LOW]: BannerAccountLow,
    [alerts.ACCOUNT_EMPTY]: BannerAccountEmpty,
    [alerts.ACCOUNT_LAPSED]: BannerLapsed,
    [alerts.ACCOUNT_OVERDRAWN]: BannerOverdrawn,
    [alerts.ANNOUNCEMENT]: BannerAnnouncement,
    [alerts.COVERAGE_EXTENSION]: BannerCoverageExtension,
    [alerts.POLICY_CANCELED]: BannerCanceled,
    [alerts.VERIFY_EMAIL]: BannerVerifyEmail,
    [alerts.VERIFY_PHONE]: BannerVerifyPhone,
    [alerts.NEW_APP_VERSION]: BannerNewAppVersion
};

export const modalsMap = {
    [alerts.API_NOTIFICATIONS]: ModalApiNotifications,
    [alerts.CARD_DECLINED]: ModalCardDeclined,
    [alerts.CONFIRM_FALLBACK_OFF]: ModalConsentForFallbackOff,
    [alerts.COVERAGE_EXTENSION_TOGGLE]: ModalExtensionToggle,
    [alerts.COVERAGE_TOGGLE]: ModalCoverageToggle,
    [alerts.COVERAGE_TOGGLE_ENDORSEMENT_WARNING]: ModalCoverageToggleEndorsementWarning,
    [alerts.LOCATION_PERMISSION_DENIED]: ModalLocationDenied,
    [alerts.NOTIFICATION]: ModalNotification,
    [alerts.OVERDRAWN_BALANCE_CHANGED]: ModalOverdrawnBalanceChanged,
    [alerts.QUOTE_ALREADY_BOUND]: ModalAlreadyBound,
    [alerts.QUOTE_APPLICATION_EXPIRED]: ModalQuoteExpired,
    [alerts.QUOTE_APPLICATION_UNAUTHORIZED]: ModalQuoteUnauthorized,
    [alerts.QUOTE_WELCOME_BACK]: ModalQuoteWelcomeBack,
    [alerts.RENEWAL_TIME_WARNING]: ModalRenewalTimeWarning,
    [alerts.RESPOND_TO_CHARGEBACK]: ModalRespondToChargeback,
    [alerts.RETURN_TO_QUOTE]: ModalReturnToQuote,
    [alerts.REVERIFY_PHONE]: ModalReverifyPhone,
    [alerts.REWARD_EARNED]: ModalRewardEarned,
    [alerts.UNSAVED_CHANGES]: ModalUnsavedChanges,
    [alerts.CHANGE_BROWSER]: ModalChangeBrowser
};
