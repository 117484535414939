import { h, FunctionComponent } from 'preact';
import uuid from 'uuid';
import { useMemo, useState, useEffect, useRef, useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import { StateCode } from '@popularlab/enums';

import { bindCoverageProposal, createCoverageProposal, hydrateData } from '../../../actions/api';
import { showApiFailModal, showOnModal } from '../../../actions/ui';
import { errorCodes } from '../../../constants';
import { CoverageStatus } from '../../../constants/status';
import { get, getDOMScreenshot, waitAtLeast } from '../../../helpers/misc';
import {
    getActiveGeo,
    getActivePolicyId,
    getCoverageStatusData,
    getLoadingArray
} from '../../../selectors';
import { StoreShape } from '../../../types/store.types';
import { Modal } from '../../components/Modal';
import asyncComponent from '../../hoc/AsyncComponent';
import { LoadingState } from '../../shared/CoverageToggle/ModalCoverageToggle';
import { TurnOnRateChange } from '../../shared/CoverageToggle/TurnOnRateChange';
import { Screenshot } from '../../types';
import ModalLocation from '../../shared/Location/ModalLocation';
import { CoverageProposalParams } from '../../proposal.types';

const NeedsInfo = asyncComponent(() => import('../../shared/CoverageToggle/NeedsInfo'));
const ModalExtension = asyncComponent(() => import('./ModalExtension'));

export interface IModalExtensionToggleProps {
    apiActive?: boolean;
    coverageProposalId: string;
    currentFallbackOffConsent: boolean;
    dayRate: number;
    dispatch: (action: any) => Promise<any>;
    forceError?: boolean;
    locationRequired: boolean;
    onHide: () => void;
    maxCoverageExtensionDays: number;
    newDayRate: number;
    policyId?: number;
    policyGeo: StateCode;
    proposalExpiry: number | null;
    rateMightChange: boolean;
    requiresMoreInfo?: boolean;
    scheduledOffDate?: Date;
    status: CoverageStatus;
}

export const ModalExtensionTogglePure: FunctionComponent<IModalExtensionToggleProps> = (props) => {
    const idempotencyKey = uuid();
    const [loading, setLoading] = useState(false);
    const [rateChangeConfirmed, setRateChangeConfirmed] = useState(false);
    const [extensionConfirmed, setExtensionConfirmed] = useState(false);
    const [locationToBind, setLocationToBind] = useState<GeolocationPosition | null>(null);
    const [screenshot, setScreenshot] = useState<Screenshot | null | undefined>(null);

    const rateChanged = useMemo(() => {
        return props.newDayRate !== props.dayRate;
    }, [props.newDayRate, props.dayRate]);

    const locationRequired = props.locationRequired && props.status === CoverageStatus.PAUSED;

    const timeoutId = useRef<number>(-1);

    const bindCoverageExtension = useCallback(
        (proposalId: string, clickScreenshot?: Screenshot | null) => {
            setLoading(true);
            const consentForFallbackOff = true;

            return props
                .dispatch(
                    bindCoverageProposal(
                        props.policyId,
                        proposalId,
                        idempotencyKey,
                        consentForFallbackOff,
                        clickScreenshot
                    )
                )
                .then(() => {
                    props.dispatch(
                        showOnModal({
                            message: 'Coverage extended!'
                        })
                    );
                })
                .catch(() => {
                    // The front end may be out of sync,
                    // so synchronize and show the user the expected success modal
                    // if the received error matches
                    props.dispatch(hydrateData());
                    props.dispatch(showApiFailModal());
                });
        },
        [props.policyId]
    );

    // Closing modal after proposal expires
    useEffect(() => {
        clearTimeout(timeoutId.current);
        if (!props.apiActive && props.proposalExpiry) {
            timeoutId.current = window.setTimeout(
                () => props.onHide(),
                Number(props.proposalExpiry - 1) * 1000
            );
        }
        return () => clearTimeout(timeoutId.current);
    }, [props.apiActive, props.proposalExpiry]);

    const reloadCoverageProposal = useCallback(
        (location?: GeolocationPosition | null) => {
            const data: CoverageProposalParams = {
                policyId: props.policyId,
                coverageExtension: true,
                forceError: props.forceError
            };
            if (location) {
                data.location = location;
            }

            if (props.status !== CoverageStatus.ACTIVE) {
                data.targetCoverageStatus = CoverageStatus.ACTIVE;
            }

            setLoading(true);
            return waitAtLeast(1000, () => props.dispatch(createCoverageProposal(data)))
                .catch((error) => {
                    if (
                        error.error === errorCodes.INSUFFICIENT_BALANCE ||
                        error.error === errorCodes.AR_INSUFFICIENT_BALANCE ||
                        error.error === errorCodes.REQUIRES_MORE_INFO
                    ) {
                        return;
                    }
                    props.dispatch(hydrateData());
                    props.dispatch(showApiFailModal());
                })
                .finally(() => setLoading(false));
        },
        [props.policyId, props.status]
    );

    useEffect(() => {
        reloadCoverageProposal();
    }, [props.policyId]);

    const ExtensionProposal = () => {
        if (loading) {
            return <LoadingState />;
        }

        if (props.requiresMoreInfo && props.status === CoverageStatus.PAUSED) {
            return <NeedsInfo />;
        }
        if (rateChanged && !rateChangeConfirmed) {
            return (
                <TurnOnRateChange
                    {...props}
                    onSubmit={() => setRateChangeConfirmed(true)}
                    loading={loading}
                    currentFallbackOffConsent={props.currentFallbackOffConsent}
                />
            );
        }
        if (!locationRequired && !extensionConfirmed) {
            return (
                <ModalExtension
                    {...props}
                    onSubmit={(
                        event: h.JSX.TargetedMouseEvent<HTMLButtonElement | HTMLAnchorElement>
                    ) => {
                        bindCoverageExtension(props.coverageProposalId, getDOMScreenshot(event));
                    }}
                />
            );
        }
        if (!extensionConfirmed) {
            return (
                <ModalExtension
                    {...props}
                    onSubmit={(
                        event: h.JSX.TargetedMouseEvent<HTMLButtonElement | HTMLAnchorElement>
                    ) => {
                        setScreenshot(getDOMScreenshot(event));
                        setExtensionConfirmed(true);
                    }}
                />
            );
        }

        if (!locationToBind) {
            return (
                <ModalLocation
                    onHide={() => {
                        setRateChangeConfirmed(false);
                        setExtensionConfirmed(false);
                        props.onHide();
                    }}
                    onSubmit={(location: GeolocationPosition) => {
                        setLocationToBind(location);
                        reloadCoverageProposal(location).then((data) => {
                            bindCoverageExtension(data.data.proposalId, screenshot);
                        });
                    }}
                    loading={false}
                />
            );
        }
        return <LoadingState />;
    };
    return (
        <Modal closeButton={false} onHide={loading ? undefined : props.onHide}>
            <ExtensionProposal />
        </Modal>
    );
};

ModalExtensionTogglePure.defaultProps = {
    apiActive: false,
    dayRate: 0,
    forceError: undefined,
    newDayRate: 0,
    onHide: () => {},
    policyId: undefined,
    proposalExpiry: 0,
    rateMightChange: false,
    requiresMoreInfo: false,
    status: undefined
};

const mapStateToProps = (state: StoreShape) => ({
    apiActive:
        getLoadingArray(state).includes('coverage:bind') ||
        getLoadingArray(state).includes('coverage:proposal'),
    coverageProposalId: state.proposal.proposalId,
    currentFallbackOffConsent: state.payment.consentForFallbackOff,
    dayRate: state.proposal.currentDayRate,
    forceError: state.ui.forceError,
    locationRequired: get(state, 'proposal.locationRequired'),
    maxCoverageExtensionDays: state.balance.maxCoverageExtensionDays,
    newDayRate: state.proposal.newDayRate,
    policyId: getActivePolicyId(state),
    policyGeo: getActiveGeo(state),
    proposalExpiry: state.proposal.proposalExpiry,
    rateMightChange: state.proposal.rateMightChange,
    requiresMoreInfo: state.proposal.requiresMoreInfo,
    scheduledOffDate: state.proposal.scheduledOffDate,
    status: getCoverageStatusData(state).status
});

export default connect(mapStateToProps)(ModalExtensionTogglePure);
