// Various helper functions.

import { lpad } from './formatters';
import { isInteger, isObject } from './value-checkers';

/**
 * Generic selector that returns propery on object path or undefined if missing.
 * https://stackoverflow.com/a/23809123/2382115
 * @type {get<T>}
 * @param {any} obj
 * @param {string} path
 * @returns {T|undefined}
 */
export const get = (obj, path) => path.split('.').reduce((o, k) => o && o[k], obj);

/**
 * Sets the provided value within the object at the specified path. Will automatically
 * create keys if they do not exist already.
 *
 * Identical to https://lodash.com/docs/4.17.15#set
 * @param {Object} obj The object to update
 * @param {String} path The path within the object to set (can be array index). EX: "path.0.to.value"
 * @param {*} value The value to set within the object
 */
export const set = (obj, path, value) => {
    const paths = path.split('.');
    let nested = obj;
    let index = -1;

    // eslint-disable-next-line no-plusplus
    while (nested != null && ++index < paths.length) {
        const key = paths[index];
        let newValue = value;

        if (index !== paths.length - 1) {
            const objValue = nested[key];
            if (objValue !== null && typeof objValue === 'object') {
                newValue = objValue;
            } else if (isInteger(paths[index + 1])) {
                newValue = [];
            } else {
                newValue = {};
            }
        }

        nested[key] = newValue;
        nested = nested[key];
    }

    return obj;
};

/**
 * Returns the list of keys for an object, or an empty list if parameter is not an object
 */

export const getKeys = (obj) => {
    if (typeof obj !== 'object') {
        return [];
    }
    return Object.keys(obj);
};

/**
 * Picks the specified keys from a given object and returns a new object with only those keys
 * @param {Object} obj Object to pick from
 * @param {string | string[]} paths Array of keys to pick
 * @returns New object containing only the specified keys
 */
export const pick = (obj, paths) => {
    const p = Array.isArray(paths) ? paths : [paths];
    return Object.keys(obj).reduce((acc, key) => {
        if (p.includes(key)) {
            acc[key] = obj[key];
        }

        return acc;
    }, {});
};

// Returns salutation string depending on time of day.
// @returns {String}
export const getSalute = () => {
    // 12:00AM - 12:00PM - Good Morning!
    // 12:00PM - 6:00PM - Good Afternoon!
    // 6:00PM - 12:00AM - Good Evening!
    const hrs = new Date().getHours();

    if (hrs < 12) {
        return 'Good morning';
    }
    if (hrs < 18) {
        return 'Good afternoon';
    }

    return 'Good evening';
};

const isTimeTomorrow = (timeMs) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(timeMs);
    date.setHours(0, 0, 0, 0);
    return date.toString() === today.setDate(today.getDate() + 1).toString();
};

const isTimeToday = (timeMs) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(timeMs);
    date.setHours(0, 0, 0, 0);
    return date.toString() === today.toString();
};

export const isSameMinute = (time1, time2) => {
    const date1 = new Date(time1);
    const date2 = new Date(time2);
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes()
    );
};

// Takes time in ms and returns {today|tomorrow} at HH:mm {pm|am}
// @param {Date} date in ms
// @param {String} date in human-readable format
export const formatDailyTimestamp = (timeMs) => {
    const endDate = new Date(timeMs);

    let date = endDate.toDateString();
    if (isTimeTomorrow(timeMs)) {
        date = 'tomorrow';
    } else if (isTimeToday(timeMs)) {
        date = 'today';
    }

    const hour = endDate.getHours() % 12 || 12;
    const minute = endDate.getMinutes();
    const period = endDate.getHours() >= 12 ? 'PM' : 'AM';

    return `${date} at ${hour}:${lpad(minute)} ${period}`;
};

/**
 * Maps a discovered/reported data object into an array of items
 * @template T
 * @param {{ discovered?: { [key: string]: T }, reported?: { [key: string]: T } }} data Data object with discovered and reported keys
 * @returns {Array<T & { key: string }>}
 */
export const mapDiscoveredReportedItems = (data) => {
    return [
        ...Object.keys(get(data, 'discovered') || {}).map((id) => ({
            ...data.discovered[id],
            key: `discovered.${id}`
        })),
        ...Object.keys(get(data, 'reported') || {}).map((id) => ({
            ...data.reported[id],
            key: `reported.${id}`
        }))
    ];
};

/**
 * Formats the unit string of the given address. If a unit does not exist, returns an empty string
 * @param {Object} address to parse
 */
export const getAddressUnit = (address = {}) => {
    if (address.unit) {
        const { unit } = address;

        const unitDesignator = get(address, 'components.secondaryDesignator') || 'unit';

        return `${unitDesignator} ${unit}`;
    }

    return '';
};

/**
 * Compares two addresses by street, state, city, and zipcode. Also allows unit check via boolean flag.
 * @param {Object} a
 * @param {Object} b
 * @param {boolean} checkUnit
 */
export const compareStreetAddresses = (a, b, checkUnit = false) => {
    let ret = false;
    if (
        get(a, 'city') === get(b, 'city') &&
        get(a, 'state') === get(b, 'state') &&
        get(a, 'street') === get(b, 'street') &&
        get(a, 'zipCode') === get(b, 'zipCode')
    ) {
        ret = true;
    }

    if (ret && checkUnit) {
        ret = get(a, 'unit') === get(b, 'unit');
    }

    return ret;
};

/**
 * Picks address fields for safe submissions
 * Omits things like isValidated and components
 * @param {Object} values
 */
export const pickAddressFields = (values) => {
    return {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
        unit: values.unit
    };
};

/**
 * Return value for passed CSS variable (custom property) name.
 * @param {String} varName - name of CSS custom property, must include `--` prefix
 * @param {HTMLElement} element that scopes variable, defaults to :root (html)
 * @return {String|Number} value attached to property
 */
export const getCssVariable = (varName, element) => {
    if (typeof window !== 'undefined') {
        const $element = element || document.documentElement;
        return getComputedStyle($element).getPropertyValue(varName);
    }
    return '';
};

/**
 * Return correct event name for CSS transition end
 * depending on browser support
 * @return {String} HTML DOM event name for CSS transition end
 */
export const getTransitionEndEventName = () => {
    if ('ontransitionend' in window) {
        return 'transitionend';
    }

    if ('onwebkittransitionend' in window) {
        return 'webkitTransitionEnd';
    }

    return false;
};

// https://2ality.com/2017/04/conditional-literal-entries.html
export const insertIf = (condition, element) => {
    return condition ? [element] : [];
};

export const retrievePhoneNumFromLeadQuote = (quoteApp) => {
    let leadPhone = get(quoteApp, 'data.meta.phone');
    if (leadPhone) {
        leadPhone = String(leadPhone);
        leadPhone = leadPhone.replace(/\D/g, '');
        if (leadPhone.length === 10 && leadPhone.charAt(0) !== 0) {
            return leadPhone;
        }
    }
    return '';
};

// From array of objects with label and value properties
// return label for passed value.
// @param {Array} { value: any, label: any }[]
// @param {Any} value
// @return {Any} label
export const getLabelForValue = (list = [], value = '') => {
    const item = list.find((e) => e.value === value);
    return get(item, 'label');
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }

    return mergeDeep(target, ...sources);
};

/**
 * Delays resolving of a Promise for minimum amount of time.
 * Used to prevent flickering loaders on fast API responses.
 * https://blog.bitsrc.io/a-brief-history-of-flickering-spinners-c9eecd6053
 * @param {Number} minimum time to wait for a Promise
 * @param {Function|Promise} promise to resolve
 * @returns {Promise} resolve of the Promise passed in
 */
export const waitAtLeast = (time, promise) => {
    const promiseTimeout = new Promise((resolve) => {
        setTimeout(resolve, time);
    });
    const promiseCombined = Promise.all([promise(), promiseTimeout]);
    return promiseCombined.then((values) => values[0]);
};

/**
 * Resets HTML element (or first parent) with scroll position back to 0
 * @param {HTMLElement|null} element
 */
export const resetScroll = (element) => {
    if (element && typeof window !== 'undefined') {
        let cur = element;
        while (cur !== null && cur !== document.body) {
            if (cur.scrollTop !== 0) {
                cur.scrollTop = 0;
                break;
            }
            cur = cur.parentElement;
        }
    }
};

/**
 * Reverse finds in the given array
 * @param Array to search
 * @param predicate to call at each iteration
 * @returns {Any|undefined}
 */
export const findLast = (arr, predicate) => {
    for (let i = arr.length - 1; i >= 0; --i) {
        const curr = arr[i];

        if (predicate(curr)) {
            return curr;
        }
    }

    return undefined;
};

/**
 * Captures a snapshot of the DOM and returns it as an HTML string
 * @param {MouseEvent?} event Event associated with DOM capture to simulate a click
 * @returns {import('../ui/types').Screenshot | undefined} HTML string of DOM snapshot, without scripts and iframes or undefined if rendering fails
 */
export const getDOMScreenshot = (event) => {
    try {
        const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const html = document.getElementsByTagName('html')[0];
        const clone = html.cloneNode(true);

        // Remove all scripts since we don't need to run scripts when capturing a screenshot
        const scripts = clone.getElementsByTagName('script');
        while (scripts.length > 0) {
            scripts[0].remove();
        }

        // Remove all iframes
        const iframes = clone.getElementsByTagName('iframe');
        while (iframes.length > 0) {
            iframes[0].remove();
        }

        // Remove linked preload script references since we don't need to run scripts when capturing a screenshot
        const linkScripts = clone.querySelectorAll('link[rel="modulepreload"],link[rel="preload"]');
        for (let i = 0; i < linkScripts.length; i++) {
            linkScripts[i].remove();
        }

        // Remove inline styles for google login button
        const googleStyles = clone.querySelector('#googleidentityservice_button_styles');
        if (googleStyles) {
            googleStyles.remove();
        }

        const icons = clone.querySelectorAll('svg > use');
        icons.forEach((icon) => {
            // Update relative hrefs for icons to use the current origin
            const href = icon.getAttribute('href');
            if (href && href.startsWith('/')) {
                icon.setAttribute('href', `${window.location.origin}${href}`);
            }
        });

        const stylesheets = clone.querySelectorAll('link[rel="stylesheet"]');
        for (let i = 0; i < stylesheets.length; i++) {
            // Update relative hrefs for stylesheets to use the current origin
            const stylesheet = stylesheets[i];
            const href = stylesheet.getAttribute('href');
            if (href && href.startsWith('/')) {
                stylesheet.setAttribute('href', `${window.location.origin}${href}`);
            }
        }

        if (event) {
            const clickSimulation = document.createElement('div');
            clickSimulation.style.position = 'fixed';
            clickSimulation.style.top = `${event.clientY}px`;
            clickSimulation.style.left = `${event.clientX}px`;
            clickSimulation.style.transform = 'translate(-50%, -50%)';
            clickSimulation.style.width = '36px';
            clickSimulation.style.height = '36px';
            clickSimulation.style.background = '#448EE1';
            clickSimulation.style.opacity = '0.5';
            clickSimulation.style.borderRadius = '100%';
            clickSimulation.style.zIndex = '100';
            clone.getElementsByTagName('body')[0].append(clickSimulation);
        }

        const screenshot = {
            darkMode,
            html: `<!DOCTYPE html>${clone.outerHTML}`,
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight
        };
        return screenshot;
    } catch (error) {
        console.error('Failed to render DOM snapshot', error);
        return undefined;
    }
};

export const infoLogger = (message) => {
    if (window.FS) {
        window.FS('log', { msg: message });
    } else {
        // eslint-disable-next-line no-console
        console.log(message);
    }
};

/**
 * Copies passed string to clipboard and calls optional callback
 * @param {String} text
 * @param {Function=} callback
 */
export const copyToClipboard = (text, callback = () => {}) => {
    if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(text).then(callback);
    } else {
        // Hacky way to copy to cliboard for browsers
        // w/o navigator.clipboard support (mainly iOS)
        // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
        const input = document.createElement('input');
        input.readOnly = false;
        input.style.display = 'none';
        input.style.position = 'fixed';
        document.body.appendChild(input);

        const range = document.createRange();
        range.selectNodeContents(input);
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
        input.setSelectionRange(0, 9999999);
        document.execCommand('copy');

        document.body.removeChild(input);
        callback();
    }
};
