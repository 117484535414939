import { h } from 'preact';

import { Modal, ModalContent, ModalActions, ModalIcon, ModalTitle } from '../components/Modal';
import { ButtonCta, Button } from '../components/Button';
import { IconCarSad } from '../icons';
import { copy } from '../../constants';

const getDeclinedCopy = (error) => {
    if (copy.formErrors.api[error]) {
        return copy.formErrors.api[error];
    }

    return 'We’re sorry, your card has been declined. Please change cards, or add money to your card and try again.';
};

const ModalCardDeclined = (props) => (
    <Modal onHide={props.onHide} closeButton={false}>
        <ModalIcon>
            <IconCarSad className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1" size="40" />
        </ModalIcon>

        <ModalTitle>We’re sorry!</ModalTitle>

        <ModalContent center>
            <p>{getDeclinedCopy(props.error)}</p>
        </ModalContent>

        <ModalActions>
            <ButtonCta
                onClick={() => {
                    if (props.onCardChange) {
                        props.onCardChange();
                    }
                    props.onHide();
                }}
            >
                Change card
            </ButtonCta>
            <Button link onClick={props.onHide}>
                Cancel
            </Button>
        </ModalActions>
    </Modal>
);

export default ModalCardDeclined;
