// Middleware to normalize API payload before reaching store.

import { Middleware } from 'redux';

import {
    clearRtqQueryDataToken,
    resetCoverageSession,
    saveAccountData,
    saveBalanceData,
    savePaymentData,
    savePolicyData,
    saveRegistrationProtectionPref,
    saveRewardBalance,
    setPendingRewrite,
    setRtqQueryDataToken,
    setUnitTimeScalar,
    updateCoverageData
} from '../actions/data';
import { setFlags } from '../actions/ui';
import { signupBind } from '../actions/signup';
import { actions, status } from '../constants';
import { mapDiscoveredReportedItems, get } from '../helpers/misc';
import { StoreShape } from '../types/store.types';
import { IAccountState } from '../reducers/account';
import { IPolicyState } from '../reducers/policy';
import { IBalanceState } from '../reducers/balance';
import { ICoverageState } from '../reducers/coverage';

export const normalizeUserData: Middleware<{}, StoreShape> =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);
        if (
            action.type === actions.MAP_API_ACCOUNT_DATA ||
            action.type === actions.MAP_API_DASHBOARD_DATA
        ) {
            const {
                account,
                policies,
                rewardsBalance,
                accountLastCoverageSession,
                registrationProtectionPref,
                unitTimeScalar,
                currentExpiredPolicy,
                pendingStateFilingRequest,
                serviceNumber
            } = action.data;

            let accountData: Partial<IAccountState> = {
                email: account.email,
                firstName: account.firstName,
                isEmailVerified: account.isEmailVerified,
                lastName: account.lastName,
                phone: account.phoneNumber,
                referralCode: account.referralCode,
                lastCoverageSession: accountLastCoverageSession,
                pendingStateFilingRequest,
                serviceNumber
            };

            if (account.flags) {
                dispatch(setFlags(account.flags));
            }
            dispatch(saveRegistrationProtectionPref(registrationProtectionPref));

            if (action.type === actions.MAP_API_DASHBOARD_DATA) {
                dispatch(saveRewardBalance({ rewardsBalance }));
                const { card, autoReloadPreferences, ticketTypes } = action.data;
                dispatch(
                    savePaymentData({
                        card,
                        autoReload: autoReloadPreferences.isEnabled,
                        reloadDays: autoReloadPreferences.reloadDays,
                        scheduledOffDate: autoReloadPreferences.scheduledOffDate
                            ? new Date(autoReloadPreferences.scheduledOffDate)
                            : null,
                        consentForFallbackOff: autoReloadPreferences.consentForFallbackOff
                    })
                );
                dispatch(setUnitTimeScalar(unitTimeScalar));

                if (ticketTypes.length) {
                    accountData = {
                        ...accountData,
                        openTickets: ticketTypes
                    };
                }

                if (policies.length) {
                    dispatch(signupBind());
                    // TODO: Multiple policies are years away so for now, always
                    // treat first policy in array as active one. Eventually some
                    // sort of policy selector will be in place, so we'll
                    // need to respect that selection when initializing information.
                    // Use first available policy as primary one for now until policy switching is implemented
                    const policy = policies[0];
                    const { balance } = policies[0];
                    accountData = {
                        ...accountData,
                        daysForRewrite: policy.daysForRewrite,
                        feeForgivenessEndDateTime: new Date(policy.feeForgivenessEndDateTime),
                        daysForReinstateFeeForgiveness: policy.daysForReinstateFeeForgiveness,
                        futureDailyFeeForReinstate: policy.futureDailyFeeForReinstate,
                        futureTotalFeeForReinstate: policy.futureTotalFeeForReinstate,
                        accountFeeDayRate: balance.accountFeeDayRate,
                        dayRate: Number(balance.policyDayRate) + Number(balance.accountFeeDayRate),
                        policyDayRate: balance.policyDayRate,
                        lastKnownGeo: policy.state,
                        lastKnownControllerVersion: policy.controllerVersion
                    };

                    const details = policy.data;
                    const policyData: Partial<IPolicyState> = {
                        additionalDrivers: mapDiscoveredReportedItems(details.additionalDrivers),
                        bundleType: policy.coverageBundleType,
                        coverageOptions: details.policy.coverageOptions,
                        startsAt: new Date(policy.startDate),
                        expiresAt: new Date(policy.expireDate),
                        garagingAddress: details.policy.garagingAddress,
                        hasFutureEndorsement: policy.hasFutureEndorsement,
                        homeAddress: details.pni.homeAddress,
                        id: policy.id,
                        policyNumber: policy.policyNumber,
                        controllerVersion: policy.data.controllerVersion,
                        primaryInsured: details.pni,
                        state: policy.state,
                        type: details.policy.policyType,
                        vehicles: mapDiscoveredReportedItems(details.vehicles),
                        isCancelledForNonpayment: policy.isCancelledForNonpayment,
                        hasExpiredPolicy: policy.isObliterated,
                        premium: policy.premium.rate,
                        term: policy.term,
                        rewriteMeta: balance.rewrites,
                        renewalMeta: {
                            ...policy.renewalMeta,
                            renewalDate: policy.renewalMeta.renewalDate
                                ? new Date(policy.renewalMeta.renewalDate)
                                : undefined
                        },
                        hasHigherLimits: policy.willDowngradeCoverageOptionOnPolicyChange
                    };

                    if (policy.meta && policy.meta.partner) {
                        const { policyPrefix, policyNumber } = policy.meta.partner;
                        policyData.partnerPolicyNumber =
                            policyPrefix && policyNumber ? `${policyPrefix}-${policyNumber}` : null;
                    }

                    dispatch(savePolicyData(policyData));

                    const balanceData: Partial<IBalanceState> = {
                        balance: balance.balance,
                        balanceInDays: balance.balanceInDays,
                        cancellationNonpaymentDate: balance.cancellationNonpaymentDate
                            ? new Date(balance.cancellationNonpaymentDate)
                            : null,
                        coverageExtensionStartDate: balance.coverageExtensionStartDate,
                        dueDate: balance.dueDate,
                        maxCoverageExtensionDays: balance.maxCoverageExtensionDays,
                        maxWarningPeriodCoveredDays: balance.maxWarningPeriodCoveredDays,
                        minTrueUpBalance: balance.minTrueUpBalance,
                        minTrueUpBalanceInDays: balance.minTrueUpBalanceInDays,
                        onAndAutoReloadBalanceRequirementInDays:
                            balance.onAndAutoReloadBalanceRequirementInDays,
                        onAndAutoReloadBalanceRequirement:
                            balance.onAndAutoReloadBalanceRequirement,
                        overdrawnBalance: balance.overdrawnBalance,
                        coverageOptionDayRates: balance.coverageOptionDayRates,
                        willEnterCoverageExtension: balance.willEnterCoverageExtension,
                        isCoverageExtensionAvailable: balance.isCoverageExtensionAvailable,
                        isOnCoverageExtension: balance.isOnCoverageExtension,
                        usedCoverageExtensionDays: balance.usedCoverageExtensionDays
                    };

                    dispatch(saveBalanceData(balanceData));

                    const { coverageSession } = policies[0];

                    if (coverageSession.status === status.PAUSED) {
                        dispatch(
                            resetCoverageSession({
                                carrier: coverageSession.carrier,
                                naic: coverageSession.naic
                            })
                        );
                    } else {
                        const coverageData: Partial<ICoverageState> = {
                            id: coverageSession.id,
                            status: coverageSession.status,
                            carrier: coverageSession.carrier,
                            naic: coverageSession.naic,
                            startTimeMs: coverageSession.startTimeMs || null,
                            endTimeMs: coverageSession.endTimeMs || null
                        };

                        dispatch(updateCoverageData(coverageData));
                    }

                    // @TODO: refactor to use policy.rewriteMeta field
                    if (balance.rewrites) {
                        dispatch(
                            setPendingRewrite(get(balance, 'rewrites.requiresMoreInfo') || false)
                        );
                    } else {
                        dispatch(setPendingRewrite(false));
                    }

                    // Clear out any pending rewrite token or stale RTQ tokens. They shouldn't exist if the policy is active
                    dispatch(clearRtqQueryDataToken());
                } else {
                    const { balance, pendingRewrite } = action.data;
                    const balanceData = {
                        balance,
                        overdrawnBalance: balance < 0 ? Math.abs(balance) : 0
                    };
                    dispatch(saveBalanceData(balanceData));

                    if (pendingRewrite) {
                        dispatch(setPendingRewrite(true));
                        dispatch(setRtqQueryDataToken(pendingRewrite.rtqToken));
                    } else {
                        dispatch(setPendingRewrite(false));
                        dispatch(clearRtqQueryDataToken());
                    }

                    // Check if there ever was a previous policy
                    if (account && account.flags && account.flags.hasEverBound) {
                        dispatch(savePolicyData({ hasExpiredPolicy: true }));
                    }

                    // Note @dan: currentExpiredPolicy will only be returned if no active policies are found
                    // Reference: https://github.com/popularlab/hugolith/pull/1763
                    if (currentExpiredPolicy) {
                        const { state, data } = currentExpiredPolicy;
                        const { controllerVersion } = data;
                        accountData = {
                            ...accountData,
                            lastKnownGeo: state,
                            lastKnownControllerVersion: controllerVersion,
                            lastActivePolicyId: currentExpiredPolicy.id
                        };
                    }
                }
            }
            dispatch(saveAccountData(accountData));
        }
    };
