export enum RewardBalanceTransferType {
    /**
     * Withdraw rewards to gift card
     */
    GiftCard,
    /**
     * A support ticket to withdraw rewards to a gift card
     */
    GiftCardRequested,
    /**
     * Reward balance transferred to main Hugo account balance
     */
    HugoBalance
}

export enum GiftCardTransferRequestResult {
    GIFT_CARD_SENT = 'gift-card-sent',
    TICKET_CREATED = 'ticket-created'
}
