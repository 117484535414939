import { Middleware } from 'redux';

import { actions } from '../constants';
import { saveQueryStringData } from '../actions/data';
import { StoreShape } from '../types/store.types';

// Catch invalid actions.
export const errorLogger: Middleware<{}, StoreShape> = () => (next) => (action) => {
    try {
        return next(action);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error, action);
        return error;
    }
};

// Allow passing of array of actions in dispatch.
export const actionArray: Middleware<{}, StoreShape> =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (Array.isArray(action)) {
            action.forEach((a) => dispatch(a));
            return;
        }

        next(action);
    };

export const clearRedirect: Middleware<{}, StoreShape> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        if (action.type === actions.SET_ACTIVE_URL) {
            const { query } = getState();
            const { origin } = query;

            // NOTE: We are _explicitly_ using the window location because a redirect to Login may cause us
            //       to lose the redirect parameter as the actions propagate
            if (origin === window.location.pathname) {
                // Clear the query string after the redirect happened
                dispatch(saveQueryStringData({ origin: null }));
            }
        }

        next(action);
    };
