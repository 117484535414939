import { h } from 'preact';
import { FC } from 'preact/compat';

import { Modal, ModalContent, ModalActions, ModalTitle, ModalIcon } from '../components/Modal';
import { Button, ButtonCta } from '../components/Button';
import { IconTrash } from '../icons';
import { IModalProps } from '../components/Modal/Modal';

import { useResetQuote } from '@/hooks/useResetQuote';

interface IModalResetQuoteApplicationProps extends IModalProps {
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
    dispatch: (action: any) => any;
}

const ModalResetQuoteApplication: FC<IModalResetQuoteApplicationProps> = ({
    isLoading,
    setIsLoading,
    onHide,
    dispatch,
    ...props
}) => {
    const resetQuote = useResetQuote(dispatch, setIsLoading, onHide);

    return (
        <Modal onHide={onHide} {...props}>
            <ModalIcon>
                <IconTrash
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="40"
                />
            </ModalIcon>
            <ModalTitle>Delete my quote application</ModalTitle>
            <ModalContent center>
                <p>
                    Are you sure you want to delete your current quote application? You will have to
                    start from the beginning if you delete it.
                </p>
            </ModalContent>
            <ModalActions>
                <ButtonCta onClick={onHide}>No, save my progress</ButtonCta>
                <Button link loading={isLoading} onClick={resetQuote}>
                    Yes, I want to delete
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default ModalResetQuoteApplication;
