import { h, FunctionComponent } from 'preact';

import { IconCaretRight } from '../../icons';
import Button from './Button';
import { IButtonProps } from './Button.types';

const ButtonLink: FunctionComponent<IButtonProps> = ({ children, ...rest }) => (
    <Button link icon {...rest}>
        <div>{children}</div>
        <IconCaretRight aria-hidden="true" />
    </Button>
);

export default ButtonLink;
