/* eslint-disable @typescript-eslint/no-shadow */
// Selector functions for Redux state.
// https://github.com/reduxjs/reselect
// https://redux.js.org/recipes/computing-derived-data
// https://read.reduxbook.com/markdown/part1/07-selectors.html

import { createSelector } from 'reselect';
import { PolicyType, CoverageBundleType, StateCode } from '@popularlab/enums';

import { accessLevels, status, alerts, apiPaths } from './constants';
import { formatDailyTimestamp, get, mapDiscoveredReportedItems } from './helpers/misc';
import { isTimeExpired } from './helpers/value-checkers';
import { phoneNumber } from './helpers/masks';
import { sessionStorage } from './helpers/storage';
import { isReinstateDisabled, isAcceptance, isSafeAuto } from './helpers/geos';
import { routes, ROUTE_KEYS } from './routes';
import {
    ACCOUNT_LOW_THRESHOLD_DAYS,
    DAY_IN_MILLISECONDS,
    MINIMUM_WITHDRAW_DAYS_REQUIRED,
    POLICY_BINDER_AVAILABLE_DAYS,
    MAXIMUM_DAYS_TO_PURCHASE
} from './constants/time';
import { StoreShape } from './types/store.types';
import { ICard } from './types/action.types';
import {
    IVehicle,
    IPolicyCoverages,
    QuoteApplication,
    IMappedVehicle
} from './ui/steps/flows/flows.types';
import { IAccessProps } from './ui/types';
import { GATED_DAYS, FAC_GEOS } from './ui/views/DocumentsStateFiling/constants';

const API_KEYS = Object.keys(apiPaths);

export const getAccountId = (state: StoreShape): number | null => state.account.accountId || null;

export const getActivePolicyId = (state: StoreShape): number | undefined => state.policy.id;

export const getLastActivePolicyId = (state: StoreShape): number | undefined =>
    state.account.lastActivePolicyId;

export const getActiveSinceMs = (state: StoreShape): number | undefined =>
    state.coverage.startTimeMs;

// Existence of accountId means user is logged in (has valid access token).
export const getIsLogged = (state: StoreShape): boolean => Boolean(getAccountId(state));

export const getIsAutoReloadEnabled = (state: StoreShape): boolean =>
    state.payment.autoReload || false;

export const getAutoReloadDays = (state: StoreShape): number => state.payment.reloadDays || 3;

export const getOverdrawnBalance = (state: StoreShape): number =>
    state.balance.overdrawnBalance || 0;

export const getBalance = (state: StoreShape): number => state.balance.balance || 0;

export const getBalanceInDays = (state: StoreShape): number => state.balance.balanceInDays;

export const getRewardsBalance = (state: StoreShape): number => state.rewards.rewardsBalance || 0;

export const getLoadingArray = (state: StoreShape): string[] => state.api.loading || [];

export const getCardData = (state: StoreShape): Partial<ICard> | {} => state.payment.card || {};

export const getMinimumDaysRequired = (state: StoreShape): number =>
    state.balance.minTrueUpBalanceInDays || 0;

export const getMinimumOnAndAutoReloadDaysRequired = (state: StoreShape): number =>
    state.balance.onAndAutoReloadBalanceRequirementInDays || 0;

export const getMinimumOnAndAutoReloadAmountRequired = (state: StoreShape): number =>
    state.balance.onAndAutoReloadBalanceRequirement || 0;

export const getActiveGeo = (state: StoreShape): StateCode | undefined => state.policy.state;
export const getActiveControllerVersion = (state: StoreShape): string | undefined | null =>
    state.policy.controllerVersion;

export const getPolicyType = (state: StoreShape): PolicyType | undefined => state.policy.type;

export const getIsCancelledForNonPayment = (state: StoreShape): boolean =>
    state.policy.isCancelledForNonpayment || false;

export const getIsRewritePending = (state: StoreShape): boolean =>
    state.policy.requiresMoreInfo || false;

export const getActiveUrl = (state: StoreShape): string | null => state.ui.activeUrl || null;

export const getVisibleModal = (state: StoreShape): null | alerts => state.ui.visibleModal || null;

export const getHasFutureEndorsement = (state: StoreShape): boolean =>
    state.policy.hasFutureEndorsement;

// HUGO SIGNUP DATA
// https://drive.google.com/file/d/1VUbpRIwJpqwway-zxDLuYQVDs22xMEGK/view?usp=sharing
export const isSignupComplete = (state: StoreShape): boolean => Boolean(state.policy.id);

export const getDayRate = createSelector(
    (state: StoreShape) => state.account.policyDayRate,
    (state: StoreShape) => state.account.accountFeeDayRate,
    (policyDayRate, accountFeeDayRate) =>
        Number(policyDayRate || 0) + Number(accountFeeDayRate || 0)
);

// Returns user full name.
// @param {Object} Redux state
// @returns {String} First name and last name joined
export const getFullName = createSelector(
    (state: StoreShape) => state.account.firstName,
    (state: StoreShape) => state.account.lastName,
    (firstName, lastName) => (firstName && lastName ? `${firstName} ${lastName}` : null)
);

export const getFullOrPartialName = createSelector(
    (data: { firstName: string; lastName: string }) => data.firstName,
    (data: { firstName: string; lastName: string }) => data.lastName,
    (firstName, lastName) => {
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }

        if (firstName) {
            return firstName;
        }

        return '';
    }
);

// Returns phone number formatted as (XXX) XXX-XXXX
// @param {Object} Redux state
// @returns {String} Phone number
export const getPhoneNumber = createSelector(
    (state: StoreShape) => state.account.phone || '',
    (phone) => phoneNumber(phone)
);

export const getAccountEmail = createSelector(
    (state: StoreShape) => state.account.email,
    (state: StoreShape) => state.account.isEmailVerified,
    (email, isEmailVerified) => (isEmailVerified ? email : null)
);

// Warning period only exists while user is ON
// at that point cancellationNonpaymentDate exists on balance object
// if user is OFF, cancellationNonpaymentDate isn't preset
export const getIsInWarningPeriod = createSelector(
    (state: StoreShape) => state.balance.cancellationNonpaymentDate,
    (cancellationNonpaymentDate) => {
        if (cancellationNonpaymentDate) {
            if (!isTimeExpired(cancellationNonpaymentDate)) {
                return true;
            }
        }
        return false;
    }
);

export const getIsPolicyTraditional = createSelector(
    getPolicyType,
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.policy.policyType'),
    (policyType, quotePolicyType) => {
        const type = policyType || quotePolicyType;
        return type === PolicyType.TRADITIONAL;
    }
);

// Return object with coverageStatus and various boolean statements.
// @param {Object} Redux state
// @returns {Object} Data about coverage status
export const getCoverageStatusData = createSelector(
    (state: StoreShape) => state.coverage.status,
    (coverageStatus) => ({
        status: coverageStatus,
        isActive: coverageStatus === status.ACTIVE,
        isPaused: coverageStatus === status.PAUSED,
        isPending: coverageStatus === status.PENDING_PAUSE,
        notPaused: coverageStatus === status.ACTIVE || coverageStatus === status.PENDING_PAUSE,
        notActive: coverageStatus === status.PENDING_PAUSE || coverageStatus === status.PAUSED
    })
);

export const getCoverageExtensionAvailable = (state: StoreShape): boolean =>
    Boolean(state.balance.isCoverageExtensionAvailable);

export const getCoverageExtensionActive = createSelector(
    (state: StoreShape) => state.balance.isOnCoverageExtension,
    (state: StoreShape) => state.balance.willEnterCoverageExtension,
    (onCoverageExtension, willEnterCoverageExtension) => {
        return onCoverageExtension || willEnterCoverageExtension;
    }
);

// Determine app access level from user data.
// https://github.com/popularlab/product/issues/341
// @param {Object} Redux state
// @returns {Object} Data about access levels
export const getAccessLevelData = createSelector(
    getIsLogged,
    isSignupComplete,
    getOverdrawnBalance,
    getIsCancelledForNonPayment,
    getIsPolicyTraditional,
    getActiveGeo,
    getActiveControllerVersion,
    (state: StoreShape) => state.policy.hasExpiredPolicy,
    getIsRewritePending,
    getCoverageExtensionAvailable,
    getCoverageExtensionActive,
    (state: StoreShape) => state.coverage.status,
    (state: StoreShape) => state.payment.consentForFallbackOff,
    (state: StoreShape) => state.account.referralCode,
    (state: StoreShape) => state.policy.renewalMeta,
    (
        isLogged,
        isSignupComplete,
        overdrawnBalance,
        isCancelledForNonpayment,
        isTraditional,
        activeGeo,
        activeVersion,
        hasExpiredPolicy,
        isRewritePending,
        isExtensionAvailable,
        isExtensionActive,
        coverageStatus,
        hasFallbackOffConsent,
        referralCode,
        renewalMeta
    ): IAccessProps => {
        // Assume basic access.
        let level = accessLevels.BASIC;

        // If user is binded, assume full access.
        if (isSignupComplete) {
            level = accessLevels.FULL;

            if (isCancelledForNonpayment) {
                level = accessLevels.RESTRICTED_LAPSED;
            } else if (overdrawnBalance) {
                level = accessLevels.RESTRICTED_OVERDRAWN;
            }
        }

        // Policy has expired without being renewed (obliteration or termination) or
        // disabled until rewrite complete.
        // @TODO: figure out better naming here.
        // Using BASIC_CANCELED for now to provide same UI experience.
        if (hasExpiredPolicy || isRewritePending) {
            level = accessLevels.BASIC_CANCELED;
        }

        return {
            isLogged,
            isTraditional,
            isBasic: level.includes(accessLevels.BASIC),
            isCanceled: level.includes(accessLevels.BASIC_CANCELED),
            isFull: level.includes(accessLevels.FULL),
            isLapsed: level.includes(accessLevels.RESTRICTED_LAPSED),
            isRestrictedAndOverdrawn: level.includes(accessLevels.RESTRICTED_OVERDRAWN),
            isRestricted: level.includes(accessLevels.RESTRICTED),
            notBasic: !level.includes(accessLevels.BASIC), // same as (isFull || isRestricted)
            isExtensionAvailable,
            isExtensionActive,
            hasOverdrawnBalance: overdrawnBalance > 0,
            activeGeo,
            activeVersion,
            isCoverageActive: coverageStatus !== status.PAUSED,
            hasFallbackOffConsent,
            hasReferralCode: !!referralCode,
            isRenewalWindow: renewalMeta.renewalNoticeSent || renewalMeta.isNonRenewal
        };
    }
);

export const getMinimumWithdrawAmountRequired = createSelector(
    getDayRate,
    (dayRate) => MINIMUM_WITHDRAW_DAYS_REQUIRED * dayRate
);

// Return nearest end time for coverage session in human-readable format.
// @param {Object} Redux state
// @returns {String} Timestamp
export const getEndCoverageTimestamp = createSelector(
    (state: StoreShape) => state.coverage.endTimeMs,
    (endingAtMs) => (endingAtMs ? formatDailyTimestamp(endingAtMs) : null)
);

// Return nearest day boundary time for active coverage session in human-readable format.
// @param {Object} Redux state
// @returns {String} Timestamp
export const getDueDate = createSelector(
    (state: StoreShape) => state.balance.dueDate,
    (dueDate) => (dueDate ? formatDailyTimestamp(dueDate) : null)
);

export const getActiveRoute = createSelector(getActiveUrl, (url) => {
    // Use activeUrl to pluck correct route object from routes.js
    const key = ROUTE_KEYS.find((key) => (routes as any)[key].path === url) || '';
    const route = (routes as any)[key] || {};
    route.key = key;
    return route;
});

export const getIsPaymentFlow = createSelector(getActiveRoute, (route) => {
    return (
        route === routes.PAYMENT_CHECKOUT ||
        route === routes.PAYMENT_REFILL_AND_ON ||
        route === routes.PAYMENT_PURCHASE ||
        route === routes.PAYMENT_REPAY_OWED
    );
});

export const getIsEndorsementFlow = createSelector(getActiveRoute, (route) => {
    return route === routes.INSURANCE_MODIFY;
});

// https://github.com/popularlab/product/issues/3006
// case when the user is not yet in warning period, but have zero day left, and AR failed
export const willEnterWarningPeriod = createSelector(
    getBalanceInDays,
    (state: StoreShape) => state.coverage.endTimeMs,
    (state: StoreShape) => state.payment.scheduledOffDate,
    getIsAutoReloadEnabled,
    (state: StoreShape) => state.payment.consentForFallbackOff,
    (
        balanceInDays,
        currentCoverageEndMs,
        scheduledOff,
        isAutoReloadEnabled,
        hasFallbackOffConsent
    ) => {
        const coverageEndDate = new Date(currentCoverageEndMs as number);
        const scheduledOffDate = new Date(scheduledOff as Date);
        // https://github.com/popularlab/product/issues/3114
        coverageEndDate.setMilliseconds(0);
        scheduledOffDate.setMilliseconds(0);
        return (
            balanceInDays === 0 &&
            (coverageEndDate < scheduledOffDate || (isAutoReloadEnabled && !hasFallbackOffConsent))
        );
    }
);

const getShowBalanceLowBanner = createSelector(
    getAccessLevelData,
    (state: StoreShape) => state.balance.balanceInDays,
    getIsAutoReloadEnabled,
    (state: StoreShape) => state.coverage.status,
    (state: StoreShape) => state.payment.scheduledOffDate,
    willEnterWarningPeriod,
    getIsPaymentFlow,
    (
        accessLevel,
        balanceInDays,
        autoReloadEnabled,
        coverageStatus,
        scheduledOffDate,
        enterWarningPeriod,
        isPaymentFlow
    ) => {
        if (
            accessLevel.notBasic &&
            balanceInDays <= ACCOUNT_LOW_THRESHOLD_DAYS &&
            !autoReloadEnabled &&
            coverageStatus === status.ACTIVE &&
            Boolean(scheduledOffDate) &&
            !enterWarningPeriod &&
            !isPaymentFlow
        ) {
            return true;
        }
        return false;
    }
);

export const getSuppressBanners = createSelector(
    getIsPaymentFlow,
    getIsEndorsementFlow,
    (isPaymentFlow, isEndorsementFlow) => {
        return isPaymentFlow || isEndorsementFlow;
    }
);

export const getCoverageExtensionState = createSelector(
    (state: StoreShape) => state.balance.isOnCoverageExtension,
    (state: StoreShape) => state.balance.willEnterCoverageExtension,
    (isOnCoverageExtension, willEnterCoverageExtension) => ({
        isOnCoverageExtension,
        willEnterCoverageExtension
    })
);

export const getGlobalAppBannerState = createSelector(
    (state: StoreShape) => state.ui.newVersionAvailable,
    (state: StoreShape) => state.ui.announcement,
    (newVersionAvailable, announcement) => {
        return {
            newVersionAvailable,
            announcement: sessionStorage.getItem(alerts.ANNOUNCEMENT) ? null : announcement
        };
    }
);

// https://github.com/popularlab/product/issues/351
// Additional discussion: https://github.com/popularlab/product/issues/2134#issuecomment-1289594657
export const getActiveBanner = createSelector(
    getAccessLevelData,
    (state: StoreShape) => state.account.phone,
    (state: StoreShape) => state.account.isEmailVerified,
    (state: StoreShape) => state.balance.balanceInDays,
    getOverdrawnBalance,
    isSignupComplete,
    getActiveRoute,
    getSuppressBanners,
    getIsRewritePending,
    getShowBalanceLowBanner,
    getCoverageExtensionState,
    getGlobalAppBannerState,
    getCoverageStatusData,
    (
        accessLevel,
        phone,
        isEmailVerified,
        balanceInDays,
        overdrawnBalance,
        isSignupComplete,
        activeRoute,
        isBannerSuppressed,
        isRewritePending,
        isBalanceLowBanner,
        coverageExtensionState,
        globalAppBannerState,
        status
    ) => {
        // Mind the order.
        // Checks are executed in order based on alert priority.

        if (isBannerSuppressed) {
            return null;
        }

        // Ensure that we show banner when account is empty and there is no fallback off consent to alert user of
        // impending warning period
        if (
            accessLevel.notBasic &&
            balanceInDays === 0 &&
            overdrawnBalance === 0 &&
            !accessLevel.hasFallbackOffConsent &&
            status.isActive
        ) {
            return alerts.ACCOUNT_EMPTY;
        }

        if (globalAppBannerState.newVersionAvailable) {
            return alerts.NEW_APP_VERSION;
        }

        if (activeRoute !== routes.QUOTE && globalAppBannerState.announcement) {
            return alerts.ANNOUNCEMENT;
        }

        if (accessLevel.isLapsed && overdrawnBalance) {
            return alerts.ACCOUNT_LAPSED;
        }
        if (coverageExtensionState.isOnCoverageExtension) {
            return alerts.COVERAGE_EXTENSION;
        }
        if (!coverageExtensionState.willEnterCoverageExtension) {
            if (accessLevel.isRestrictedAndOverdrawn) {
                return alerts.ACCOUNT_OVERDRAWN;
            }

            if (isBalanceLowBanner) {
                return alerts.ACCOUNT_BALANCE_LOW;
            }

            if (
                accessLevel.notBasic &&
                balanceInDays === 0 &&
                overdrawnBalance === 0 &&
                status.isActive
            ) {
                return alerts.ACCOUNT_EMPTY;
            }
        }

        if (accessLevel.isCanceled && !isRewritePending) {
            return alerts.POLICY_CANCELED;
        }

        if (!isEmailVerified && isSignupComplete && activeRoute !== routes.ACCOUNT_VERIFY_EMAIL) {
            // Only way to dismiss is by clicking verification link sent by email.
            return alerts.VERIFY_EMAIL;
        }

        if (!phone && isSignupComplete) {
            return alerts.VERIFY_PHONE;
        }

        // No banner to show.
        return null;
    }
);

// Check if all payment card data is present in store.
// @returns {Boolean}
export const isCardOnFile = createSelector(getCardData, (card) =>
    Boolean(get(card, 'brand') && get(card, 'last4'))
);

// Return formatted string to surface payment card data on client.
// @returns {String}
export const paymentCardString = createSelector(getCardData, isCardOnFile, (card, isOnFile) =>
    isOnFile ? `${get(card, 'brand')} **** **** **** ${get(card, 'last4')}` : ''
);

export const isApiLoading = createSelector(
    getLoadingArray,
    getActivePolicyId,
    (loading, policyId) => {
        const status: any = {};
        // status.any returns true if any api request is in progress.
        status.any = Boolean(loading.length);
        // Attach actual values from loading array as keys since apiCall fn
        // allows specifying label to be used instead of URL.
        // https://github.com/popularlab/hugo-client-app/blob/master/src/scripts/actions/api.js#L61
        loading.forEach((key) => {
            status[key] = true;
        });
        // Attach all API keys so we can save actual values from paths defined as functions.
        API_KEYS.forEach((key) => {
            if (typeof (apiPaths as any)[key] === 'string') {
                status[key] = loading.includes((apiPaths as any)[key]);
            }

            // Only paths that require specifying active policy in url
            // are defined as functions to be retrived dynamically.
            if (typeof (apiPaths as any)[key] === 'function') {
                status[key] = loading.includes((apiPaths as any)[key](policyId));
            }
        });
        return status;
    }
);

export const getActiveLayout = createSelector(getActiveRoute, (activeRoute) => activeRoute.layout);

export const getReferralUrl = createSelector(
    (state: StoreShape) => state.account.referralCode,
    (referralCode) => `${process.env.HUGO_CLIENT_URL}/join/?ref=${referralCode}`
);

export const isTicketOpen = createSelector(
    (state: StoreShape) => state.account.openTickets,
    (openTickets) => {
        const tickets: any = {};
        openTickets.forEach((key) => {
            tickets[key] = true;
        });
        return tickets;
    }
);

export const getInsuredVehicles = createSelector(
    (state: StoreShape) => state.policy,
    (policy) => policy.vehicles.filter((vehicle) => vehicle.isIncluded)
);

export const getCurrentSignupStep = createSelector(
    (state: StoreShape) => state.signup,
    (signup) => (signup && signup.navigation[0]) || {}
);

export const getIncludedDrivers = createSelector(
    (state: StoreShape) => state.policy.additionalDrivers,
    (additionalDrivers = []) => additionalDrivers.filter((driver) => driver.isIncluded)
);

export const getBundleType = createSelector(
    (state: StoreShape) => get(state, 'signup.meta.quoteDriveDays') || 0,
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.meta.coverageBundleType'),
    (state: StoreShape) => get(state, 'signup.meta.coverageBundleType'),
    (state: StoreShape) => get(state, 'signup.meta.coverageBundles'),
    (quoteDriveDays, coverageBundleBe, coverageBundleFe, availableBundles) => {
        if (coverageBundleFe) {
            return coverageBundleFe;
        }
        if (coverageBundleBe) {
            return coverageBundleBe;
        }
        if (
            availableBundles.includes(CoverageBundleType.FLEX) &&
            availableBundles.includes(CoverageBundleType.BASIC)
        ) {
            // Heuristic used to determine initial bundle for QS before user accepts first quote:
            // If user selected 5 days or less, put them in FLEX bucket, else start them on BASIC
            return quoteDriveDays > 5 ? CoverageBundleType.BASIC : CoverageBundleType.FLEX;
        }
        return availableBundles[0];
    }
);

// Needs to match IPolicyCoverages
export const getAllCoverages = createSelector(
    (state: StoreShape) => state.policy.coverageOptions,
    (state: StoreShape) => state.policy.type,
    (state: StoreShape) => state.policy.vehicles,
    (policyCoverages, policyType, allVehicles) => ({
        policy: {
            coverageOptions: policyCoverages,
            policyType
        },
        vehicles: allVehicles.filter((v: IVehicle) => v.isIncluded)
    })
);

// Needs to match IPolicyCoverages
export const getAllQuoteApplicationCoverages = createSelector(
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.policy.coverageOptions'),
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.policy.policyType'),
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.vehicles'),
    (policyCoverages, policyType, allVehicles): IPolicyCoverages => {
        const vehicles = mapDiscoveredReportedItems<IMappedVehicle>(allVehicles).filter(
            (v) => v.isIncluded
        );
        return {
            policy: {
                coverageOptions: policyCoverages,
                policyType
            },
            vehicles: vehicles.filter((v) => v.isIncluded)
        };
    }
);

export const getIsBalanceAboveMinimumForBind = createSelector(
    getBalance,
    (state: StoreShape) => get(state, 'signup.quoteApplication.quote.dayRate'),
    (state: StoreShape) => get(state, 'signup.quoteApplication.state'),
    (balance, signupDayRate, geo) => {
        // At signup for expired policies balance in days will be 0 so check balance.
        // Balance needs to be more than signup day rate * minimum days threshold.
        const minDaysThreshold = isReinstateDisabled(geo) ? 1 : 3;
        return balance >= signupDayRate * minDaysThreshold;
    }
);

export const getCurrentEndorsementStep = createSelector(
    (state: StoreShape) => state.endorsement,
    (endorsement) => (endorsement && endorsement.navigation && endorsement.navigation[0]) || {}
);

export const getAmountAvailableForWithdraw = createSelector(
    getBalance,
    getMinimumWithdrawAmountRequired,
    getCoverageStatusData,
    (balance, amountRequired, status) => {
        return status.notPaused ? Math.max(0, balance - amountRequired) : balance;
    }
);

export const getNeedsRefillPaused = createSelector(
    getCoverageStatusData,
    getBalance,
    getDayRate,
    (status, balance, dayRate) => {
        if (status.isPaused && balance < dayRate) {
            return true;
        }
        return false;
    }
);

export const isInQuoteFlow = createSelector(getIsLogged, getActiveUrl, (isLogged, activeUrl) => {
    return !isLogged || activeUrl === '/quote';
});

export const getZipCode = createSelector(
    (state: StoreShape) => state.policy.homeAddress,
    (state: StoreShape) => get(state, 'signup.quoteApplication.data.pni.homeAddress'),
    (state: StoreShape) => get(state, 'signup.quoteApplication.zipCode'),
    (policyAddress, quoteAddress, quoteZipCode): string | undefined => {
        if (policyAddress && policyAddress.zipCode) {
            return policyAddress.zipCode;
        }

        if (quoteAddress && quoteAddress.zipCode) {
            return quoteAddress.zipCode;
        }

        return quoteZipCode;
    }
);

export const getQuoteApplicationFullPrice = createSelector(
    (state: StoreShape) => get(state, 'signup.quoteApplication'),
    (quoteApplication?: QuoteApplication): number => {
        if (!quoteApplication) {
            return 0;
        }

        if (!quoteApplication.quote) {
            return 0;
        }

        const { premium, unpaidFeeTotal } = quoteApplication.quote;

        return premium + (unpaidFeeTotal || 0);
    }
);

export const getBlockDriverExclusionEndorsement = createSelector(
    getActiveGeo,
    getActiveControllerVersion,
    (state: StoreShape) => get(state, 'policy'),
    (geo, controllerVersion, policy): boolean => {
        if (!isAcceptance(geo, controllerVersion)) {
            return false;
        }

        if (geo === StateCode.VA) {
            // Virginia does not allow driver exclusions
            // @see https://github.com/popularlab/product/issues/4265
            return true;
        }

        // Driver exclusions aren't allowed with certain higher limit coverages
        // @see https://github.com/popularlab/product/issues/3680
        const pdLimit = 2500000;
        let biLimit = { perPersonLimit: 2500000, totalLimit: 5000000 };
        if (geo === StateCode.TX) {
            biLimit = { perPersonLimit: 3000000, totalLimit: 6000000 };
        }
        if (
            get(policy, 'coverageOptions.bi.perPersonLimit') > biLimit.perPersonLimit ||
            get(policy, 'coverageOptions.bi.totalLimit') > biLimit.totalLimit ||
            get(policy, 'coverageOptions.pd.propertyDamageLimit') > pdLimit
        ) {
            return true;
        }
        return false;
    }
);

export const getIsInFeeForgivenessPeriod = createSelector(
    (state: StoreShape) =>
        state.account.lastCoverageSession && state.account.lastCoverageSession.daysSinceEnd,
    (state: StoreShape) => state.account.daysForReinstateFeeForgiveness,
    (daysSinceLastCoverage, daysForReinstateFeeForgiveness) => {
        return daysSinceLastCoverage && daysSinceLastCoverage <= daysForReinstateFeeForgiveness;
    }
);

export const getIsAcceptance = createSelector(
    getActiveGeo,
    getActiveControllerVersion,
    (geo, controllerVersion): boolean => isAcceptance(geo, controllerVersion)
);

export const getIsSafeAuto = createSelector(
    getActiveGeo,
    getActiveControllerVersion,
    (geo, controllerVersion): boolean => isSafeAuto(geo, controllerVersion)
);

export const getIsRateSafe = createSelector(
    getCoverageStatusData,
    getIsInFeeForgivenessPeriod,
    getIsAcceptance,
    (coverageStatus, isInFeeForgivenessPeriod, isAcceptance) => {
        return isAcceptance && (coverageStatus.notPaused || isInFeeForgivenessPeriod);
    }
);

export const getHasPhoneNumber = (state: StoreShape) => !!state.account.phone;

export const getHasRateSafeRemindersDisabled = (state: StoreShape) =>
    state.ui.hasRateSafeRemindersDisabled;

export const getIsPolicyBinderAvailable = createSelector(
    getActiveGeo,
    (state: StoreShape) => state.policy.startsAt,
    (state: StoreShape) => state.payment.unitTimeScalar,
    (geo, policyStartDate, scalar) => {
        if (!policyStartDate) {
            return false;
        }

        const daysSinceStart =
            (Date.now() - policyStartDate.getTime()) / (DAY_IN_MILLISECONDS * scalar);
        if (geo === StateCode.GA && daysSinceStart <= POLICY_BINDER_AVAILABLE_DAYS) {
            return true;
        }
        return false;
    }
);

export const getIsStateFilingGated = createSelector(
    getActiveGeo,
    (state: StoreShape) => state.policy.startsAt,
    (state: StoreShape) => state.payment.unitTimeScalar,
    (geo, policyStartDate, scalar) => {
        if (!policyStartDate) {
            return false;
        }

        const elapsedTime = Date.now() - policyStartDate.getTime();
        if (elapsedTime > 0) {
            const elapsedDays = elapsedTime / (DAY_IN_MILLISECONDS * scalar);
            if (FAC_GEOS.includes(geo as StateCode) && elapsedDays <= GATED_DAYS) {
                return true;
            }
        }
        return false;
    }
);

export const getShouldRewrite = (state: StoreShape) =>
    Boolean(get(state, 'policy.rewriteMeta.shouldRewrite'));

// NOTE: We're not using createSelector because function is impure due to Date.now()
export const getMaxDaysForPurchase = (state: StoreShape) => {
    const { expiresAt, renewalMeta } = state.policy;
    const { isNonRenewal } = renewalMeta;
    const { balanceInDays } = state.balance;
    const scalar = state.payment.unitTimeScalar;
    if (isNonRenewal) {
        const oneDay = DAY_IN_MILLISECONDS * scalar;
        const policyExpires = new Date(expiresAt as Date);
        // We're rounding up to allow users to reach end of their policy
        // and account for fractional days (when coverage period doesn't match policy expire date).
        const daysUntilExpired = Math.ceil((policyExpires.valueOf() - Date.now()) / oneDay);
        return daysUntilExpired - balanceInDays;
    }
    return MAXIMUM_DAYS_TO_PURCHASE;
};

export const getIsPurchaseEnabled = (state: StoreShape) => {
    const hasPolicy = Boolean(getActivePolicyId(state));
    const { notPaused } = getCoverageStatusData(state);
    return hasPolicy && notPaused && getMaxDaysForPurchase(state) > 0;
};

export const getHasHigherLimits = (state: StoreShape) => state.policy.hasHigherLimits;
