import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface IGridProps {
    className?: string;
    cols?: {
        xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    };
    container?: boolean;
    gap?: {
        xs?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        sm?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        md?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        lg?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    };
}

// Default cols and gap values are set to follow
// Hugo brand guidelines. Check with a designer
// before modifying.
const Grid: FunctionComponent<IGridProps> = ({
    children,
    className,
    container = false,
    gap = { xs: 3, sm: 4 },
    cols = { xs: 4, md: 8 },
    ...rest
}) => (
    <div
        className={classnames('l-grid', className, {
            'l-container': container,
            [`l-grid__cols-${cols.xs}`]: cols.xs,
            [`sm:l-grid__cols-${cols.sm}`]: cols.sm,
            [`md:l-grid__cols-${cols.md}`]: cols.md,
            [`lg:l-grid__cols-${cols.lg}`]: cols.lg,
            [`l-grid__gap-${gap.xs}`]: gap.xs,
            [`sm:l-grid__gap-${gap.sm}`]: gap.sm,
            [`md:l-grid__gap-${gap.md}`]: gap.md,
            [`lg:l-grid__gap-${gap.lg}`]: gap.lg
        })}
        {...rest}
    >
        {children}
    </div>
);

export default Grid;
