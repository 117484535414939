import { h } from 'preact';
import { connect } from 'react-redux';

import { getPhoneNumber, getAccountEmail } from '../../selectors';
import { phoneNumber } from '../../helpers/masks';
import { Button } from '../components/Button';
import { IconLikeWhite } from '../icons';
import { Modal, ModalContent, ModalIcon, ModalActions } from '../components/Modal';

export const ModalReturnToQuotePure = ({ phone, email, onHide }) => (
    <Modal dark drawer onHide={onHide}>
        <ModalIcon>
            <IconLikeWhite size="32" />
        </ModalIcon>
        <ModalContent center>
            <p>
                Great! We’ll send a link to{' '}
                <span className="u-no-wrap u-font-bold">{phoneNumber(phone) || email}</span>.
            </p>
            <p>When you’re ready to finish, use the link to pick up where you left off.</p>
        </ModalContent>
        <ModalActions>
            <Button link inverted href={process.env.HUGO_CLIENT_URL}>
                Finish later
            </Button>
        </ModalActions>
    </Modal>
);

const mapStateToProps = (state) => ({
    phone: getPhoneNumber(state),
    email: getAccountEmail(state)
});

export default connect(mapStateToProps)(ModalReturnToQuotePure);
