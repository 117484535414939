import { h } from 'preact';
import { connect } from 'react-redux';

import { routes } from '../../routes';
import { getIsPolicyTraditional } from '../../selectors';
import { Banner, BannerActions, BannerTitle, BannerContent } from '../components/Banner';
import { ButtonLink } from '../components/Button';

const BannerVerifyPhone = () => (
    <Banner light>
        <BannerTitle>Welcome to Hugo!</BannerTitle>
        <BannerContent>
            First, please verify your phone number, so you can request instant proof of insurance,
            or report an accident by text message.
        </BannerContent>
        <BannerActions>
            <ButtonLink className="track:phone-banner__verify" href={routes.ACCOUNT_PHONE.path}>
                Verify
            </ButtonLink>
        </BannerActions>
    </Banner>
);

const mapStateToProps = (state) => ({
    isTraditional: getIsPolicyTraditional(state)
});

export default connect(mapStateToProps)(BannerVerifyPhone);
