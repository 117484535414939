// Unified export of all app views.
// All views are loaded async using HOC.

import asyncComponent from '../hoc/AsyncComponent';

export const Accident = asyncComponent(() => import('./Accident'));
export const Account = asyncComponent(() => import('./Account'));
export const AccountEmail = asyncComponent(() => import('./AccountEmail'));
export const AccountPhone = asyncComponent(() => import('./AccountPhone'));
export const AccountPushNotifications = asyncComponent(() => import('./AccountPushNotifications'));
export const AccountVerifyEmail = asyncComponent(() => import('./AccountVerifyEmail'));
export const Dashboard = asyncComponent(() => import('./Dashboard'));
export const Documents = asyncComponent(() => import('./Documents'));
export const DocumentsPolicyBinder = asyncComponent(() => import('./DocumentsPolicyBinder'));
export const DocumentsPolicyPacket = asyncComponent(() => import('./DocumentsPolicyPacket'));
export const DocumentsLetterOfExperience = asyncComponent(
    () => import('./DocumentsLetterOfExperience')
);
export const DocumentsRegisterVehicle = asyncComponent(() => import('./DocumentsRegisterVehicle'));
export const DocumentsDeclarationsPage = asyncComponent(
    () => import('./DocumentsDeclarationsPage')
);
export const DocumentsStateFiling = asyncComponent(() => import('./DocumentsStateFiling'));
export const DocumentsStateFilingOld = asyncComponent(
    () => import('./DocumentsStateFiling/DocumentsStateFiling.old')
);
export const ErrorView = asyncComponent(() => import('./Error'));
export const Help = asyncComponent(() => import('./Help'));
export const HelpClaims = asyncComponent(() => import('./HelpClaims'));
export const HelpHugoText = asyncComponent(() => import('./HelpHugoText'));
export const Helpdesk = asyncComponent(() => import('./Helpdesk'));
export const HelpdeskFeedback = asyncComponent(() => import('./HelpdeskFeedback'));
export const Insurance = asyncComponent(() => import('./Insurance'));
export const InsuranceDiscounts = asyncComponent(() => import('./InsuranceDiscounts'));
export const InsuranceExtension = asyncComponent(() => import('./InsuranceExtension'));
export const InsuranceModify = asyncComponent(() => import('./InsuranceModify'));
export const InsurancePolicy = asyncComponent(() => import('./InsurancePolicy'));
export const InsuranceProof = asyncComponent(() => import('./InsuranceProof'));
export const InsuranceRateBreakdown = asyncComponent(() => import('./InsuranceRateBreakdown'));
export const InsuranceRateSafe = asyncComponent(() => import('./InsuranceRateSafe'));
export const InsuranceRenewal = asyncComponent(() => import('./InsuranceRenewal'));
export const InsuranceTerminate = asyncComponent(() => import('./InsuranceTerminate'));
export const InsuranceTerminateToChange = asyncComponent(
    () => import('./InsuranceTerminateToChange')
);
export const InsuranceTerminateToChangeAddress = asyncComponent(
    () => import('./InsuranceTerminateToChangeAddress')
);
export const Login = asyncComponent(() => import('./Login'));
export const LoginHelp = asyncComponent(() => import('./LoginHelp'));
export const Maintenance = asyncComponent(() => import('./Maintenance'));
export const Payment = asyncComponent(() => import('./Payment'));
export const PaymentCard = asyncComponent(() => import('./PaymentCard'));
export const PaymentCheckout = asyncComponent(() => import('./PaymentCheckout'));
export const PaymentHistory = asyncComponent(() => import('./PaymentHistory'));
export const PaymentPurchase = asyncComponent(() => import('./PaymentPurchase'));
export const PaymentRefillAndOn = asyncComponent(() => import('./PaymentRefillAndOn'));
export const PaymentRepayOwedBalance = asyncComponent(() => import('./PaymentRepayOwedBalance'));
export const PaymentSettings = asyncComponent(() => import('./PaymentSettings'));
export const PaymentRefund = asyncComponent(() => import('./PaymentRefund'));
export const Quote = asyncComponent(() => import('./Quote'));
export const Register = asyncComponent(() => import('./Register'));
export const RegistrationProtection = asyncComponent(() => import('./RegistrationProtection'));
export const ReturnToQuote = asyncComponent(() => import('./ReturnToQuote'));
export const Rewards = asyncComponent(() => import('./Rewards'));
export const RewardsShare = asyncComponent(() => import('./RewardsShare'));
export const RewardsTerms = asyncComponent(() => import('./RewardsTerms'));
export const Testing = asyncComponent(() => import('./Testing'));
