/* eslint-disable no-param-reassign */
// Wrapper around Fetch API that normalizes req. and error handling.

import { HugoCookie } from '@popularlab/enums';
import Cookies from 'js-cookie';

import { errorCodes, apiPaths, cookies } from '../constants';
import { get } from './misc';
import { localStorage } from './storage';

let refresh: Promise<unknown> | null = null;
let retryAttempted = false;

export interface FetchPromiseOptions extends Omit<RequestInit, 'body'> {
    hasFiles?: boolean;
}

// Using named export so we can set up spies and mocks in tests.
export const fetchPromise = <TResponse>(
    url: string,
    options: FetchPromiseOptions = {},
    data: any = null,
    refreshAttempts = 5
): Promise<TResponse> => {
    const opts: RequestInit = options || {};
    const headers = new Headers(opts.headers || {});

    if (process.env.HUGO_APP_VERSION) {
        headers.set('x-hugo-client-version', process.env.HUGO_APP_VERSION);
    }

    if (!options || !options.hasFiles) {
        headers.set('content-type', 'application/json');
    }

    // https://github.com/popularlab/product/issues/1028
    const csrfTokenCookie = Cookies.get(HugoCookie.CSRF_TOKEN_COOKIE);
    if (csrfTokenCookie) {
        headers.set('X-CSRF-Token', csrfTokenCookie);
    }

    opts.headers = headers;

    if (data) {
        if (options && options.hasFiles) {
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });
            opts.body = formData;
        } else {
            opts.body = JSON.stringify(data);
        }
    }

    return fetch(url, {
        // Default fetch options
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
        cache: 'no-store',
        credentials: 'include',
        method: 'GET',
        ...opts
    })
        .then((response) => {
            if ((get(opts, 'method') || '').toLowerCase() === 'head') {
                return response;
            }

            // @WARN: Assuming Hugo API will always respond with
            // object and application/json header.
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return (
                    response
                        .json()
                        // eslint-disable-next-line
                        .then((json) => {
                            if (response.ok) {
                                return json;
                            }
                            // Attach response status code to error object.
                            json.status = response.status;
                            return Promise.reject(json);
                        })
                );
            }

            // @WARN: Even if response.ok, fall into catch
            // since it's not expected type.
            throw response;
        })
        .catch((error) => {
            // fetch throws TypeError for network errors.
            // In case of NETWORK_ERROR wait for 1s and try re-fetch once.
            if (error instanceof TypeError) {
                if (retryAttempted) {
                    throw errorCodes.NETWORK_ERROR;
                }

                retryAttempted = true;
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() => fetchPromise(url, opts, data))
                    .catch(() => {
                        throw errorCodes.NETWORK_ERROR;
                    });
            }

            // Handle expired session by trying to get new access token
            if (error.status === 401) {
                // Refresh already in progress
                if (refresh && url !== apiPaths.REFRESH_LOGIN) {
                    return refresh.then(() => fetchPromise(url, opts, data));
                }

                // Check for accountId in localStorage
                const accountId = localStorage.getItem(cookies.loginCookie);

                // No accountId means first-time visit.
                if (!accountId && !refresh) {
                    console.info('Clean-slate visit. Ignore errors.');
                    throw error;
                }

                if (!refresh) {
                    console.info('Session expired. Getting fresh token.');
                    refresh = fetchPromise(
                        apiPaths.REFRESH_LOGIN,
                        { method: 'POST' },
                        { accountId }
                    )
                        .then(() => {
                            refresh = null;
                            localStorage.setItem(cookies.loginCookie, accountId);
                            return fetchPromise(url, opts, data);
                        })
                        .catch((refreshError) => {
                            refresh = null;
                            if (refreshError.status === 401) {
                                // Couldn't refresh the token
                                localStorage.removeItem(cookies.loginCookie);
                            }
                            throw refreshError;
                        });

                    return refresh;
                }
            }

            // When a Coverage Session expires, there's a non-trivial amount of time
            // delay before the next coverage session is written (1-3 sec due to eventqueue),
            // in which case the policy is in undefined state. We re-try BE 5 times in 1s intervals
            // until we get correct result or other kind of error.
            if (refreshAttempts > 0 && error && error.error === errorCodes.REFRESH_REQUIRED) {
                return new Promise((resolve) => {
                    setTimeout(resolve, 2000);
                }).then(() => {
                    return fetchPromise(url, opts, data, refreshAttempts - 1);
                });
            }

            if (!refreshAttempts && error && error.error === errorCodes.REFRESH_REQUIRED) {
                throw new Error(errorCodes.REFRESH_FAILED);
            }

            // This is full valid response thrown bc content-type wasn't application/json
            if (error.ok && error.ok === true) {
                throw errorCodes.UNEXPECTED_RESPONSE_TYPE;
            }

            // Standard Hugolith error
            if (error && error.error) {
                throw error;
            }

            // Probably unhandled 3rd party error.
            if (error && error.status === 500) {
                throw errorCodes.INTERNAL_SERVER_ERROR;
            }

            if (error && error.status === 404) {
                throw errorCodes.MISSING_ENDPOINT;
            }

            if (error && error.status === 429) {
                throw errorCodes.TOO_MANY_REQUESTS;
            }

            throw errorCodes.UNKNOWN_API_ERROR;
        });
};
