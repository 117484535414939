import uuid from 'uuid';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { h, FunctionComponent } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

import { ICoverageBindSubmitParameters, IHocProps, IWithCoverageBindProps } from './coverage.types';
import { getActiveGeo, getActivePolicyId, getCoverageStatusData } from '../../../selectors';
import { CoverageStatus } from '../../../constants/status';
import { StoreShape } from '../../../types/store.types';
import ModalLocation from '../Location/ModalLocation';
import { hideModal, showApiFailModal, showOnModal } from '../../../actions/ui';
import { Screenshot } from '../../types';
import { bindCoverageProposal, createCoverageProposal, hydrateData } from '../../../actions/api';
import { get, getDOMScreenshot, isSameMinute, waitAtLeast } from '../../../helpers/misc';
import { errorCodes } from '../../../constants';
import { CoverageProposalParams } from '../../proposal.types';

function withLocation<T extends IHocProps>(
    WrappedComponent: FunctionComponent<IWithCoverageBindProps>
): (props: T) => h.JSX.Element {
    const WithLocation = (props: T) => {
        const [showLocation, setShowLocation] = useState(false);
        const [screenshot, setScreenshot] = useState<Screenshot | null | undefined>(null);
        const [isLoading, setIsLoading] = useState(false);
        const [isBinding, setIsBinding] = useState(false);
        const [confirmedDayRate, setConfirmedDayRate] = useState<Number | undefined>(undefined);
        const [confirmedScheduledOffDate, setConfirmedScheduledOffDate] = useState<
            Date | undefined
        >(undefined);
        const idempotencyKey = uuid();

        const locationRequired = useMemo(
            () => props.locationRequired && props.status === CoverageStatus.PAUSED,
            [props.policyGeo, props.status]
        );

        const reloadCoverageProposal = useCallback(
            (location?: GeolocationPosition | null) => {
                const data: CoverageProposalParams = {
                    policyId: props.policyId,
                    targetCoverageStatus:
                        props.status === CoverageStatus.ACTIVE
                            ? CoverageStatus.PAUSED
                            : CoverageStatus.ACTIVE,
                    forceError: props.forceError
                };
                if (location) {
                    data.location = location;
                }
                setIsLoading(true);
                return waitAtLeast(1000, () => props.dispatch(createCoverageProposal(data)))
                    .catch((error) => {
                        if (
                            error.error === errorCodes.INSUFFICIENT_BALANCE ||
                            error.error === errorCodes.AR_INSUFFICIENT_BALANCE ||
                            error.error === errorCodes.REQUIRES_MORE_INFO ||
                            error.error === errorCodes.POLICY_NOT_ACTIVE
                        ) {
                            return;
                        }
                        props.dispatch(hideModal());
                    })
                    .finally(() => setIsLoading(false));
            },
            [props.policyId]
        );

        const bindCoverageExtension = useCallback(
            (proposalId: string, clickScreenshot?: Screenshot | null) => {
                setIsLoading(true);
                const consentForFallbackOff = true;

                return props
                    .dispatch(
                        bindCoverageProposal(
                            props.policyId,
                            proposalId,
                            idempotencyKey,
                            consentForFallbackOff,
                            clickScreenshot
                        )
                    )
                    .then(() => {
                        setIsLoading(false);
                        props.dispatch(
                            showOnModal({
                                message: 'Insurance ON!'
                            })
                        );
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        // The front end may be out of sync,
                        // so synchronize and show the user the expected success modal
                        // if the received error matches
                        props.dispatch(hydrateData());
                        if (
                            props.status === CoverageStatus.PENDING_PAUSE &&
                            error.error === errorCodes.COVERAGE_ALREADY_ON
                        ) {
                            props.dispatch(
                                showOnModal({
                                    message: 'Insurance ON!'
                                })
                            );
                        } else {
                            props.dispatch(showApiFailModal());
                        }
                    });
            },
            [props.policyId, props.status]
        );

        const onConfirmStep = useCallback(
            (params: ICoverageBindSubmitParameters) => {
                const confirmScreenshot = getDOMScreenshot(params.event);
                setScreenshot(confirmScreenshot);
                setConfirmedDayRate(props.newDayRate);
                setConfirmedScheduledOffDate(props.scheduledOffDate);
                if (!params.isTurningOn) {
                    throw new Error(
                        'withLocation should only be used in the context of turning insurance on'
                    );
                }
                // Only reconfirm payment settings when turning ON insurance. Don't need to reconfirm if
                // clearing a PENDING_OFF
                if (
                    props.status === CoverageStatus.PAUSED &&
                    params.isTurningOn &&
                    locationRequired
                ) {
                    setShowLocation(true);
                    return Promise.resolve();
                }
                return bindCoverageExtension(props.coverageProposalId, confirmScreenshot);
            },
            [
                props.status,
                props.coverageProposalId,
                props.newDayRate,
                props.scheduledOffDate,
                bindCoverageExtension
            ]
        );

        const onLocationConfirmStep = useCallback(
            (location: GeolocationPosition) => {
                setIsBinding(true);
                reloadCoverageProposal(location).then((data) => {
                    if (
                        confirmedDayRate !== data.data.newDayRate ||
                        !confirmedScheduledOffDate ||
                        !isSameMinute(confirmedScheduledOffDate, data.data.scheduledOffDate)
                    ) {
                        setIsBinding(false);
                        setShowLocation(false);
                        return;
                    }
                    bindCoverageExtension(data.data.proposalId, screenshot);
                });
            },
            [confirmedDayRate, confirmedScheduledOffDate, screenshot, setIsBinding, setShowLocation]
        );

        if (showLocation) {
            return (
                <ModalLocation
                    onSubmit={onLocationConfirmStep}
                    isBinding={isBinding}
                    onHide={() => props.dispatch(hideModal())}
                    loading={props.loading || isLoading}
                />
            );
        }

        return (
            <WrappedComponent
                {...props}
                loading={props.loading || isLoading}
                onSubmit={locationRequired ? onConfirmStep : undefined}
            />
        );
    };

    return WithLocation;
}

const mapStateToProps = (state: StoreShape) => ({
    forceError: state.ui.forceError,
    locationRequired: get(state, 'proposal.locationRequired'),
    policyId: getActivePolicyId(state),
    policyGeo: getActiveGeo(state),
    status: getCoverageStatusData(state).status,
    newDayRate: state.proposal.newDayRate,
    scheduledOffDate: state.proposal.scheduledOffDate
});

export default compose(connect(mapStateToProps), withLocation);
