// Creates wrapper component with passed class for styling.

import { h } from 'preact';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const wrapper = (baseClass, Element = 'div') => {
    const wrapped = ({ className, children, ...rest }) => (
        <Element className={classnames(baseClass, className)} {...rest}>
            {children}
        </Element>
    );

    wrapped.defaultProps = {
        className: ''
    };

    wrapped.propTypes = {
        className: PropTypes.string
    };

    return wrapped;
};

export default wrapper;
