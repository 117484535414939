import { h, FunctionComponent } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { alerts, mediaQuery } from '../../../constants';
import { getAccessLevelData } from '../../../selectors';
import { useMediaQuery } from '../../../hooks';
import BannerRoot from '../../hoc/BannerRoot';
import { ButtonHelp } from '../../components/Button';
import PortalNavigation from './PortalNavigation';
import PortalActions from './PortalActions';
import PortalHeader from './PortalHeader';
import { StoreShape } from '../../../types/store.types';

import { routes } from '@/routes';

interface IPortalLayoutProps {
    activeUrl?: string;
    isBasic?: boolean;
    visibleBanner?: alerts;
}

export const PortalLayout: FunctionComponent<IPortalLayoutProps> = (props) => {
    const isDesktop = useMediaQuery(mediaQuery.MIN_MEDIUM);
    const scrollContainer = useRef<HTMLDivElement>(null);

    // This effect runs after url changes,
    // and forces the main viewport to scroll to the top
    useEffect(() => {
        if (!scrollContainer.current) {
            return;
        }

        scrollContainer.current.scrollTop = 0;
    }, [props.activeUrl]);

    return (
        <div className={classnames('l-portal', { 'l-portal--basic': props.isBasic })}>
            <div className="l-portal__header">
                {isDesktop ? (
                    <span className="l-btn-helpdesk">
                        <ButtonHelp
                            className="track:ticket-open"
                            size={30}
                            href={routes.HELPDESK.path}
                        />
                    </span>
                ) : (
                    <PortalHeader />
                )}
            </div>

            <div className="l-portal__banner">
                <BannerRoot visibleBanner={props.visibleBanner} />
            </div>

            <div className="l-portal__body" ref={scrollContainer}>
                {props.children}
            </div>

            <div className="l-portal__actions">{!props.isBasic && <PortalActions />}</div>

            <PortalNavigation className="l-portal__nav" />
        </div>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    activeUrl: state.ui.activeUrl,
    visibleBanner: state.ui.visibleBanner,
    isBasic: getAccessLevelData(state).isBasic
});

export default connect(mapStateToProps)(PortalLayout);
