// Redux container for Modal alerts.
// https://stackoverflow.com/a/35641680/2382115

import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/ui';
import { modalsMap } from '../alerts';
import { Modal } from '../components/Modal';

const ModalRoot = (props) => {
    const onHide = useCallback(() => {
        props.dispatch(hideModal());

        if (typeof props.visibleModalProps.onHide === 'function') {
            props.visibleModalProps.onHide();
        }
    }, [props.visibleModalProps]);

    if (!props.visibleModal || !modalsMap[props.visibleModal]) {
        return null;
    }

    const VisibleModal = modalsMap[props.visibleModal];
    return <VisibleModal {...props.visibleModalProps} onHide={onHide} />;
};

ModalRoot.defaultProps = {
    dispatch: () => {},
    visibleModal: null,
    visibleModalProps: {}
};

ModalRoot.propTypes = {
    dispatch: PropTypes.func,
    visibleModal: PropTypes.string,
    visibleModalProps: PropTypes.shape({ ...Modal.propTypes })
};

const mapStateToProps = (state) => ({
    visibleModal: state.ui.visibleModal,
    visibleModalProps: state.ui.visibleModalProps
});

export default connect(mapStateToProps)(ModalRoot);
