// Actions handling domain data (data that the application needs to show, use, or modify).

import { SupportTicketType } from '@popularlab/enums';

import { actions } from '../constants';
import { IAccountState } from '../reducers/account';
import { IPolicyState } from '../reducers/policy';
import { ICoverageState } from '../reducers/coverage';
import { IBalanceState } from '../reducers/balance';
import { IQueryState } from '../reducers/query';
import { IUpdateRewardsActions } from '../reducers/rewards';
import { IProposalState } from '../reducers/proposal';
import { IPaymentState } from '../reducers/payment';
import {
    IResetCoverageSessionAction,
    ISaveCardDataAction,
    ICard,
    ISetUnitTimeScalarAction,
    IPackage,
    ISetAvailablePackagesAction,
    ISavePaymentHistoryAction,
    ISaveRegistrationProtectionPrefAction,
    ISetUnpaidFeeTotalAction
} from '../types/action.types';
import { PaymentHistoryData } from '../ui/views/PaymentHistory/PaymentHistory.types';
import { RegistrationProtectionPref } from '../ui/views/RegistrationProtection/RegistrationProtection.types';

interface ISaveAccountIdAction {
    type: typeof actions.SAVE_ACCOUNT_ID;
    payload: {
        accountId: string;
    };
}

interface ISetPhoneAction {
    type: typeof actions.SET_PHONE;
    payload: {
        phone: string | number;
    };
}

interface ISetEmailAction {
    type: typeof actions.SET_EMAIL;
    payload: {
        email: string;
    };
}

interface ISaveAccountDataAction {
    type: typeof actions.SAVE_ACCOUNT_DATA;
    payload: Partial<IAccountState>;
}

interface ISavePolicyDataAction {
    type: typeof actions.SAVE_POLICY_DATA;
    payload: Partial<IPolicyState>;
}

interface IUpdateCoverageDataAction {
    type: typeof actions.UPDATE_COVERAGE_DATA;
    payload?: Partial<ICoverageState>;
    error?: boolean;
}

interface ISavePaymentDataAction {
    type: typeof actions.SAVE_PAYMENT_DATA;
    payload: Partial<IPaymentState>;
}

interface IAddToCartAction {
    type: typeof actions.ADD_TO_CART;
    payload?: Partial<IPackage>;
    error?: boolean;
}

interface IClearCartAction {
    type: typeof actions.CLEAR_CART;
}

interface ISaveBalanceAction {
    type: typeof actions.SAVE_BALANCE_DATA;
    payload: Partial<IBalanceState>;
}

interface IResetCoverageProposalAction {
    type: typeof actions.COVERAGE_PROPOSAL_RESET;
}

interface IUpdateOpenTicketsAction {
    type: typeof actions.UPDATE_OPEN_TICKETS;
    payload: {
        openTickets: SupportTicketType[];
    };
}

interface ISaveQueryStringAction {
    type: typeof actions.SAVE_QUERY_STRING;
    payload: Partial<IQueryState>;
}

interface ISaveRewardsBalanceAction {
    type: typeof actions.SAVE_REWARD_BALANCE_DATA;
    payload: {
        rewardsBalance: number;
    };
}

interface IUpdateProposalAction {
    type: typeof actions.COVERAGE_PROPOSAL_UPDATE;
    payload: Partial<IProposalState>;
}

interface ISetPendingRewriteAction {
    type: typeof actions.SET_PENDING_REWRITE;
    payload: {
        requiresMoreInfo: boolean;
    };
}

export interface ISetPendingStateFilingRequestAction {
    type: typeof actions.SET_PENDING_STATE_FILING_REQUEST;
    payload: boolean;
}

export const saveAccountId = (accountId: string): ISaveAccountIdAction => ({
    type: actions.SAVE_ACCOUNT_ID,
    payload: { accountId }
});

export const setPhone = (phone: string | number): ISetPhoneAction => ({
    type: actions.SET_PHONE,
    payload: { phone }
});

export const setEmail = (email: string): ISetEmailAction => ({
    type: actions.SET_EMAIL,
    payload: { email }
});

export const saveAccountData = (data: Partial<IAccountState>): ISaveAccountDataAction => ({
    type: actions.SAVE_ACCOUNT_DATA,
    payload: data
});

export const savePolicyData = (data: Partial<IPolicyState>): ISavePolicyDataAction => ({
    type: actions.SAVE_POLICY_DATA,
    payload: data
});

export const updateCoverageData = (payload: Partial<ICoverageState>): IUpdateCoverageDataAction => {
    if (!payload.status) {
        // eslint-disable-next-line no-console
        console.log('updateCoverageData: Invalid data argument. Missing status prop.');
        return { type: actions.UPDATE_COVERAGE_DATA, error: true };
    }

    return { type: actions.UPDATE_COVERAGE_DATA, payload };
};

export const resetCoverageSession = (payload: {
    carrier: string;
    naic: string;
    [key: string]: any;
}): IResetCoverageSessionAction => ({ type: actions.RESET_COVERAGE_VALUES, payload });

export const saveCardData = (payload: ICard): ISaveCardDataAction => ({
    type: actions.SAVE_CARD_DATA,
    payload
});

export const savePaymentData = (payload: Partial<IPaymentState>): ISavePaymentDataAction => ({
    type: actions.SAVE_PAYMENT_DATA,
    payload
});

export const addToCart = (payload: Partial<IPackage>): IAddToCartAction => {
    if (!payload.cost || !payload.days) {
        // eslint-disable-next-line no-console
        console.log('addToCart: Invalid payload. Make sure to pass cost and days.');
        return { type: actions.ADD_TO_CART, error: true };
    }
    return { type: actions.ADD_TO_CART, payload };
};

export const clearCart = (): IClearCartAction => ({ type: actions.CLEAR_CART });

export const setUnitTimeScalar = (payload = 1): ISetUnitTimeScalarAction => ({
    type: actions.SET_UNIT_TIME_SCALAR,
    payload
});

export const saveBalanceData = (payload: Partial<IBalanceState>): ISaveBalanceAction => ({
    type: actions.SAVE_BALANCE_DATA,
    payload
});

export const setUnpaidFeeTotal = (payload: number): ISetUnpaidFeeTotalAction => ({
    type: actions.SET_UNPAID_FEE_TOTAL,
    payload
});

export const setAvailablePackages = (payload: IPackage[]): ISetAvailablePackagesAction => ({
    type: actions.SET_AVAILABLE_PACKAGES,
    payload
});

export const updateOpenTickets = (openTickets: SupportTicketType[]): IUpdateOpenTicketsAction => ({
    type: actions.UPDATE_OPEN_TICKETS,
    payload: { openTickets }
});

// Save parsed query string object into query reducer.
// Any existing data in reducer will get overwritten.
// @param {Object} result of running parseQuery(window.location.seach)
export const saveQueryStringData = (payload: Partial<IQueryState>): ISaveQueryStringAction => ({
    type: actions.SAVE_QUERY_STRING,
    payload
});

export const savePaymentHistory = (payload: PaymentHistoryData): ISavePaymentHistoryAction => ({
    type: actions.SAVE_PAYMENT_HISTORY,
    payload
});

export const saveRegistrationProtectionPref = (
    payload: RegistrationProtectionPref
): ISaveRegistrationProtectionPrefAction => ({
    type: actions.SAVE_REGISTRATION_PROTECTION_PREF,
    payload
});

export const saveRewardBalance = (payload: {
    rewardsBalance: number;
}): ISaveRewardsBalanceAction => ({ type: actions.SAVE_REWARD_BALANCE_DATA, payload });

export const updateRewards = (payload: Array<{ value: number }>): IUpdateRewardsActions => ({
    type: actions.UPDATE_REWARDS,
    payload
});

export const updateCoverageProposal = (
    payload: Partial<IProposalState>
): IUpdateProposalAction => ({ type: actions.COVERAGE_PROPOSAL_UPDATE, payload });

export const resetCoverageProposal = (): IResetCoverageProposalAction => ({
    type: actions.COVERAGE_PROPOSAL_RESET
});

export const setPendingRewrite = (requiresMoreInfo: boolean): ISetPendingRewriteAction => ({
    type: actions.SET_PENDING_REWRITE,
    payload: { requiresMoreInfo }
});

export const setPendingStateFilingRequest = (
    payload: boolean
): ISetPendingStateFilingRequestAction => ({
    type: actions.SET_PENDING_STATE_FILING_REQUEST,
    payload
});

// Clears RTQ token either from RTQ emails or Pending rewrites from the query data store
export const clearRtqQueryDataToken = (): ISaveQueryStringAction =>
    saveQueryStringData({ rtqToken: null });

// Sets the RTQ token either from RTQ emails or Pending rewrites from the query data store
// @param {String} The RTQ token to set
export const setRtqQueryDataToken = (rtqToken: string): ISaveQueryStringAction =>
    saveQueryStringData({ rtqToken });
