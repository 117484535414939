// Component for main off-canvas navigation.
// https://material.io/design/components/navigation-drawer.html

import { h, FunctionComponent } from 'preact';
import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'preact/hooks';
import classnames from 'classnames';

import { logOut } from '../../../actions/api';
import { mediaQuery } from '../../../constants';
import { normalizeTitle } from '../../../helpers/formatters';
import { closeMenu } from '../../../actions/ui';
import { routes } from '../../../routes';
import { getAccessLevelData } from '../../../selectors';
import { useMediaQuery } from '../../../hooks';
import { Button } from '../../components/Button';
import { IconClose } from '../../icons';
import { LogoShort } from '../../logo';
import { StoreShape } from '../../../types/store.types';
import RouterLink from './RouterLink';

export const LINKS = [
    routes.DASHBOARD,
    routes.ACCOUNT,
    routes.PAYMENT,
    routes.INSURANCE,
    routes.INSURANCE_MODIFY,
    routes.REWARDS,
    routes.HELP
];

export const ESCAPE_KEY = 27;

interface INavigationProps {
    className?: string;
    dispatch: (action: any) => Promise<any>;
    isMenuVisible?: boolean;
    links: { path: string; name: string }[];
}

export const Navigation: FunctionComponent<INavigationProps> = (props) => {
    const root = useRef<HTMLDivElement>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const isMobile = useMediaQuery(mediaQuery.MAX_MEDIUM);

    const trapFocus = (event: any) => {
        if (isMobile && root.current && root.current.contains(document.activeElement) === false) {
            event.preventDefault();
            root.current.focus();
        }
    };

    useEffect(() => {
        if (props.isMenuVisible) {
            document.addEventListener('focus', trapFocus, true);
            setAnchor(document.activeElement as HTMLElement);
            setTimeout(() => root.current && root.current.focus(), 0);
        } else {
            document.removeEventListener('focus', trapFocus, true);
            if (anchor instanceof HTMLElement) {
                anchor.focus();
            }
            setAnchor(null);
        }
        return () => {
            document.removeEventListener('focus', trapFocus, true);
        };
    }, [props.isMenuVisible]);

    return (
        <div
            className={classnames('c-navigation', props.className, {
                'is-visible': props.isMenuVisible
            })}
            aria-hidden={!props.isMenuVisible && isMobile}
            onKeyDown={(event) => event.which === ESCAPE_KEY && props.dispatch(closeMenu())}
            ref={root}
            tabIndex={props.isMenuVisible || !isMobile ? 0 : -1}
        >
            <div
                aria-hidden="true"
                className="c-navigation__overlay"
                onClick={() => props.dispatch(closeMenu())}
                tabIndex={-1}
            />

            <nav className="c-navigation__nav" role="navigation" id="main-navigation">
                {isMobile && (
                    <div className="u-text-right u-mb-10">
                        <Button
                            icon
                            minimal
                            className="c-navigation__close-btn"
                            onClick={() => props.dispatch(closeMenu())}
                        >
                            <IconClose title="Exit navigation" />
                        </Button>
                    </div>
                )}

                {!isMobile && (
                    <div className="c-navigation__logo">
                        <a className="u-inline-block" href={routes.DASHBOARD.path}>
                            <LogoShort width="120" className="" />
                        </a>
                    </div>
                )}

                <ul className="a-navigation-list c-navigation__list">
                    {props.links.map((link, index) => (
                        <li key={index}>
                            <RouterLink
                                className="c-navigation__item"
                                href={link.path}
                                onClick={() => props.dispatch(closeMenu())}
                            >
                                {link.name}
                            </RouterLink>
                        </li>
                    ))}
                    <li>
                        <button
                            type="button"
                            className="c-navigation__item"
                            onClick={() => props.dispatch(logOut())}
                        >
                            {normalizeTitle('Log Out')}
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

const mapStateToProps = (state: StoreShape) => {
    const access = getAccessLevelData(state);
    const links = LINKS.filter((link) => link.isAllowed(access));
    return {
        links,
        isMenuVisible: state.ui.isMenuVisible
    };
};

export default connect(mapStateToProps)(Navigation);
