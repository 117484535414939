import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

const Header: FunctionComponent<{ className?: string; sticky?: boolean }> = ({
    children,
    className,
    sticky,
    ...rest
}) => (
    <header
        className={classnames('c-header', { 'is-sticky': sticky }, className)}
        role="banner"
        {...rest}
    >
        <div className="c-header__items">{children}</div>
    </header>
);

export default Header;
