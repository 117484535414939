import { Middleware } from 'redux';

import { clearCart } from '../actions/data';
import { setMetaData } from '../actions/signup';
import { actions } from '../constants';
import { get } from '../helpers/misc';
import { isAcceptance, isAcceptanceExclusiveInternal } from '../helpers/geos';
import { StoreShape } from '../types/store.types';

export const clearCartOnSignup: Middleware<{}, StoreShape> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const { signup } = getState() as any;

        next(action);

        if (action.type === actions.SIGNUP_UPDATE) {
            if (
                get(action.payload, 'quote.dayRate') !==
                    get(signup.quoteApplication, 'quote.dayRate') &&
                signup.meta.isPackageSelected
            ) {
                dispatch(setMetaData({ isPackageSelected: false }));
                dispatch(clearCart());
            }
        }
        if (action.type === actions.SIGNUP_TEMP_QUOTE) {
            try {
                const currentDayRate = signup.tempPolicy[signup.meta.coverageBundleType].dayRate;
                const isQuoteUsingInternalRater =
                    get(signup, 'quoteApplication.data.meta.isQuoteUsingInternalRater') || false;
                if (
                    action.payload.quote.dayRate !== currentDayRate &&
                    signup.meta.isPackageSelected &&
                    isAcceptance(
                        signup.quoteApplication.state,
                        signup.quoteApplication.data.controllerVersion
                    ) &&
                    (!isQuoteUsingInternalRater ||
                        !isAcceptanceExclusiveInternal(
                            signup.quoteApplication.state,
                            signup.quoteApplication.data.controllerVersion
                        ))
                ) {
                    dispatch(setMetaData({ isPackageSelected: false }));
                    dispatch(clearCart());
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
    };
