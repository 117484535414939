// Redux container for Banner alerts.
// https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions

import { h } from 'preact';
import PropTypes from 'prop-types';

import { bannersMap } from '../alerts';

const BannerRoot = ({ visibleBanner }) => {
    if (!visibleBanner || !bannersMap[visibleBanner]) {
        return null;
    }

    const Banner = bannersMap[visibleBanner];
    return <Banner />;
};

BannerRoot.defaultProps = {
    visibleBanner: null
};

BannerRoot.propTypes = {
    visibleBanner: PropTypes.string
};

export default BannerRoot;
