import { h, FunctionComponent, Fragment } from 'preact';

import {
    IconAlertWhite,
    IconLikeWhite,
    IconInsuranceOnWhite,
    IconSnoozeWhite,
    IconAirplaneWhite
} from '../icons';
import { Modal, ModalContent, ModalIcon, ModalTitle } from '../components/Modal';

interface IModalNotificationProps {
    onHide: () => void;
    message: string | string[];
    done?: boolean;
    error?: boolean;
    off?: boolean;
    on?: boolean;
    sent?: boolean;
    icon?: FunctionComponent<{ size: string }>;
    title?: string;
}

const ModalNotification: FunctionComponent<IModalNotificationProps> = ({
    onHide,
    message,
    title,
    done = false,
    error = false,
    off = false,
    on = false,
    sent = false,
    icon: Icon
}) => (
    <Modal onHide={onHide} dark drawer>
        <ModalIcon>
            {Icon ? (
                <Icon size="32" />
            ) : (
                <Fragment>
                    {done && <IconLikeWhite size="32" />}
                    {error && <IconAlertWhite size="32" />}
                    {off && <IconSnoozeWhite size="32" />}
                    {on && <IconInsuranceOnWhite size="32" />}
                    {sent && <IconAirplaneWhite size="32" />}
                </Fragment>
            )}
        </ModalIcon>
        {title && <ModalTitle center>{title}</ModalTitle>}
        <ModalContent center>
            {Array.isArray(message) ? (
                message.map((msg, i) => <p key={i}>{msg}</p>)
            ) : (
                <p>{message}</p>
            )}
        </ModalContent>
    </Modal>
);

export default ModalNotification;
