import { h, FunctionComponent, Fragment } from 'preact';
import { connect } from 'react-redux';
import { useState } from 'preact/hooks';
import classnames from 'classnames';

import { hideModal } from '../../../actions/ui';
import { moneyString } from '../../../helpers/formatters';
import { ModalContent, ModalActions, ModalIcon, ModalTitle } from '../../components/Modal';
import { ButtonCta, Button } from '../../components/Button';
import { IconCalculator, IconCaretDown } from '../../icons';
import { Grid, GridItem } from '../../components/Grid';
import { StoreShape } from '../../../types/store.types';
import { IWithCoverageBindProps } from './coverage.types';
import withCoverageBind from './withCoverageBind';
import withLocation from './withLocation';

interface IModalProps extends IWithCoverageBindProps {
    dayRate: number;
    newDayRate: number;
}

export const RateChange: FunctionComponent<IModalProps> = (props) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className="u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-3 u-br-small">
            <Grid
                cols={{ xs: 2 }}
                gap={{ xs: 0 }}
                className="u-txt-gray-100 dark:u-txt-white u-items-center"
            >
                <GridItem>your new rate:</GridItem>
                <GridItem
                    className="u-font-bold c-link u-pointer u-flex u-items-center"
                    onClick={() => setExpanded(!expanded)}
                >
                    <span>{moneyString(props.newDayRate)} per day</span>
                    <IconCaretDown
                        className={classnames('u-font-5 u-flex-none', {
                            'is-expanded': expanded
                        })}
                    />
                </GridItem>
                <GridItem
                    span={{ xs: 2 }}
                    className={classnames('l-collapsible', { 'is-expanded': expanded })}
                >
                    <p>
                        Change in vehicle age, age of primary driver, included drivers, or fees may
                        cause changes in your rate.
                    </p>
                </GridItem>
                <GridItem className="u-muted">old rate:</GridItem>
                <GridItem className="u-muted">{moneyString(props.dayRate)} per day</GridItem>
            </Grid>
        </div>
    );
};

export const TurnOnRateChange: FunctionComponent<IModalProps> = (props) => (
    <Fragment>
        <ModalIcon>
            <IconCalculator
                className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                size="40"
            />
        </ModalIcon>

        <ModalTitle>You have a new rate</ModalTitle>

        <ModalContent>
            <p>Your rate has been updated. First, please confirm your new rate.</p>
            <RateChange {...props} />
        </ModalContent>

        <ModalActions>
            <ButtonCta
                className="track:coverage__turn-on--rate-change"
                onClick={(e) => props.onSubmit && props.onSubmit({ event: e, isTurningOn: true })}
                loading={props.loading}
            >
                Accept my new rate
            </ButtonCta>
            <Button link disabled={props.loading} onClick={() => props.dispatch(hideModal())}>
                No thanks
            </Button>
        </ModalActions>
    </Fragment>
);

const TurnOnRateChangeWithCoverageBind = withLocation(withCoverageBind(TurnOnRateChange));

const mapStateToProps = (state: StoreShape) => ({
    dayRate: state.proposal.currentDayRate,
    newDayRate: state.proposal.newDayRate
});

export default connect(mapStateToProps)(TurnOnRateChangeWithCoverageBind as FunctionComponent);
