import { compose } from 'redux';
import { connect } from 'react-redux';
import { h, FunctionComponent } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import uuid from 'uuid';

import { status as CoverageStatus, errorCodes } from '../../../constants';
import { getCoverageStatusData, getActivePolicyId, getLoadingArray } from '../../../selectors';
import { showOnModal, showApiFailModal, showOffModal } from '../../../actions/ui';
import { bindCoverageProposal, hydrateData } from '../../../actions/api';
import { StoreShape } from '../../../types/store.types';
import { ICoverageBindSubmitParameters, IHocProps, IWithCoverageBindProps } from './coverage.types';
import ReviewPaymentSettings from './ReviewPaymentSettings';
import { getDOMScreenshot } from '../../../helpers/misc';

function withCoverageBind<T extends IHocProps>(
    WrappedComponent: FunctionComponent<IWithCoverageBindProps>
): (props: T) => h.JSX.Element {
    const WithCoverageBind = (props: T) => {
        const [isBinding, setIsBinding] = useState(false);
        const idempotencyKey = uuid();
        const [showReviewPaymentSettings, setShowReviewPaymentSettings] = useState(false);

        const onBindCoverageProposal = useCallback(
            (params: ICoverageBindSubmitParameters) => {
                setIsBinding(true);

                const { consentForFallbackOff, event, isTurningOn } = params;
                let consentUpdate = consentForFallbackOff;
                if (
                    typeof consentUpdate === 'undefined' &&
                    props.status !== CoverageStatus.PAUSED
                ) {
                    // Resubmit the current consent because we haven't turned OFF yet and we aren't submitting a change
                    consentUpdate = props.currentFallbackOffConsent;
                }

                const screenshot = getDOMScreenshot(event);

                return props
                    .dispatch(
                        bindCoverageProposal(
                            props.policyId,
                            props.coverageProposalId,
                            idempotencyKey,
                            consentUpdate,
                            screenshot
                        )
                    )
                    .then(() => {
                        setIsBinding(false);

                        if (isTurningOn) {
                            return props.dispatch(
                                showOnModal({
                                    message: 'Insurance ON!'
                                })
                            );
                        }
                        return props.dispatch(
                            showOffModal({
                                message: 'Insurance scheduled to turn OFF.'
                            })
                        );
                    })
                    .catch((error) => {
                        setIsBinding(false);

                        // The front end may be out of sync,
                        // so synchronize and show the user the expected success modal
                        // if the received error matches
                        props.dispatch(hydrateData());
                        if (
                            props.status === CoverageStatus.ACTIVE &&
                            error.error === errorCodes.COVERAGE_ALREADY_SCHEDULE_OFF
                        ) {
                            props.dispatch(
                                showOffModal({
                                    message: 'Insurance scheduled to turn OFF.'
                                })
                            );
                        } else if (
                            props.status === CoverageStatus.PENDING_PAUSE &&
                            error.error === errorCodes.COVERAGE_ALREADY_ON
                        ) {
                            props.dispatch(
                                showOnModal({
                                    message: 'Insurance ON!'
                                })
                            );
                        } else {
                            props.dispatch(showApiFailModal());
                        }
                    });
            },
            [
                props.policyId,
                props.coverageProposalId,
                props.status,
                props.currentFallbackOffConsent
            ]
        );

        const nextStep = props.onSubmit ? props.onSubmit : onBindCoverageProposal;

        const onConfirmFirstStep = useCallback(
            (params: ICoverageBindSubmitParameters) => {
                // Only reconfirm payment settings when turning ON insurance. Don't need to reconfirm if
                // clearing a PENDING_OFF
                if (props.status === CoverageStatus.PAUSED && params.isTurningOn) {
                    setShowReviewPaymentSettings(true);
                    return Promise.resolve();
                }
                return nextStep(params);
            },
            [props.status]
        );

        if (showReviewPaymentSettings) {
            return (
                <ReviewPaymentSettings
                    {...props}
                    onSubmit={(params: ICoverageBindSubmitParameters) =>
                        nextStep({
                            ...params,
                            isTurningOn: true,
                            consentForFallbackOff: true
                        })
                    }
                />
            );
        }

        return (
            <WrappedComponent
                {...props}
                loading={props.loading || isBinding}
                onSubmit={onConfirmFirstStep}
            />
        );
    };

    return WithCoverageBind;
}

const mapStateToProps = (state: StoreShape) => ({
    currentFallbackOffConsent: state.payment.consentForFallbackOff,
    coverageProposalId: state.proposal.proposalId,
    loading: getLoadingArray(state).includes('coverage:bind'),
    policyId: getActivePolicyId(state),
    status: getCoverageStatusData(state).status
});

export default compose(connect(mapStateToProps), withCoverageBind);
