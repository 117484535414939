import {
    CoverageBundleType,
    StateCode,
    MaritalStatus,
    Gender,
    PolicyType
} from '@popularlab/enums';

import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import { IVehicle } from '../ui/steps/flows/flows.types';

interface IAddressState {
    street: string;
    zipCode: string;
    city: string;
    state: StateCode;
    isValidated: boolean;
    components: any;
    analysis: any;
}

interface IPniState {
    firstName: string;
    lastName: string;
    isProhibited: boolean;
    dateOfBirth: string;
    homeAddress: IAddressState;
    priorPolicyLapseInDays: null | number;
    priorPolicyRetentionInDays: null | number;
    isNewBusinessTransfer: boolean;
    maritalStatus: MaritalStatus;
    gender: Gender;
    driverLicense: string;
    ssn: string;
    violations: any[];
    lexisAdpfPullResult: any;
    lexisCluePullResult: any;
    lexisNcfPullResult: any;
}

export interface RenewalRateMeta {
    newViolationsDiscovered?: boolean;
    geoRateIncrease?: boolean;
    rateIncreaseDate?: Date;
}

export interface RenewalMeta {
    renewalNoticeSent: boolean;
    isNonRenewal: boolean;
    renewalDate?: Date | string;
    renewalRate?: number;
    renewalRateMeta?: RenewalRateMeta;
}

interface RewriteMeta {
    hasRewriteSupport?: boolean;
    shouldRewrite?: boolean;
    requiresMoreInfo?: boolean;
}

export interface IVehicleState extends IVehicle {
    make: any;
    model: any;
    trim: any;
    year: string;
    vinPattern: string;
    type: string;
    hasAntiTheft: boolean;
    securityType: string;
    coverageOptions: any;
    polkPullResult: any;
}

export interface IPolicyState {
    additionalDrivers: any[];
    bundleType: CoverageBundleType;
    coverageOptions: any | null;
    startsAt: Date | null;
    expiresAt: Date | null;
    garagingAddress: null | IAddressState;
    hasFutureEndorsement: boolean;
    homeAddress: null | IAddressState;
    id?: number;
    lastCoverageSession: null;
    policyNumber: null | number;
    controllerVersion: null | string;
    primaryInsured: null | IPniState;
    state?: StateCode;
    type?: PolicyType;
    vehicles: IVehicleState[];
    isCancelledForNonpayment: boolean;
    hasExpiredPolicy: boolean;
    partnerPolicyNumber: null | string;
    requiresMoreInfo: boolean;
    premium?: number;
    term?: number;
    renewalMeta: RenewalMeta;
    rewriteMeta: RewriteMeta;
    hasHigherLimits: boolean;
}

export const initialState: IPolicyState = {
    additionalDrivers: [],
    bundleType: CoverageBundleType.FLEX,
    coverageOptions: null,
    startsAt: null,
    expiresAt: null,
    garagingAddress: null,
    hasFutureEndorsement: false,
    homeAddress: null,
    id: undefined,
    lastCoverageSession: null,
    policyNumber: null,
    controllerVersion: null,
    primaryInsured: null,
    state: undefined,
    type: undefined,
    vehicles: [],
    isCancelledForNonpayment: false,
    hasExpiredPolicy: false,
    partnerPolicyNumber: null,
    requiresMoreInfo: false, // @TODO: move to rewriteMeta
    premium: undefined,
    rewriteMeta: {
        hasRewriteSupport: false,
        shouldRewrite: false
    },
    renewalMeta: {
        isNonRenewal: false,
        renewalNoticeSent: false
    },
    hasHigherLimits: false
};

export const actionMap = {
    [actions.SAVE_POLICY_DATA]: updateState,

    [actions.SET_PENDING_REWRITE]: updateState
};

export default createReducer(initialState, actionMap);
