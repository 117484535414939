// Watcher for idle state.

import { debounce } from 'debounce';

import store from './store';
import { trackEvents } from './constants';
import { setAppIdle, windowInFocus, saveInstallPromptEvent } from './actions/ui';

// Fire off action after 30s on inactivity.
const idleWatcher = debounce(() => {
    // requestAnimationFrame ensures it doesn't run when tab isn't active.
    // requestIdleCallback makes sure the browser isn't busy with something else.
    requestAnimationFrame(() =>
        // This timeout option for requestIdleCallback is a maximum amount of time
        // to wait. There have been a few browser bugs where idle callbacks
        // failed to trigger without this argument.
        requestIdleCallback(() => store.dispatch(setAppIdle()), { timeout: 500 })
    );
}, 30 * 1000);

store.subscribe(idleWatcher);

// Fire off action when window/tab regains focus.
window.onfocus = () => {
    store.dispatch(windowInFocus());
    window.analytics.track(trackEvents.FOCUS_GAINED);
};

window.onblur = () => {
    window.analytics.track(trackEvents.FOCUS_LOST);
};

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    const pwaInstallEvent = e;
    store.dispatch(saveInstallPromptEvent({ pwaInstallEvent }));
});
