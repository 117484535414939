import { Middleware } from 'redux';

import { dismissBanner, showBanner } from '../actions/ui';
import { actions } from '../constants';
import { getActiveBanner } from '../selectors';
import { StoreShape } from '../types/store.types';

export const displayBanner: Middleware<{}, StoreShape> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);

        if (
            action.type === actions.CHECK_FOR_ALERTS ||
            action.type === actions.SET_ACTIVE_URL ||
            action.type === actions.SET_UPDATE_AVAILABLE ||
            action.type === actions.SET_ANNOUNCEMENT
        ) {
            const state = getState();
            // If no banner to show, selector returns null.
            const banner = getActiveBanner(state);
            if (banner) {
                dispatch(showBanner(banner));
            } else if (state.ui.visibleBanner) {
                dispatch(dismissBanner());
            }
        }
    };
