// Slice of state dealing with account balance.

import { actions } from '../constants';
import { createReducer, updateState } from '../helpers/redux';
import { PaymentHistoryItem } from '../ui/views/PaymentHistory/PaymentHistory.types';
import { ISavePaymentHistoryAction } from '../types/action.types';

export interface IBalanceState {
    balance: number;
    balanceInDays: number;
    cancellationNonpaymentDate: Date | null;
    coverageExtensionStartDate?: Date;
    dueDate: Date | number | null;
    historyItems: PaymentHistoryItem[];
    historyTotal?: number;
    maxCoverageExtensionDays: number;
    maxWarningPeriodCoveredDays: number;
    minTrueUpBalance: number;
    minTrueUpBalanceInDays: number;
    onAndAutoReloadBalanceRequirement: number;
    onAndAutoReloadBalanceRequirementInDays: number;
    overdrawnBalance: number;
    coverageOptionDayRates: {
        value?: number | null;
        name?: string;
    };
    isCoverageExtensionAvailable: boolean;
    isOnCoverageExtension: boolean;
    usedCoverageExtensionDays: number;
    willEnterCoverageExtension: boolean;
}

export const initialState: IBalanceState = {
    balance: 0,
    balanceInDays: 0,
    cancellationNonpaymentDate: null,
    dueDate: null,
    historyItems: [],
    historyTotal: 0,
    maxCoverageExtensionDays: 3,
    maxWarningPeriodCoveredDays: 10,
    minTrueUpBalance: 0,
    minTrueUpBalanceInDays: 0,
    onAndAutoReloadBalanceRequirement: 0,
    onAndAutoReloadBalanceRequirementInDays: 0,
    overdrawnBalance: 0,
    coverageOptionDayRates: {},
    isCoverageExtensionAvailable: false,
    isOnCoverageExtension: false,
    usedCoverageExtensionDays: 0,
    willEnterCoverageExtension: false
};

export const actionMap = {
    [actions.SAVE_BALANCE_DATA]: updateState,

    [actions.SAVE_PAYMENT_HISTORY]: (
        state: IBalanceState,
        action: ISavePaymentHistoryAction
    ): IBalanceState => ({
        ...state,
        historyItems: [...state.historyItems, ...action.payload.items],
        historyTotal: action.payload.total
    })
};

export default createReducer(initialState, actionMap);
