import { h } from 'preact';
import classnames from 'classnames';

const LogoMin = ({ className, ...rest }) => (
    <svg
        className={classnames('c-logo c-logo--min', className)}
        fill="none"
        viewBox="0 0 70 52"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            fill="#434254"
            d="M69.1 18.79H45.822l1.767 6.943h.134a3.691 3.691 0 013.301-3.238L69.2 20.627c.466-.066.8-.434.8-.9.033-.535-.4-.935-.9-.935z"
        />
        <path
            fill="#B6DD4C"
            d="M40.553 15.453a8.548 8.548 0 00-2.068-.233c-5.47 0-11.906 2.937-16.008 6.107 0 0-8.67 15.086-10.571 16.321a30.03 30.03 0 00-.834 6.976l-4.769 4.773L3.702 52h14.74c3.035 0 5.936-.467 8.67-1.302-.833-1.869 6.104-18.89 6.304-18.99-.167-.601 2.401-7.644 3.568-8.311h3.569V18.79h1.234l-1.234-3.338z"
        />
        <path
            fill="#A0CA28"
            d="M14.44 30.906c-.7 1.335-1.3 2.737-1.8 4.206-.268.834-.534 1.669-.734 2.536a25.645 25.645 0 0113.906-4.072c-2.568-.033-10.872-2.436-11.372-2.67z"
        />
        <path
            fill="#D1F374"
            d="M28.413 27.235l-5.936-5.941-8.337-8.344c2.668 2.67 4.302 19.525 4.302 19.525 2.335.7 4.802 1.068 7.37 1.068 1.034 0 1.935-.4 2.601-1.068a3.687 3.687 0 001.068-2.603c0-1.035-.4-1.97-1.068-2.637z"
        />
        <path
            fill="#6E66DB"
            d="M18.442 23.363c0-4.072-1.634-7.743-4.302-10.413l-.033-.033L1.2 0A25.71 25.71 0 000 7.71c0 .467 0 .901.033 1.335A25.81 25.81 0 0014.44 30.906c.5.234 1 .468 1.5.668.801.334 1.635.634 2.502.868v-9.079z"
        />
        <path
            fill="#EFEFEF"
            d="M33.416 31.707c-.2.1-.367.2-.567.334-.433.267-.867.568-1.267.868-.434.334-.867.7-1.234 1.068a14.69 14.69 0 00-4.536 10.647c0 2.17.467 4.205 1.3 6.074A29.682 29.682 0 0042.42 39.651a31.263 31.263 0 002.235-3.638c-1.168.768-11.172-4.105-11.24-4.306z"
        />
        <path
            fill="#ED56A1"
            d="M47.79 23.897c-.067-.7-.167-1.401-.367-2.069a9.418 9.418 0 00-6.87-6.408v3.337c2.034 0 1.7 6.91 0 6.91-1.701 0-3.168-.702-3.569-2.304a7.21 7.21 0 00-2.801 2.77 7.299 7.299 0 00-1 3.672c0 .634.1 1.235.233 1.836.067.233.133.434.2.634.033.067.067.167.1.233.467 1.202 1.267 2.236 2.268 3.038a7.14 7.14 0 002.701 1.368c.6.167 1.234.267 1.901.267 1.5 0 2.935-.467 4.102-1.235a27.445 27.445 0 001.968-4.806c.366-1.202.667-2.47.867-3.738.1-.601.2-1.202.266-1.836v-.133c.034-.3.034-.601.034-.901 0-.2-.034-.434-.034-.635z"
        />
        <path
            fill="#fff"
            d="M44.221 22.462a3.695 3.695 0 01-3.702 3.705c-2.034 0-3.668-1.636-3.668-3.705a3.66 3.66 0 013.668-3.671c2.068 0 3.702 1.635 3.702 3.671z"
        />
        <path
            fill="#434254"
            d="M43.32 22.462a2.762 2.762 0 01-2.767 2.77 2.762 2.762 0 01-2.768-2.77 2.762 2.762 0 012.768-2.77c1.534 0 2.768 1.268 2.768 2.77z"
        />
    </svg>
);

export default LogoMin;
