import { h, FunctionComponent, Fragment } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';

import { ModalActions, ModalContent, ModalIcon, ModalTitle } from '../../components/Modal';
import { Button, ButtonCta } from '../../components/Button';
import { Tile } from '../../components/Tile';
import { dateTimeString, daysString, moneyString } from '../../../helpers/formatters';
import { get } from '../../../helpers/misc';
import { getIsAutoReloadEnabled } from '../../../selectors';
import { IconPaymentView, IconAutoReloadCircle, IconCalendar, IconBell } from '../../icons';
import { StoreShape } from '../../../types/store.types';
import { routes } from '../../../routes';
import { hideModal } from '../../../actions/ui';
import { IWithCoverageBindProps } from './coverage.types';

interface IReviewPaymentSettingsProps extends IWithCoverageBindProps {
    autoReloadEnabled: boolean;
    autoReloadTriggerDate: Date | null;
    dayRate: number;
    reloadDays: number;
    scheduledOffDate: Date;
}

export const ReviewPaymentSettings: FunctionComponent<IReviewPaymentSettingsProps> = (props) => {
    const Icon = props.autoReloadEnabled ? IconAutoReloadCircle : IconCalendar;
    const reloadAmount = props.dayRate * props.reloadDays;

    return (
        <Fragment>
            <ModalIcon>
                <IconPaymentView
                    className="u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                    size="40"
                />
            </ModalIcon>
            <ModalTitle>Review Payment Settings</ModalTitle>
            <ModalContent>
                <p>
                    <b>Okay, finally review and confirm your payment settings to turn on.</b>
                </p>
                <div className="c-media u-mb-4">
                    <Icon
                        className="u-mr-3 u-circle u-bg-gray-05 dark:u-bg-dark-gray-80 u-p-1"
                        size="40"
                    />
                    <div className="c-media__content">
                        <p className="u-font-bold u-mb-1">
                            Auto Reload: {props.autoReloadEnabled ? 'ON' : 'OFF'}
                        </p>
                        {props.autoReloadEnabled ? (
                            <div>
                                Your account will reload with {moneyString(reloadAmount)} (
                                {daysString(props.reloadDays)}) on{' '}
                                {dateTimeString(props.autoReloadTriggerDate)}
                            </div>
                        ) : (
                            <div>
                                Add money before your account hits empty on{' '}
                                {dateTimeString(props.scheduledOffDate)}
                            </div>
                        )}
                    </div>
                </div>

                <Tile bgColor="pink" className="u-txt-pink-100 dark:u-txt-white">
                    <div className="c-media">
                        <IconBell className="u-mr-2" size="32" />
                        <div className="c-media__content">
                            {props.autoReloadEnabled ? (
                                <div>
                                    If my charge fails, turn my insurance OFF on{' '}
                                    {dateTimeString(props.scheduledOffDate)} to prevent overdraft.
                                </div>
                            ) : (
                                <div>
                                    If I don’t add money before{' '}
                                    {dateTimeString(props.scheduledOffDate)}, turn my coverage OFF
                                    to prevent overdraft.
                                </div>
                            )}
                        </div>
                    </div>
                </Tile>
            </ModalContent>
            <ModalActions>
                <ButtonCta
                    className="track:review-payment-settings__confirm"
                    loading={props.loading}
                    onClick={(e) => props.onSubmit({ event: e })}
                >
                    Confirm
                </ButtonCta>
                <Button
                    className="track:review-payment-settings__edit"
                    link
                    onClick={() => {
                        props.dispatch(hideModal());
                        route(routes.PAYMENT_SETTINGS.path);
                    }}
                    disabled={props.loading}
                >
                    Edit Payment Settings
                </Button>
            </ModalActions>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreShape) => ({
    autoReloadEnabled: getIsAutoReloadEnabled(state),
    autoReloadTriggerDate: get(state, 'proposal.autoReloadTriggerDate'),
    dayRate: state.proposal.newDayRate,
    reloadDays: state.payment.reloadDays,
    scheduledOffDate: get(state, 'proposal.scheduledOffDate')
});

export default connect(mapStateToProps)(ReviewPaymentSettings);
