// Unified export for all analytics tracking events.
// String values for these events are passed and used in our analytics tools.
// NOTE: track events don't include pageviews and user.identify calls,
// those are defined in middleware/analytics.js

export default {
    FLOW_STEP_VIEWED: 'Flow Step Viewed',
    NAVIGATION: 'Navigation Step Viewed',
    HELP_CATEGORY: 'Help Category Viewed',

    ESIG_COMPLETED: 'Esig Flow Completed',
    ESIG_DECLINED: 'Esig Declined',
    ESIG_STARTED: 'Esig Documents Loaded',
    FINAL_QUOTE_ACCEPTED: 'Final Quote Accepted',
    FIRST_QUOTE_ACCEPTED: 'Initial Quote Accepted',
    FOCUS_GAINED: 'Focus Gained',
    FOCUS_LOST: 'Focus Lost',
    PRE_QUOTE: 'Pre Quote',
    PURCHASE: 'Purchase',
    QUOTE_DATA_PULL: 'Quote Data Pull',
    QUOTE_SLIDER_INTERACTION: 'Quote Slider Interaction',
    QUOTE_FLOW_STARTED: 'Quote Flow Started'
};
